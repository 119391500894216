export const STATUS_DRAFT = 'Draft';
export const STATUS_PLANNING = 'Planning';
export const STATUS_COST_ESTIMATION = 'CostEstimation';
export const STATUS_MUSKETEER_CHECK = 'MusketeerCheck';
export const STATUS_OVERSEER_CHECK = 'OverseerCheck';
export const STATUS_EXECUTION = 'Execution';
export const STATUS_REPORTING = 'Reporting';
export const STATUS_CONTROLLING = 'Controlling';
export const STATUS_FINANCIAL_CHECK = 'FinancialCheck';
export const STATUS_FINAL_ACCEPTANCE = 'FinalAcceptance';
export const STATUS_FINISHED = 'Finished';

export const STATUSES = [
  STATUS_DRAFT,
  STATUS_PLANNING,
  STATUS_COST_ESTIMATION,
  STATUS_MUSKETEER_CHECK,
  STATUS_OVERSEER_CHECK,
  STATUS_EXECUTION,
  STATUS_REPORTING,
  STATUS_CONTROLLING,
  STATUS_FINANCIAL_CHECK,
  STATUS_FINAL_ACCEPTANCE,
  STATUS_FINISHED,
];

export function isValidStatus(status) {
  return STATUSES.includes(status);
}

export function compareStatus(currentStatus, comparison, expectedStatus) {
  if (typeof expectedStatus === 'string') {
    if (comparison === '<=') {
      return STATUSES.indexOf(currentStatus) <= STATUSES.indexOf(expectedStatus);
    } else if (comparison === '>=') {
      return STATUSES.indexOf(currentStatus) >= STATUSES.indexOf(expectedStatus);
    } else if (comparison === '>') {
      return STATUSES.indexOf(currentStatus) > STATUSES.indexOf(expectedStatus);
    } else if (comparison === '<') {
      return STATUSES.indexOf(currentStatus) < STATUSES.indexOf(expectedStatus);
    } else if (comparison === '=') {
      return currentStatus === expectedStatus;
    }
  } else if (expectedStatus instanceof Array) {
    if (comparison === '=') {
      return expectedStatus.indexOf(currentStatus) !== -1;
    }
  }
}

export function isMandatoryNow(currentStatus, comparison, expectedStatus) {
  let mandatoryStarsWith = 0;
  let expectedIndex = STATUSES.indexOf(expectedStatus);
  if (comparison === '>') {
    mandatoryStarsWith = expectedIndex + 1;
  } else if (comparison === '>=') {
    mandatoryStarsWith = expectedIndex;
  }

  return STATUSES.indexOf(currentStatus) >= mandatoryStarsWith;
}

export function isMandatoryNext(currentStatus, comparison, expectedStatus) {
  let mandatoryStarsWith = 0;
  let expectedIndex = STATUSES.indexOf(expectedStatus);
  if (comparison === '>') {
    mandatoryStarsWith = expectedIndex + 1;
  } else if (comparison === '>=') {
    mandatoryStarsWith = expectedIndex;
  }

  return STATUSES.indexOf(currentStatus) + 1 >= mandatoryStarsWith;
}

export function getNextStatus(currentStatus) {
  let currentIndex = STATUSES.indexOf(currentStatus);
  return STATUSES[currentIndex + 1];
}

export function canProceedStatus(currentStatus) {
  return currentStatus !== STATUS_FINISHED;
}

export function canRejectStatus(currentStatus) {
  return [STATUS_MUSKETEER_CHECK, STATUS_OVERSEER_CHECK, STATUS_FINANCIAL_CHECK, STATUS_CONTROLLING, STATUS_FINAL_ACCEPTANCE].includes(currentStatus);
}

export function canReturnStatus(currentStatus) {
  return [STATUS_MUSKETEER_CHECK].includes(currentStatus);
}
