<template>
  <div>
    <div v-if="isReported" class="form">
      <h3>{{ $t('eventsForm.expenses.settlement.title') }}</h3>
      <div class="form-wrapper">
        <div class="columns is-multiline">
          <template v-for="item in settlementItems">
            <FormElement :key="item" :name="item" :field="form[item]" :css-class="'column is-6'" />
          </template>
        </div>
      </div>
    </div>

    <table v-if="!isAgency" class="table expenses-budget-table">
      <thead>
        <tr>
          <th>{{ $t('eventsForm.expenses.budget.titleBudget') }}</th>
          <th v-for="category in availableCategories" :key="category.id">{{ category.name }}</th>
        </tr>
      </thead>
      <tbody>
        <ExpensesBudgetInput v-for="fee in feesProminent" :key="fee.id" :fee="fee" />
        <ExpensesBudgetInput v-for="fee in feesOther" v-show="showMore" :key="fee.id" :fee="fee" />
        <tr class="toggle-row">
          <td :colspan="availableCategories.length + 1">
            <a class="show-more" @click="toggleMore">
              <span>
                <inline-svg v-if="!showMore" :src="require('../../assets/icons/arrow-down.svg')"></inline-svg>
                <inline-svg v-else :src="require('../../assets/icons/arrow-up.svg')"></inline-svg>
              </span>
              {{ !showMore ? $t('eventsForm.expenses.budget.more') : $t('eventsForm.expenses.budget.less') }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form">
      <h3>{{ $t('eventsForm.expenses.basicSettings.title') }}</h3>
      <div class="form-wrapper">
        <div class="columns is-multiline">
          <template v-for="item in basicSettings">
            <FormElement v-if="item !== 'differentStaffDifferentDays'" :key="item" :name="item" :field="form[item]" :css-class="'column is-6'" />
            <div v-else :key="item" class="form-field column is-6">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label">{{ $t(form.differentStaffDifferentDays.label) }}</label>
                </div>
                <div class="field-body">
                  <Toggle v-model="formState.differentStaffDifferentDays" :disabled="!isPlanning || isAgency" @input="onStaffDaysChange($event)" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="expenses-box-header">
      <span>{{ $t('eventsForm.expenses.costs.titleType') }}</span>
      <span>{{ $t('eventsForm.expenses.costs.titlePrice') }}</span>
    </div>

    <template v-if="staffActive">
      <ExpensesSimpleStaffBox :title="$t('eventsForm.expenses.simpleStaff.title')" />
      <template v-if="!formState.differentStaffDifferentDays">
        <ExpensesStaffBox v-for="day in listOfConstructionDays" :key="day" :title="formatStaffDay(day)" :day="day" />
        <ExpensesStaffBox
          :title="$t('eventsForm.expenses.staff.title')"
          :day="listOfEventDays[0]"
          :day-list="listOfEventDays"
          :grouped="true"
          @change="onUnifiedDayChange"
        />
        <ExpensesStaffBox v-for="day in listOfDeconstructionDays" :key="day" :title="formatStaffDay(day)" :day="day" />
      </template>
      <template v-else>
        <ExpensesStaffBox v-for="(day, index) in listOfDays" :key="day" :title="formatStaffDay(day, index)" :day="day" />
      </template>
    </template>
    <template v-else>
      <div class="expenses-box">
        <div class="expenses-box__title">
          <h3>Staff</h3>
          <span class="staff-inactive">
            {{ $t('eventsForm.expenses.staff.inactive') }}
          </span>
        </div>
      </div>
    </template>

    <ExpensesCostsBox :title="$t('eventsForm.expenses.costs.agency.title')" type="Agency" />
    <ExpensesCostsBox :title="$t('eventsForm.expenses.costs.event.title')" type="Event" />

    <div class="expenses-box">
      <div class="expenses-box__toggle">
        <button class="button is-input" :class="{ 'is-active': toggleTotal }" @click.prevent="toggleTotal = !toggleTotal">
          <span class="icon"><inline-svg :src="require('../../assets/icons/arrow-down.svg')"></inline-svg></span>
        </button>
      </div>
      <div class="expenses-box__title is-total" :class="{ 'is-open': toggleTotal }">
        <h3>{{ $t('eventsForm.expenses.agencyTotal.title') }}</h3>
        <span>
          <span>{{ costTotal | formatPrice(formState.currencyCode) }}</span>
          <span
            v-if="costTotalOther !== null && shouldHaveInitialComparison"
            class="report-difference"
            :class="$options.filters.amountDifferenceClass(costTotal, costTotalOther, isPlanning)"
            >{{ costTotalOther | amountDifference(costTotal, true, formState.currencyCode) }}</span
          >
        </span>
      </div>
      <div v-if="toggleTotal" class="expenses-box__content">
        <table class="table is-total">
          <tbody>
            <tr>
              <td>{{ $t('eventsForm.expenses.agencyTotal.helpers') }}</td>
              <td class="has-text-right">
                <span>{{ simpleStaffCostSubtotal | formatPrice(formState.currencyCode) }}</span>
                <span
                  v-if="simpleStaffCostSubtotalOther !== null && shouldHaveInitialComparison"
                  class="report-difference"
                  :class="$options.filters.amountDifferenceClass(simpleStaffCostSubtotal, simpleStaffCostSubtotalOther, isPlanning)"
                  >{{ simpleStaffCostSubtotalOther | amountDifference(simpleStaffCostSubtotal, true, formState.currencyCode) }}</span
                >
              </td>
            </tr>
            <template v-if="!formState.differentStaffDifferentDays">
              <tr v-for="day in listOfConstructionDays" :key="day">
                <td>{{ $t('eventsForm.expenses.agencyTotal.staff') }} ({{ formatDay(day) }})</td>
                <td class="has-text-right">
                  <span>{{ staffCostDaySubtotal(day) | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffCostDaySubtotalOther(day) && shouldHaveInitialComparison"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(staffCostDaySubtotal(day), staffCostDaySubtotalOther(day), isPlanning)"
                    >{{ staffCostDaySubtotalOther(day) | amountDifference(staffCostDaySubtotal(day), true, formState.currencyCode) }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('eventsForm.expenses.agencyTotal.staff') }} <strong>x{{ listOfEventDays.length }}</strong>
                </td>
                <td class="has-text-right">
                  <span>{{ staffCostSubtotalEventDays | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffCostSubtotalEventDaysOther !== null && shouldHaveInitialComparison"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(staffCostSubtotalEventDays, staffCostSubtotalEventDaysOther, isPlanning)"
                    >{{ staffCostSubtotalEventDaysOther | amountDifference(staffCostSubtotalEventDays, true, formState.currencyCode) }}</span
                  >
                </td>
              </tr>
              <tr v-for="day in listOfDeconstructionDays" :key="day">
                <td>{{ $t('eventsForm.expenses.agencyTotal.staff') }} ({{ formatDay(day) }})</td>
                <td class="has-text-right">
                  <span>{{ staffCostDaySubtotal(day) | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffCostDaySubtotalOther(day) && shouldHaveInitialComparison"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(staffCostDaySubtotal(day), staffCostDaySubtotalOther(day), isPlanning)"
                    >{{ staffCostDaySubtotalOther(day) | amountDifference(staffCostDaySubtotal(day), true, formState.currencyCode) }}</span
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="day in listOfDays" :key="day">
                <td>{{ $t('eventsForm.expenses.agencyTotal.staff') }} ({{ formatDay(day) }})</td>
                <td class="has-text-right">
                  <span>{{ staffCostDaySubtotal(day) | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffCostDaySubtotalOther(day) && shouldHaveInitialComparison"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(staffCostDaySubtotal(day), staffCostDaySubtotalOther(day), isPlanning)"
                    >{{ staffCostDaySubtotalOther(day) | amountDifference(staffCostDaySubtotal(day), true, formState.currencyCode) }}</span
                  >
                </td>
              </tr>
            </template>
            <tr>
              <td>{{ $t('eventsForm.expenses.agencyTotal.agency') }}</td>
              <td class="has-text-right">
                <span>{{ agencyCostSubtotal | formatPrice(formState.currencyCode) }}</span>
                <span
                  v-if="agencyCostSubtotalOther !== null && shouldHaveInitialComparison"
                  class="report-difference"
                  :class="$options.filters.amountDifferenceClass(agencyCostSubtotal, agencyCostSubtotalOther, isPlanning)"
                  >{{ agencyCostSubtotalOther | amountDifference(agencyCostSubtotal, true, formState.currencyCode) }}</span
                >
              </td>
            </tr>
            <tr>
              <td>{{ $t('eventsForm.expenses.agencyTotal.event') }}</td>
              <td class="has-text-right">
                <span>{{ eventCostSubtotal | formatPrice(formState.currencyCode) }}</span>
                <span
                  v-if="eventCostSubtotalOther !== null && shouldHaveInitialComparison"
                  class="report-difference"
                  :class="$options.filters.amountDifferenceClass(eventCostSubtotal, eventCostSubtotalOther, isPlanning)"
                  >{{ eventCostSubtotalOther | amountDifference(eventCostSubtotal, true, formState.currencyCode) }}</span
                >
              </td>
            </tr>
            <tr v-if="formState[`${visibleVariant}OrganizerContribution`]">
              <td>{{ $t('eventsForm.expenses.agencyTotal.organizerContribution') }}</td>
              <td class="has-text-right">- {{ formState[`${visibleVariant}OrganizerContribution`] | formatPrice(formState.currencyCode) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="form">
      <h3>{{ $t('eventsForm.expenses.notes.title') }}</h3>
      <div class="form-wrapper">
        <FormElement name="noteExpenses" class="is-note-mobile" :field="form['noteExpenses']" css-class="has-short-label" />
      </div>
    </div>
  </div>
</template>

<script>
  import Toggle from '@/components/Toggle';
  import form, { eventFormDefinition, EXPENSES_EXECUTIVE_FEE, EXPENSES_FIX, EXPENSES_REPORTING_FEE, EXPENSES_VOLUME_FEE } from '@/services/eventForm';
  import FormElement from '@/components/EventsForm/FormElement';
  import ExpensesBudgetInput from '@/components/EventsForm/ExpensesBudgetInput';
  import { isUserAgency } from '@/services/roles';
  import ExpensesStaffBox from '@/components/EventsForm/ExpensesStaffBox';
  import ExpensesCostsBox from '@/components/EventsForm/ExpensesCostsBox';
  import { compareStatus, STATUS_CONTROLLING, STATUS_PLANNING, STATUS_MUSKETEER_CHECK } from '@/services/status';
  import ExpensesSimpleStaffBox from '@/components/EventsForm/ExpensesSimpleStaffBox';

  const formatDate = date => {
    date = new Date(date);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
  };

  export default {
    name: 'Expenses',
    components: { ExpensesSimpleStaffBox, ExpensesCostsBox, ExpensesStaffBox, ExpensesBudgetInput, FormElement, Toggle },
    data() {
      return {
        form: eventFormDefinition,
        formEnums: form.enums,
        formState: form.state,
        formTemp: form.temp,

        basicSettings: [
          'expectedAmountOfBars',
          'realAmountOfBars',
          'constructionDays',
          'expectedOrganizerContribution',
          'realOrganizerContribution',
          'deconstructionDays',
          'differentStaffDifferentDays',
          'logisticsCosts',
        ],
        settlementItems: ['satisfactionExecution', 'satisfactionReporting', 'fine', 'fineReason'],

        toggleTotal: false,
        showMore: false,
      };
    },
    computed: {
      availableCategories() {
        return this.formEnums.productCategories.filter(e => e.active);
      },
      feesProminent() {
        return this.formEnums.feeTypes.filter(e => e.displayedProminently);
      },
      feesOther() {
        return this.formEnums.feeTypes.filter(e => !e.displayedProminently);
      },
      isAgency() {
        return isUserAgency();
      },
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.extraCosts.expected.visible[0], this.form.extraCosts.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.extraCosts.real.visible[0], this.form.extraCosts.real.visible[1])) {
          return 'real';
        }
        return false;
      },
      propertyVariant() {
        return this.visibleVariant === 'expected' && this.isPlanning ? 'initial' : this.visibleVariant;
      },
      actualFirstDate() {
        if (!this.formState.eventStart) return false;
        let eventStart = new Date(this.formState.eventStart);
        let construction = parseInt(this.formState.constructionDays);
        if (construction > 0) {
          eventStart.setDate(eventStart.getDate() - construction);
        }
        return formatDate(eventStart);
      },
      actualLastDate() {
        if (!this.formState.eventEnd) return false;
        let eventEnd = new Date(this.formState.eventEnd);
        eventEnd.setHours(0);
        let deconstruction = parseInt(this.formState.deconstructionDays);
        if (deconstruction > 0) {
          eventEnd.setDate(eventEnd.getDate() + deconstruction);
        }
        return formatDate(eventEnd);
      },
      staffActive() {
        return !(
          !this.formState.eventStart ||
          !this.formState.eventEnd ||
          !this.formState.sizeId ||
          !this.formState.districtCode ||
          !this.formState.wageTypeId
        );
      },
      listOfDays() {
        let list = [];
        let date = new Date(this.actualFirstDate);
        while (date <= new Date(this.actualLastDate)) {
          list.push(formatDate(date));
          let newDate = date.setDate(date.getDate() + 1);

          date = new Date(newDate);
        }
        return list;
      },
      listOfConstructionDays() {
        let list = [];
        let construction = this.formState.constructionDays;

        for (let i in this.listOfDays) {
          if (i < construction) {
            list.push(this.listOfDays[i]);
          }
        }

        return list;
      },
      listOfEventDays() {
        let list = [];
        let construction = this.formState.constructionDays;
        let deconstruction = this.formState.deconstructionDays;
        let event = this.listOfDays.length - construction - deconstruction;

        for (let i in this.listOfDays) {
          if (i >= construction && i < construction + event) {
            list.push(this.listOfDays[i]);
          }
        }

        return list;
      },
      listOfDeconstructionDays() {
        let list = [];
        let construction = this.formState.constructionDays;
        let deconstruction = this.formState.deconstructionDays;
        let event = this.listOfDays.length - construction - deconstruction;

        for (let i in this.listOfDays) {
          if (i >= construction + event) {
            list.push(this.listOfDays[i]);
          }
        }

        return list;
      },
      extraCosts() {
        return this.formState.extraCosts.map(e => {
          let costData = this.formEnums.extraCosts.find(f => f.id === e.costTypeId);
          e.type = costData.type;
          return e;
        });
      },
      eventCostSubtotal() {
        if (!this.formState.extraCosts) return null;
        let subtotal = 0;
        for (let cost of this.extraCosts.filter(e => e.type === 'Event')) {
          if (cost[`${this.propertyVariant}Amount`] !== null) {
            subtotal += parseFloat(cost[`${this.propertyVariant}Amount`]);
          }
        }
        if (this.type === 'Agency') {
          subtotal += this.agencyCost.total;
        }
        return subtotal;
      },
      eventCostSubtotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.extraCosts) return null;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let cost of this.extraCosts.filter(e => e.type === 'Event')) {
          if (cost[`${otherVariant}Amount`] !== null) {
            subtotal += parseFloat(cost[`${otherVariant}Amount`]);
          }
        }
        if (this.type === 'Agency') {
          subtotal += this.agencyCost.total;
        }
        return subtotal;
      },
      staffCostSubtotalEventDays() {
        if (!this.formState.assignedStaff) return 0;
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff.filter(e => this.listOfEventDays.includes(e.eventDate))) {
          subtotal += staff[`${this.propertyVariant}Amount`] * staff[`${this.propertyVariant}Units`] * staff[`${this.propertyVariant}UnitCost`];
        }
        return subtotal;
      },
      staffCostSubtotalEventDaysOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.assignedStaff) return 0;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff.filter(e => this.listOfEventDays.includes(e.eventDate))) {
          subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
        }
        return subtotal;
      },
      staffCostSubtotal() {
        if (!this.formState.assignedStaff) return 0;
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff) {
          subtotal += staff[`${this.propertyVariant}Amount`] * staff[`${this.propertyVariant}Units`] * staff[`${this.propertyVariant}UnitCost`];
        }
        return subtotal;
      },
      staffCostSubtotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.assignedStaff) return 0;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff) {
          subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
        }
        return subtotal;
      },
      simpleStaffCostSubtotal() {
        if (!this.formState.assignedSimpleStaff) return 0;
        let subtotal = 0;
        for (let staff of this.formState.assignedSimpleStaff) {
          subtotal += staff[`${this.propertyVariant}Amount`] * staff[`${this.propertyVariant}Units`] * staff[`${this.propertyVariant}UnitCost`];
        }
        return subtotal;
      },
      simpleStaffCostSubtotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.assignedSimpleStaff) return 0;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let staff of this.formState.assignedSimpleStaff) {
          subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
        }
        return subtotal;
      },
      agencyCost() {
        let base = this.staffCostSubtotal + this.simpleStaffCostSubtotal;

        for (let cost of this.extraCosts.filter(e => e.type === 'Agency')) {
          if (cost[`${this.propertyVariant}Amount`] !== null) {
            base += parseFloat(cost[`${this.propertyVariant}Amount`]);
          }
        }

        return {
          fix: (base * (EXPENSES_FIX * 1000)) / 1000,
          executiveFee: (((base * (EXPENSES_EXECUTIVE_FEE * 1000)) / 1000) * (form.getSatisfactionExecution(this.visibleVariant) * 1000)) / 1000,
          volumeFee: (base * (EXPENSES_VOLUME_FEE * 1000)) / 1000,
          reportingFee: (((base * (EXPENSES_REPORTING_FEE * 1000)) / 1000) * (form.getSatisfactionReporting(this.visibleVariant) * 1000)) / 1000,
          get total() {
            return this.fix + this.executiveFee + this.volumeFee + this.reportingFee;
          },
        };
      },
      agencyCostOther() {
        let base = this.staffCostSubtotalOther + this.simpleStaffCostSubtotalOther;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        for (let cost of this.extraCosts.filter(e => e.type === 'Agency')) {
          if (cost[`${otherVariant}Amount`] !== null) {
            base += parseFloat(cost[`${otherVariant}Amount`]);
          }
        }

        return {
          fix: (base * (EXPENSES_FIX * 1000)) / 1000,
          executiveFee: (((base * (EXPENSES_EXECUTIVE_FEE * 1000)) / 1000) * (form.getSatisfactionExecution(otherVariant) * 1000)) / 1000,
          volumeFee: (base * (EXPENSES_VOLUME_FEE * 1000)) / 1000,
          reportingFee: (((base * (EXPENSES_REPORTING_FEE * 1000)) / 1000) * (form.getSatisfactionReporting(otherVariant) * 1000)) / 1000,
          get total() {
            return this.fix + this.executiveFee + this.volumeFee + this.reportingFee;
          },
        };
      },
      agencyCostSubtotal() {
        if (!this.formState.extraCosts) return null;
        let subtotal = 0;
        for (let cost of this.extraCosts.filter(e => e.type === 'Agency')) {
          if (cost[`${this.propertyVariant}Amount`] !== null) {
            subtotal += parseFloat(cost[`${this.propertyVariant}Amount`]);
          }
        }
        subtotal += this.agencyCost.total;
        return subtotal;
      },
      agencyCostSubtotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.extraCosts) return null;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let cost of this.extraCosts.filter(e => e.type === 'Agency')) {
          if (cost[`${otherVariant}Amount`] !== null) {
            subtotal += parseFloat(cost[`${otherVariant}Amount`]);
          }
        }
        subtotal += this.agencyCostOther.total;
        return subtotal;
      },
      costTotal() {
        let organizer = this.formState[`${this.visibleVariant}OrganizerContribution`] || 0;
        return this.staffCostSubtotal + this.simpleStaffCostSubtotal + this.agencyCostSubtotal + this.eventCostSubtotal - parseFloat(organizer);
      },
      costTotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (this.staffCostSubtotalOther !== null || this.eventCostSubtotalOther !== null) {
          let organizer = this.formState[`${this.visibleVariant}OrganizerContribution`] || 0;
          let cost =
            this.staffCostSubtotalOther +
            this.simpleStaffCostSubtotalOther +
            this.agencyCostSubtotalOther +
            this.eventCostSubtotalOther -
            parseFloat(organizer);
          if (cost < 0) cost = 0;
          return cost;
        }
        return null;
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      isPlanning() {
        return compareStatus(this.formState.status, '<=', STATUS_PLANNING);
      },
      shouldHaveInitialComparison() {
        let a = this.formState.assignedStaff.find(e => e.initialUnitCost !== null && e.expectedUnitCost !== null);
        let b = this.formState.extraCosts.find(e => e.initialAmount !== null && e.expectedAmount !== null && e.expectedAmount !== 0);
        return typeof a !== 'undefined' && typeof b !== 'undefined';
      },
    },
    watch: {
      'formState.eventStart': function(a, b) {
        this.changedDays(a, b, 'eventStart');
      },
      'formState.eventEnd': function(a, b) {
        this.changedDays(a, b, 'eventEnd');
      },
      'formState.constructionDays': function(a, b) {
        this.changedDays(a, b, 'constructionDays');
      },
      'formState.deconstructionDays': function(a, b) {
        this.changedDays(a, b, 'deconstructionDays');
      },
      'formState.expectedAmountOfBars': 'handleBars',
    },
    methods: {
      toggleMore() {
        this.showMore = !this.showMore;
      },

      formatStaffDay(day, index) {
        if (!index) index = this.listOfDays.indexOf(day);
        day = new Date(day);
        let construction = this.formState.constructionDays;
        let deconstruction = this.formState.deconstructionDays;
        let event = this.listOfDays.length - construction - deconstruction;
        if (index < construction) return this.$t('eventsForm.expenses.staff.construction') + ` (${day.toLocaleDateString()})`;
        if (index < construction + event) return this.$t(`common.days.${day.getDay()}`) + ` (${day.toLocaleDateString()})`;
        if (index < construction + event + deconstruction) return this.$t('eventsForm.expenses.staff.deconstruction') + ` (${day.toLocaleDateString()})`;
      },

      formatDay(day) {
        day = new Date(day);
        return day.toLocaleDateString();
      },

      getStaff(id) {
        return this.formEnums.staffTypes.find(e => e.id === id);
      },

      onStaffDaysChange(value) {
        if (value === false) {
          this.unifyStaffDays();
        }
      },

      unifyStaffDays() {
        let dayOne = this.formState.assignedStaff.filter(e => {
          return e.eventDate === this.listOfEventDays[0];
        });

        let newAssignedStaff = [];

        for (let day of this.listOfEventDays) {
          for (let staff of dayOne) {
            let newStaff = { ...staff, eventDate: day };
            if (newStaff.id) delete newStaff.id;
            newAssignedStaff.push(newStaff);
          }
        }

        this.$set(this.formState, 'assignedStaff', newAssignedStaff);
      },

      onUnifiedDayChange() {
        let dataFields = [
          'initialAmount',
          'initialUnits',
          'initialUnitCost',
          'expectedAmount',
          'expectedUnits',
          'expectedUnitCost',
          'realAmount',
          'realUnits',
          'realUnitCost',
        ];

        let dayOne = this.formState.assignedStaff.filter(e => {
          return e.eventDate === this.listOfEventDays[0];
        });

        let modified = [];

        for (let day of this.listOfEventDays) {
          if (day !== this.listOfEventDays[0]) {
            for (let copyStaff of dayOne) {
              let staff = this.formState.assignedStaff.find(e => e.staffTypeId === copyStaff.staffTypeId && e.eventDate === day && modified.indexOf(e) === -1);
              if (staff) {
                modified.push(staff);
                for (let field of dataFields) {
                  staff[field] = copyStaff[field];
                }
              }
            }
          }
        }
      },

      changedDays(newValue, oldValue, type) {
        if (this.formState.eventStart && this.formState.eventEnd && this.formTemp.staff && this.formTemp.staff.length > 0) {
          this.formState.differentStaffDifferentDays = this.formTemp.diffStaff;
          let copyDays = [];
          for (let staff of this.formTemp.staff) {
            if (copyDays.indexOf(staff.eventDate) === -1) {
              copyDays.push(staff.eventDate);
            }
          }
          if (this.formState.differentStaffDifferentDays === true) {
            copyDays.sort((x, y) => Date.parse(x) - Date.parse(y));
            let copied = 0;
            for (let day of this.listOfDays) {
              for (let staff of this.formTemp.staff.filter(e => e.eventDate === copyDays[copied])) {
                this.formState.assignedStaff.push({ ...staff, ...{ eventDate: day } });
              }
              copied += 1;
            }
          } else {
            for (let day of this.listOfDays) {
              for (let staff of this.formTemp.staff.filter(e => e.eventDate === copyDays[0])) {
                this.formState.assignedStaff.push({ ...staff, ...{ eventDate: day } });
              }
            }
          }

          this.formTemp.staff = [];
          type = 'backfill';
        }

        if (this.formState.assignedStaff.length > 0) {
          if (type === 'eventStart') {
            let newDate = new Date(newValue);
            newDate.setHours(0);
            let newDateUnix = newDate.getTime();

            let oldDate = new Date(oldValue);
            oldDate.setHours(0);
            let oldDateUnix = oldDate.getTime();

            //let newDateISO = formatDate(newDate);
            let oldDateISO = formatDate(oldDate);

            let diff = (oldDateUnix - newDateUnix) / (1000 * 3600 * 24);

            let construction = parseInt(this.formState.constructionDays);
            let constructionDays = [];
            if (construction > 0) {
              for (let i = 1; i <= construction; i++) {
                let date = new Date(oldDate);
                let constructionDay = new Date(date.setDate(date.getDate() - i));
                constructionDays.push(constructionDay);
              }
            }

            if (diff > 0) {
              let copyDay = this.formState.assignedStaff.filter(e => e.eventDate === oldDateISO);
              let constructionData = {};
              if (constructionDays.length > 0) {
                for (let i = 0; i < constructionDays.length; i++) {
                  constructionData[i] = this.formState.assignedStaff.filter(e => e.eventDate === formatDate(constructionDays[i]));
                  this.formState.assignedStaff = this.formState.assignedStaff.filter(e => e.eventDate !== formatDate(constructionDays[i]));
                }
              }

              for (let i = 1; i <= diff; i++) {
                let date = new Date(oldDate);
                let copyTo = formatDate(new Date(date.setDate(date.getDate() - i)));
                for (let staff of copyDay) {
                  if (staff.id) delete staff.id;
                  this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                }
              }

              if (constructionDays.length > 0) {
                for (let i = 0; i < constructionDays.length; i++) {
                  let date = new Date(newDate);
                  let copyTo = formatDate(new Date(date.setDate(date.getDate() - i - 1)));
                  for (let staff of constructionData[i]) {
                    this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                  }
                }
              }
            } else if (diff < 0) {
              let constructionData = {};
              if (constructionDays.length > 0) {
                for (let i = 0; i < constructionDays.length; i++) {
                  constructionData[i] = this.formState.assignedStaff.filter(e => e.eventDate === formatDate(constructionDays[i]));
                  this.formState.assignedStaff = this.formState.assignedStaff.filter(e => e.eventDate !== formatDate(constructionDays[i]));
                }
              }

              for (let i = -1; i >= diff; i--) {
                let date = new Date(oldDate);
                let deleteDay = formatDate(new Date(date.setDate(date.getDate() - i - 1)));
                this.formState.assignedStaff = this.formState.assignedStaff.filter(e => e.eventDate !== deleteDay);
              }

              if (constructionDays.length > 0) {
                for (let i = 0; i < constructionDays.length; i++) {
                  let date = new Date(newDate);
                  let copyTo = formatDate(new Date(date.setDate(date.getDate() - i - 1)));
                  for (let staff of constructionData[i]) {
                    this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                  }
                }
              }
            }
          }
          if (type === 'eventEnd') {
            let newDate = new Date(newValue);
            newDate.setHours(0);
            let newDateUnix = newDate.getTime();

            let oldDate = new Date(oldValue);
            oldDate.setHours(0);
            let oldDateUnix = oldDate.getTime();

            //let newDateISO = formatDate(newDate);
            let oldDateISO = formatDate(oldDate);

            let diff = (newDateUnix - oldDateUnix) / (1000 * 3600 * 24);

            let deconstruction = parseInt(this.formState.deconstructionDays);
            let deconstructionDays = [];
            if (deconstruction > 0) {
              for (let i = 1; i <= deconstruction; i++) {
                let date = new Date(oldDate);
                let deconstructionDay = new Date(date.setDate(date.getDate() + i));
                deconstructionDays.push(deconstructionDay);
              }
            }

            if (diff > 0) {
              let copyDay = this.formState.assignedStaff.filter(e => e.eventDate === oldDateISO);
              let deconstructionData = {};
              if (deconstructionDays.length > 0) {
                for (let i = 0; i < deconstructionDays.length; i++) {
                  deconstructionData[i] = this.formState.assignedStaff.filter(e => e.eventDate === formatDate(deconstructionDays[i]));
                  this.formState.assignedStaff = this.formState.assignedStaff.filter(e => e.eventDate !== formatDate(deconstructionDays[i]));
                }
              }

              for (let i = 1; i <= diff; i++) {
                let date = new Date(oldDate);
                let copyTo = formatDate(new Date(date.setDate(date.getDate() + i)));
                for (let staff of copyDay) {
                  if (staff.id) delete staff.id;
                  this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                }
              }

              if (deconstructionDays.length > 0) {
                for (let i = 0; i < deconstructionDays.length; i++) {
                  let date = new Date(newDate);
                  let copyTo = formatDate(new Date(date.setDate(date.getDate() + i + 1)));
                  for (let staff of deconstructionData[i]) {
                    this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                  }
                }
              }
            } else if (diff < 0) {
              let deconstructionData = {};
              if (deconstructionDays.length > 0) {
                for (let i = 0; i < deconstructionDays.length; i++) {
                  deconstructionData[i] = this.formState.assignedStaff.filter(e => e.eventDate === formatDate(deconstructionDays[i]));
                  this.formState.assignedStaff = this.formState.assignedStaff.filter(e => e.eventDate !== formatDate(deconstructionDays[i]));
                }
              }

              for (let i = -1; i >= diff; i--) {
                let date = new Date(oldDate);
                let deleteDay = formatDate(new Date(date.setDate(date.getDate() + i + 1)));
                this.formState.assignedStaff = this.formState.assignedStaff.filter(e => e.eventDate !== deleteDay);
              }

              if (deconstructionDays.length > 0) {
                for (let i = 0; i < deconstructionDays.length; i++) {
                  let date = new Date(newDate);
                  let copyTo = formatDate(new Date(date.setDate(date.getDate() + i + 1)));
                  for (let staff of deconstructionData[i]) {
                    this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                  }
                }
              }
            }
          }
          if (type === 'deconstructionDays') {
            let diff = newValue - oldValue;

            if (diff > 0) {
              /* let ddate = this.formState.eventEnd;

              if (oldValue > 0) {
                let date = new Date(ddate);
                ddate = formatDate(new Date(date.setDate(date.getDate() + oldValue)));
              }

              let copyDay = this.formState.assignedStaff.filter(e => e.eventDate === ddate);

              for (let i = 1; i <= diff; i++) {
                let date = new Date(ddate);
                let copyTo = formatDate(new Date(date.setDate(date.getDate() + i)));
                for (let staff of copyDay) {
                  if (staff.id) delete staff.id;
                  this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                }
              }*/
            } else if (diff < 0) {
              this.formState.assignedStaff = this.formState.assignedStaff.filter(e => this.listOfDays.some(d => e.eventDate === d));
            }
          }
          if (type === 'constructionDays') {
            let diff = newValue - oldValue;

            if (diff > 0) {
              /* let ddate = this.formState.eventStart;

              if (oldValue > 0) {
                let date = new Date(ddate);
                ddate = formatDate(new Date(date.setDate(date.getDate() - oldValue)));
              }

              let copyDay = this.formState.assignedStaff.filter(e => e.eventDate === ddate);

              for (let i = 1; i <= diff; i++) {
                let date = new Date(ddate);
                let copyTo = formatDate(new Date(date.setDate(date.getDate() - i)));
                for (let staff of copyDay) {
                  if (staff.id) delete staff.id;
                  this.formState.assignedStaff.push({ ...staff, ...{ eventDate: copyTo } });
                }
              }*/
            } else if (diff < 0) {
              this.formState.assignedStaff = this.formState.assignedStaff.filter(e => this.listOfDays.some(d => e.eventDate === d));
            }
          }
        }
      },

      staffCostDaySubtotal(day) {
        if (!this.formState.assignedStaff) return 0;
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff.filter(e => e.eventDate === day)) {
          subtotal += staff[`${this.propertyVariant}Amount`] * staff[`${this.propertyVariant}Units`] * staff[`${this.propertyVariant}UnitCost`];
        }
        return subtotal;
      },

      staffCostDaySubtotalOther(day) {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.assignedStaff) return 0;
        let subtotal = 0;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        for (let staff of this.formState.assignedStaff.filter(e => e.eventDate === day)) {
          subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
        }
        return subtotal;
      },

      handleBars() {
        if (!this.formState.expectedAmountOfBars) this.formState.expectedAmountOfBars = 0;
      },
    },
  };
</script>
