<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.activities.searchButton')" />
      </p>
    </div>

    <div>
      <div v-if="!loading">
        <div class="columns">
          <div class="column is-8">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.activities.form.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.activities.form.headers.headerTwo') }}</th>
                  <th>{{ $t('admin.tabs.activities.form.headers.headerThree') }}</th>
                  <th colspan="2">{{ $t('admin.tabs.activities.form.headers.headerFour') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(activity, index) in filteredActivities" :key="activity.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="activity.name" :readonly="!activity.isEditing" class="input" />
                  </td>
                  <td>
                    <input v-model="activity.unitType" :readonly="!activity.isEditing" class="input" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="activity.expectedAmountRequired" :disabled="!activity.isEditing" type="icon" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="activity.realAmountRequired" :disabled="!activity.isEditing" type="icon" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!activity.isEditing" class="button is-input" @click="editItem(activity.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(activity)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(activity)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="5"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Toggle from '@/components/Toggle';
  import * as activitiesService from '@/api/activitiesService';
  import { searchTerm } from '@/filters';

  export default {
    name: 'AdminActivities',
    components: { Toggle },
    data() {
      return {
        loading: false,
        filterText: null,
        activities: [],
      };
    },
    computed: {
      filteredActivities() {
        if (this.filterText && this.filterText !== '') {
          return this.activities.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.activities;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await Promise.all([this.loadActivities()]);
      },
      editItem(id) {
        this.$set(
          this.activities.find(item => item.id === id),
          'isEditing',
          true
        );
      },
      addItem() {
        this.activities.push({ name: '', isEditing: true });
      },
      processItem(data) {
        data.id ? this.updateItem(data) : this.setNewItem(data);
      },
      async updateItem({ id, name, expectedAmountRequired, realAmountRequired, unitType }) {
        try {
          this.loading = true;
          const res = await activitiesService.updateActivity(id, {
            name,
            expectedAmountRequired: expectedAmountRequired ? expectedAmountRequired : false,
            realAmountRequired: realAmountRequired ? realAmountRequired : false,
            unitType,
            active: true,
          });
          if (res.status === 200) await this.loadActivities();
        } finally {
          this.loading = false;
        }
      },
      async deleteItem({ id, name, expectedAmountRequired, realAmountRequired, unitType }) {
        if (!id) {
          this.activities.splice(-1, 1);
        } else {
          try {
            this.loading = true;
            const res = await activitiesService.updateActivity(id, {
              name,
              expectedAmountRequired: expectedAmountRequired ? expectedAmountRequired : false,
              realAmountRequired: realAmountRequired ? realAmountRequired : false,
              unitType,
              active: false,
            });
            if (res.status === 200) await this.loadActivities();
          } finally {
            this.loading = false;
          }
        }
      },
      async setNewItem({ name, expectedAmountRequired, realAmountRequired, unitType }) {
        if (name) {
          try {
            this.loading = true;
            const res = await activitiesService.createNewActivity({
              name,
              expectedAmountRequired: expectedAmountRequired ? expectedAmountRequired : false,
              realAmountRequired: realAmountRequired ? realAmountRequired : false,
              unitType,
            });
            if (res.status === 201) await this.loadActivities();
          } finally {
            this.loading = false;
          }
        }
      },
      async loadActivities() {
        try {
          this.loading = true;
          const activities = await activitiesService.getActivities('active');
          this.activities = activities.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
