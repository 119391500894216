<template>
  <vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" v-on="$listeners">
    <div class="confirmation-modal">
      <p>{{ $t('eventsForm.rejectEventConfirm.message') }}</p>
      <div class="buttons">
        <button class="button is-warning" @click="$emit('confirm', close)">
          {{ $t('eventsForm.rejectEventConfirm.confirm') }}
        </button>
        <button class="button is-primary" @click="$emit('cancel', close)">
          {{ $t('eventsForm.rejectEventConfirm.deny') }}
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
  export default {
    name: 'EventRejectConfirm',
    inheritAttrs: false,
  };
</script>
