import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getHostedUsers() {
  try {
    const res = await http.get('/HostedUser/all?withPhoto=false');
    return res.data.data.map(item => ({ ...item, name: `${item?.firstName || ''} ${item?.lastName || ''}` }));
  } catch (e) {
    console.log(e);
  }
}

export async function getAllLogisticCosts() {
  try {
    const res = await http.get(`/HostedUser/all/logistics-costs`);
    return res.data.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getHostedUserLogisticsCosts(id) {
  try {
    const res = await http.get(`/HostedUser/${id}/logistics-costs`);
    return res.data.logisticsCosts;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateUserLogisticCost(id, data) {
  try {
    const res = await http.put(`/HostedUser/${id}/logistics-costs`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
