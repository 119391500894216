<template>
  <div :class="{ 'is-loading': loading }">
    <ValidationObserver ref="observer" tag="form" class="form" novalidate @submit.prevent="submit">
      <div class="page-head is-event">
        <h1 class="title is-2">
          <ValidationProvider v-slot="{ errors }" name="eventName" :rules="{ required: true }">
            <input
              v-model="formState.name"
              type="text"
              class="title-input is-hidden-touch"
              :class="{ 'is-warning': errors[0] }"
              :placeholder="$t('eventsForm.title')"
              data-lpignore="true"
            />
            <textarea
              v-model="formState.name"
              type="text"
              class="title-input is-hidden-desktop"
              :class="{ 'is-warning': errors[0] }"
              :placeholder="$t('eventsForm.title')"
              data-lpignore="true"
            ></textarea>
            <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="mobile-status is-hidden-desktop">
            <EventStatus :status="formState.status" />
          </div>
        </h1>

        <div class="actions" :class="{ 'is-disabled': isCancelled }">
          <EventStatus class="is-hidden-touch" :status="formState.status" />

          <button class="button is-primary" type="submit">
            <span v-if="!eventSaving" class="icon">
              <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t('eventsForm.saveEvent') }}</span>
          </button>

          <button
            v-if="hasProceedButton"
            class="button is-secondary"
            :disabled="!proceedButtonEnabled"
            :class="{ 'has-tooltip-top has-tooltip-arrow has-tooltip-danger': !proceedButtonEnabled }"
            :data-tooltip="!proceedButtonEnabled && $t('events.unauthorized.proceed')"
            @click.prevent="eventProceed"
          >
            <span v-if="!eventSavingProceed" class="icon">
              <inline-svg :src="require('../assets/icons/form.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t(`events.proceed.${formState.status}`) }}</span>
          </button>

          <ValidationProvider vid="proceedSave">
            <input v-model="proceedSave" type="hidden" name="proceedSave" />
          </ValidationProvider>

          <button
            v-if="hasRejectButton"
            class="button is-warning"
            :disabled="!rejectButtonEnabled"
            :class="{ 'has-tooltip-top has-tooltip-arrow has-tooltip-danger': !rejectButtonEnabled }"
            :data-tooltip="!rejectButtonEnabled && $t('events.unauthorized.reject')"
            @click.prevent="eventReject"
          >
            <span v-if="!eventSavingReject" class="icon">
              <inline-svg :src="require('../assets/icons/cross.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t(`events.reject.${formState.status}`) }}</span>
          </button>

          <button
            v-if="hasReturnButton"
            class="button is-warning"
            :disabled="!returnButtonEnabled"
            :class="{ 'has-tooltip-top has-tooltip-arrow has-tooltip-danger': !returnButtonEnabled }"
            :data-tooltip="!returnButtonEnabled && $t('events.unauthorized.return')"
            @click.prevent="eventReturn"
          >
            <span v-if="!eventSavingReturn" class="icon">
              <inline-svg :src="require('../assets/icons/cross.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t(`events.return.${formState.status}`) }}</span>
          </button>

          <button class="button is-secondary" :disabled="mode !== 'edit'" @click.prevent="downloadBrief">
            <span v-if="!briefDownloading" class="icon">
              <inline-svg :src="require('../assets/icons/cloud-download.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t('eventsForm.downloadBrief') }}</span>
          </button>

          <button v-if="isCancelable" class="button is-warning" @click.prevent="eventCancel">
            <span class="icon">
              <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
            </span>
            <span>{{ $t('eventsForm.cancelEvent') }}</span>
          </button>

          <button v-if="isRejectable" class="button is-warning" @click.prevent="eventAgencyReject">
            <span class="icon">
              <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
            </span>

            <span>{{ $t('eventsForm.rejectEvent') }}</span>
          </button>
        </div>
      </div>

      <div class="page-tabs">
        <div class="tabs">
          <ul>
            <li v-for="tab in tabList" :key="tab" :class="{ 'is-active': active === tab, 'is-invalid': tabListInvalid.includes(tab) }">
              <a @click="active = tab">{{ $t(`eventsForm.tabs.${tab}`) }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="!loading" class="page-content">
        <BasicInfo v-show="active === 'basicInfo'" ref="basicInfo" />
        <Expenses v-show="active === 'expenses'" />
        <Products v-show="active === 'products'" />
        <Activities v-show="active === 'activities'" />
        <Hardware v-show="active === 'hardware'" />
        <Inventory v-show="active === 'inventory'" />
        <EventBalance v-show="active === 'eventBalance'" />
        <PhotoGallery v-if="!isCancelled && active === 'photos'" />

        <Comments v-if="!isCancelled" />
      </div>
    </ValidationObserver>
    <Loader v-if="loading" />
  </div>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';

  import form from '@/services/eventForm';
  import { canProceedStatus, canRejectStatus, canReturnStatus, compareStatus, isValidStatus, STATUS_REPORTING } from '@/services/status';

  import EventStatus from '@/components/EventStatus';
  import Expenses from '@/components/EventsForm/Expenses';
  import Products from '@/components/EventsForm/Products';
  import BasicInfo from '@/components/EventsForm/BasicInfo';
  import Activities from '@/components/EventsForm/Activities';
  import Hardware from '@/components/EventsForm/Hardware';
  import { isUserAgency } from '@/services/roles';
  import Loader from '@/components/Loader';
  import Inventory from '@/components/EventsForm/Inventory';
  import EventBalance from '@/components/EventsForm/EventBalance';
  import PhotoGallery from '@/components/EventsForm/PhotoGallery';
  import Comments from '@/components/EventsForm/Comments';
  import http from '@/services/http';
  import EventRejectConfirm from '@/components/EventsForm/EventRejectConfirm';
  import EventCancelModal from '@/components/EventsForm/EventCancelModal';
  import EventErrorModal from "@/components/EventsForm/EventErrorModal";

  const addressInputs = ['addressCity', 'addressDistrict', 'addressName', 'addressPostCode', 'addressStreet', 'organisedInCode'];

  const formValidation = {
    basicInfo: [
      'addressCity',
      'addressDistrict',
      'addressName',
      'addressPostCode',
      'addressStreet',
      'agencyId',
      'categoryId',
      'companyName',
      'contactRole',
      'contractTypeId',
      'doorClose',
      'doorOpen',
      'eventEnd',
      'eventName',
      'eventStart',
      'expectedAttendance',
      'expectedEarnings',
      'realAttendance',
      'realEarnings',
      'eventActivity',
      'averageAge',
      'expectedConstructionStart',
      'realConstructionStart',
      'ownCashiers',
      'organisedByUserId',
      'organisedInCode',
      'overseerUserId',
      'supervisorUserId',
      'invoicingCompanyId',
      'segmentId',
      'sizeId',
      'tips',
      'typeId',
      'wageTypeId',
      'wholeSalerId',
      'evaluation',
    ],
    expenses: [
      'expectedAmountOfBars',
      'realAmountOfBars',
      'expectedOrganizerContribution',
      'realOrganizerContribution',
      'constructionDays',
      'deconstructionDays',
      'logisticsCosts',
      'feesexpectedAmount',
      'feesrealAmount',
      'costexpectedAmount',
      'costrealAmount',
      'satisfactionExecution',
      'satisfactionReporting',
    ],
    products: [
      'expectedPriceRedbullMix',
      'realPriceRedbullMix',
      'expectedPriceOrganicsMix',
      'realPriceOrganicsMix',
      'priceBeer',
      'offeredProducts',
      'productexpectedFgtMarketing',
      'productexpectedFgtOnp',
      'productexpectedPrice',
      'productexpectedSale',
      'productrealFgtMarketing',
      'productrealFgtOnp',
      'productrealPrice',
      'productrealSale',
    ],
    activities: ['activityexpectedAmount', 'activityrealAmount'],
    hardware: ['hardwares'],
    inventory: ['totalSales', 'inventories', 'inventoryFile'],
  };

  export default {
    name: 'EventsForm',
    components: {
      PhotoGallery,
      Comments,
      Inventory,
      EventBalance,
      Loader,
      Hardware,
      Activities,
      BasicInfo,
      ValidationObserver,
      ValidationProvider,
      EventRejectConfirm,

      Expenses,
      EventStatus,
      Products,
    },
    data() {
      return {
        tabList: ['basicInfo', 'expenses', 'products', 'activities', 'hardware', 'eventBalance', 'photos'],
        tabListInvalid: [],
        loading: true,
        active: 'basicInfo',
        formState: {},
        proceedSave: false,
        eventSaving: false,
        eventSavingProceed: false,
        eventSavingReject: false,
        eventSavingReturn: false,
        briefDownloading: false,
      };
    },
    computed: {
      mode() {
        return this.$route.params.id ? 'edit' : 'add';
      },
      hasProceedButton() {
        return isValidStatus(this.formState.status) && canProceedStatus(this.formState.status);
      },
      proceedButtonEnabled() {
        return form.canProceedEvent();
      },
      hasRejectButton() {
        return isValidStatus(this.formState.status) && canRejectStatus(this.formState.status);
      },
      rejectButtonEnabled() {
        return form.canRejectEvent();
      },
      hasReturnButton() {
        return isValidStatus(this.formState.status) && canReturnStatus(this.formState.status);
      },
      returnButtonEnabled() {
        return form.canReturnEvent();
      },
      isAgency() {
        return isUserAgency();
      },
      isReporting() {
        return compareStatus(this.formState.status, '>=', STATUS_REPORTING);
      },
      isCancelable() {
        return this.formState.cancelable && !this.formState.isCancelled;
      },
      isRejectable() {
        return this.formState.rejectable;
      },
      isCancelled() {
        return this.formState.isCancelled;
      },
    },
    async mounted() {
      if (this.$route.params.duplicate) {
        await this.copyEvent();
      } else {
        await this.fetchData();
      }

      if (this.mode !== 'edit' || this.isCancelled) {
        this.tabList.splice(this.tabList.indexOf('photos'), 1);
      }
      if (this.isAgency) {
        this.tabList.splice(this.tabList.indexOf('eventBalance'), 1);
      }
      if (this.isReporting) {
        if (this.tabList.indexOf('inventory') === -1) {
          this.tabList.splice(this.tabList.indexOf('photos'), 0, 'inventory');
        }
      }
    },
    beforeRouteUpdate(to, from, next) {
      next();
    },
    beforeMount() {
      form.clearState();
    },
    methods: {
      submit() {
        this.proceedSave = false;
        this.eventSaving = true;
        this.tabListInvalid = [];
        this.$nextTick(() => {
          this.$refs.observer.validateWithInfo().then(async ({ isValid, errors }) => {
            if (isValid) {
              try {
                const id = await form.saveEvent();
                this.$refs.observer.reset();
                if (this.mode === 'add' && id) {
                  this.$router.push({ name: 'EditEvent', params: { id } });
                }
                window.setTimeout(() => {
                  this.eventSaving = false;
                }, 200);
              } catch (e) {
                this.eventSaving = false;

                this.$vfm.show({
                  component: EventErrorModal,
                  bind: {
                    error: e,
                  },
                  on: {
                    cancel(close) {
                      close();
                    },
                    async confirm(type, note, close) {
                      const loaded = await form.cancelEvent(type, note);
                      close();
                      if (!loaded) rtr.push({ name: 'Events' });
                    },
                  },
                });
              }
            } else {
              window.setTimeout(() => {
                this.eventSaving = false;
              }, 200);
              this.validationHelper(errors);
            }
          });
        });
      },
      eventProceed() {
        this.proceedSave = true;
        this.eventSavingProceed = true;
        this.tabListInvalid = [];
        this.$nextTick(() => {
          this.$refs.observer.validateWithInfo().then(async ({ isValid, errors }) => {
            if (isValid) {
              const id = await form.saveEvent(true);
              this.$refs.observer.reset();
              if (this.mode === 'add') {
                this.$router.push({ name: 'EditEvent', params: { id } });
              }
              window.setTimeout(() => {
                this.eventSavingProceed = false;
              }, 200);
              if (this.isReporting) {
                if (this.tabList.indexOf('inventory') === -1) {
                  this.tabList.splice(this.tabList.indexOf('photos'), 0, 'inventory');
                }
              }
            } else {
              window.setTimeout(() => {
                this.eventSavingProceed = false;
              }, 200);
              this.validationHelper(errors);
            }
          });
        });
      },
      async eventReject() {
        this.eventSavingReject = true;
        const loaded = await form.rejectEvent();
        window.setTimeout(() => {
          this.eventSavingReject = false;
          if (!loaded) this.$router.push({ name: 'Events' });
        }, 200);
      },
      async eventReturn() {
        this.eventSavingReturn = true;
        const loaded = await form.returnEvent();
        window.setTimeout(() => {
          this.eventSavingReturn = false;
          if (!loaded) this.$router.push({ name: 'Events' });
        }, 200);
      },
      async fetchData() {
        try {
          this.loading = true;
          await form.initForm(this.$route.params.id ? this.$route.params.id : null);
        } catch (err) {
          if (err.message === 'NOT_FOUND') {
            this.$router.push({ name: '404' });
          } else {
            this.$router.push({ name: 'Events' });
          }
        } finally {
          this.formState = form.state;
          this.loading = false;
        }
      },
      async copyEvent() {
        try {
          this.loading = true;
          await form.copyForm(this.$route.params.duplicate);
        } catch (err) {
          if (err.message === 'NOT_FOUND') {
            this.$router.push({ name: '404' });
          } else {
            this.$router.push({ name: 'Events' });
          }
        } finally {
          this.formState = form.state;
          this.loading = false;
        }
      },
      validationHelper(errors) {
        let invalid = [];
        for (let key of Object.keys(errors)) {
          if (Array.isArray(errors[key]) && errors[key].length > 0) {
            invalid.push(key);
          }
        }

        for (let key of Object.keys(formValidation)) {
          for (let input of formValidation[key]) {
            if (invalid.findIndex(element => element.includes(input)) > -1) {
              this.tabListInvalid.indexOf(key) === -1 && this.tabListInvalid.push(key);
            }
          }
        }

        if (invalid.some(e => addressInputs.includes(e))) {
          this.$refs.basicInfo.toggleAddress = true;
        }

        if (this.tabListInvalid.length > 0) {
          this.active = this.tabListInvalid[0];
        }
      },
      async downloadBrief() {
        this.briefDownloading = true;
        http({
          url: `EventExport/${this.formState.id}/generateBrief`,
          method: 'GET',
          responseType: 'blob',
        })
          .then(response => {
            const uri = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = uri;
            link.setAttribute('download', `${this.formState.name}.pdf`);
            document.body.appendChild(link);
            link.click();
            this.briefDownloading = false;
          })
          .catch(() => {
            this.briefDownloading = false;
          });
      },
      eventCancel() {
        let rtr = this.$router;

        this.$vfm.show({
          component: EventCancelModal,
          on: {
            cancel(close) {
              close();
            },
            async confirm(type, note, close) {
              const loaded = await form.cancelEvent(type, note);
              close();
              if (!loaded) rtr.push({ name: 'Events' });
            },
          },
        });
      },
      eventAgencyReject() {
        let rtr = this.$router;

        this.$vfm.show({
          component: EventRejectConfirm,
          on: {
            cancel(close) {
              close();
            },
            async confirm(close) {
              const loaded = await form.rejectAgencyEvent();
              close();
              if (!loaded) rtr.push({ name: 'Events' });
            },
          },
        });
      },
    },
  };
</script>
