<template>
  <div v-if="verifyVisibility(field)" class="form-field" :class="cssClass">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRule">
      <div class="field is-horizontal">
        <div :class="{ 'field-label': field.type !== 'checkboxes', 'is-normal': field.type === 'textarea' }">
          <label :class="{ 'is-required': validationRule.required && verifyEditability(field) }" class="label has-text-left">
            {{ $t(field.label) }}
          </label>
          <span v-if="mandatoryNext" class="icon is-mandatory">
            <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
          </span>
        </div>
        <div class="field-body">
          <div class="field" :class="{ 'has-addons is-numeric': field.type === 'numeric' }">
            <template v-if="field.type === 'text'">
              <p class="control">
                <input
                  v-model="form[name]"
                  class="input"
                  type="text"
                  :class="{ 'is-warning': errors[0] }"
                  :placeholder="field.placeholder ? $t(field.placeholder) : false"
                  :readonly="!verifyEditability(field)"
                />
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </p>
            </template>

            <template v-if="field.type === 'textarea'">
              <div class="control">
                <textarea
                  v-model="form[name]"
                  class="textarea"
                  :class="{ 'is-warning': errors[0] }"
                  :readonly="!verifyEditability(field)"
                  :maxlength="field.maxLength ? field.maxLength : null"
                ></textarea>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </div>
            </template>

            <template v-else-if="field.type === 'select'">
              <div class="control is-expanded">
                <div class="select is-fullwidth" :class="{ 'is-clearable': verifyEditability(field) && !!form[name] }">
                  <select v-model="form[name]" :class="{ 'is-warning': errors[0] }" :disabled="!verifyEditability(field)">
                    <option
                      v-for="option in selectOptions(field)"
                      :key="option.hostId ? option.hostId : option.id"
                      :value="option.hostId ? option.hostId : option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <button v-if="verifyEditability(field) && !!form[name]" class="select-clearer" @click="form[name] = null">
                    <inline-svg :src="require('../../assets/icons/cross.svg')"></inline-svg>
                  </button>
                </div>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </div>
            </template>

            <template v-else-if="field.type === 'date'">
              <p class="control has-icons-right">
                <flat-pickr
                  v-model="form[name]"
                  :config="dateConfig"
                  class="input"
                  :class="{ 'is-warning': errors[0] }"
                  :disabled="!verifyEditability(field)"
                  @input="checkField(name)"
                ></flat-pickr>
                <span class="icon is-hint is-right">
                  <inline-svg :src="require('../../assets/icons/calendar.svg')"></inline-svg>
                </span>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </p>
            </template>

            <template v-else-if="field.type === 'time'">
              <p class="control has-icons-right">
                <flat-pickr
                  v-model="form[name]"
                  :config="timeConfig"
                  class="input"
                  :class="{ 'is-warning': errors[0] }"
                  :disabled="!verifyEditability(field)"
                ></flat-pickr>
                <span class="icon is-hint is-right">
                  <inline-svg :src="require('../../assets/icons/clock.svg')"></inline-svg>
                </span>
                <button v-if="verifyEditability(field) && !!form[name]" class="select-clearer" @click="form[name] = ''">
                  <inline-svg :src="require('../../assets/icons/cross.svg')"></inline-svg>
                </button>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </p>
            </template>

            <template v-else-if="field.type === 'slider'">
              <div class="slider-wrapper" :class="{ 'is-warning': errors[0], 'is-disabled': !verifyEditability(field) }">
                <div class="slider-wrapper__count">
                  {{ form[name] }}
                  <span v-if="field.unit === 'currency'">
                    {{ form.currencyCode | formatCurrency }}
                  </span>
                  <span v-else-if="field.unit">
                    {{ field.unit }}
                  </span>
                </div>
                <div class="slider-wrapper__slider">
                  <vue-slider
                    v-model="form[name]"
                    dot-size="26"
                    :contained="true"
                    tooltip="none"
                    :min="field.min"
                    :max="field.max"
                    :disabled="!verifyEditability(field)"
                  ></vue-slider>
                </div>
              </div>
              <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
            </template>

            <template v-else-if="field.type === 'number'">
              <p class="control has-icons-right">
                <NumInput
                  v-model.number="form[name]"
                  class="input"
                  :min="field.min ? field.min : 0"
                  :readonly="!verifyEditability(field)"
                  :class="{ 'is-warning': errors[0] }"
                />
                <span v-if="field.unit === 'currency'" class="icon is-small is-right">
                  {{ form.currencyCode | formatCurrency }}
                </span>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </p>
            </template>

            <template v-else-if="field.type === 'numeric'">
              <p class="control">
                <button class="button addon-button-left" :disabled="!verifyButtonEditability(field) || !verifyEditability(field)" @click.prevent="form[name]--">
                  <inline-svg :src="require('../../assets/icons/minus.svg')"></inline-svg>
                </button>
              </p>
              <div class="control">
                <p class="control is-expanded">
                  <input
                    v-model.number="form[name]"
                    class="input has-arrows-hidden"
                    type="number"
                    :min="field.min ? field.min : 0"
                    :readonly="!verifyEditability(field)"
                    :class="{ 'is-warning': errors[0] }"
                  />
                </p>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </div>
              <p class="control">
                <button class="button addon-button-right" :disabled="!verifyEditability(field)" @click.prevent="form[name]++">
                  <inline-svg :src="require('../../assets/icons/plus.svg')"></inline-svg>
                </button>
              </p>
            </template>

            <template v-else-if="field.type === 'checkbox'">
              <p class="control">
                <label class="checkbox">
                  <input v-model="form[name]" type="checkbox" :class="{ 'is-warning': errors[0] }" :disabled="!verifyEditability(field)" />
                  <span>{{ $t(field.label) }}</span>
                </label>
                <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
              </p>
            </template>

            <template v-else-if="field.type === 'toggle'">
               <Toggle v-model="form[name]" :disabled="!verifyEditability(field)" />
            </template>
          </div>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import flatPickr from 'vue-flatpickr-component';

  import { compareStatus, isMandatoryNow, isMandatoryNext, STATUS_FINISHED } from '@/services/status';
  import form, { EDITOR_AGENCY, EDITOR_AUTHORITY } from '@/services/eventForm';
  import auth from '@/services/auth';
  import { isUserAgency, isUserEventManager, isUserRedbull } from '@/services/roles';
  import NumInput from '@/components/EventsForm/NumInput';
  import Toggle from "@/components/Toggle";

  export default {
    name: 'FormElement',
    components: {Toggle, NumInput, ValidationProvider, flatPickr },
    props: {
      cssClass: {
        type: String,
      },
      name: {
        type: String,
        required: true,
      },
      field: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showAdditionals: false,
        newContact: '',
        timeConfig: {
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true,
        },
        form: form.state,
        formTemp: form.temp,
      };
    },
    computed: {
      dateConfig() {
        return {
          dateFormat: 'Y-m-d',
          altFormat: 'd.m.Y',
          altInput: true,
          ...(this.name === 'eventEnd' && this.form.eventStart && { minDate: this.form.eventStart }),
        };
      },
      validationRule() {
        let rules = {};
        if (this.field.mandatory === true) {
          rules.required = true;
        }

        if (this.field.mandatoryDependency && this.field.mandatory instanceof Array) {
          let mandatoryNow = this.form[this.field.mandatoryDependency] && isMandatoryNow(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          if (mandatoryNow) rules.required = true;

          let mandatoryNext = this.form[this.field.mandatoryDependency] && isMandatoryNext(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          if (mandatoryNext) rules.required_if = 'proceedSave';
        } else if (this.field.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          if (mandatoryNow) rules.required = true;

          let mandatoryNext = isMandatoryNext(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          if (mandatoryNext) rules.required_if = 'proceedSave';
        }

        if (rules.required === true && 'minValue' in this.field) {
          rules.min_value = this.field.minValue;
        }

        if (rules.required_if === 'proceedSave' && 'minValue' in this.field) {
          rules.min_value = this.field.minValue;
        }

        return rules;
      },
      mandatoryNext() {
        if (this.field.mandatory instanceof Array) {
          if (!isMandatoryNow(this.form.status, this.field.mandatory[0], this.field.mandatory[1])) {
            return isMandatoryNext(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          }
        }
        return false;
      },
    },
    methods: {
      verifyVisibility(field) {
        let visible = false;
        if ('visible' in field) {
          visible = compareStatus(this.form.status, field.visible[0], field.visible[1]);
        } else {
          visible = true;
        }
        if ('agencyHidden' in field && visible) {
          if (isUserAgency() && !isUserRedbull()) {
            visible = false;
          }
        }

        return visible;
      },
      verifyEditability(field) {
        let editable = false;

        if ('editable' in field) {
          editable = compareStatus(this.form.status, field.editable[0], field.editable[1]);
        } else {
          editable = true;
        }

        if (editable && this.name === 'typeId') {
          editable = !!this.form.segmentId;
        }

        if (editable && this.name === 'eventEnd') {
          editable = !!this.form.eventStart;
        }

        if (editable && this.name === 'fineReason') {
          editable = this.form.fine > 0;
        }

        if ('editor' in field && editable) {
          if (field.editor.includes(EDITOR_AGENCY) && !field.editor.includes(EDITOR_AUTHORITY)) {
            editable = isUserAgency() || isUserEventManager();
          }
          if (!field.editor.includes(EDITOR_AGENCY) && field.editor.includes(EDITOR_AUTHORITY)) {
            editable = isUserRedbull();
          }
        }

        if (editable && this.form.status === STATUS_FINISHED) {
          editable = false;
        }

        // event manager override
        if (isUserEventManager()) {
          editable = true;
        }

        return editable;
      },
      verifyButtonEditability(field) {
        let editable = true;

        if (editable && field.type === 'numeric' && this.form[this.name] <= this.field.min) {
          editable = false;
        }

        return editable;
      },
      selectOptions(field) {
        let options;

        if (this.name === 'typeId') {
          options = field.options.filter(item => item.segmentId === this.form.segmentId);
        } else {
          options = field.options;
        }

        return options;
      },
      checkField(name) {
        if (name === 'eventStart') {
          if (!this.form.eventEnd) {
            if (this.formTemp.staff.length === 0) {
              this.form.eventEnd = this.form.eventStart;
            }
          }
        }
      },
    },
  };
</script>
