import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getCategories(filter) {
  try {
    let res = await http.get('/Category');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateCategory(id, data) {
  try {
    const res = await http.put(`/Category/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewCategory(data) {
  try {
    const res = await http.post('Category', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
