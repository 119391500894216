import * as msal from '@azure/msal-browser';
import http from '@/services/http';

import { MSAL_AUTHORITY, MSAL_CLIENT, MSAL_REDIRECTURI } from '../consts';
import { ROLES_MAPPING } from '@/services/roles';

let msalInstance = null;

const loginRequest = {
  scopes: ['api://795d55a5-8471-43d1-a4f4-ba979894c0b8/access_as_user'],
};

msalInstance = new msal.PublicClientApplication({
  auth: {
    clientId: MSAL_CLIENT,
    authority: MSAL_AUTHORITY,
    redirectUri: MSAL_REDIRECTURI,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
});

const auth = {
  state: {
    accountId: null,
    accessToken: null,
    username: null,
    name: null,
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    photo: null,
    roles: [],
  },
  msal: msalInstance,
  async init() {
    if (this.state.accountId && !this.state.accessToken) {
      await this.getToken();
    }
  },
  login(prompt) {
    !prompt ? msalInstance.loginRedirect(loginRequest) : msalInstance.loginRedirect({ prompt: 'select_account', loginRequest });
  },
  logout() {
    msalInstance.logout();
  },
  isAuth() {
    return this.state.accountId && this.state.accessToken;
  },
  checkAuth() {
    return new Promise((resolve, reject) => {
      this.initPromise.then(
        () => {
          if (this.isAuth()) {
            resolve();
          } else {
            reject();
          }
        },
        () => {
          reject();
        }
      );
    });
  },
  setState(acc) {
    this.state.accountId = acc.homeAccountId;
    this.state.username = acc.username;
    this.state.name = acc.name;
    if (acc?.idTokenClaims?.groups) {
      for (let group of acc.idTokenClaims.groups) {
        if (Object.keys(ROLES_MAPPING).includes(group)) {
          this.state.roles.push(ROLES_MAPPING[group]);
        }
      }
    }
  },
  setUserData(data) {
    this.state.id = data.id;
    this.state.firstName = data.firstName;
    this.state.lastName = data.lastName;
    this.state.email = data.email;
    this.state.photo = data.photo;
  },
  setToken(token) {
    this.state.accessToken = token;
  },
  handleRedirect() {
    this.initPromise = new Promise((resolve, reject) => {
      msalInstance
        .handleRedirectPromise()
        .then(resp => {
          if (resp !== null) {
            this.setState(resp.account);
            this.setToken(resp.accessToken);
            msalInstance.setActiveAccount(resp.account);
            http.get('/UserProfile').then(
              data => {
                this.setUserData(data.data);
                resolve();
              },
              err => {
                //
              }
            );
          } else {
            const currentAccounts = msalInstance.getAllAccounts();
            if (!currentAccounts || currentAccounts.length < 1) {
              reject();
            } else if (currentAccounts.length > 1) {
              msalInstance.loginRedirect({ prompt: 'select_account', ...loginRequest });
            } else if (currentAccounts.length === 1) {
              const activeAccount = currentAccounts[0];
              msalInstance.setActiveAccount(activeAccount);
              this.setState(activeAccount);
              auth.init().then(() => {
                http.get('/UserProfile').then(
                  data => {
                    this.setUserData(data.data);
                    resolve();
                  },
                  err => {
                    //
                  }
                );
              });
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    });
    window.inp = this.initPromise;
    return this.initPromise;
  },
  async getToken() {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: { username: this.state.username, homeAccountId: this.state.accountId },
        forceRefresh: false,
      });
      this.setToken(tokenResponse.accessToken);
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect({ ...loginRequest, loginHint: this.state.accountId });
      }
    }
  },
};

export default auth;
