<template>
  <ul class="menu">
    <li v-if="eventsVisible">
      <router-link to="/events" @click.native="onClick">
        <span class="icon"><inline-svg :src="require('../assets/icons/map.svg')"/></span><span>{{ $t('sidebar.menu.events') }}</span>
      </router-link>
    </li>
    <li v-if="eventsVisible">
      <router-link to="/quality-check" @click.native="onClick">
        <span class="icon"><inline-svg :src="require('../assets/icons/check.svg')"/></span><span>{{ $t('sidebar.menu.qualityCheck') }}</span>
      </router-link>
    </li>
    <li v-if="adminVisible">
      <router-link to="/admin" @click.native="onClick">
        <span class="icon"><inline-svg :src="require('../assets/icons/user.svg')"/></span><span>{{ $t('sidebar.menu.admin') }}</span>
      </router-link>
    </li>
    <li class="is-hidden-desktop">
      <a @click="logout">
        <span class="icon"><inline-svg :src="require('../assets/icons/exit.svg')"/></span><span>{{ $t('common.logOut') }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
  import { isUserAdministrator, isUserAgency, isUserRedbull } from '@/services/roles';
  import auth from '@/services/auth';

  export default {
    name: 'MainMenu',
    computed: {
      adminVisible() {
        return isUserAdministrator();
      },
      eventsVisible() {
        return isUserRedbull() || isUserAgency();
      },
    },
    methods: {
      logout() {
        auth.logout();
        this.onClick();
      },
      onClick() {
        this.$emit('clicked');
      },
    },
  };
</script>
