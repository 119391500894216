<template>
  <div>
    <div class="columns is-desktop is-multiline">
      <div class="column is-half-desktop">
        <div class="form">
          <h3>{{ $t('admin.tabs.exports.title') }}</h3>

          <div class="form-wrapper">

            <div class="form-field">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label has-text-left">{{ $t('admin.tabs.exports.dateStart') }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <flat-pickr v-model="exportForm.dateStart" :config="dateConfig" class="input"></flat-pickr>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-field">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label has-text-left">{{ $t('admin.tabs.exports.dateStop') }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <flat-pickr v-model="exportForm.dateStop" :config="dateConfig" class="input"></flat-pickr>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-field">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label has-text-left">{{ $t('admin.tabs.exports.status') }}</label>
                </div>
                <div class="field-body">
                  <div class="field field-multiselect">
                    <multiselect
                        v-if="listStatus"
                        v-model="exportForm.status"
                        :options="listStatus"
                        class="is-status"
                        track-by="value"
                        label="label"
                        :multiple="true"
                        :searchable="false"
                        :close-on-select="false"
                        :limit-text="count => `+ ${count}`"
                        :placeholder="$t('events.status')"
                        :show-labels="false"
                    >
                      <template slot="tag" slot-scope="props"
                      ><span>{{ props.option.label }}</span></template
                      >
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>




    <button class="button is-primary" @click="generateExport">
      Export
    </button>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import flatPickr from 'vue-flatpickr-component';
  import { STATUSES } from '@/services/status';
  import moment from 'moment';
  import http from '@/services/http';

  export default {
    name: 'AdminExports',
    components: { ValidationProvider, flatPickr },
    data() {
      return {
        exportForm: {
          dateStart: null,
          dateStop: null,
          status: [],
        },
        dateConfig: {
          dateFormat: 'Y-m-d',
          altFormat: 'd.m.Y',
          altInput: true,
        },
      };
    },
    computed: {
      listStatus() {
        let list = [];
        for (const value of STATUSES) {
          list.push({ value, label: this.$t(`events.states.${value}`) });
        }
        return list;
      },
    },
    methods: {
      async generateExport() {
        let dateStart = moment(this.exportForm.dateStart)
          .startOf('day')
          .toISOString();
        let dateStop = moment(this.exportForm.dateStop)
          .endOf('day')
          .toISOString();
        let status = this.exportForm.status.map(e => e.value);

        http({
          url: `Events/exportReport`,
          method: 'POST',
          data: { dateStart, dateStop, status },
          responseType: 'blob',
        }).then(response => {
          const uri = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = uri;
          link.setAttribute('download', 'export.xlsx');
          document.body.appendChild(link);
          link.click();
        });
      },
      async downloadFile({ name, originalName }) {
        http({
          url: `EventAttachment/${this.formState.id}/download/${name}`,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          const uri = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = uri;
          link.setAttribute('download', originalName ? originalName : name);
          document.body.appendChild(link);
          link.click();
        });
      },
    },
  };
</script>
