<template>
  <div class="topbar">
    <Search v-if="searchVisible" />
    <div v-else class="search"></div>
    <ThemeSwitch />
    <div class="logout">
      <span class="icon"><inline-svg :src="require('../assets/icons/exit.svg')"></inline-svg></span><a @click="logout">{{ $t('common.logOut') }}</a>
    </div>
  </div>
</template>

<script>
  import Search from '../components/Search';
  import ThemeSwitch from '../components/ThemeSwitch';
  import auth from '../services/auth';

  export default {
    name: 'TopBar',
    components: { Search, ThemeSwitch },
    computed: {
      searchVisible() {
        return this.$route.name === 'Events';
      },
    },
    methods: {
      logout() {
        auth.logout();
      },
    },
  };
</script>
