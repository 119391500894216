<template>
  <ValidationProvider v-if="verifyVisibility(field)" v-slot="{ errors }" :name="name" :rules="validationRule">
    <div v-if="field.type === 'text'">
      <p class="control">
        <input
          v-model="form[name]"
          class="input"
          type="text"
          :class="{ 'is-warning': errors[0] }"
          :placeholder="field.placeholder ? $t(field.placeholder) : false"
          :readonly="!verifyEditability(field)"
          @change="onChange"
        />
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </p>
    </div>

    <div v-if="field.type === 'textarea'">
      <div class="control">
        <textarea v-model="form[name]" class="textarea" :readonly="!verifyEditability(field)" :class="{ 'is-warning': errors[0] }"></textarea>
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </div>
    </div>

    <div v-else-if="field.type === 'select'">
      <div class="control is-expanded">
        <div class="select is-fullwidth">
          <select v-model="form[name]" :class="{ 'is-warning': errors[0] }" :disabled="!verifyEditability(field)" @change="onChange">
            <option v-if="field.placeholder" :value="null" selected disabled>{{ $t(field.placeholder) }}</option>
            <option v-for="option in options ? options : field.options" :key="option.id" :value="option.id">
              {{ option.name }}
            </option>
          </select>
        </div>
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </div>
    </div>

    <div v-else-if="field.type === 'date'">
      <p class="control has-icons-right">
        <flat-pickr
          v-model="form[name]"
          :config="dateConfig"
          class="input"
          :class="{ 'is-warning': errors[0] }"
          :disabled="!verifyEditability(field)"
        ></flat-pickr>
        <span class="icon is-small is-right">
          <inline-svg :src="require('../../assets/icons/calendar.svg')"></inline-svg>
        </span>
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </p>
    </div>

    <div v-else-if="field.type === 'time'">
      <p class="control has-icons-right">
        <flat-pickr
          v-model="form[name]"
          :config="timeConfig"
          class="input"
          :class="{ 'is-warning': errors[0] }"
          :disabled="!verifyEditability(field)"
        ></flat-pickr>
        <span class="icon is-small is-right">
          <inline-svg :src="require('../../assets/icons/clock.svg')"></inline-svg>
        </span>
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </p>
    </div>

    <div v-else-if="field.type === 'number'">
      <p class="control has-icons-right">
        <input
          v-model.number="form[name]"
          class="input"
          type="number"
          :min="field.min ? field.min : 0"
          :readonly="!verifyEditability(field)"
          :class="{ 'is-warning': errors[0] }"
        />
        <span v-if="field.unit === 'currency'" class="icon is-small is-right">
          {{ form.currencyCode | formatCurrency }}
        </span>
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </p>
    </div>

    <div v-else-if="field.type === 'checkbox'">
      <p class="control">
        <label class="checkbox">
          <input v-model="form[name]" type="checkbox" :disabled="!verifyEditability(field)" :class="{ 'is-warning': errors[0] }" />
          <span>{{ $t(field.label) }}</span>
        </label>
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </p>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import flatPickr from 'vue-flatpickr-component';
  import { compareStatus, isMandatoryNow, isMandatoryNext } from '@/services/status';
  import form, { EDITOR_AGENCY, EDITOR_AUTHORITY } from '@/services/eventForm';
  import { isUserAgency, isUserEventManager, isUserRedbull } from '@/services/roles';

  export default {
    name: 'FormElementInput',
    components: { ValidationProvider, flatPickr },
    props: {
      name: {
        type: String,
        required: true,
      },
      field: {
        type: Object,
        required: true,
      },
      options: {
        type: Array,
      },
    },
    data() {
      return {
        showAdditionals: false,
        newContact: '',
        dateConfig: {
          dateFormat: 'Y-m-d',
          altFormat: 'd.m.Y',
          altInput: true,
        },
        timeConfig: {
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true,
        },
        form: form.state,
      };
    },
    computed: {
      columnClass() {
        return `is-${this.col}`;
      },
      validationRule() {
        let rules = {};
        if (this.field.mandatory === true) {
          rules.required = true;
        }
        if (this.field.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          if (mandatoryNow) rules.required = true;

          let mandatoryNext = isMandatoryNext(this.form.status, this.field.mandatory[0], this.field.mandatory[1]);
          if (mandatoryNext) rules.required_if = 'proceedSave';
        }

        if (rules.required === true && 'minValue' in this.field) {
          rules.min_value = this.field.minValue;
        }

        if (rules.required_if === 'proceedSave' && 'minValue' in this.field) {
          rules.minValueIf = { min: this.field.minValue, target: 'proceedSave' };
        }

        return rules;
      },
    },
    methods: {
      verifyVisibility(field) {
        let visible = false;
        if ('visible' in field) {
          visible = compareStatus(this.form.status, field.visible[0], field.visible[1]);
        } else {
          visible = true;
        }
        if ('agencyHidden' in field && visible) {
          if (isUserAgency() && !isUserRedbull()) {
            visible = false;
          }
        }

        return visible;
      },
      verifyEditability(field) {
        let editable = false;

        if ('editable' in field) {
          editable = compareStatus(this.form.status, field.editable[0], field.editable[1]);
        } else {
          editable = true;
        }

        if ('editor' in field && editable) {
          if (field.editor.includes(EDITOR_AGENCY) && !field.editor.includes(EDITOR_AUTHORITY)) {
            return isUserAgency() || isUserEventManager();
          }
          if (!field.editor.includes(EDITOR_AGENCY) && field.editor.includes(EDITOR_AUTHORITY)) {
            return isUserRedbull();
          }
        }

        // event manager override
        if (isUserEventManager()) {
          editable = true;
        }

        return editable;
      },
      onChange() {
        this.$emit('change');
      },
    },
  };
</script>
