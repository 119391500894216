<template>
  <ToggleButton :value="value" :sync="true" class="toggle-switch" :class="toggleType" :css-colors="true" :labels="labels" :disabled="disabled" @input="emit" />
</template>

<script>
  import { ToggleButton } from 'vue-js-toggle-button';

  export default {
    name: 'Toggle',
    components: { ToggleButton },
    props: {
      type: String,
      value: Boolean,
      disabled: Boolean,
    },
    computed: {
      toggleType() {
        return this.type === 'text' ? 'is-text' : 'is-icon';
      },
      labels() {
        return this.type === 'text' ? { checked: this.$t('common.toggle.yes'), unchecked: this.$t('common.toggle.no') } : false;
      },
    },
    methods: {
      emit(value) {
        this.$emit('input', value);
      },
    },
  };
</script>
