import http from '@/services/http';

export async function getZipCode(data) {
  try {
    const res = await http.get(`/PostalCode?search=${parseInt(data)}&take=10`);
    return res.data.data;
  } catch (e) {
    console.log(e);
  }
}
