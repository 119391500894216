<template>
  <label class="search" :class="{ 'is-active': searchActive }">
    <span class="icon"><inline-svg :src="require('../assets/icons/search.svg')"></inline-svg></span>
    <input v-model="search.value" type="text" :placeholder="$t('events.search')" @focus="focus" @blur="blur" />
    <a v-if="searchFilled" class="cancel" @click="clear"><inline-svg :src="require('../assets/icons/close.svg')"></inline-svg></a>
  </label>
</template>

<script>
  import form from '@/services/eventForm';

  export default {
    name: 'Search',
    data() {
      return {
        search: form.search,
        searchActive: false,
      };
    },
    computed: {
      searchFilled() {
        return this.search.value && this.search.value !== '';
      },
    },
    methods: {
      focus() {
        this.searchActive = true;
      },
      blur() {
        if (!this.search.value) {
          this.searchActive = false;
        }
      },
      clear() {
        this.search.value = '';
        this.searchActive = false;
      },
    },
  };
</script>
