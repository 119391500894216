<template>
  <div>
    <input
      ref="element"
      v-model="text"
      type="text"
      class="input comments__input"
      :placeholder="placeholder"
      @input="update"
      @blur="update"
      @keypress="onKeypress"
      @keydown="onKeydown"
    />
    <ul v-if="showUsers" v-show="filteredUserList.length > 0" ref="userList" class="comments__userlist" :style="{left: `${userlistPosition}px` }">
      <li
        v-for="(user, index) in filteredUserList"
        :key="index"
        ref="userListItem"
        :class="{ 'is-highlighted': index === usersHighlight }"
        @click="selectUser(user)"
      >
        {{ user.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  function replaceAll(str, search, replacement) {
    return str.split(search).join(replacement);
  }

  function scrollParentToChild(parent, child) {
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // Should we scroll using top or bottom? Find the smaller ABS adjustment
      const scrollTop = childRect.top - parentRect.top;
      const scrollBot = childRect.bottom - parentRect.bottom;
      if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        // we're near the top of the list
        parent.scrollTop += scrollTop;
      } else {
        // we're near the bottom of the list
        parent.scrollTop += scrollBot;
      }
    }
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  const getCursorXY = (input, selectionPoint) => {
    const {
      offsetLeft: inputX,
      offsetTop: inputY,
    } = input
    // create a dummy element that will be a clone of our input
    const div = document.createElement('div')
    // get the computed style of the input and clone it onto the dummy element
    const copyStyle = getComputedStyle(input)
    for (const prop of copyStyle) {
      div.style[prop] = copyStyle[prop]
    }
    // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
    const swap = '.'
    const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value
    // set the div content to that of the textarea up until selection
    const textContent = inputValue.substr(0, selectionPoint)
    // set the text content of the dummy element div
    div.textContent = textContent
    if (input.tagName === 'TEXTAREA') div.style.height = 'auto'
    // if a single line input then the div needs to be single line and not break out like a text area
    if (input.tagName === 'INPUT') div.style.width = 'auto'
    // create a marker element to obtain caret position
    const span = document.createElement('span')
    // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
    span.textContent = inputValue.substr(selectionPoint) || '.'
    // append the span marker to the div
    div.appendChild(span)
    // append the dummy element to the body
    document.body.appendChild(div)
    // get the marker position, this is the caret position top and left relative to the input
    const { offsetLeft: spanX, offsetTop: spanY } = span
    // lastly, remove that dummy element
    // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
    document.body.removeChild(div)
    // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
    return {
      x: inputX + spanX,
      y: inputY + spanY,
    }
  }

  export default {
    name: 'CommentInput',
    props: {
      tag: {
        type: String,
        default: 'div',
      },
      contenteditable: {
        type: Boolean,
        default: true,
      },
      value: String,
      noNL: {
        type: Boolean,
        default: true,
      },
      userList: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showUsers: false,
        usersHighlight: 0,
        usersSearch: '',
        text: '',
        replacements: {},
        userlistPosition: 0,
      };
    },
    computed: {
      filteredUserList() {
        if (this.usersSearch.length > 0) {
          return [...this.userList].sort((a, b) => a.name.localeCompare(b.name)).filter(e => e.name.toLowerCase().indexOf(this.usersSearch) !== -1);
        } else {
          return [...this.userList].sort((a, b) => a.name.localeCompare(b.name));
        }
      },
    },
    watch: {
      value(newval, oldval) {
        if (newval != this.currentContent()) {
          this.updateContent(newval);
        }
      },
    },
    mounted() {
      this.updateContent(this.value);

    },
    methods: {
      currentContent() {
        let text = this.text;
        for (let replacement in this.replacements) {
          text = text.replace(replacement, `[~${this.replacements[replacement]}]`);
        }
        return text;
      },
      updateContent(newcontent) {
        for (let user of this.userList) {
          newcontent = newcontent.replace(
            new RegExp(escapeRegExp(`[~${user.id}]`), 'g'),
            `@${user.name}`
          );
          this.replacements[`@${user.name}`] = user.id;
        }

        this.text = newcontent;
      },
      update(event) {
        this.$emit('input', this.currentContent());
      },
      onKeydown(event) {
        if (this.showUsers === true) {
          if (this.usersSearch.length > 0 && event.key === 'Backspace') {
            this.usersSearch = this.usersSearch.slice(0, -1);
          } else if (['Escape', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Home'].indexOf(event.key) !== -1) {
            this.hideUsers();
          } else if (event.key === 'ArrowDown') {
            this.usersHighlight += 1;
            if (this.usersHighlight >= this.filteredUserList.length) this.usersHighlight = 0;
            scrollParentToChild(this.$refs.userList, this.$refs.userListItem[this.usersHighlight]);
            event.preventDefault();
          } else if (event.key === 'ArrowUp') {
            this.usersHighlight -= 1;
            if (this.usersHighlight < 0) this.usersHighlight = this.filteredUserList.length - 1;
            scrollParentToChild(this.$refs.userList, this.$refs.userListItem[this.usersHighlight]);
            event.preventDefault();
          } else if (event.key === 'Enter') {
            this.selectUser(this.filteredUserList[this.usersHighlight]);
            event.preventDefault();
          } else {
            if (event.key.match(/^[a-zA-Z0-9]{1}$/)) {
              this.usersSearch += event.key.toLowerCase();
            }
          }
        } else {
          if (event.key === 'Enter') {
            this.$emit('returned');
          }
        }

        this.fwdEv(event);
      },
      onKeypress(event) {
        if (event.key === '@') {
          if (
            this.$refs.element.selectionStart === 0 ||
            this.$refs.element.value.substring(this.$refs.element.selectionStart - 1, this.$refs.element.selectionStart) === ' '
          ) {
            let cursor = getCursorXY(this.$refs.element, this.$refs.element.selectionStart);
            let element = this.$refs.element.offsetWidth;
            if (cursor.x + 260 < element) {
              this.userlistPosition = cursor.x;
            } else {
              this.userlistPosition = element - 260;
            }

            this.showUsers = true;
          }
        }
        this.fwdEv(event);
      },
      fwdEv(event) {
        this.$emit(event.type, event);
      },
      selectUser(user) {
        if (this.$refs.element.selectionStart || this.$refs.element.selectionStart == '0') {
          var startPos = this.$refs.element.selectionStart - this.usersSearch.length - 1;
          var endPos = this.$refs.element.selectionEnd;
          this.$refs.element.value =
            this.$refs.element.value.substring(0, startPos) + `@${user.name} ` + this.$refs.element.value.substring(endPos, this.$refs.element.value.length);
          this.text = this.$refs.element.value;
        }
        this.$refs.element.focus();
        this.replacements[`@${user.name}`] = user.id;
        this.hideUsers();
        this.$emit('input', this.currentContent());
      },
      hideUsers() {
        this.showUsers = false;
        this.usersHighlight = 0;
        this.usersSearch = '';
      },
    },
  };
</script>
