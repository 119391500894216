const LAST_VISIT_COUNT = 4;
const STORE_KEY = 'redbull-events-visited';

export const eventVisited = {
  state: {
    lastVisits: [],
  },

  initEventVisited() {
    let store = window.localStorage.getItem(STORE_KEY);
    if (store) {
      this.state.lastVisits = JSON.parse(store);
    }
  },

  save() {
    window.localStorage.setItem(STORE_KEY, JSON.stringify(this.state.lastVisits));
  },

  async validateEventList(list) {
    let existingEventIds = list.map(e => e.id);
    this.state.lastVisits = this.state.lastVisits.filter(e => existingEventIds.includes(e.id));
    this.save();
  },

  addEventVisited({ id, name }) {
    let array = this.state.lastVisits.filter(ev => ev.id !== id);
    let length = array.unshift({ id, name });

    if (length > LAST_VISIT_COUNT) {
      array = array.slice(0, LAST_VISIT_COUNT);
    }

    this.state.lastVisits = array;

    this.save();
  },

  updateEventVisited({ id, name }) {
    let event = this.state.lastVisits.findIndex(ev => ev.id === id);
    if (event !== -1 && this.state.lastVisits[event].name !== name) {
      this.state.lastVisits[event].name = name;
      this.save();
    }
  },
};

eventVisited.initEventVisited();
