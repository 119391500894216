<template>
  <div class="theme-switch">
    <span :class="{ 'is-active': currentTheme === 'dark' }">{{ $t('themeSwitch.dark') }}</span>
    <input id="theme" v-model="theme" type="checkbox" name="theme" value="light" class="switch is-rounded" @change="changeTheme" />
    <label for="theme"></label>
    <span :class="{ 'is-active': currentTheme === 'light' }">{{ $t('themeSwitch.light') }}</span>
  </div>
</template>

<script>
  import theme from '../services/theme';

  export default {
    name: 'ThemeSwitch',
    data() {
      return {
        theme: null,
        state: theme.state,
      };
    },
    computed: {
      currentTheme() {
        return this.state.currentTheme;
      },
    },
    mounted() {
      if (this.state.currentTheme === 'light') this.theme = true;
    },
    methods: {
      changeTheme() {
        if (this.theme === true) {
          theme.changeTheme('light');
        } else {
          theme.changeTheme('dark');
        }
      },
    },
  };
</script>
