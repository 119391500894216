<template>
  <div v-if="formState.id && loaded" class="comments" :class="{ 'is-open': open }" :style="[{ top: `${top}px` }]">
    <a class="comments__toggle" @click="toggle">
      <span v-if="messageCount" class="count">
        {{ messageCount }}
      </span>
      <span class="icon">
        <inline-svg :src="require('../../assets/icons/comments.svg')"></inline-svg>
      </span>
    </a>
    <div class="comments__content" :style="open && height && { height: height }">
      <h3>
        <span class="icon">
          <inline-svg :src="require('../../assets/icons/comments.svg')"></inline-svg>
        </span>
        <span>{{ $t('eventsForm.comments.title') }}</span>
        <a class="close is-hidden-desktop" @click="toggle"><inline-svg :src="require('../../assets/icons/close.svg')"></inline-svg></a>
      </h3>
      <div ref="messages" class="comments__messages__wrapper" :style="open && heightMessages && { height: heightMessages }">
        <section class="comments__messages" :style="open && heightMessages && { minHeight: heightMessages }">
          <article
            v-for="message in sortedMessages"
            :key="message.id"
            class="comments__message"
            :class="{ 'is-updated': message.id === update.id, 'is-unread': unreadMessages.includes(message.id) }"
          >
            <div>
              <strong>{{ getUser(message.sendByUserId) }}</strong>
              <div class="time">
                <span>{{ message.createdAt | dateFormat }}</span>
                <span>{{ message.createdAt | timeFormat }}</span>
              </div>
            </div>
            <div>
              <p v-html="handleMessage(message.message)"></p>
            </div>
            <aside v-if="isCurrentUsers(message)">
              <a class="is-update" @click.prevent="updateMessage(message)"><inline-svg :src="require('../../assets/icons/edit.svg')"></inline-svg></a>
              <a class="is-delete" @click.prevent="deleteMessage(message)"><inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg></a>
            </aside>
          </article>
        </section>
      </div>
      <div class="comments__send">
        <div class="field is-grouped">
          <p class="control is-expanded" :class="{ 'has-icons-right': update }">
            <CommentInput v-model="messageText" ref="input" :user-list="userList" :placeholder="$t('eventsForm.comments.newComment')" @returned="sendMessage" />
            <a v-if="update" class="icon is-right" @click.prevent="cancelUpdate">
              <inline-svg :src="require('../../assets/icons/cross.svg')"></inline-svg>
            </a>
          </p>
          <p class="control">
            <button class="button is-input" :disabled="!messageText" @click.prevent="sendMessage">
              <span class="icon">
                <inline-svg :src="require('../../assets/icons/send.svg')"></inline-svg>
              </span>
            </button>
          </p>
        </div>
        <!--        <input
            ref="input"
            v-model="messageText"
            class="input"
            type="text"
            :placeholder="$t('eventsForm.comments.newComment')"
            @keydown.enter.prevent="sendMessage"
        />-->
      </div>
    </div>
    <div class="comments__overlay" @click="toggle"></div>
  </div>
</template>

<script>
  import * as api from '@/api/commentService';
  import form from '@/services/eventForm';
  import auth from '@/services/auth';
  import * as store from '@/services/eventComments';
  import CommentInput from '@/components/CommentInput';
  import { getHostedUsers } from '@/api/hostedUserService';

  let originalTop = 0;
  let scrollLastPosition = 0;
  let scrollTicking = false;

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  export default {
    name: 'Comments',
    components: { CommentInput },
    filters: {
      dateFormat(value) {
        if (!value) return;
        let date = new Date(value);
        if (!(date instanceof Date && !isNaN(date))) return false;
        return date.toLocaleDateString([], { day: 'numeric', month: 'numeric', year: '2-digit' });
      },
      timeFormat(value) {
        if (!value) return;
        let date = new Date(value);
        if (!(date instanceof Date && !isNaN(date))) return false;
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      },
    },
    data() {
      return {
        open: false,
        loaded: false,
        formState: form.state,
        users: [],
        messages: [],
        unreadMessages: [],
        messageText: '',
        update: false,
        auth: auth.state,
        timeout: null,
        setRead: false,
        top: originalTop,
        height: null,
        heightMessages: null,
        userList: [],
      };
    },
    computed: {
      sortedMessages() {
        return [...this.messages].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      },
      messageCount() {
        return this.unreadMessages.length || '';
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollListener);
      window.removeEventListener('resize', this.resizeListener);
    },
    async mounted() {
      window.addEventListener('scroll', this.scrollListener);
      window.addEventListener('resize', this.resizeListener);
      this.resizeListener();
      this.top = originalTop = document.querySelector('.page-content').getBoundingClientRect().top + window.scrollY;

      if (this.formState.id) {
        try {
          const { users, messages } = await api.getEventComments(this.formState.id);
          this.users = users;
          this.users.push({
            id: this.auth.id,
            firstName: this.auth.firstName,
            lastName: this.auth.lastName,
            email: this.auth.email,
            photo: this.auth.photo,
          });
          this.messages = messages;
          this.getUnreadComments();
          this.loaded = true;
          this.$nextTick(() => {
            this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight - this.$refs.messages.clientHeight;
          });

          this.userList = await getHostedUsers();
        } catch (err) {
          this.loaded = false;
        }
      }
    },
    methods: {
      toggle() {
        this.open = !this.open;

        if (this.open) {
          this.timeout = window.setTimeout(() => {
            this.setReadComments();
          }, 2 * 1000);
        }

        if (!this.open) {
          if (this.timeout) {
            window.clearTimeout(this.timeout);
          }
          if (this.setRead) {
            this.unreadMessages = [];
          }

          if (this.update) {
            this.update = false;
          }
        }
      },
      async sendMessage() {
        if (!this.update) {
          let { id } = await api.createEventComment(this.formState.id, { message: this.messageText });
          let message = await api.getComment(id);
          this.messageText = '';
          this.messages.push(message);
          this.$nextTick(() => {
            this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight - this.$refs.messages.clientHeight;
          });
        } else {
          let updateMsg = this.messageText;
          let updateId = this.update.id;
          await api.updateComment(updateId, { message: updateMsg });
          this.update.message = updateMsg;
          this.messageText = '';
          this.update = false;
        }
      },
      getUser(id) {
        let user = this.users.find(e => e.id === id);
        return `${user.firstName || ''} ${user.lastName || ''}`;
      },
      isCurrentUsers({ sendByUserId }) {
        return sendByUserId === this.auth.id;
      },
      updateMessage(message) {
        this.update = message;
        this.messageText = message.message;
        this.$refs.input.focus();
      },
      cancelUpdate() {
        this.update = false;
        this.messageText = '';
      },
      async deleteMessage(message) {
        const res = await api.deleteComment(message.id);
        if (res.status === 204) this.messages.splice(this.messages.indexOf(message), 1);
      },
      getUnreadComments() {
        this.unreadMessages = store.getUnreadComments(
          this.formState.id,
          this.messages
            .filter(e => e.sendByUserId !== this.auth.id)
            .map(e => {
              return { id: e.id, updated: e.updatedAt };
            })
        );
      },
      setReadComments() {
        store.setReadComments(
          this.formState.id,
          this.messages
            .filter(e => e.sendByUserId !== this.auth.id)
            .map(e => {
              return { id: e.id, updated: e.updatedAt };
            })
        );
        this.setRead = true;
      },
      scrollListener() {
        scrollLastPosition = window.scrollY;
        if (!scrollTicking) {
          window.requestAnimationFrame(() => {
            this.scrollHandler(scrollLastPosition);
            scrollTicking = false;
          });
          scrollTicking = true;
        }
      },
      resizeListener() {
        if (window.innerWidth < 1024) {
          this.height = `${window.innerHeight}px`;
          this.heightMessages = `${window.innerHeight - 136}px`;
        } else {
          this.height = null;
          this.heightMessages = null;
        }
      },
      scrollHandler(position) {
        this.top = position < originalTop - 20 ? originalTop - position : 20;
      },
      handleMessage(text) {
        for (let user of this.userList) {
          text = text.replace(new RegExp(escapeRegExp(`[~${user.id}]`), 'g'), '<b>' + user.name + '</b>');
        }
        return text;
      },
    },
  };
</script>
