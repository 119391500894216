const STORE_KEY = 'redbull-event-comments';
const CUTOFF = new Date().getTime() / 1000 - 4 * 31 * 24 * 60 * 60;

const readFromStore = eventId => {
  let store = window.localStorage.getItem(STORE_KEY);
  if (store) {
    store = JSON.parse(store);
    if (eventId in store) {
      return store[eventId];
    }
  }
  return [];
};

const saveToStore = (eventId, messages) => {
  let data = {};
  let store = window.localStorage.getItem(STORE_KEY);

  if (store) {
    data = JSON.parse(store);
  }

  data[eventId] = messages;

  for (let id in data) {
    data[id] = data[id].filter(e => e.updated > CUTOFF);
    if (data[id] && data[id].length === 0) delete data[id];
  }

  window.localStorage.setItem(STORE_KEY, JSON.stringify(data));
  return true;
};

export function getUnreadComments(eventId, messages) {
  let store = readFromStore(eventId);

  messages = messages
    .map(e => {
      e.updated = Math.round(new Date(e.updated).getTime() / 1000);
      return e;
    })
    .filter(e => e.updated > CUTOFF);

  let unread = [];
  for (let message of messages) {
    let inStore = store.find(e => e.id === message.id);
    if (!(inStore && inStore.updated === message.updated)) {
      unread.push(message.id);
    }
  }
  return unread;
}

export function setReadComments(eventId, messages) {
  messages = messages
    .map(e => {
      e.updated = Math.round(new Date(e.updated).getTime() / 1000);
      return e;
    })
    .filter(e => e.updated > CUTOFF);

  saveToStore(eventId, messages);
}
