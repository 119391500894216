<template>
  <vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal-container" content-class="modal-content error-modal" v-on="$listeners">
    <div class="error-modal__content">
      <div>
        <h1>
          <inline-svg :src="require('../../assets/icons/cross-circled.svg')"></inline-svg>
          <span>{{ $t('errorModal.title') }}</span>
        </h1>
        <p v-if="error && error.message">{{ $t('errorModal.titleSecondary') }}: {{ error.message }}</p>
        <p>
          {{ $t('errorModal.contact') }} <a href="mailto:redbull-onp-support@quanti.cz">redbull-onp-support&#64;<!-- -->quanti.cz</a>
        </p>
      </div>
    </div>
    <div v-if="error" class="error-modal__button">
      <button class="button is-warning" @click="copyError">
        <span class="icon">
          <inline-svg :src="require('../../assets/icons/copy.svg')"></inline-svg>
        </span>
        <span>{{ $t('errorModal.copy') }}</span>
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      //var msg = successful ? 'successful' : 'unsuccessful';
      //console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      //console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text);
  }

  export default {
    name: 'EventErrorModal',
    inheritAttrs: false,
    // eslint-disable-next-line vue/require-prop-types
    props: ['error'],
    methods: {
      copyError() {
        if (this.error) {
          if (this.error.response) {
            let data = {
              config: this.error.response.config,
              response: this.error.response.data,
            };
            copyTextToClipboard(JSON.stringify(data));
          } else {
            copyTextToClipboard(JSON.stringify(this.error));
          }
        }
      },
    },
  };
</script>
