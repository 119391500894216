<template>
  <span v-if="hasStatus" class="status" :class="classStatus">
    <span>{{ $t(`events.states.${status}`) }}</span>
  </span>
</template>

<script>
  import { isValidStatus } from '@/services/status';

  export default {
    name: 'EventStatus',
    props: {
      status: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'basic',
      },
    },
    computed: {
      classStatus() {
        return `is-${this.status.toLowerCase()} is-${this.type}`;
      },
      hasStatus() {
        return isValidStatus(this.status);
      },
    },
  };
</script>
