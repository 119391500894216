import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { APIURL } from '../consts';
import auth from './auth';
import router from '../router';

const refreshToken = () =>
  auth.getToken().then(() => {
    return Promise.resolve();
  });

createAuthRefreshInterceptor(axios, refreshToken);

axios.defaults.baseURL = APIURL;

axios.interceptors.request.use(config => {
  if (auth.state.accessToken) {
    config.headers = {
      Authorization: `Bearer ${auth.state.accessToken}`,
      'Content-Type': 'application/json',
    };
  }
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    return new Promise((resolve, reject) => {
      if (error?.response?.status === 403) {
        if (router.currentRoute.name !== 'Login') {
          router.push({ name: 'Login', params: { forbidden: true } });
        }
      } else throw error;
    });
  }
);

export default axios;
