import { configure, extend } from 'vee-validate';
import { required, required_if, email, min, min_value } from 'vee-validate/dist/rules';
import i18n from './i18n';

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`validations.fields.${field}`);

    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});

const includes = (collection, item) => {
  return collection.indexOf(item) !== -1;
};

const isEmptyArray = arr => {
  return Array.isArray(arr) && arr.length === 0;
};

const testEmpty = value => {
  return isEmptyArray(value) || includes([false, null, undefined], value) || !String(value).trim().length;
};

extend('required', required);
extend('required_if', required_if);
extend('email', email);
extend('min', min);
extend('min_value', min_value);

extend('minValueIf', {
  computesRequired: true,
  params: [
    'min',
    {
      name: 'target',
      isTarget: true,
    },
  ],
  validate: (value, { min, target }) => {
    if (testEmpty(target)) return true;
    return !testEmpty(target) && Number(value) >= min;
  },
  message: (field, values) => {
    values._field_ = i18n.t(`validations.fields.${field}`);
    return i18n.t('validations.messages.minValueIf', values);
  },
});

extend('minContacts', {
  computesRequired: true,
  params: ['count', 'min'],
  validate: (value, { count, min }) => {
    return count >= min;
  },
  message: i18n.t('validations.messages.minContacts'),
});

extend('minContactsIf', {
  computesRequired: true,
  params: [
    'count',
    'min',
    {
      name: 'target',
      isTarget: true,
    },
  ],
  validate: (value, { count, min, target }) => {
    if (testEmpty(target)) return true;
    return !testEmpty(target) && count >= min;
  },
  message: i18n.t('validations.messages.minContactsIf'),
});

extend('minProducts', {
  computesRequired: true,
  params: ['count', 'min'],
  validate: (value, { count, min }) => {
    return count >= min;
  },
  message: i18n.t('validations.messages.minProducts'),
});

extend('minProductsIf', {
  computesRequired: true,
  params: [
    'count',
    'min',
    {
      name: 'target',
      isTarget: true,
    },
  ],
  validate: (value, { count, min, target }) => {
    if (testEmpty(target)) return true;
    return !testEmpty(target) && count >= min;
  },
  message: i18n.t('validations.messages.minProductsIf'),
});

extend('minHardware', {
  computesRequired: true,
  params: ['count', 'min'],
  validate: (value, { count, min }) => {
    return count >= min;
  },
  message: i18n.t('validations.messages.minHardware'),
});

extend('minHardwareIf', {
  computesRequired: true,
  params: [
    'count',
    'min',
    {
      name: 'target',
      isTarget: true,
    },
  ],
  validate: (value, { count, min, target }) => {
    if (testEmpty(target)) return true;
    return !testEmpty(target) && count >= min;
  },
  message: i18n.t('validations.messages.minHardwareIf'),
});

extend('hardwareValidValues', {
  computesRequired: true,
  params: ['data'],
  validate: (value, { data }) => {
    for (let item of data) {
      if (isNaN(parseInt(item.amount)) || isNaN(parseInt(item.amountVIP))) return false;
    }
    return true;
  },
  message: i18n.t('validations.messages.hardwareValidValues'),
});

extend('minInventory', {
  computesRequired: true,
  params: ['count', 'min'],
  validate: (value, { count, min }) => {
    return count >= min;
  },
  message: i18n.t('validations.messages.minInventory'),
});

extend('minInventoryIf', {
  computesRequired: true,
  params: [
    'count',
    'min',
    {
      name: 'target',
      isTarget: true,
    },
  ],
  validate: (value, { count, min, target }) => {
    if (testEmpty(target)) return true;
    return !testEmpty(target) && count >= min;
  },
  message: i18n.t('validations.messages.minInventoryIf'),
});

extend('minInventoryFile', {
  computesRequired: true,
  params: ['count', 'min'],
  validate: (value, { count, min }) => {
    return !!count;
  },
  message: i18n.t('validations.messages.minInventoryFile'),
});

extend('minInventoryFileIf', {
  computesRequired: true,
  params: [
    'count',
    'min',
    {
      name: 'target',
      isTarget: true,
    },
  ],
  validate: (value, { count, min, target }) => {
    if (testEmpty(target)) return true;
    return !testEmpty(target) && !!count;
  },
  message: i18n.t('validations.messages.minInventoryFileIf'),
});
