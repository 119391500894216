<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.inventory.searchButton')" />
      </p>
    </div>

    <div class="tabs inner-tabs">
      <ul>
        <li :class="{ 'is-active': active === 'items' }">
          <a @click="active = 'items'">
            {{ $t('admin.tabs.inventory.tabs.items.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'categories' }">
          <a @click="active = 'categories'">
            {{ $t('admin.tabs.inventory.tabs.categories.title') }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div v-if="active === 'items' && !loading">
        <div class="columns">
          <div class="column is-6">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.inventory.tabs.items.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.inventory.tabs.items.headers.headerTwo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredInventoryItems" :key="item.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="item.name" :readonly="!item.isEditing" class="input" />
                  </td>

                  <td>
                    <div class="select is-fullwidth">
                      <select v-model="item.categoryId" :disabled="!item.isEditing">
                        <option v-for="category in inventoryCategories" :key="category.id" :value="category.id" class="help">
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="table-actions">
                    <button v-if="!item.isEditing" class="button is-input" @click="editItem(item.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(item)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(item)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'categories'">
        <div class="columns">
          <div class="column is-4">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.inventory.tabs.categories.title') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(category, index) in filteredInventoryCategories" :key="category.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="category.name" :readonly="!category.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!category.isEditing" class="button is-input" @click="editItem(category.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(category)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a v-if="!category.isUsed" class="table-delete">
                      <span class="icon" @click="deleteItem(category)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                    <a v-else class="table-delete">
                      <span
                        class="icon has-tooltip-right has-tooltip-arrow has-tooltip-danger"
                        :data-tooltip="$t('admin.tabs.inventory.tabs.categories.tooltip')"
                      >
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as inventoryService from '@/api/inventoryService';
  import { searchTerm } from '@/filters';

  export default {
    name: 'AdminInventory',
    data() {
      return {
        active: 'items',
        loading: false,
        filterText: null,
        inventoryItems: [],
        inventoryCategories: [],
      };
    },
    computed: {
      filteredInventoryItems() {
        if (this.filterText && this.filterText !== '') {
          return this.inventoryItems.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.inventoryItems;
        }
      },
      filteredInventoryCategories() {
        const intersection = this.inventoryCategories.filter(category => this.inventoryItems.find(item => item.categoryId === category.id));

        intersection.forEach(x => {
          x.isUsed = true;
        });

        if (this.filterText && this.filterText !== '') {
          return this.inventoryCategories.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.inventoryCategories;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await Promise.all([this.loadInventoryItems(), this.loadInventoryCategories()]);
      },
      editItem(id) {
        if (this.active === 'items') {
          this.$set(
            this.inventoryItems.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'categories') {
          this.$set(
            this.inventoryCategories.find(item => item.id === id),
            'isEditing',
            true
          );
        }
      },
      addItem() {
        if (this.active === 'items') {
          this.inventoryItems.push({ name: '', isEditing: true });
        } else if (this.active === 'categories') {
          this.inventoryCategories.push({ name: '', isEditing: true });
        }
      },
      processItem(data) {
        data.id ? this.updateItem(data) : this.setNewItem(data);
      },
      async updateItem({ id, name, categoryId }) {
        if (this.active === 'items') {
          try {
            this.loading = true;
            const res = await inventoryService.updateInventory(id, { name, categoryId, active: true });
            if (res.status === 200) await this.loadInventoryItems();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'categories') {
          try {
            this.loading = true;
            const res = await inventoryService.updateInventoryCategory(id, { name, active: true });
            if (res.status === 200) await this.loadInventoryCategories();
          } finally {
            this.loading = false;
          }
        }
      },
      async deleteItem({ id, name, categoryId }) {
        if (this.active === 'items') {
          if (!id) {
            this.inventoryItems.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await inventoryService.updateInventory(id, { name, categoryId, active: false });
              if (res.status === 200) await this.loadInventoryItems();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'categories') {
          if (!id) {
            this.inventoryCategories.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await inventoryService.updateInventoryCategory(id, { name, active: false });
              if (res.status === 200) await this.loadInventoryCategories();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async setNewItem({ name, categoryId }) {
        if (this.active === 'items') {
          if (name) {
            try {
              this.loading = true;
              const res = await inventoryService.createNewInventory({ name, categoryId });
              if (res.status === 201) await this.loadInventoryItems();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'categories') {
          if (name) {
            try {
              this.loading = true;
              const res = await inventoryService.createNewInventoryCategory({ name });
              if (res.status === 201) await this.loadInventoryCategories();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async loadInventoryItems() {
        try {
          this.loading = true;
          const inventoryItems = await inventoryService.getInventories('active');
          this.inventoryItems = inventoryItems.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadInventoryCategories() {
        try {
          this.loading = true;
          const inventoryCategories = await inventoryService.getInventoryCategories('active');
          this.inventoryCategories = inventoryCategories.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
