<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.staffContracts.searchButton')" />
      </p>
    </div>

    <div class="tabs inner-tabs">
      <ul>
        <li :class="{ 'is-active': active === 'contractType' }">
          <a @click="active = 'contractType'">
            {{ $t('admin.tabs.staffContracts.tabs.contractType.title') }}
          </a>
        </li>
        <!--        <li :class="{ 'is-active': active === 'countries' }">-->
        <!--          <a @click="active = 'countries'">-->
        <!--            {{ $t('admin.tabs.staffContracts.tabs.countries.title') }}-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li :class="{ 'is-active': active === 'currency' }">-->
        <!--          <a @click="active = 'currency'">-->
        <!--            {{ $t('admin.tabs.staffContracts.tabs.currency.title') }}-->
        <!--          </a>-->
        <!--        </li>-->
        <li :class="{ 'is-active': active === 'extraCosts' }">
          <a @click="active = 'extraCosts'">
            {{ $t('admin.tabs.staffContracts.tabs.extraCosts.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'wageTypes' }">
          <a @click="active = 'wageTypes'">
            {{ $t('admin.tabs.staffContracts.tabs.wageTypes.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'wages' }">
          <a @click="active = 'wages'">
            {{ $t('admin.tabs.staffContracts.tabs.wages.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'feeTypes' }">
          <a @click="active = 'feeTypes'">
            {{ $t('admin.tabs.staffContracts.tabs.feeTypes.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'staff' }">
          <a @click="active = 'staff'">
            {{ $t('admin.tabs.staffContracts.tabs.staff.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'districtGroups' }">
          <a @click="active = 'districtGroups'">
            {{ $t('admin.tabs.staffContracts.tabs.districtGroups.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'agency' }">
          <a @click="active = 'agency'">
            {{ $t('admin.tabs.staffContracts.tabs.agency.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'logisticCosts' }">
          <a @click="active = 'logisticCosts'">
            {{ $t('admin.tabs.staffContracts.tabs.logisticCosts.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'subordinates' }">
          <a @click="active = 'subordinates'">
            {{ $t('admin.tabs.staffContracts.tabs.subordinates.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'cancellationTypes' }">
          <a @click="active = 'cancellationTypes'">
            {{ $t('admin.tabs.staffContracts.tabs.cancellationTypes.title') }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div v-if="active === 'contractType' && !loading" class="columns">
        <div class="form column is-5">
          <h3>{{ $t('admin.tabs.staffContracts.tabs.contractType.subtitle') }}</h3>
          <div class="form-wrapper">
            <div v-for="(contractType, index) in filteredContractTypes" :key="contractType.id" class="form-field">
              <div class="field is-horizontal">
                <div class="field-label is-counter">
                  <span class="label">{{ parseInt(index) + 1 }}</span>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input v-model="contractType.name" :readonly="!contractType.isEditing" class="input" />
                    </p>
                  </div>
                  <div class="field is-narrow">
                    <p class="control">
                      <button v-if="!contractType.isEditing" class="button is-input" @click="editItem(contractType.id)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                        </span>
                      </button>
                      <button v-else class="button is-input" @click="processItem(contractType)">
                        <span class="icon is-green">
                          <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                        </span>
                      </button>
                      <a class="field-delete" @click.prevent="deleteItem(contractType)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field">
              <div class="field is-horizontal level-right">
                <div class="field is-grouped">
                  <p class="control">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div v-if="active === 'countries'">countries</div>-->
      <!--      <div v-if="active === 'currency'">currency</div>-->
      <div v-if="active === 'extraCosts' && !loading" class="columns">
        <div class="column is-10">
          <table class="table admin-table">
            <thead>
              <tr>
                <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.extraCosts.title') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.extraCosts.titleType') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.extraCosts.titlePreferably') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.extraCosts.agencyCostEstimation') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.extraCosts.agencyReporting') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(extraCost, index) in filteredExtraCosts" :key="extraCost.id">
                <td class="table-counter">
                  <span>{{ parseInt(index) + 1 }}</span>
                </td>
                <td>
                  <input v-model="extraCost.name" :readonly="!extraCost.isEditing" class="input" />
                </td>

                <td>
                  <div class="select is-fullwidth">
                    <select v-model="extraCost.type" :disabled="!extraCost.isEditing">
                      <option v-for="(type, i) in extraCostsTypes" :key="i" class="help">
                        {{ type }}
                      </option>
                    </select>
                  </div>
                </td>
                <td class="table-switch">
                  <Toggle v-model="extraCost.displayedProminently" :disabled="!extraCost.isEditing" type="icon" />
                </td>
                <td class="table-switch">
                  <Toggle v-model="extraCost.agencyCostEstimation" :disabled="!extraCost.isEditing" type="icon" />
                </td>
                <td class="table-switch">
                  <Toggle v-model="extraCost.agencyReporting" :disabled="!extraCost.isEditing" type="icon" />
                </td>
                <td class="table-actions">
                  <button v-if="!extraCost.isEditing" class="button is-input" @click="editItem(extraCost.id)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                    </span>
                  </button>
                  <button v-else class="button is-input" @click="processItem(extraCost)">
                    <span class="icon is-green">
                      <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                    </span>
                  </button>
                  <a class="table-delete">
                    <span class="icon" @click="deleteItem(extraCost)">
                      <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="6"></td>
                <td class="table-actions">
                  <button class="button is-input" @click="addItem">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="active === 'wageTypes' && !loading" class="columns">
        <div class="form column is-5">
          <h3>{{ $t('admin.tabs.staffContracts.tabs.wageTypes.title') }}</h3>
          <div class="form-wrapper">
            <div v-for="(wageType, index) in filteredWageTypes" :key="wageType.id" class="form-field">
              <div class="field is-horizontal">
                <div class="field-label is-counter">
                  <span class="label">{{ parseInt(index) + 1 }}</span>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input v-model="wageType.name" :readonly="!wageType.isEditing" class="input" />
                    </p>
                  </div>
                  <div class="field is-narrow">
                    <p class="control">
                      <button v-if="!wageType.isEditing" class="button is-input" @click="editItem(wageType.id)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                        </span>
                      </button>
                      <button v-else class="button is-input" @click="processItem(wageType)">
                        <span class="icon is-green">
                          <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                        </span>
                      </button>
                      <a class="field-delete" @click.prevent="deleteItem(wageType)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field">
              <div class="field is-horizontal level-right">
                <div class="field is-grouped">
                  <p class="control">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="active === 'wages' && !loading">
        <div class="column is-10">
          <table class="table admin-table">
            <thead>
              <tr>
                <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.wages.headers.headerOne') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.wages.headers.headerTwo') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.wages.headers.headerThree') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.wages.headers.headerFour') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.wages.headers.headerFive') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.wages.headers.headerSix') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(wage, index) in filteredWages" :key="wage.id">
                <td class="table-counter">
                  <span>{{ parseInt(index) + 1 }}</span>
                </td>
                <td>
                  <input v-model="wage.cost" :readonly="!wage.isEditing" class="input" type="number" />
                </td>
                <td>
                  <div class="select is-fullwidth">
                    <select v-model="wage.currencyCode" :disabled="!wage.isEditing">
                      <option v-for="(currency, i) in currencies" :key="i" class="help">
                        {{ currency.code }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="select is-fullwidth">
                    <select v-model="wage.wageTypeId" :disabled="!wage.isEditing">
                      <option v-for="wageType in wageTypes" :key="wageType.id" :value="wageType.id" class="help">
                        {{ wageType.name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="select is-fullwidth">
                    <select v-model="wage.staffTypeId" :disabled="!wage.isEditing">
                      <option v-for="staffType in staffTypes" :key="staffType.id" :value="staffType.id" class="help">
                        {{ staffType.name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="select is-fullwidth">
                    <select v-model="wage.sizeId" :disabled="!wage.isEditing">
                      <option v-for="size in sizes" :key="size.id" :value="size.id" class="help">
                        {{ size.name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="select is-fullwidth">
                    <select v-model="wage.districtGroupId" :disabled="!wage.isEditing">
                      <option v-for="districtGroup in districtGroups" :key="districtGroup.id" :value="districtGroup.id" class="help">
                        {{ districtGroup.name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td class="table-actions">
                  <button v-if="!wage.isEditing" class="button is-input" @click="editItem(wage.id)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                    </span>
                  </button>
                  <button v-else class="button is-input" @click="processItem(wage)">
                    <span class="icon is-green">
                      <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                    </span>
                  </button>
                  <a class="table-delete">
                    <span class="icon" @click="deleteItem(wage)">
                      <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="7"></td>
                <td class="table-actions">
                  <button class="button is-input" @click="addItem">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="active === 'feeTypes' && !loading">
        <div class="columns">
          <div class="column is-8">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.feeTypes.title') }}</th>
                  <th>{{ $t('admin.tabs.staffContracts.tabs.feeTypes.titleOrder') }}</th>
                  <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.feeTypes.titlePreferably') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(feeType, index) in filteredFeeTypes" :key="feeType.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="feeType.name" :readonly="!feeType.isEditing" class="input" />
                  </td>

                  <td class="table-order">
                    <input v-model="feeType.order" :readonly="!feeType.isEditing" class="input" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="feeType.displayedProminently" :disabled="!feeType.isEditing" type="icon" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!feeType.isEditing" class="button is-input" @click="editItem(feeType.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(feeType)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(feeType)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'staff'">
        <div class="columns">
          <div class="column is-8">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.staff.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.staffContracts.tabs.staff.headers.type') }}</th>
                  <th>{{ $t('admin.tabs.staffContracts.tabs.staff.headers.headerTwo') }}</th>
                  <th>{{ $t('admin.tabs.staffContracts.tabs.staff.headers.headerThree') }}</th>
                  <th>{{ $t('admin.tabs.staffContracts.tabs.staff.headers.agencyCostEstimation') }}</th>
                  <th>{{ $t('admin.tabs.staffContracts.tabs.staff.headers.agencyReporting') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(staffType, index) in filteredStaffTypes" :key="staffType.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="staffType.name" :readonly="!staffType.isEditing" class="input" />
                  </td>
                  <td class="is-width-2">
                    <div class="select is-fullwidth">
                      <select v-model="staffType.type" :disabled="!staffType.isEditing">
                        <option v-for="(type, i) in ['Simple', 'Scheduled']" :key="i" class="help">
                          {{ type }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="staffType.default" :disabled="!staffType.isEditing" type="icon" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="staffType.unitRequired" :disabled="!staffType.isEditing" type="icon" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="staffType.agencyCostEstimation" :disabled="!staffType.isEditing" type="icon" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="staffType.agencyReporting" :disabled="!staffType.isEditing" type="icon" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!staffType.isEditing" class="button is-input" @click="editItem(staffType.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(staffType)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(staffType)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="7"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'districtGroups' && !loading" class="columns">
        <div class="column is-12">
          <table class="table admin-table">
            <thead>
              <tr>
                <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.districtGroups.headers.headerOne') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.districtGroups.headers.headerTwo') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(districtGroup, index) in filteredDistrictGroups" :key="districtGroup.id">
                <td class="table-counter">
                  <span>{{ parseInt(index) + 1 }}</span>
                </td>
                <td class="table-district-input">
                  <input v-model="districtGroup.name" :readonly="!districtGroup.isEditing" class="input" />
                </td>
                <td class="table-multiselect">
                  <Multiselect
                    v-model="districtGroup.districts"
                    :options="filteredDistricts"
                    track-by="code"
                    label="name"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :disabled="!districtGroup.isEditing"
                    placeholder=""
                    :select-label="$t('multiselect.dataSelect')"
                    :selected-label="$t('multiselect.dataSelected')"
                    :deselect-label="$t('multiselect.dataDeselect')"
                  >
                    <template slot="noResult">{{ $t('multiselect.noResult') }}</template>
                    <template slot="noOptions">{{ $t('multiselect.noOptions') }}</template>
                  </Multiselect>
                </td>
                <td class="table-actions">
                  <button v-if="!districtGroup.isEditing" class="button is-input" @click="editItem(districtGroup.id)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                    </span>
                  </button>
                  <button v-else class="button is-input" @click="processItem(districtGroup)">
                    <span class="icon is-green">
                      <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                    </span>
                  </button>
                  <a v-if="!districtGroup.hasDistricts" class="table-delete" @click.prevent="deleteItem(districtGroup)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                    </span>
                  </a>
                  <a v-else class="table-delete">
                    <span
                      class="icon has-tooltip-left has-tooltip-arrow has-tooltip-danger"
                      :data-tooltip="$t('admin.tabs.staffContracts.tabs.districtGroups.tooltip')"
                    >
                      <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td class="table-actions">
                  <button class="button is-input" @click="addItem">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="active === 'agency' && !loading">
        <div class="column is-12">
          <table class="table admin-table">
            <thead>
              <tr>
                <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.agency.headers.headerOne') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.agency.headers.headerTwo') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.agency.headers.headerThree') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(agency, index) in filteredAgencies" :key="agency.id">
                <td class="table-counter">
                  <span>{{ parseInt(index) + 1 }}</span>
                </td>
                <td class="table-district-input">
                  <input v-model="agency.name" :readonly="!agency.isEditing" class="input" />
                </td>
                <td class="table-district-input">
                  <input v-model="agency.code" :readonly="!agency.isEditing" class="input" />
                </td>
                <td class="table-multiselect">
                  <Multiselect
                    v-model="agency.users"
                    :options="agencyHostedUsers"
                    track-by="userId"
                    label="firstName"
                    :custom-label="nameWithLastname"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :disabled="!agency.isEditing"
                    placeholder=""
                    :select-label="$t('multiselect.dataSelect')"
                    :selected-label="$t('multiselect.dataSelected')"
                    :deselect-label="$t('multiselect.dataDeselect')"
                  ></Multiselect>
                </td>
                <td class="table-actions">
                  <button v-if="!agency.isEditing" class="button is-input" @click="editItem(agency.id)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                    </span>
                  </button>
                  <button v-else class="button is-input" @click="processItem(agency)">
                    <span class="icon is-green">
                      <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                    </span>
                  </button>
                  <a class="table-delete">
                    <span class="icon" @click="deleteItem(agency)">
                      <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="4"></td>
                <td class="table-actions">
                  <button class="button is-input" @click="addItem">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="active === 'logisticCosts' && !loading">
        <div class="column is-12">
          <table class="table admin-table">
            <thead>
              <tr>
                <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.logisticCosts.headers.headerOne') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.logisticCosts.headers.headerTwo') }}</th>
                <th v-for="currency in currencies" :key="currency.code">
                  {{ $t('admin.tabs.staffContracts.tabs.logisticCosts.headers.headerCost') }} {{ currency.code }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in filteredHostedUsers" :key="user.id">
                <td class="table-counter">
                  <span>{{ parseInt(index) + 1 }}</span>
                </td>
                <td>
                  <span>{{ user.name }}</span>
                </td>
                <td>
                  <span>{{ user.email }}</span>
                </td>
                <td v-for="currency in currencies" :key="currency.code" class="table-district-input">
                  <input v-model.number="logistics[user.id][currency.code]" :readonly="!user.isEditing" class="input" />
                </td>
                <td class="table-actions">
                  <button v-if="!user.isEditing" class="button is-input" @click="editItem(user.id)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                    </span>
                  </button>
                  <button v-else class="button is-input" @click="processItem(logistics[user.id], user.id)">
                    <span class="icon is-green">
                      <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="active === 'subordinates' && !loading">
        <div class="column is-12">
          <table class="table admin-table">
            <thead>
              <tr>
                <th colspan="2">{{ $t('admin.tabs.staffContracts.tabs.subordinates.headers.headerOne') }}</th>
                <th>{{ $t('admin.tabs.staffContracts.tabs.subordinates.headers.headerTwo') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subordinate, index) in filteredSubordinates" :key="subordinate.userId">
                <td class="table-counter">
                  <span>{{ parseInt(index) + 1 }}</span>
                </td>
                <td class="table-district-input">
                  <span>{{ subordinate.name }}</span>
                </td>
                <td class="table-multiselect">
                  <Multiselect
                    v-model="subordinate.hierarchy"
                    :options="withoutSelf(subordinate)"
                    track-by="userId"
                    label="firstName"
                    :custom-label="nameWithLastname"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :disabled="!subordinate.isEditing"
                    placeholder=""
                    :select-label="$t('multiselect.dataSelect')"
                    :selected-label="$t('multiselect.dataSelected')"
                    :deselect-label="$t('multiselect.dataDeselect')"
                  ></Multiselect>
                </td>
                <td class="table-actions">
                  <button v-if="!subordinate.isEditing" class="button is-input" @click="editItem(subordinate.userId)">
                    <span class="icon">
                      <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                    </span>
                  </button>
                  <button v-else class="button is-input" @click="processItem(subordinate)">
                    <span class="icon is-green">
                      <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="active === 'cancellationTypes' && !loading" class="columns">
        <div class="form column is-5">
          <h3>{{ $t('admin.tabs.staffContracts.tabs.cancellationTypes.title') }}</h3>
          <div class="form-wrapper">
            <div v-for="(cancellationType, index) in filteredCancellationTypes" :key="cancellationType.id" class="form-field">
              <div class="field is-horizontal">
                <div class="field-label is-counter">
                  <span class="label">{{ parseInt(index) + 1 }}</span>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input v-model="cancellationType.name" :readonly="!cancellationType.isEditing" class="input" />
                    </p>
                  </div>
                  <div class="field is-narrow">
                    <p class="control">
                      <button v-if="!cancellationType.isEditing" class="button is-input" @click="editItem(cancellationType.id)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                        </span>
                      </button>
                      <button v-else class="button is-input" @click="processItem(cancellationType)">
                        <span class="icon is-green">
                          <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                        </span>
                      </button>
                      <a class="field-delete" @click.prevent="deleteItem(cancellationType)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field">
              <div class="field is-horizontal level-right">
                <div class="field is-grouped">
                  <p class="control">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as contractService from '@/api/contractService';
  import * as extraCostsService from '@/api/extraCostsService';
  import * as wageService from '@/api/wageService';
  import * as feesService from '@/api/feesService';
  import * as staffService from '@/api/staffTypeService';
  import { getCurrencies } from '@/api/currencyService';
  import * as districtService from '@/api/districtService';
  import * as agencyService from '@/api/agencyService';
  import * as hostedUserService from '@/api/hostedUserService';
  import * as hierarchyService from '@/api/hierarchyService';
  import * as cancellationService from '@/api/cancellationService';
  import Toggle from '@/components/Toggle';
  import { getSizes } from '@/api/sizeService';
  import { searchTerm } from '@/filters';
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'AdminGeneral',
    components: { Toggle, Multiselect },
    data() {
      return {
        active: 'contractType',
        loading: false,
        filterText: null,
        contractTypes: [],
        extraCosts: [],
        wageTypes: [],
        wages: [],
        feeTypes: [],
        currencies: [],
        sizes: [],
        staffTypes: [],
        districtGroups: [],
        districts: [],
        agencies: [],
        hostedUsers: [],
        logistics: {},
        subordinates: [],
        cancellationTypes: [],
      };
    },
    computed: {
      filteredContractTypes() {
        if (this.filterText && this.filterText !== '') {
          return this.contractTypes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.contractTypes;
        }
      },
      filteredExtraCosts() {
        if (this.filterText && this.filterText !== '') {
          return this.extraCosts.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.extraCosts;
        }
      },
      extraCostsTypes() {
        return Array.from(new Set(this.extraCosts.map(item => item.type).filter(x => x)));
      },
      filteredWageTypes() {
        if (this.filterText && this.filterText !== '') {
          return this.wageTypes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.wageTypes;
        }
      },
      filteredWages() {
        if (this.filterText && this.filterText !== '') {
          return this.wages.filter(item => Object.values(item).some(value => searchTerm(value.toString(), this.filterText)));
        } else {
          return this.wages;
        }
      },
      filteredFeeTypes() {
        if (this.filterText && this.filterText !== '') {
          return this.feeTypes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.feeTypes;
        }
      },
      filteredStaffTypes() {
        if (this.filterText && this.filterText !== '') {
          return this.staffTypes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.staffTypes;
        }
      },
      filteredDistrictGroups() {
        const intersection = this.districtGroups.filter(district => district.districts?.length);

        intersection.forEach(x => {
          x.hasDistricts = true;
        });

        if (this.filterText && this.filterText !== '') {
          return this.districtGroups.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.districtGroups;
        }
      },
      filteredDistricts() {
        return this.districts.filter(district => !district.districtGroupId);
      },
      filteredAgencies() {
        if (this.filterText && this.filterText !== '') {
          return this.agencies.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.agencies;
        }
      },
      agencyHostedUsers() {
        return this.hostedUsers.map(user => ({ userId: user.id, firstName: user.firstName, lastName: user.lastName }));
      },
      filteredHostedUsers() {
        if (this.filterText && this.filterText !== '') {
          return this.hostedUsers.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.hostedUsers;
        }
      },
      filteredSubordinates() {
        if (this.filterText && this.filterText !== '') {
          return this.subordinates.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.subordinates;
        }
      },
      filteredCancellationTypes() {
        if (this.filterText && this.filterText !== '') {
          return this.cancellationTypes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.cancellationTypes;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      nameWithLastname(data) {
        if (typeof data === 'string') {
          const user = this.hostedUsers.find(user => user.id === data);
          return `${user.firstName} ${user.lastName}`;
        } else {
          return `${data.firstName} ${data.lastName}`;
        }
      },
      withoutSelf(subordinate) {
        return this.hostedUsers
          .map(user => ({ userId: user.id, firstName: user.firstName, lastName: user.lastName }))
          .filter(user => user.userId !== subordinate.userId);
      },
      async fetchData() {
        await Promise.all([
          this.loadContractTypes(),
          this.loadExtraCosts(),
          this.loadFeeTypes(),
          this.loadWageTypes(),
          this.loadWages(),
          this.loadCurrencies(),
          this.loadSizes(),
          this.loadStaffTypes(),
          this.loadDistrictGroups(),
          this.loadDistricts(),
          this.loadAgencies(),
          this.loadHostedUsers(),
          this.loadLogisticCosts(),
          this.loadSubordinates(),
          this.loadCancellationTypes(),
        ]);
      },
      editItem(id) {
        if (this.active === 'contractType') {
          this.$set(
            this.contractTypes.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'extraCosts') {
          this.$set(
            this.extraCosts.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'feeTypes') {
          this.$set(
            this.feeTypes.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'wageTypes') {
          this.$set(
            this.wageTypes.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'wages') {
          this.$set(
            this.wages.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'staff') {
          this.$set(
            this.staffTypes.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'districtGroups') {
          this.$set(
            this.districtGroups.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'agency') {
          this.$set(
            this.agencies.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'logisticCosts') {
          this.$set(
            this.hostedUsers.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'subordinates') {
          this.$set(
            this.subordinates.find(item => item.userId === id),
            'isEditing',
            true
          );
        } else if (this.active === 'cancellationTypes') {
          this.$set(
            this.cancellationTypes.find(item => item.id === id),
            'isEditing',
            true
          );
        }
      },
      addItem() {
        if (this.active === 'contractType') {
          this.contractTypes.push({ name: '', isEditing: true });
        } else if (this.active === 'extraCosts') {
          this.extraCosts.push({ name: '', agencyCostEstimation: false, agencyReporting: false, isEditing: true });
        } else if (this.active === 'feeTypes') {
          this.feeTypes.push({ name: '', isEditing: true });
        } else if (this.active === 'wageTypes') {
          this.wageTypes.push({ name: '', isEditing: true });
        } else if (this.active === 'wages') {
          this.wages.push({ name: '', isEditing: true });
        } else if (this.active === 'staff') {
          this.staffTypes.push({ name: '', agencyCostEstimation: false, agencyReporting: false, isEditing: true });
        } else if (this.active === 'districtGroups') {
          this.districtGroups.push({ name: '', isEditing: true });
        } else if (this.active === 'agency') {
          this.agencies.push({ name: '', isEditing: true });
        } else if (this.active === 'cancellationTypes') {
          this.cancellationTypes.push({ name: '', isEditing: true });
        }
      },
      processItem(data, id) {
        if (this.active === 'logisticCosts') {
          this.updateItem({ id, logistics: data });
        } else if (this.active === 'subordinates') {
          this.updateItem({ id, hierarchy: data });
        } else {
          data.id ? this.updateItem(data) : this.setNewItem(data);
        }
      },
      async updateItem({
        id,
        name,
        displayedProminently,
        type,
        order,
        default: def,
        cost,
        currencyCode,
        wageTypeId,
        staffTypeId,
        sizeId,
        districtGroupId,
        districts,
        unitRequired,
        agencyCostEstimation,
        agencyReporting,
        code,
        users,
        logistics,
        hierarchy,
      }) {
        if (this.active === 'contractType') {
          try {
            this.loading = true;
            const res = await contractService.updateContractType(id, { name, active: true });
            if (res.status === 200) await this.loadContractTypes();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'extraCosts') {
          try {
            this.loading = true;
            const res = await extraCostsService.updateExtraCosts(id, { name, displayedProminently, agencyCostEstimation, agencyReporting, type, active: true });
            if (res.status === 200) await this.loadExtraCosts();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'feeTypes') {
          try {
            this.loading = true;
            const res = await feesService.updateFeeType(id, { name, displayedProminently, order, active: true });
            if (res.status === 200) await this.loadFeeTypes();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'wageTypes') {
          try {
            this.loading = true;
            const res = await wageService.updateWageType(id, { name, active: true });
            if (res.status === 200) await this.loadWageTypes();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'wages') {
          try {
            this.loading = true;
            const res = await wageService.updateWage(id, { cost, currencyCode, wageTypeId, staffTypeId, sizeId, districtGroupId, active: true });
            if (res.status === 200) await this.loadWages();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'staff') {
          try {
            this.loading = true;
            const res = await staffService.updateStaffType(id, { name, default: def, unitRequired, agencyCostEstimation, agencyReporting, type, active: true });
            if (res.status === 200) await this.loadStaffTypes();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'districtGroups') {
          let districtCodes = districts.map(item => item.code);
          try {
            this.loading = true;
            const res = await districtService.updateDistrictGroup(id, { name, districtCodes, active: true });
            if (res.status === 200) await this.loadDistrictGroups();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'agency') {
          try {
            this.loading = true;
            const res = await agencyService.updateAgency(id, { name, code, users, active: true });
            if (res.status === 200) await this.loadAgencies();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'logisticCosts') {
          try {
            let logisticsCosts = [];
            for (const [key, value] of Object.entries(logistics)) {
              logisticsCosts.push({ currencyCode: key, cost: value });
            }
            this.loading = true;
            const res = await hostedUserService.updateUserLogisticCost(id, { logisticsCosts });
            if (res.status === 200) await this.loadHostedUserLogisticsCosts(id);
          } finally {
            this.loading = false;
            this.hostedUsers.find(user => user.id === id).isEditing = false;
          }
        } else if (this.active === 'subordinates') {
          const subs = hierarchy.hierarchy.map(item => item.userId);
          try {
            this.loading = true;
            const res = await hierarchyService.updateSubordinate(hierarchy.userId, { userIds: subs });
            if (res.status === 200) await this.loadSubordinates();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'cancellationTypes') {
          try {
            this.loading = true;
            const res = await cancellationService.updateCancellationType(id, { name, active: true });
            if (res.status === 200) await this.loadCancellationTypes();
          } finally {
            this.loading = false;
          }
        }
      },
      async deleteItem({
        id,
        name,
        displayedProminently,
        type,
        order,
        default: def,
        cost,
        currencyCode,
        wageTypeId,
        staffTypeId,
        sizeId,
        districtGroupId,
        districts,
        unitRequired,
        agencyCostEstimation,
        agencyReporting,
        code,
        users,
      }) {
        if (this.active === 'contractType') {
          if (!id) {
            this.contractTypes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await contractService.updateContractType(id, { name, active: false });
              if (res.status === 200) await this.loadContractTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'extraCosts') {
          if (!id) {
            this.extraCosts.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await extraCostsService.updateExtraCosts(id, {
                name,
                displayedProminently,
                agencyCostEstimation,
                agencyReporting,
                type,
                active: false,
              });
              if (res.status === 200) await this.loadExtraCosts();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'feeTypes') {
          if (!id) {
            this.feeTypes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await feesService.updateFeeType(id, { name, displayedProminently, order, active: false });
              if (res.status === 200) await this.loadFeeTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'wageTypes') {
          if (!id) {
            this.wageTypes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await wageService.updateWageType(id, { name, active: false });
              if (res.status === 200) await this.loadWageTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'wages') {
          if (!id) {
            this.wages.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await wageService.updateWage(id, { cost, currencyCode, wageTypeId, staffTypeId, sizeId, districtGroupId, active: false });
              if (res.status === 200) await this.loadWages();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'staff') {
          if (!id) {
            this.staffTypes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await staffService.updateStaffType(id, {
                name,
                default: def,
                unitRequired,
                agencyCostEstimation,
                agencyReporting,
                type,
                active: false,
              });
              if (res.status === 200) await this.loadStaffTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'districtGroups') {
          if (!id) {
            this.districtGroups.splice(-1, 1);
          } else {
            let districtCodes = districts.map(item => item.code);
            try {
              this.loading = true;
              const res = await districtService.updateDistrictGroup(id, { name, districtCodes, active: false });
              if (res.status === 200) await this.loadDistrictGroups();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'agency') {
          if (!id) {
            this.agencies.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await agencyService.updateAgency(id, { name, code, users, active: false });
              if (res.status === 200) await this.loadAgencies();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'cancellationTypes') {
          if (!id) {
            this.cancellationTypes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await cancellationService.updateCancellationType(id, { name, active: false });
              if (res.status === 200) await this.loadCancellationTypes();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async setNewItem({
        name,
        displayedProminently,
        type,
        order,
        default: def,
        cost,
        currencyCode,
        wageTypeId,
        staffTypeId,
        sizeId,
        districtGroupId,
        districts,
        unitRequired,
        agencyCostEstimation,
        agencyReporting,
        code,
        users,
      }) {
        if (this.active === 'contractType') {
          if (name) {
            try {
              this.loading = true;
              const res = await contractService.createNewContractType({ name });
              if (res.status === 201) await this.loadContractTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'extraCosts') {
          if (name) {
            try {
              this.loading = true;
              const res = await extraCostsService.createNewExtraCost({
                name,
                displayedProminently: displayedProminently ? displayedProminently : false,
                agencyCostEstimation,
                agencyReporting,
                type,
              });
              if (res.status === 201) await this.loadExtraCosts();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'feeTypes') {
          if (name) {
            try {
              this.loading = true;
              const res = await feesService.createNewFeeType({
                name,
                displayedProminently: displayedProminently ? displayedProminently : false,
                order,
              });
              if (res.status === 201) await this.loadFeeTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'wageTypes') {
          if (name) {
            try {
              this.loading = true;
              const res = await wageService.createNewWageType({ name });
              if (res.status === 201) await this.loadWageTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'wages') {
          if (cost) {
            try {
              this.loading = true;
              const res = await wageService.createNewWage({ cost, currencyCode, wageTypeId, staffTypeId, sizeId, districtGroupId });
              if (res.status === 201) await this.loadWages();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'staff') {
          if (name) {
            if (!def) def = false;
            try {
              this.loading = true;
              const res = await staffService.createNewStaffType({ name, default: def, agencyCostEstimation, agencyReporting, type, unitRequired });
              if (res.status === 201) await this.loadStaffTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'districtGroups') {
          if (name) {
            let districtCodes = districts?.map(item => item.code);
            try {
              this.loading = true;
              const res = await districtService.createNewDistrictGroup({ name, districtCodes });
              if (res.status === 201) await this.loadDistrictGroups();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'agency') {
          if (name) {
            try {
              this.loading = true;
              const res = await agencyService.createNewAgency({ name, code, users });
              if (res.status === 201) await this.loadAgencies();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'cancellationTypes') {
          if (name) {
            try {
              this.loading = true;
              const res = await cancellationService.createNewCancellationType({ name });
              if (res.status === 201) await this.loadCancellationTypes();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async loadContractTypes() {
        try {
          this.loading = true;
          const contractTypes = await contractService.getStaffContracts('active');
          this.contractTypes = contractTypes.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadExtraCosts() {
        try {
          this.loading = true;
          const extraCosts = await extraCostsService.getExtraCosts('active');
          this.extraCosts = extraCosts.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadFeeTypes() {
        try {
          this.loading = true;
          const feeTypes = await feesService.getFeeTypes('active');
          this.feeTypes = feeTypes.sort((a, b) => a.order - b.order);
        } finally {
          this.loading = false;
        }
      },
      async loadWageTypes() {
        try {
          this.loading = true;
          const wageTypes = await wageService.getWageTypes('active');
          this.wageTypes = wageTypes.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadWages() {
        try {
          this.loading = true;
          const wages = await wageService.getWages('active');
          this.wages = wages.sort((a, b) => a.cost - b.cost);
        } finally {
          this.loading = false;
        }
      },
      async loadCurrencies() {
        try {
          this.loading = true;
          const currencies = await getCurrencies('active');
          this.currencies = currencies.sort((a, b) => a.code.localeCompare(b.code));
        } finally {
          this.loading = false;
        }
      },
      async loadSizes() {
        try {
          this.loading = true;
          const sizes = await getSizes('active');
          this.sizes = sizes.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadStaffTypes() {
        try {
          this.loading = true;
          const staffTypes = await staffService.getStaffTypes('active');
          this.staffTypes = staffTypes.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadDistricts() {
        try {
          this.loading = true;
          const districts = await districtService.getDistricts('active');
          this.districts = districts.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadDistrictGroups() {
        try {
          this.loading = true;
          const districtGroups = await districtService.getDistrictGroups('active');
          this.districtGroups = districtGroups.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadAgencies() {
        try {
          this.loading = true;
          const agencies = await agencyService.getAgencies('active');
          this.agencies = agencies.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadHostedUsers() {
        try {
          this.loading = true;
          const hostedUsers = await hostedUserService.getHostedUsers();
          this.hostedUsers = hostedUsers.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadLogisticCosts() {
        try {
          this.loading = true;
          const logisticCosts = await hostedUserService.getAllLogisticCosts();
          logisticCosts.forEach(userCost => {
            this.logistics[userCost.userId] = {};
            userCost.logisticsCosts.forEach(cost => {
              this.logistics[userCost.userId][cost.currencyCode] = cost.cost;
            });
          });
        } finally {
          this.loading = false;
        }
      },
      async loadHostedUserLogisticsCosts(id) {
        try {
          this.loading = true;
          const logisticsCosts = await hostedUserService.getHostedUserLogisticsCosts(id);
          this.logistics[id] = {};
          logisticsCosts.forEach(cost => {
            this.logistics[id][cost.currencyCode] = cost.cost;
          });
        } finally {
          this.loading = false;
        }
      },
      async loadSubordinates() {
        try {
          this.loading = true;
          this.subordinates = await hierarchyService.getAllSubordinates();
        } finally {
          this.subordinates = this.subordinates.map(subordinate => ({
            hierarchy: subordinate.hierarchy.map(item => ({
              firstName: item.firstName ? item.firstName : this.hostedUsers.find(user => user.id === item).firstName,
              lastName: item.lastName ? item.lastName : this.hostedUsers.find(user => user.id === item).lastName,
              userId: item.userId ? item.userId : item,
            })),
            userId: subordinate.userId,
            name: this.hostedUsers.find(user => user.id === subordinate.userId)?.name,
          }));
          this.loading = false;
        }
      },
      async loadCancellationTypes() {
        try {
          this.loading = true;
          const cancellationTypes = await cancellationService.getCancellationTypes('active');
          this.cancellationTypes = cancellationTypes.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
