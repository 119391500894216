<template>
  <div class="gallery">
    <h3 v-if="isEditable">{{ $t('eventsForm.gallery.upload.title') }}</h3>
    <div
      v-if="isEditable"
      class="gallery-upload"
      @dragenter.prevent.stop="onDragEnter"
      @dragleave.prevent.stop="onDragLeave"
      @dragover.prevent.stop="onDragEnter"
      @drop.stop.prevent="onDrop"
    >
      <form @submit.prevent="submit">
        <div v-for="file in files" class="gallery-dropzone is-preview">
          <img :src="file.preview" />
          <a class="is-delete" @click.prevent.stop="deleteUpload(file)"><inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg></a>
        </div>

        <div class="gallery-dropzone" :class="{ 'is-active': dropActive }" @click="onClick">
          <span class="icon">
            <inline-svg :src="require('../../assets/icons/drop-photo.svg')"></inline-svg>
          </span>
        </div>
        <label>
          <input ref="input" type="file" multiple accept="image/*" @change="onChange($event)" />
        </label>

        <button class="button is-primary" :disabled="files.length === 0">
          <span v-if="!uploading" class="icon">
            <inline-svg :src="require('../../assets/icons/cloud-upload.svg')"></inline-svg>
          </span>
          <span v-else class="icon loader"></span>
          <span>{{ $t('eventsForm.gallery.upload.submit') }}</span>
        </button>
      </form>
    </div>

    <div class="level">
      <div class="level-left">
        <h3>{{ $t('eventsForm.gallery.gallery') }}</h3>
      </div>
      <div class="level-right">
        <button class="button is-secondary" @click.prevent.stop="downloadAll">
          <span class="icon">
            <inline-svg :src="require('../../assets/icons/cloud-download.svg')"></inline-svg>
          </span>
          <span>{{ $t('eventsForm.gallery.downloadZip') }}</span>
        </button>
      </div>
    </div>
    <div class="gallery-box">
      <div v-for="(image, index) in gallery" :key="index" class="gallery-item" @click="tinyboxIndex = index">
        <img :src="image.thumbnail ? image.thumbnail.sasUri : image.image.sasUri" />
        <a v-if="isEditable" class="is-delete" @click.prevent.stop="deleteFile(image)"
          ><inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg
        ></a>
      </div>
      <p v-if="gallery.length === 0">{{ $t('eventsForm.gallery.empty') }}</p>
    </div>
    <Tinybox v-model="tinyboxIndex" :images="tinyboxImages" />
  </div>
</template>

<script>
  import http from '@/services/http';
  import form from '@/services/eventForm';
  import Tinybox from 'vue-tinybox';
  import { compareStatus, STATUS_FINISHED } from '@/services/status';

  export default {
    name: 'PhotoGallery',
    components: { Tinybox },
    data() {
      return {
        formState: form.state,
        files: [],
        gallery: [],
        uploading: false,
        dropActive: false,
        tinyboxIndex: null,
      };
    },
    computed: {
      tinyboxImages() {
        if (!this.gallery) return [];
        return this.gallery.map(e => {
          return {
            src: e.image.sasUri,
            ...(e.thumbnail?.sasUri && { thumbnail: e.thumbnail.sasUri }),
          };
        });
      },
      isEditable() {
        return compareStatus(this.formState.status, '<', STATUS_FINISHED);
      },
    },
    async mounted() {
      await this.loadImages();
    },
    methods: {
      async loadImages() {
        try {
          let res = await http.get(`EventGallery/${this.formState.id}`, { params: { generateSasUri: true } });
          if (res.data?.data?.length > 0) {
            this.gallery = res.data.data;
          }
        } catch (err) {
          //
        }
      },
      async submit() {
        if (!this.uploading) {
          this.uploading = true;
          const uploaded = await this.fileUpload();
          if (uploaded.items) {
            for (let img of uploaded.items) {
              this.gallery.push(img);
            }
          }
          this.files = [];
          this.uploading = false;
        }
      },
      async fileUpload() {
        const formData = new FormData();
        for (let file of this.files) {
          formData.append('medias', file.file);
        }

        const data = await http.post(`EventGallery/${this.formState.id}/upload`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });

        return data.data;
      },
      async deleteFile(image) {
        let confirmMsg = this.$t('eventsForm.gallery.deleteImage').replace('%file%', image.image.originalName ? image.image.originalName : image.image.name);
        if (window.confirm(confirmMsg)) {
          try {
            await http.delete(`EventGallery/${this.formState.id}/delete/${image.image.name}`);
            this.gallery.splice(this.gallery.indexOf(image), 1);
          } catch (err) {
            // err
          }
        }
      },
      deleteUpload(file) {
        this.files.splice(this.files.indexOf(file), 1);
      },
      onDragEnter({ dataTransfer: { types } }) {
        if (!types.includes('Files')) {
          return;
        }
        this.dropActive = true;
      },
      onDragLeave() {
        this.dropActive = false;
      },
      async onDrop(e) {
        this.dropActive = false;
        if (e.dataTransfer?.files.length > 0) {
          for (let file of e.dataTransfer.files) {
            const preview = await this.previewFile(file);
            this.files.push({ file, preview });
          }
        }
      },
      onClick() {
        this.$refs.input.click();
      },
      async onChange(e) {
        if (e.target?.files.length > 0) {
          for (let file of e.target.files) {
            const preview = await this.previewFile(file);
            this.files.push({ file, preview });
          }
        }
      },
      previewFile(file) {
        return new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      },
      async downloadAll() {
        http({
          url: `EventGallery/${this.formState.id}/zip`,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          const uri = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = uri;
          link.setAttribute('download', `${this.formState.name}.zip`);
          document.body.appendChild(link);
          link.click();
        });
      },
    },
  };
</script>
