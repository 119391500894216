<template>
  <input
    ref="numinput"
    v-model="amount"
    :readonly="readonly"
    :placeholder="placeholder"
    :disabled="disabled"
    type="tel"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput"
  />
</template>

<script>
  import { formatNumber } from '@/filters';

  export default {
    name: 'NumInput',
    props: {
      placeholder: {
        type: String,
        default: '',
      },
      value: {
        type: [Number, String],
        default: 0,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      zeronull: {
        type: Boolean,
        default: false,
      },
      integer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        amount: '',
      };
    },
    computed: {
      valueNumber() {
        if (typeof this.value === 'string') {
          if (!this.integer) return parseFloat(this.value.replace(/\s/g, '').replace(/,/g, '.'));
          if (this.integer) return parseInt(this.value.replace(/\s/g, '').replace(/,/g, '.'));
        } else {
          return this.value;
        }
      },
    },
    watch: {
      valueNumber(newValue) {
        if (this.$refs.numinput !== document.activeElement) {
          this.amount = this.format(newValue);
        }
      },
    },
    mounted() {
      this.amount = this.format(this.valueNumber);
    },
    methods: {
      onFocus() {
        if (!this.readonly && this.zeronull && this.valueNumber === 0) {
          this.amount = '';
        } else if (!this.readonly && this.amount !== '') {
          this.amount = this.valueNumber;
        }
      },
      onBlur() {
        if (!this.readonly && this.zeronull && this.amount === '') {
          this.amount = 0;
        } else if (!this.readonly && this.amount !== '') {
          this.amount = this.format(this.valueNumber);
          if (isNaN(parseFloat(this.amount))) this.amount = '';
          this.$emit('change');
        }
        if (!this.readonly || !this.disabled) this.$emit('blur');
      },
      onInput() {
        if (this.amount !== '') {
          this.$emit('input', this.clean(this.amount));
        } else {
          this.$emit('input', null);
        }
      },
      clean(amount) {
        if (typeof amount === 'string') {
          if (!this.integer) amount = parseFloat(amount.replace(/\s/g, '').replace(/,/g, '.'));
          if (this.integer) amount = parseInt(amount.replace(/\s/g, '').replace(/,/g, '.'));
          if (isNaN(amount)) amount = '';
          return amount;
        } else {
          return amount;
        }
      },
      format(value) {
        if (value) {
          return formatNumber(this.valueNumber);
        } else {
          return value;
        }
      },
    },
  };
</script>
