<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.wholesaler.searchButton')" />
      </p>
    </div>

    <div>
      <div v-if="!loading">
        <div class="form column is-5">
          <h3>{{ $t('admin.tabs.wholesaler.title') }}</h3>
          <div class="form-wrapper">
            <div v-for="(wholesaler, index) in filteredWholesalers" :key="wholesaler.id" class="form-field">
              <div class="field is-horizontal">
                <div class="field-label is-counter">
                  <span class="label">{{ parseInt(index) + 1 }}</span>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input v-model="wholesaler.name" :readonly="!wholesaler.isEditing" class="input" />
                    </p>
                  </div>
                  <div class="field is-narrow">
                    <p class="control">
                      <button v-if="!wholesaler.isEditing" class="button is-input" @click="editItem(wholesaler.id)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                        </span>
                      </button>
                      <button v-else class="button is-input" @click="processItem(wholesaler)">
                        <span class="icon is-green">
                          <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                        </span>
                      </button>
                      <a class="field-delete" @click.prevent="deleteItem(wholesaler)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field">
              <div class="field is-horizontal level-right">
                <div class="field is-grouped">
                  <p class="control">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as wholesalerService from '@/api/wholesalerService';
  import { searchTerm } from '@/filters';

  export default {
    name: 'AdminWholesaler',
    data() {
      return {
        loading: false,
        filterText: null,
        wholesalers: [],
      };
    },
    computed: {
      filteredWholesalers() {
        if (this.filterText && this.filterText !== '') {
          return this.wholesalers.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.wholesalers;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await Promise.all([this.loadWholesalers()]);
      },
      editItem(id) {
        this.$set(
          this.wholesalers.find(item => item.id === id),
          'isEditing',
          true
        );
      },
      addItem() {
        this.wholesalers.push({ name: '', isEditing: true });
      },
      processItem(data) {
        data.id ? this.updateItem(data) : this.setNewItem(data);
      },
      async updateItem({ id, name }) {
        try {
          this.loading = true;
          const res = await wholesalerService.updateWholesaler(id, { name, active: true });
          if (res.status === 200) await this.loadWholesalers();
        } finally {
          this.loading = false;
        }
      },
      async deleteItem({ id, name }) {
        if (!id) {
          this.wholesalers.splice(-1, 1);
        } else {
          try {
            this.loading = true;
            const res = await wholesalerService.updateWholesaler(id, { name, active: false });
            if (res.status === 200) await this.loadWholesalers();
          } finally {
            this.loading = false;
          }
        }
      },
      async setNewItem({ name }) {
        if (name) {
          try {
            this.loading = true;
            const res = await wholesalerService.createNewWholesaler({ name });
            if (res.status === 201) await this.loadWholesalers();
          } finally {
            this.loading = false;
          }
        }
      },
      async loadWholesalers() {
        try {
          this.loading = true;
          const wholesalers = await wholesalerService.getWholesalers('active');
          this.wholesalers = wholesalers.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
