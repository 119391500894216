<template>
  <div>
    <h3>{{ $t('eventsForm.inventory.attachments') }}</h3>
    <div class="inventory-upload">
      <form v-if="isEditable" class="inventory-upload__form" @submit.prevent="submit">
        <div
          ref="dropzone"
          class="inventory-dropzone"
          :class="{ 'is-active': dropActive }"
          @dragenter.prevent.stop="onDragEnter"
          @dragleave.prevent.stop="onDragLeave"
          @dragover.prevent.stop="onDragEnter"
          @drop.stop.prevent="onDrop"
          @click="onClick"
        >
          <span class="icon">
            <inline-svg :src="require('../../assets/icons/file.svg')"></inline-svg>
          </span>
        </div>
        <label>
          <input ref="input" type="file" @change="onChange($event)" />
        </label>
        <div>
          <p>
            <span v-if="file && fileName">{{ fileName }}</span
            >&#8203;
          </p>
          <button class="button is-primary" :disabled="!file">
            <span v-if="!uploading" class="icon">
              <inline-svg :src="require('../../assets/icons/cloud-upload.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t('eventsForm.inventory.upload') }}</span>
          </button>
        </div>
      </form>
      <div class="inventory-upload__attachments">
        <div v-for="attachment in attachments" :key="attachment.id">
          <strong>{{ attachment.originalName ? attachment.originalName : attachment.name }}</strong>
          <span>
            <a class="is-download" @click.prevent="downloadFile(attachment)"
              ><inline-svg :src="require('../../assets/icons/cloud-download.svg')"></inline-svg
            ></a>
            <a v-if="isEditable" class="is-delete" @click.prevent="deleteFile(attachment)"
              ><inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg
            ></a>
          </span>
        </div>
        <p v-if="!isEditable && attachments.length === 0">{{ $t('eventsForm.inventory.attachmentEmpty') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/services/http';
  import form from '@/services/eventForm';
  import { compareStatus, STATUS_FINISHED, STATUS_REPORTING, STATUS_CONTROLLING } from '@/services/status';
  import { isUserEventManager } from '@/services/roles';

  const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

  export default {
    name: 'InventoryFiles',
    data() {
      return {
        formState: form.state,
        dropActive: false,
        attachments: [],
        file: null,
        fileName: null,
        uploading: false,
      };
    },
    computed: {
      isEditable() {
        if (isUserEventManager() && compareStatus(this.formState.status, '<', STATUS_FINISHED)) {
          return true;
        } else {
          return compareStatus(this.formState.status, '=', [STATUS_REPORTING, STATUS_CONTROLLING]);
        }
      },
    },
    async mounted() {
      if (this.formState.id) {
        await this.loadAttachments();
      }
    },
    methods: {
      async loadAttachments() {
        let res = await http.get(`EventAttachment/${this.formState.id}/all`);
        if (res.data?.data?.length > 0) {
          this.attachments = res.data.data;
        }
      },
      async submit() {
        if (!this.uploading) {
          this.uploading = true;
          const uploaded = await this.fileUpload(this.file);
          if (uploaded.items) {
            for (let file of uploaded.items) {
              this.attachments.push(file);
            }
          }
          this.file = null;
          this.fileName = null;
          this.uploading = false;
        }
      },
      async fileUpload(file) {
        const formData = new FormData();
        formData.append('files', file);

        const data = await http.post(`EventAttachment/${this.formState.id}/upload`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });

        return data.data;
      },
      onDragEnter({ dataTransfer: { types } }) {
        if (!types.includes('Files')) {
          return;
        }
        this.dropActive = true;
      },
      onDragLeave() {
        this.dropActive = false;
      },
      onDrop(e) {
        this.dropActive = false;
        if (e.dataTransfer?.files.length > 0) {
          this.file = e.dataTransfer.files[0];
          this.fileName = this.file.name;
          if (this.file.size > FILE_SIZE_LIMIT) {
            this.file = null;
            this.fileName = null;
          }
        }
      },
      onClick() {
        this.$refs.input.click();
      },
      onChange(e) {
        this.file = e.target.files[0];
        this.fileName = this.file.name;
        if (this.file.size > FILE_SIZE_LIMIT) {
          this.file = null;
          this.fileName = null;
        }
      },
      async downloadFile({ name, originalName }) {
        http({
          url: `EventAttachment/${this.formState.id}/download/${name}`,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          const uri = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = uri;
          link.setAttribute('download', originalName ? originalName : name);
          document.body.appendChild(link);
          link.click();
        });
      },
      async deleteFile(file) {
        let confirmMsg = this.$t('eventsForm.inventory.deleteAttachment').replace('%file%', file.originalName ? file.originalName : file.name);

        if (window.confirm(confirmMsg)) {
          let res = await http.delete(`EventAttachment/${this.formState.id}/delete/${file.id}`);
          if (res.status === 204) {
            this.attachments.splice(this.attachments.indexOf(file), 1);
          }
        }
      },
    },
  };
</script>
