<template>
  <tr :class="{ 'is-inactive': !isSelected(product.id) }">
    <td v-if="!isReported || isFinancialCheck">
      <Toggle :value="isSelected(product.id)" :disabled="!isEditable" @input="onSelected(product, $event)" />
    </td>
    <td>
      <span>{{ getProductCategoryName(product.id) }}</span>
    </td>
    <td>
      <span>{{ product.name }}</span>
    </td>
    <td v-if="!isReported || isFinancialCheck" class="is-width-2">
      <span class="control has-icons-right">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`product.${visibleVariant}Price`"
          :vid="`product${visibleVariant}Price-${product.id}`"
          :rules="validationRule"
        >
          <NumInput
            v-if="getProduct(product.id)"
            v-model.number="getProduct(product.id)[`${visibleVariant}Price`]"
            class="input"
            min="0"
            :readonly="!isEditable"
            :class="{ 'is-warning': errors[0] }"
            @change="onPriceChange"
          />
          <NumInput v-else readonly class="input" />
          <span class="icon is-small is-right">
            {{ formState.currencyCode | formatCurrency }}
          </span>
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>
      </span>
    </td>
    <td v-else class="is-width-2">
      <span class="report-field has-units">
        <span>
          {{ product.realPrice }}
          <span class="report-difference" :class="$options.filters.amountDifferenceClass(product.expectedPrice, product.realPrice)">
            {{ product.expectedPrice | amountDifference(product.realPrice, true) }}</span
          >
        </span>
        <span class="unit">{{ formState.currencyCode | formatCurrency }}</span>
      </span>
    </td>

    <td v-if="!isReported || isFinancialCheck" class="is-width-2">
      <span class="control has-icons-right">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`product.${visibleVariant}FgtOnp`"
          :vid="`product${visibleVariant}FgtOnp-${product.id}`"
          :rules="validationRule"
        >
          <NumInput
            v-if="getProduct(product.id)"
            v-model.number="getProduct(product.id)[`${visibleVariant}FgtOnp`]"
            class="input"
            min="0"
            :readonly="!isEditable"
            :class="{ 'is-warning': errors[0] }"
            :zeronull="true"
          />
          <NumInput v-else readonly class="input" />
          <span class="icon is-small is-right">
            {{ $t('common.unit.pcs') }}
          </span>
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>
      </span>
    </td>

    <td v-else class="is-width-2">
      <span class="report-field has-units">
        <span>{{ product.realFgtOnp | formatNumber }} {{ product.expectedFgtOnp | amountDifference(product.realFgtOnp, true) }} </span>
        <span class="unit">{{ $t('common.unit.pcs') }}</span>
      </span>
    </td>

    <td v-if="!isReported || isFinancialCheck" class="is-width-2">
      <span class="control has-icons-right">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`product.${visibleVariant}FgtMarketing`"
          :vid="`product${visibleVariant}FgtMarketing-${product.id}`"
          :rules="validationRule"
        >
          <NumInput
            v-if="getProduct(product.id)"
            v-model.number="getProduct(product.id)[`${visibleVariant}FgtMarketing`]"
            class="input"
            min="0"
            :readonly="!isEditable"
            :class="{ 'is-warning': errors[0] }"
            :zeronull="true"
          />
          <NumInput v-else readonly class="input" />
          <span class="icon is-small is-right">
            {{ $t('common.unit.pcs') }}
          </span>
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>
      </span>
    </td>

    <td v-else class="is-width-2">
      <span class="report-field has-units">
        <span>{{ product.realFgtMarketing | formatNumber }} {{ product.expectedFgtMarketing | amountDifference(product.realFgtMarketing, true) }} </span>
        <span class="unit">{{ $t('common.unit.pcs') }}</span>
      </span>
    </td>

    <td v-if="!isReported && !isFinancialCheck" class="is-width-2">
      <span class="control has-icons-right">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`product.${visibleVariant}Sale`"
          :vid="`product${visibleVariant}Sale-${product.id}`"
          :rules="validationRule"
        >
          <NumInput
            v-if="getProduct(product.id)"
            v-model.number="getProduct(product.id)[`${visibleVariant}Sale`]"
            class="input"
            min="0"
            :readonly="!isEditable"
            :class="{ 'is-warning': errors[0] }"
          />
          <NumInput v-else readonly class="input" />
          <span class="icon is-small is-right">
            {{ $t('common.unit.pcs') }}
          </span>
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>
      </span>
    </td>
    <td v-else-if="isReported && !isFinancialCheck" class="is-width-2">
      <span class="report-field has-units">
        <span>
          {{ product.realSale | formatNumber }}
          <span class="report-difference" :class="$options.filters.amountDifferenceClass(product.expectedSale, product.realSale)">{{
            product.expectedSale | amountDifference(product.realSale, true)
          }}</span>
        </span>
        <span class="unit">{{ $t('common.unit.pcs') }}</span>
      </span>
    </td>
    <td v-else-if="isFinancialCheck" class="is-width-2">
      <span class="control has-icons-right">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`product.${visibleVariant}Sale`"
          :vid="`product${visibleVariant}Sale-${product.id}`"
          :rules="validationRule"
        >
          <NumInputComp
            v-if="getProduct(product.id)"
            v-model.number="getProduct(product.id)[`${visibleVariant}Sale`]"
            :comparison="product.expectedSale"
            min="0"
            :readonly="!isEditable"
            :class="{ 'is-warning': errors[0] }"
            :inverted="true"
          />
          <NumInput v-else readonly class="input" />
          <span class="icon is-small is-right">
            {{ $t('common.unit.pcs') }}
          </span>
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>
      </span>
    </td>
  </tr>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import form, { eventFormDefinition } from '@/services/eventForm';
  import Toggle from '@/components/Toggle';
  import {
    isMandatoryNow,
    isMandatoryNext,
    compareStatus,
    STATUS_FINISHED,
    STATUS_REPORTING,
    STATUS_CONTROLLING,
    STATUS_FINANCIAL_CHECK,
  } from '@/services/status';
  import NumInput from '@/components/EventsForm/NumInput';
  import { isUserEventManager, isUserRedbull } from '@/services/roles';
  import NumInputComp from '@/components/EventsForm/NumInputComp';

  export default {
    name: 'ProductsListingRow',
    components: { NumInputComp, NumInput, ValidationProvider, Toggle },
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        form: eventFormDefinition,
        formEnums: form.enums,
        formState: form.state,
      };
    },
    computed: {
      validationRule() {
        let rules = {};
        let product = this.getProduct(this.product.id);
        if (product) {
          if (this.form.offeredProducts[this.visibleVariant].mandatory instanceof Array) {
            let mandatoryNow = isMandatoryNow(
              this.formState.status,
              this.form.offeredProducts[this.visibleVariant].mandatory[0],
              this.form.offeredProducts[this.visibleVariant].mandatory[1]
            );
            if (product[`${this.visibleVariant}Listing`] && mandatoryNow) {
              if (!(this.visibleVariant === 'real' && compareStatus(this.formState.status, '<', STATUS_REPORTING))) {
                rules.required = true;
              }
            }

            let mandatoryNext = isMandatoryNext(
              this.formState.status,
              this.form.offeredProducts[this.visibleVariant].mandatory[0],
              this.form.offeredProducts[this.visibleVariant].mandatory[1]
            );
            if (product[`${this.visibleVariant}Listing`] && mandatoryNext) {
              if (!(this.visibleVariant === 'real' && compareStatus(this.formState.status, '<', STATUS_REPORTING))) {
                rules.required_if = 'proceedSave';
              }
            }
          }
        }
        return rules;
      },
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.offeredProducts.expected.visible[0], this.form.offeredProducts.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.offeredProducts.real.visible[0], this.form.offeredProducts.real.visible[1])) {
          return 'real';
        }
        return false;
      },
      isEditable() {
        // event manager override
        if (isUserEventManager()) {
          return true;
        } else {
          return compareStatus(
            this.formState.status,
            this.form.offeredProducts[this.visibleVariant].editable[0],
            this.form.offeredProducts[this.visibleVariant].editable[1]
          );
        }
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      isFinancialCheck() {
        return isUserRedbull() && compareStatus(this.formState.status, '=', STATUS_FINANCIAL_CHECK);
      },
    },
    methods: {
      onSelected(product, state) {
        if (this.visibleVariant === 'expected') {
          if (state === true) {
            let is = this.formState.offeredProducts.find(e => e.productId === product.id);
            if (!is) {
              let price = null;

              if (this.formState.offeredProducts.length > 0) {
                price = this.formState.offeredProducts[0].expectedPrice;
              }

              this.formState.offeredProducts.push({
                productId: product.id,
                expectedListing: true,
                expectedPrice: price,
                expectedFgtOnp: 0,
                expectedFgtMarketing: 0,
              });
            }
          } else if (state === false) {
            this.formState.offeredProducts.splice(
              this.formState.offeredProducts.findIndex(e => e.productId === product.id),
              1
            );
          }
        } else {
          if (state === true) {
            let is = this.formState.offeredProducts.find(e => e.productId === product.id);
            if (is) {
              is.realListing = true;

              if ('tempPrice' in is) {
                is.realPrice = is.tempPrice;
                is.realSale = is.tempSale;
                is.realFgtOnp = is.tempFgtOnp;
                is.realFgtMarketing = is.tempFgtMarketing;
              }
            } else {
              this.formState.offeredProducts.push({
                productId: product.id,
                expectedListing: false,
                expectedPrice: 0,
                expectedSale: 0,
                expectedFgtOnp: 0,
                expectedFgtMarketing: 0,
                realListing: true,
              });
            }
          } else if (state === false) {
            let is = this.formState.offeredProducts.find(e => e.productId === product.id);
            if (is) {
              is.realListing = false;

              if (is.realListing === false && is.expectedListing === false) {
                this.formState.offeredProducts.splice(this.formState.offeredProducts.indexOf(is), 1);
              } else {
                is.tempPrice = is.realPrice;
                is.tempSale = is.realSale;
                is.tempFgtOnp = is.realFgtOnp;
                is.tempFgtMarketing = is.realFgtMarketing;

                is.realPrice = 0;
                is.realSale = 0;
                is.realFgtOnp = 0;
                is.realFgtMarketing = 0;
              }
            }
          }
        }
      },
      isSelected(productId) {
        if (this.visibleVariant === 'expected') {
          return !!this.formState.offeredProducts.find(e => e.productId === productId);
        } else {
          let product = this.formState.offeredProducts.find(e => e.productId === productId);
          if (product) {
            if (product.realListing === null) {
              return product.expectedListing;
            } else {
              return product.realListing;
            }
          } else {
            return false;
          }
        }
      },
      getProduct(productId) {
        return this.formState.offeredProducts.find(e => e.productId === productId);
      },
      getCategoryName(id) {
        return this.formEnums.productCategories.find(e => e.id === id)?.name;
      },
      getProductCategoryName(id) {
        return this.getCategoryName(this.formEnums.products.find(e => e.id === id)?.categoryId);
      },
      zeroFocus(id, property) {
        let product = this.formState.offeredProducts.find(e => e.productId === id);
        if (product[property] === 0) {
          product[property] = null;
        }
      },
      zeroBlur(id, property) {
        let product = this.formState.offeredProducts.find(e => e.productId === id);
        if (product[property] === null) {
          product[property] = 0;
        }
      },
      onPriceChange() {
        if (this.visibleVariant === 'expected') {
          let priceCount = 0;
          let priceVal = 0;
          for (let product of this.formState.offeredProducts) {
            if (product.expectedPrice !== null && product.expectedPrice > 0) {
              priceCount = priceCount + 1;
              priceVal = product.expectedPrice;
            }
          }

          if (priceCount === 1) {
            for (let product of this.formState.offeredProducts) {
              if (product.expectedPrice === null || product.expectedPrice <= 0) {
                product.expectedPrice = priceVal;
              }
            }
          }
        }
      },
    },
  };
</script>
