<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.hardware.searchButton')" />
      </p>
    </div>

    <div class="tabs inner-tabs">
      <ul>
        <li :class="{ 'is-active': active === 'hardware' }">
          <a @click="active = 'hardware'">
            {{ $t('admin.tabs.hardware.tabs.hardware.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'category' }">
          <a @click="active = 'category'">
            {{ $t('admin.tabs.hardware.tabs.category.title') }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div v-if="active === 'hardware' && !loading">
        <div class="columns">
          <div class="column is-9">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.hardware.tabs.hardware.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.hardware.tabs.hardware.headers.headerTwo') }}</th>
                  <th>{{ $t('admin.tabs.hardware.tabs.hardware.headers.headerThree') }}</th>
                  <th>{{ $t('admin.tabs.hardware.tabs.hardware.headers.headerFour') }}</th>
                  <th>{{ $t('admin.tabs.hardware.tabs.hardware.headers.order') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(hardware, index) in filteredHardwares" :key="hardware.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="hardware.name" :readonly="!hardware.isEditing" class="input" />
                  </td>
                  <td>
                    <input v-model="hardware.unitType" :readonly="!hardware.isEditing" class="input" />
                  </td>
                  <td class="table-switch">
                    <Toggle v-model="hardware.vipAvailable" :disabled="!hardware.isEditing" type="icon" />
                  </td>
                  <td>
                    <div class="select is-fullwidth">
                      <select v-model="hardware.categoryId" :disabled="!hardware.isEditing">
                        <option v-for="category in hardwareCategories" :key="category.id" :value="category.id" class="help">
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="table-order">
                    <input v-model.number="hardware.order" :readonly="!hardware.isEditing" class="input" />
                  </td>

                  <td class="table-actions">
                    <button v-if="!hardware.isEditing" class="button is-input" @click="editItem(hardware.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(hardware)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(hardware)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="6"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'category' && !loading">
        <div class="columns">
          <div class="column is-4">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.hardware.tabs.category.title') }}</th>
                  <th>{{ $t('admin.tabs.hardware.tabs.category.order') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(category, index) in filteredHardwareCategories" :key="category.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="category.name" :readonly="!category.isEditing" class="input" />
                  </td>
                  <td class="table-order">
                    <input v-model.number="category.order" :readonly="!category.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!category.isEditing" class="button is-input" @click="editItem(category.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(category)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a v-if="!category.isUsed" class="table-delete">
                      <span class="icon" @click="deleteItem(category)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                    <a v-else class="table-delete">
                      <span class="icon has-tooltip-right has-tooltip-arrow has-tooltip-danger" :data-tooltip="$t('admin.tabs.hardware.tabs.category.tooltip')">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as hardwareService from '@/api/hardwareService';
  import Toggle from '@/components/Toggle';
  import { searchTerm } from '@/filters';

  export default {
    name: 'AdminHardware',
    components: { Toggle },
    data() {
      return {
        active: 'hardware',
        loading: false,
        filterText: null,
        hardwares: [],
        hardwareCategories: [],
      };
    },
    computed: {
      filteredHardwares() {
        if (this.filterText && this.filterText !== '') {
          return this.hardwares.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.hardwares;
        }
      },
      filteredHardwareCategories() {
        const intersection = this.hardwareCategories.filter(category => this.hardwares.find(hardware => hardware.categoryId === category.id));

        intersection.forEach(x => {
          x.isUsed = true;
        });

        if (this.filterText && this.filterText !== '') {
          return this.hardwareCategories.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.hardwareCategories;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await Promise.all([this.loadHardwares(), this.loadHardwareCategories()]);
      },
      editItem(id) {
        if (this.active === 'hardware') {
          this.$set(
            this.hardwares.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'category') {
          this.$set(
            this.hardwareCategories.find(item => item.id === id),
            'isEditing',
            true
          );
        }
      },
      addItem() {
        if (this.active === 'hardware') {
          this.hardwares.push({ name: '', isEditing: true });
        } else if (this.active === 'category') {
          this.hardwareCategories.push({ name: '', isEditing: true });
        }
      },
      processItem(data) {
        data.id ? this.updateItem(data) : this.setNewItem(data);
      },
      async updateItem({ id, name, unitType, vipAvailable, categoryId, order }) {
        if (this.active === 'hardware') {
          try {
            this.loading = true;
            const res = await hardwareService.updateHardware(id, { name, unitType, vipAvailable, categoryId, order, active: true });
            if (res.status === 200) await this.loadHardwares();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'category') {
          try {
            this.loading = true;
            const res = await hardwareService.updateHardwareCategory(id, { name, order, active: true });
            if (res.status === 200) await this.loadHardwareCategories();
          } finally {
            this.loading = false;
          }
        }
      },
      async deleteItem({ id, name, unitType, vipAvailable, categoryId, order }) {
        if (this.active === 'hardware') {
          if (!id) {
            this.hardwares.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await hardwareService.updateHardware(id, { name, unitType, vipAvailable, categoryId, order, active: false });
              if (res.status === 200) await this.loadHardwares();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'category') {
          if (!id) {
            this.hardwareCategories.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await hardwareService.updateHardwareCategory(id, { name, order, active: false });
              if (res.status === 200) await this.loadHardwareCategories();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async setNewItem({ name, unitType, vipAvailable, categoryId, order }) {
        if (this.active === 'hardware') {
          if (name) {
            try {
              this.loading = true;
              const res = await hardwareService.createNewHardware({ name, unitType, vipAvailable, categoryId, order });
              if (res.status === 201) await this.loadHardwares();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'category') {
          if (name) {
            try {
              this.loading = true;
              const res = await hardwareService.createNewHardwareCategory({ name, order });
              if (res.status === 201) await this.loadHardwareCategories();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async loadHardwares() {
        try {
          this.loading = true;
          const hardwares = await hardwareService.getHardwares('active');
          this.hardwares = hardwares.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadHardwareCategories() {
        try {
          this.loading = true;
          const hardwareCategories = await hardwareService.getHardwareCategories('active');
          this.hardwareCategories = hardwareCategories.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
