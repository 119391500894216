<template>
  <span class="report-arrow" :class="{ 'is-expenses': expenses, 'is-more': real > expected, 'is-less': real < expected, 'is-same': same && real === expected }"
    ><inline-svg :src="require(`@/assets/icons/arrow2.svg`)"></inline-svg
  ></span>
</template>

<script>
  export default {
    name: 'ArrowCompare',
    props: {
      real: {
        type: Number,
        required: true,
      },
      expected: {
        type: Number,
        required: true,
      },
      expenses: {
        type: Boolean,
        default: false,
      },
      same: {
        type: Boolean,
        default: false,
      }
    },
  };
</script>
