<template>
  <div id="app">
    <div v-if="$route.name === 'Login'">
      <router-view />
    </div>
    <div v-else class="layout">
      <div class="is-hidden-desktop"></div>
      <SideBar />
      <div class="main" :class="{ 'is-light': isLightTheme }">
        <TopBar />
        <main class="container" :class="mainClass">
          <router-view :key="$route.fullPath" />
        </main>
      </div>
      <ModalsContainer />
    </div>
  </div>
</template>

<script>
  import SideBar from './components/SideBar';
  import TopBar from './components/TopBar';
  import theme from './services/theme';

  export default {
    components: { TopBar, SideBar },
    data() {
      return {
        theme: theme.state,
      };
    },
    computed: {
      mainClass() {
        if (!this.$route || !this.$route.name) return false;
        let route = this.$route.name.toLowerCase();
        if (route === 'newevent' || route === 'editevent') {
          return 'is-event';
        } else {
          return `is-${route}`;
        }
      },
      isLightTheme() {
        return this.theme.currentTheme === 'light';
      },
    },
    watch: {
      $route() {
        this.$vfm.hideAll();
      },
    },
  };
</script>
