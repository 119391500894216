import {
  compareStatus,
  getNextStatus,
  STATUS_CONTROLLING,
  STATUS_COST_ESTIMATION,
  STATUS_DRAFT,
  STATUS_EXECUTION,
  STATUS_FINAL_ACCEPTANCE,
  STATUS_FINANCIAL_CHECK,
  STATUS_FINISHED,
  STATUS_MUSKETEER_CHECK,
  STATUS_OVERSEER_CHECK,
  STATUS_PLANNING,
  STATUS_REPORTING,
} from '@/services/status';
import * as eventService from '@/api/eventService';
import { getProductCategories, getProductPrices, getProducts } from '@/api/productService';
import { getStaffContracts } from '@/api/contractService';
import { getCountries } from '@/api/countryService';
import { getFeeTypes } from '@/api/feesService';
import { getExtraCosts } from '@/api/extraCostsService';
import { getWholesalers } from '@/api/wholesalerService';
import { getInvoicingCompanies } from '@/api/invoicingCompanyService';
import { getWages, getWageTypes } from '@/api/wageService';
import { getActivities } from '@/api/activitiesService';
import { getHardwares, getHardwareCategories } from '@/api/hardwareService';
import { eventVisited } from '@/services/eventVisit';
import { getEventSegments } from '@/api/segmentService';
import { getCategories } from '@/api/categoryService';
import { getSizes } from '@/api/sizeService';
import { getStaffTypes } from '@/api/staffTypeService';
import { getDistrictGroups, getDistricts } from '@/api/districtService';
import { isUserRedbull, isUserAgency, isUserEventManager, canApproveSelf, isUserAgencyManager } from '@/services/roles';
import { getInventories, getInventoryCategories } from '@/api/inventoryService';
import auth from '@/services/auth';
import { getAgencies } from '@/api/agencyService';

export const EDITOR_AUTHORITY = 'authority';
export const EDITOR_AGENCY = 'agency';
const EDITOR_SUPERVISOR = 'supervisor';
const EDITOR_OVERSEER = 'overseer';

export const EXPENSES_FIX = 0.07;
export const EXPENSES_EXECUTIVE_FEE = 0.015;
export const EXPENSES_VOLUME_FEE = 0.01;
export const EXPENSES_REPORTING_FEE = 0.005;

const nowYear = new Date().getFullYear();

const copyKeys = [
  'name',
  'noteGeneral',
  'noteExpenses',
  'noteHardware',
  'wageTypeId',
  'invoicingCompanyId',
  'ownCashiers',
  'tips',
  'addressPostCode',
  'typeId',
  'segmentId',
  'categoryId',
  'organisedInCode',
  'contractTypeId',
  'agencyId',
  'logisticsCosts',
  'expectedPriceRedbullMix',
  'expectedPriceOrganicsMix',
  'expectedOrganizerContribution',
  'expectedEarnings',
  'expectedConstructionStart',
  'expectedAttendance',
  'expectedAmountOfBars',
  'sizeId',
  'doorOpen',
  'doorClose',
  'districtCode',
  'constructionDays',
  'deconstructionDays',
  'companyName',
  'addressName',
  'addressStreet',
  'addressCity',
  'currencyCode',
];

const copyCleanKeys = ['eventContacts', 'offeredActivities', 'extraCosts', 'hardwares', 'offeredProducts', 'fees'];

export const eventFormDefinition = {
  id: {
    type: 'hidden',
  },
  name: {
    type: 'text',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  status: {
    type: 'hidden',
  },

  // Basic info
  addressName: {
    type: 'text',
    label: 'eventsForm.basicInfo.eventInfoForm.place.label',
    placeholder: 'eventsForm.basicInfo.eventInfoForm.place.placeholder',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: true,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  addressStreet: {
    type: 'text',
    label: 'eventsForm.basicInfo.eventInfoForm.street.placeholder',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  addressPostCode: {
    type: 'text',
    //numeric
    label: 'eventsForm.basicInfo.eventInfoForm.zip.placeholder',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  addressCity: {
    type: 'text',
    label: 'eventsForm.basicInfo.eventInfoForm.city.placeholder',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: true,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  addressDistrict: {
    type: 'hidden',
    label: 'eventsForm.basicInfo.eventInfoForm.district.placeholder',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  districtCode: {
    type: 'select',
    label: 'eventsForm.basicInfo.eventInfoForm.district.placeholder',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: true,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  organisedInCode: {
    type: 'select',
    label: 'eventsForm.basicInfo.eventInfoForm.country.placeholder',
    editable: ['=', STATUS_DRAFT],
    mandatory: true,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  currencyCode: {
    type: 'hidden',
    editable: ['=', STATUS_DRAFT],
    mandatory: true,
  },
  eventStart: {
    type: 'date',
    label: 'eventsForm.basicInfo.eventInfoForm.start.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  eventEnd: {
    type: 'date',
    label: 'eventsForm.basicInfo.eventInfoForm.end.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  doorOpen: {
    type: 'time',
    label: 'eventsForm.basicInfo.eventInfoForm.doorOpen.label',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  doorClose: {
    type: 'time',
    label: 'eventsForm.basicInfo.eventInfoForm.doorClosed.label',
    editable: ['<', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  expectedConstructionStart: {
    type: 'time',
    label: 'eventsForm.basicInfo.eventInfoForm.assembleTime.label',
    editable: ['<', STATUS_REPORTING],
    visible: ['<', STATUS_REPORTING],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  realConstructionStart: {
    type: 'time',
    label: 'eventsForm.basicInfo.eventInfoForm.assembleTime.label',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY],
  },
  categoryId: {
    type: 'select',
    label: 'eventsForm.basicInfo.eventInfoForm.categoryId.label',
    editable: ['<=', STATUS_FINAL_ACCEPTANCE],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
    agencyHidden: true,
  },
  segmentId: {
    type: 'select',
    label: 'eventsForm.basicInfo.eventInfoForm.segment.label',
    editable: ['<=', STATUS_FINAL_ACCEPTANCE],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
    agencyHidden: true,
  },
  typeId: {
    type: 'select',
    label: 'eventsForm.basicInfo.eventInfoForm.type.label',
    editable: ['<=', STATUS_FINAL_ACCEPTANCE],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
    agencyHidden: true,
  },
  expectedAttendance: {
    type: 'number',
    minValue: 1,
    label: 'eventsForm.basicInfo.eventInfoForm.expectedAttendance.label',
    editable: ['<=', STATUS_PLANNING],
    visible: ['<', STATUS_REPORTING],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  realAttendance: {
    type: 'number',
    minValue: 1,
    label: 'eventsForm.basicInfo.eventInfoForm.realAttendance.label',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  expectedEarnings: {
    type: 'number',
    label: 'eventsForm.basicInfo.eventInfoForm.expectedEarnings.label',
    unit: 'currency',
    editable: ['<=', STATUS_PLANNING],
    visible: ['<', STATUS_FINANCIAL_CHECK],
    agencyHidden: true,
  },
  realEarnings: {
    type: 'number',
    label: 'eventsForm.basicInfo.eventInfoForm.realEarnings.label',
    unit: 'currency',
    editable: ['=', STATUS_FINANCIAL_CHECK],
    visible: ['>=', STATUS_FINANCIAL_CHECK],
    mandatory: ['>=', STATUS_FINANCIAL_CHECK],
    mandatoryDependency: 'expectedEarnings',
    agencyHidden: true,
  },
  eventActivity: {
    type: 'slider',
    label: 'eventsForm.basicInfo.eventInfoForm.eventActivity.label',
    unit: '%',
    min: 0,
    max: 100,
    minValue: 1,
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY],
  },
  averageAge: {
    type: 'number',
    label: 'eventsForm.basicInfo.eventInfoForm.averageAge.label',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY],
  },
  totalSales: {
    type: 'number',
    unit: 'currency',
    label: 'eventsForm.basicInfo.eventInfoForm.totalSales.label',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY],
  },

  //box organizační tým
  organisedByUserId: {
    type: 'select',
    label: 'eventsForm.basicInfo.organizationalTeamForm.authority.label',
    editable: ['<=', STATUS_OVERSEER_CHECK],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  overseerUserId: {
    type: 'select',
    label: 'eventsForm.basicInfo.organizationalTeamForm.overseer.label',
    editable: ['<', STATUS_OVERSEER_CHECK],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  agencyId: {
    type: 'select',
    label: 'eventsForm.basicInfo.organizationalTeamForm.agency.label',
    editable: ['<', STATUS_COST_ESTIMATION],
    mandatory: ['>=', STATUS_PLANNING],
    editor: [EDITOR_AUTHORITY],
  },
  supervisorUserId: {
    type: 'select',
    label: 'eventsForm.basicInfo.organizationalTeamForm.supervisor.label',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: ['>=', STATUS_MUSKETEER_CHECK],
    visible: ['>=', STATUS_PLANNING],
    editor: [EDITOR_AGENCY],
  },

  // box redbull hradí

  // box kontakty
  eventContacts: {
    type: 'custom',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },

  // box smluvní údaje
  invoicingCompanyId: {
    type: 'select',
    label: 'eventsForm.basicInfo.contractInfoForm.invoiceCompany.label',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: false,
    editor: [EDITOR_AUTHORITY],
    agencyHidden: true,
  },
  companyName: {
    type: 'text',
    label: 'eventsForm.basicInfo.contractInfoForm.companyName.label',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: false,
    editor: [EDITOR_AUTHORITY],
  },
  wholeSalerId: {
    type: 'select',
    label: 'eventsForm.basicInfo.contractInfoForm.wholesale.label',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  contractTypeId: {
    type: 'select',
    label: 'eventsForm.basicInfo.contractInfoForm.staffContracts.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY],
  },
  sizeId: {
    type: 'select',
    label: 'eventsForm.basicInfo.contractInfoForm.eventSize.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  wageTypeId: {
    type: 'select',
    label: 'eventsForm.basicInfo.contractInfoForm.wagesType.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY],
  },
  ownCashiers: {
    type: 'checkbox',
    label: 'eventsForm.basicInfo.contractInfoForm.ownRBCashRegister.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },
  tips: {
    type: 'checkbox',
    label: 'eventsForm.basicInfo.contractInfoForm.tips.label',
    editable: ['<=', STATUS_PLANNING],
    mandatory: true,
    editor: [EDITOR_AUTHORITY],
  },

  // Expenses
  fees: {
    type: 'custom',
    expected: {
      editable: ['<=', STATUS_PLANNING],
      mandatory: ['>=', STATUS_COST_ESTIMATION],
      visible: ['<', STATUS_REPORTING],
      editor: [EDITOR_AUTHORITY],
    },
    real: {
      editable: ['<', STATUS_FINAL_ACCEPTANCE],
      mandatory: ['>=', STATUS_FINAL_ACCEPTANCE],
      visible: ['>=', STATUS_REPORTING],
      editor: [EDITOR_AUTHORITY],
    },
  },
  assignedStaff: {
    type: 'custom',
    initial: {
      editable: ['<=', STATUS_PLANNING],
      mandatory: ['>=', STATUS_MUSKETEER_CHECK],
      visible: ['<=', STATUS_PLANNING],
      editor: [EDITOR_AUTHORITY],
    },
    expected: {
      editable: ['=', STATUS_COST_ESTIMATION],
      mandatory: ['>=', STATUS_MUSKETEER_CHECK],
      visible: ['<', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
    real: {
      editable: ['<', STATUS_FINANCIAL_CHECK],
      mandatory: ['>=', STATUS_CONTROLLING],
      visible: ['>=', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
  },
  assignedSimpleStaff: {
    type: 'custom',
    initial: {
      editable: ['<=', STATUS_PLANNING],
      mandatory: ['>=', STATUS_MUSKETEER_CHECK],
      visible: ['<=', STATUS_PLANNING],
      editor: [EDITOR_AUTHORITY],
    },
    expected: {
      editable: ['=', STATUS_COST_ESTIMATION],
      mandatory: ['>=', STATUS_MUSKETEER_CHECK],
      visible: ['<', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
    real: {
      editable: ['<', STATUS_FINANCIAL_CHECK],
      mandatory: ['>=', STATUS_CONTROLLING],
      visible: ['>=', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
  },
  extraCosts: {
    type: 'custom',
    initial: {
      editable: ['<=', STATUS_PLANNING],
      visible: ['<=', STATUS_PLANNING],
      editor: [EDITOR_AUTHORITY],
    },
    expected: {
      editable: ['=', STATUS_COST_ESTIMATION],
      visible: ['<', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
    real: {
      editable: ['<', STATUS_FINANCIAL_CHECK],
      visible: ['>=', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
  },

  expectedAmountOfBars: {
    type: 'numeric',
    label: 'eventsForm.expenses.basicSettings.numberOfBars',
    min: 0,
    editable: ['<=', STATUS_PLANNING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    visible: ['<', STATUS_REPORTING],
    editor: [EDITOR_AUTHORITY],
  },
  realAmountOfBars: {
    type: 'numeric',
    label: 'eventsForm.expenses.basicSettings.numberOfBars',
    min: 0,
    editable: ['<=', STATUS_FINAL_ACCEPTANCE],
    mandatory: ['>=', STATUS_CONTROLLING],
    visible: ['>=', STATUS_REPORTING],
    editor: [EDITOR_AGENCY],
  },
  expectedOrganizerContribution: {
    type: 'number',
    label: 'eventsForm.expenses.basicSettings.contribution',
    unit: 'currency',
    editable: ['<=', STATUS_PLANNING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    visible: ['<', STATUS_REPORTING],
    editor: [EDITOR_AUTHORITY],
  },
  realOrganizerContribution: {
    type: 'number',
    label: 'eventsForm.expenses.basicSettings.contribution',
    unit: 'currency',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    mandatory: ['>=', STATUS_CONTROLLING],
    visible: ['>=', STATUS_REPORTING],
    editor: [EDITOR_AGENCY],
  },
  constructionDays: {
    type: 'numeric',
    label: 'eventsForm.expenses.basicSettings.construction',
    min: 0,
    editable: ['<=', STATUS_COST_ESTIMATION],
    mandatory: true,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  deconstructionDays: {
    type: 'numeric',
    label: 'eventsForm.expenses.basicSettings.deconstruction',
    min: 0,
    editable: ['<=', STATUS_COST_ESTIMATION],
    mandatory: true,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  logisticsCosts: {
    type: 'number',
    label: 'eventsForm.expenses.basicSettings.logistics',
    unit: 'currency',
    editable: ['<=', STATUS_PLANNING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY],
  },
  differentStaffDifferentDays: {
    type: 'toggle',
    label: 'eventsForm.expenses.basicSettings.differentStaffEachDay',
    mandatory: false,
  },

  fine: {
    type: 'number',
    label: 'eventsForm.expenses.fine.title',
    unit: 'currency',
    editable: ['=', STATUS_FINANCIAL_CHECK],
    mandatory: ['>=', STATUS_FINANCIAL_CHECK],
    editor: [EDITOR_AUTHORITY],
  },
  fineReason: {
    type: 'textarea',
    label: 'eventsForm.expenses.fineReason.title',
    editable: ['=', STATUS_FINANCIAL_CHECK],
    editor: [EDITOR_AUTHORITY],
  },
  satisfactionExecution: {
    type: 'slider',
    label: 'eventsForm.expenses.executionFee.title',
    unit: '%',
    min: 0,
    max: 120,
    minValue: 1,
    editable: ['=', STATUS_FINANCIAL_CHECK],
    mandatory: ['>', STATUS_FINANCIAL_CHECK],
    editor: [EDITOR_AUTHORITY],
  },
  satisfactionReporting: {
    type: 'slider',
    label: 'eventsForm.expenses.reportingFee.title',
    unit: '%',
    min: 0,
    max: 100,
    minValue: 1,
    editable: ['=', STATUS_FINANCIAL_CHECK],
    mandatory: ['>', STATUS_FINANCIAL_CHECK],
    editor: [EDITOR_AUTHORITY],
  },

  // tab3
  expectedPriceRedbullMix: {
    type: 'number',
    label: 'eventsForm.products.productGenericForm.priceRedbullMix.label',
    unit: 'currency',
    editable: ['<=', STATUS_PLANNING],
    visible: ['<', STATUS_REPORTING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY],
  },
  realPriceRedbullMix: {
    type: 'number',
    label: 'eventsForm.products.productGenericForm.priceRedbullMix.label',
    unit: 'currency',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY],
  },
  expectedPriceOrganicsMix: {
    type: 'number',
    label: 'eventsForm.products.productGenericForm.priceOrganicsMix.label',
    unit: 'currency',
    editable: ['<=', STATUS_PLANNING],
    visible: ['<', STATUS_REPORTING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
    editor: [EDITOR_AUTHORITY],
  },
  realPriceOrganicsMix: {
    type: 'number',
    label: 'eventsForm.products.productGenericForm.priceOrganicsMix.label',
    unit: 'currency',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY],
  },
  priceBeer: {
    type: 'number',
    label: 'eventsForm.products.productGenericForm.priceBeer.label',
    unit: 'currency',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },

  offeredProducts: {
    type: 'custom',
    expected: {
      editable: ['<=', STATUS_PLANNING],
      mandatory: ['>=', STATUS_COST_ESTIMATION],
      visible: ['<', STATUS_REPORTING],
    },
    real: {
      editable: ['<=', STATUS_CONTROLLING],
      mandatory: ['>=', STATUS_REPORTING],
      visible: ['>=', STATUS_REPORTING],
    },
  },

  missingProductSalesInfo: {
    type: 'toggle',
    label: 'eventsForm.products.missingProductSalesInfo',
    editable: ['<=', STATUS_CONTROLLING],
    mandatory: false,
    visible: ['>=', STATUS_REPORTING],
  },

  offeredActivities: {
    type: 'custom',
    expected: {
      editable: ['<=', STATUS_PLANNING],
      mandatory: false,
      visible: ['<', STATUS_REPORTING],
      editor: [EDITOR_AUTHORITY],
    },
    real: {
      editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
      mandatory: false,
      visible: ['>=', STATUS_REPORTING],
      editor: [EDITOR_AGENCY],
    },
  },

  hardwares: {
    type: 'custom',
    editable: ['<', STATUS_REPORTING],
    mandatory: ['>=', STATUS_COST_ESTIMATION],
  },

  inventories: {
    type: 'custom',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    mandatory: ['>=', STATUS_CONTROLLING],
    visible: ['>=', STATUS_REPORTING],
  },

  noteGeneral: {
    type: 'textarea',
    label: 'eventsForm.basicInfo.notesForm.title',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    maxLength: 4000,
    editor: [EDITOR_AUTHORITY],
  },
  noteExpenses: {
    type: 'textarea',
    label: 'eventsForm.expenses.notes.title',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    maxLength: 4000,
    editor: [EDITOR_AUTHORITY, EDITOR_AGENCY],
  },
  noteProducts: {
    type: 'textarea',
    label: 'eventsForm.products.notes.title',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    maxLength: 4000,
    editor: [EDITOR_AUTHORITY],
  },
  noteActivities: {
    type: 'textarea',
    label: 'eventsForm.activities.notes.title',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    maxLength: 4000,
    editor: [EDITOR_AUTHORITY],
  },
  noteHardware: {
    type: 'textarea',
    label: 'eventsForm.hardware.notes.title',
    editable: ['<=', STATUS_FINANCIAL_CHECK],
    mandatory: false,
    maxLength: 4000,
    editor: [EDITOR_AUTHORITY],
  },
  evaluation: {
    type: 'textarea',
    label: 'eventsForm.basicInfo.evaluation',
    editable: ['=', [STATUS_REPORTING, STATUS_CONTROLLING]],
    visible: ['>=', STATUS_REPORTING],
    mandatory: ['>=', STATUS_CONTROLLING],
    editor: [EDITOR_AGENCY, EDITOR_AUTHORITY],
  },
};

const eventForm = {
  state: {
    id: null,
    name: '',
    status: STATUS_DRAFT,
    addressName: '',
    addressStreet: '',
    addressPostCode: '',
    addressCity: '',
    addressDistrict: '',
    districtCode: null,
    organisedInCode: 'CZ',
    currencyCode: 'CZK',
    eventStart: '',
    eventEnd: '',
    doorOpen: '',
    doorClose: '',
    expectedConstructionStart: '',
    realConstructionStart: '',
    categoryId: null,
    segmentId: null,
    typeId: null,
    expectedAttendance: null,
    realAttendance: null,
    expectedEarnings: null,
    realEarnings: null,
    organisedByUserId: null,
    supervisorUserId: null,
    agencyId: null,
    overseerUserId: null,
    eventContacts: [],
    invoicingCompanyId: null,
    companyName: null,
    wholeSalerId: null,
    contractTypeId: null,
    sizeId: null,
    wageTypeId: null,
    ownCashiers: false,
    tips: false,
    fees: [],
    expectedAmountOfBars: 0,
    realAmountOfBars: null,
    expectedOrganizerContribution: null,
    realOrganizerContribution: null,
    constructionDays: 0,
    deconstructionDays: 0,
    logisticsCosts: 0,
    differentStaffDifferentDays: false,
    expectedPriceRedbullMix: null,
    realPriceRedbullMix: null,
    expectedPriceOrganicsMix: null,
    realPriceOrganicsMix: null,
    priceBeer: null,
    offeredProducts: [],
    missingProductSalesInfo: false,
    satisfactionExecution: 0,
    satisfactionReporting: 0,
    marketingInvestment: 0,
    totalSales: null,
    fine: 0,
    fineReason: null,
    constructionInfo: null,
    activityId: null,
    averageAge: null,
    assignedStaff: [],
    assignedSimpleStaff: [],
    extraCosts: [],
    offeredActivities: [],
    hardwares: [],
    inventories: [],
    createdByUserId: null,
    noteGeneral: '',
    noteExpenses: '',
    noteProducts: '',
    noteActivities: '',
    noteHardware: '',
    evaluation: '',
    cancelable: false,
    rejectable: false,
  },
  definition: eventFormDefinition,
  enums: {
    activities: [],
    agencies: [],
    countries: [],
    districtGroups: [],
    districts: [],
    eventCategories: [],
    eventSegments: [],
    eventSizes: [],
    eventTypes: [],
    extraCosts: [],
    feeTypes: [],
    hardware: [],
    hardwareCategories: [],
    inventory: [],
    inventoryCategories: [],
    invoicingCompanies: [],
    productCategories: [],
    products: [],
    productPrices: [],
    staffContracts: [],
    staffTypes: [],
    wages: [],
    wageTypes: [],
    wholeSalers: [],
  },
  enumsLoaded: false,
  search: {
    value: '',
    filterMonth: [],
    filterYear: [{ value: nowYear, label: nowYear }],
    filterStatus: [],
    filterCountry: [],
    filterOrganiser: [],
    filterSupervisor: [],
    filterSegment: [],
    filterAgency: [],
  },
  temp: {
    staff: [],
    diffStaff: false,
  },

  async initForm(eventId) {
    await this.doLoadEnums();
    await this.initDefinitions();

    if (eventId) {
      // eslint-disable-next-line no-useless-catch
      try {
        await this.loadEvent(eventId);
      } catch (err) {
        throw err;
      }
    } else {
      await this.postLoad();
    }
  },

  async copyForm(eventId) {
    await this.doLoadEnums();
    await this.initDefinitions();

    if (eventId) {
      try {
        let eventData = await eventService.getEvent(eventId);
        if (eventData) {
          for (let key of copyKeys) {
            this.state[key] = eventData[key];
          }

          for (let key of copyCleanKeys) {
            for (let object of eventData[key]) {
              const { id, ...data } = object;

              for (const prop in data) {
                if (prop && prop.startsWith('real')) {
                  data[prop] = null;
                }
              }

              this.state[key].push(data);
            }
          }

          for (let object of eventData.assignedStaff) {
            const { id, ...data } = object;

            for (const prop in data) {
              if (prop && prop.startsWith('real')) {
                data[prop] = null;
              }
            }

            this.temp.staff.push(data);
          }

          for (let object of eventData.assignedSimpleStaff) {
            const { id, ...data } = object;

            for (const prop in data) {
              if (prop && prop.startsWith('real')) {
                data[prop] = null;
              }
            }

            this.temp.staff.push(data);
          }

          this.temp.diffStaff = eventData.differentStaffDifferentDays;
        }

        await this.postLoad();
      } catch (err) {
        if (err.response.status === 404) {
          throw new Error('NOT_FOUND');
        } else {
          throw new Error('ERROR');
        }
      }
    }
  },

  async doLoadEnums() {
    if (this.enumsLoaded) return true;
    if (!this.enumsLoaded) {
      await this.loadEnums();
    }
  },

  saveSearch(page) {
    window.sessionStorage.setItem('redbull-onp-search', JSON.stringify({ ...eventForm.search, page: page }));
  },

  loadSearch() {
    let data = window.sessionStorage.getItem('redbull-onp-search');
    let page = 1;
    if (data) {
      try {
        data = JSON.parse(data);
        if (data.value) eventForm.search.value = data.value;
        if (data.filterMonth) eventForm.search.filterMonth = data.filterMonth;
        if (data.filterStatus) eventForm.search.filterStatus = data.filterStatus;
        if (data.filterCountry) eventForm.search.filterCountry = data.filterCountry;
        if (data.filterOrganiser) eventForm.search.filterOrganiser = data.filterOrganiser;
        if (data.filterSupervisor) eventForm.search.filterSupervisor = data.filterSupervisor;
        if (data.filterSegment) eventForm.search.filterSegment = data.filterSegment;
        if (data.filterAgency) eventForm.search.filterAgency = data.filterAgency;
        if (data.filterYear) eventForm.search.filterYear = data.filterYear;
        if (data.page) page = data.page;
      } catch (err) {
        //
      }
    }
    return page;
  },

  async loadEnums() {
    const eventTypes = await eventService.getEventTypes('active');

    const [
      productCategories,
      products,
      productPrices,
      eventCategories,
      eventSegments,
      feeTypes,
      extraCosts,
      agencies,
      invoicingCompanies,
      wholeSalers,
      staffContracts,
      staffTypes,
      eventSizes,
      wages,
      wageTypes,
      countries,
      activities,
      hardwareCategories,
      hardware,
      districts,
      districtGroups,
      inventory,
      inventoryCategories,
    ] = await Promise.all([
      getProductCategories(),
      getProducts(),
      getProductPrices(),
      getCategories('active'),
      getEventSegments('active'),
      getFeeTypes('active'),
      getExtraCosts('active'),
      getAgencies('active'),
      getInvoicingCompanies('active'),
      getWholesalers('active'),
      getStaffContracts('active'),
      getStaffTypes('active'),
      getSizes('active'),
      getWages('active'),
      getWageTypes('active'),
      getCountries('active'),
      getActivities(),
      getHardwareCategories('active'),
      getHardwares('active'),
      getDistricts('active'),
      getDistrictGroups('active'),
      getInventories(),
      getInventoryCategories(),
    ]);

    this.enums.activities = activities.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.agencies = agencies.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.countries = countries;
    this.enums.districtGroups = districtGroups;
    this.enums.districts = districts.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.eventCategories = eventCategories.sort((x, y) => x.priorityOrder - y.priorityOrder);
    this.enums.eventSegments = eventSegments.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.eventSizes = eventSizes.sort((x, y) => x.order - y.order);
    this.enums.eventTypes = eventTypes.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.extraCosts = extraCosts.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.feeTypes = feeTypes
      .filter(e => e.active)
      .sort((x, y) => x.order - y.order)
      .sort((x, y) => (x.displayedProminently === y.displayedProminently ? 0 : x.displayedProminently ? -1 : 1));
    this.enums.hardware = hardware.sort((x, y) => x.order - y.order);
    this.enums.hardwareCategories = hardwareCategories.sort((x, y) => x.order - y.order);
    this.enums.inventory = inventory.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.inventoryCategories = inventoryCategories.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.invoicingCompanies = invoicingCompanies.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.productCategories = productCategories.sort((x, y) => x.order - y.order);
    this.enums.products = products.sort((x, y) => x.order - y.order);
    this.enums.productPrices = productPrices;
    this.enums.staffContracts = staffContracts.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.staffTypes = staffTypes.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.wages = wages;
    this.enums.wageTypes = wageTypes.sort((a, b) => a.name.localeCompare(b.name));
    this.enums.wholeSalers = wholeSalers.sort((a, b) => a.name.localeCompare(b.name));

    this.enumsLoaded = true;
  },

  async initDefinitions() {
    this.definition.organisedInCode.options = this.enums.countries
      .map(e => {
        e.id = e.code;
        return e;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.definition.categoryId.options = this.enums.eventCategories;
    this.definition.segmentId.options = this.enums.eventSegments;
    this.definition.typeId.options = this.enums.eventTypes;

    this.definition.agencyId.options = this.enums.agencies;

    this.definition.invoicingCompanyId.options = this.enums.invoicingCompanies;

    this.definition.wholeSalerId.options = this.enums.wholeSalers;
    this.definition.contractTypeId.options = this.enums.staffContracts;
    this.definition.sizeId.options = this.enums.eventSizes;
    this.definition.wageTypeId.options = this.enums.wageTypes;
  },

  async loadEvent(id) {
    let eventData;
    try {
      eventData = await eventService.getEvent(id);
    } catch (err) {
      if (err.response.status === 404) {
        throw new Error('NOT_FOUND');
      } else {
        throw new Error('ERROR');
      }
    }

    if (eventData) {
      for (let key in eventData) {
        if (!(key in this.state)) {
          // Debug for key not in state
        }
        this.state[key] = eventData[key];
      }

      eventVisited.addEventVisited(eventData);
    }

    await this.postLoad();
  },

  async postLoad() {
    if (this.state.fees.length === 0 || compareStatus(this.state.status, '<=', STATUS_PLANNING)) {
      this.checkFees();
    }

    if (compareStatus(this.state.status, '=', STATUS_REPORTING)) {
      this.checkStaff();
      this.checkProductListing();
      this.checkActivities();
      if (this.state.eventActivity === null) this.state.eventActivity = 0;
    }

    if (compareStatus(this.state.status, '=', STATUS_FINANCIAL_CHECK)) {
      if (this.state.fine === null) this.state.fine = 0;
      if (this.state.satisfactionExecution === null) this.state.satisfactionExecution = 0;
      if (this.state.satisfactionReporting === null) this.state.satisfactionReporting = 0;

      this.checkRealFees();
    }
  },

  async saveEvent(proceedToNextStep) {
    let response;

    try {
      if (!this.state.id) {
        response = await eventService.createEvent(this.state);
      } else {
        response = await eventService.updateEvent(this.state.id, this.state);
      }

      if (!this.state.id && response?.data?.id) {
        this.state.id = response.data.id;
      }
    } catch (e) {
      console.log('tutuk');
      throw e;
    }
    eventVisited.updateEventVisited(this.state);

    if (proceedToNextStep) {
      await this.proceedEvent();
    } else {
      await this.loadEvent(this.state.id);
    }

    return this.state.id;
  },

  async rejectAgencyEvent() {
    if (this.state.id) {
      await eventService.rejectEvent(this.state.id);
      try {
        await this.loadEvent(this.state.id);
        return true;
      } catch (error) {
        return false;
      }
    }
  },

  async cancelEvent(type, note) {
    if (this.state.id) {
      await eventService.cancelEvent(this.state.id, type, note);
      try {
        await this.loadEvent(this.state.id);
        return true;
      } catch (error) {
        return false;
      }
    }
  },

  canProceedEvent() {
    // ToDo: improve user role validation

    let next = getNextStatus(this.state.status);

    // EDITOR_AUTHORITY
    if (next === STATUS_PLANNING && isUserRedbull()) return true;
    // EDITOR_AUTHORITY
    if (next === STATUS_COST_ESTIMATION && isUserRedbull()) return true;

    if (next === STATUS_MUSKETEER_CHECK && (isUserAgency() || isUserEventManager())) return true;
    // EDITOR_SUPERVISOR
    if (next === STATUS_OVERSEER_CHECK && isUserRedbull()) return true;
    // EDITOR_OVERSEER
    if (next === STATUS_EXECUTION && isUserRedbull()) {
      if (canApproveSelf() || (!canApproveSelf() && this.state.organisedByUserId !== auth.state.id)) {
        return true;
      }
    }
    // EDITOR_SUPERVISOR
    if (next === STATUS_REPORTING && (isUserAgency() || isUserEventManager())) return true;
    // EDITOR_SUPERVISOR
    if (next === STATUS_CONTROLLING && (isUserAgency() || isUserEventManager())) return true;
    // EDITOR_SUPERVISOR
    if (next === STATUS_FINANCIAL_CHECK && (isUserAgencyManager() || isUserEventManager())) return true;
    // EDITOR_AUTHORITY
    if (next === STATUS_FINAL_ACCEPTANCE && isUserRedbull()) return true;
    // EDITOR_OVERSEER
    if (next === STATUS_FINISHED && isUserRedbull()) {
      if (canApproveSelf() || (!canApproveSelf() && this.state.organisedByUserId !== auth.state.id)) {
        return true;
      }
    }
  },

  async proceedEvent() {
    let next = getNextStatus(this.state.status);
    if (next === STATUS_PLANNING) await eventService.proceedEvent(this.state.id, 'planning');
    if (next === STATUS_COST_ESTIMATION) await eventService.proceedEvent(this.state.id, 'cost-estimation');
    if (next === STATUS_MUSKETEER_CHECK) await eventService.proceedEvent(this.state.id, 'musketeer-check');
    if (next === STATUS_OVERSEER_CHECK) await eventService.proceedEvent(this.state.id, 'check-by-superior');
    if (next === STATUS_EXECUTION) await eventService.proceedEvent(this.state.id, 'execute');
    if (next === STATUS_REPORTING) await eventService.proceedEvent(this.state.id, 'report');
    if (next === STATUS_CONTROLLING) await eventService.proceedEvent(this.state.id, 'make-controlling');
    if (next === STATUS_FINANCIAL_CHECK) await eventService.proceedEvent(this.state.id, 'make-financial-check');
    if (next === STATUS_FINAL_ACCEPTANCE) await eventService.proceedEvent(this.state.id, 'make-final-acceptance');
    if (next === STATUS_FINISHED) await eventService.proceedEvent(this.state.id, 'finish');
    await this.loadEvent(this.state.id);
  },

  canRejectEvent() {
    // ToDo: improve user role validation

    // EDITOR_SUPERVISOR
    if (this.state.status === STATUS_MUSKETEER_CHECK && isUserRedbull()) return true;
    // EDITOR_OVERSEER
    if (this.state.status === STATUS_OVERSEER_CHECK && isUserRedbull()) {
      if (canApproveSelf() || (!canApproveSelf() && this.state.organisedByUserId !== auth.state.id)) {
        return true;
      }
    }
    // EDITOR_AUTHORITY
    if (this.state.status === STATUS_CONTROLLING && (isUserEventManager() || isUserAgencyManager())) return true;
    // EDITOR_AUTHORITY
    if (this.state.status === STATUS_FINANCIAL_CHECK && isUserRedbull()) return true;
    // EDITOR_OVERSEER
    if (this.state.status === STATUS_FINAL_ACCEPTANCE && isUserRedbull()) {
      if (canApproveSelf() || (!canApproveSelf() && this.state.organisedByUserId !== auth.state.id)) {
        return true;
      }
    }
  },

  async rejectEvent() {
    if (this.state.status === STATUS_MUSKETEER_CHECK) await eventService.proceedEvent(this.state.id, 'deny-musketeer-check');
    if (this.state.status === STATUS_OVERSEER_CHECK) await eventService.proceedEvent(this.state.id, 'deny-by-superior');
    if (this.state.status === STATUS_CONTROLLING) await eventService.proceedEvent(this.state.id, 'deny-controlling');
    if (this.state.status === STATUS_FINANCIAL_CHECK) await eventService.proceedEvent(this.state.id, 'deny-financial-check');
    if (this.state.status === STATUS_FINAL_ACCEPTANCE) await eventService.proceedEvent(this.state.id, 'deny');
    try {
      await this.loadEvent(this.state.id);
      return true;
    } catch (error) {
      return false;
    }
  },

  canReturnEvent() {
    // ToDo: improve user role validation
    if (this.state.status === STATUS_MUSKETEER_CHECK && isUserRedbull()) return true;
  },

  async returnEvent() {
    if (this.state.status === STATUS_MUSKETEER_CHECK) await eventService.proceedEvent(this.state.id, 'replan');
    try {
      await this.loadEvent(this.state.id);
      return true;
    } catch (error) {
      return false;
    }
  },

  clearState() {
    let newState = JSON.parse(cleanState);
    for (let key in newState) {
      this.state[key] = newState[key];
    }
    this.temp = {
      staff: [],
      diffStaff: false,
    };
  },

  checkFees() {
    let fees = [];
    this.enums.feeTypes.forEach(fee => {
      this.enums.productCategories.map(cat => {
        fees.push({
          expectedAmount: null,
          realAmount: null,
          typeId: fee.id,
          productCategoryId: cat.id,
        });
      });
    });

    if (this.state.fees.length === 0) {
      this.state.fees = fees;
    } else if (compareStatus(this.state.status, '<=', STATUS_PLANNING)) {
      for (let allFee of fees) {
        let ff = this.state.fees.find(e => e.typeId === allFee.typeId && e.productCategoryId === allFee.productCategoryId);
        if (!ff) {
          this.state.fees.push(allFee);
        }
      }
    }
  },

  checkRealFees() {
    for (let fee of this.state.fees) {
      if (fee.realAmount === null) fee.realAmount = fee.expectedAmount;
    }
  },

  checkStaff() {
    for (let staff of this.state.assignedStaff) {
      if (staff.realAmount === null) staff.realAmount = staff.expectedAmount;
      if (staff.realUnits === null) staff.realUnits = staff.expectedUnits;
      if (staff.realUnitCost === null) staff.realUnitCost = staff.expectedUnitCost;
    }

    for (let staff of this.state.assignedSimpleStaff) {
      if (staff.realAmount === null) staff.realAmount = staff.expectedAmount;
      if (staff.realUnits === null) staff.realUnits = staff.expectedUnits;
      if (staff.realUnitCost === null) staff.realUnitCost = staff.expectedUnitCost;
    }

    const groupedData = this.state.assignedStaff.reduce((groups, item) => {
      const { staffTypeId } = item;
      if (!groups[staffTypeId]) {
        groups[staffTypeId] = [];
      }
      groups[staffTypeId].push(item);
      return groups;
    }, {});

    // Sort groupedData by eventDate ascending
    for (const staffTypeId in groupedData) {
      groupedData[staffTypeId].sort((a, b) => {
        return new Date(a.eventDate) - new Date(b.eventDate);
      });
    }

    // Check if properties are identical within each group
    if (this.state.differentStaffDifferentDays === false) {
      for (const staffTypeId in groupedData) {
        const group = groupedData[staffTypeId];
        const firstItem = group[0];
        const { initialAmount, initialUnits, initialUnitCost, expectedAmount, expectedUnits, expectedUnitCost } = firstItem;

        const identical = group.every(
          item =>
            item.initialAmount === initialAmount &&
            item.initialUnits === initialUnits &&
            item.initialUnitCost === initialUnitCost &&
            item.expectedAmount === expectedAmount &&
            item.expectedUnits === expectedUnits &&
            item.expectedUnitCost === expectedUnitCost
        );

        if (!identical) {
          this.state.assignedStaff.forEach(item => {
            if (item.staffTypeId === staffTypeId) {
              item.initialAmount = initialAmount;
              item.initialUnits = initialUnits;
              item.initialUnitCost = initialUnitCost;
              item.expectedAmount = expectedAmount;
              item.expectedUnits = expectedUnits;
              item.expectedUnitCost = expectedUnitCost;
            }
          });
        }
      }
    }
  },

  checkCosts() {
    for (let cost of this.state.extraCosts) {
      if (cost.realAmount === null) cost.realAmount = cost.expectedAmount;
    }
  },

  checkProductListing() {
    for (let product of this.state.offeredProducts) {
      if (product.expectedListing === true && product.realListing === null) {
        product.realListing = true;
      }
      if (product.expectedListing === true && product.realFgtMarketing === null) {
        product.realFgtMarketing = product.expectedFgtMarketing;
      }
      if (product.expectedListing === true && product.realFgtOnp === null) {
        product.realFgtOnp = product.expectedFgtOnp;
      }
      if (product.expectedListing === true && product.realPrice === null) {
        product.realPrice = product.expectedPrice;
      }

      if (product.expectedListing === true && product.realListing === false) {
        if (!(product.realPrice !== null && product.realPrice > 0)) product.realPrice = 0;
        if (!(product.realSale !== null && product.realSale > 0)) product.realSale = 0;
        if (!(product.realFgtOnp !== null && product.realFgtOnp > 0)) product.realFgtOnp = 0;
        if (!(product.realFgtMarketing !== null && product.realFgtMarketing > 0)) product.realFgtMarketing = 0;
      }
    }
  },

  checkActivities() {
    for (let activity of this.state.offeredActivities) {
      if (activity.expectedListing === true && activity.realListing === null) {
        activity.realListing = true;
      }
    }
  },

  addContact(role) {
    return this.state.eventContacts.push({
      fullName: '',
      phoneNumber: '',
      email: '',
      role,
      toggle: true,
    });
  },

  removeContact(contact) {
    this.state.eventContacts.splice(this.state.eventContacts.indexOf(contact), 1);
  },

  getSatisfactionExecution(visibleVariant) {
    if ((!visibleVariant || visibleVariant === 'real') && compareStatus(this.state.status, '>=', STATUS_FINANCIAL_CHECK) && this.state.satisfactionExecution) {
      return this.state.satisfactionExecution / 100;
    } else {
      return 1;
    }
  },

  getSatisfactionReporting(visibleVariant) {
    if ((!visibleVariant || visibleVariant === 'real') && compareStatus(this.state.status, '>=', STATUS_FINANCIAL_CHECK) && this.state.satisfactionReporting) {
      return this.state.satisfactionReporting / 100;
    } else {
      return 1;
    }
  },
};

const cleanState = JSON.stringify(eventForm.state);

export default eventForm;
