import http from '@/services/http';
import { eventVisited } from '@/services/eventVisit';

//-------------------- GETTERS ----------------------

export async function getEvents() {
  try {
    const res = await http.get('/Events');
    eventVisited.validateEventList(res.data.data);
    return res.data.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getEventsQC() {
  try {
    const res = await http.get('/Events/QualityCheck');
    return res.data.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getEvent(id) {
  try {
    const res = await http.get(`/Events/${id}`);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export async function getEventTypes(filter) {
  try {
    const res = await http.get('/EventType');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//------------------------------ POSTS ------------------------

export async function createEvent(data) {
  try {
    return await http.post('/Events', data);
  } catch (e) {
    throw e;
  }
}

export async function createNewEventType(data) {
  try {
    const res = await http.post('EventType', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//------------------------------ PUTS ------------------------

export async function updateEvent(id, data) {
  try {
    const res = await http.put(`/Events/${id}`, data);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function proceedEvent(id, step) {
  try {
    const res = await http.put(`/Events/${id}/${step}`, {});
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function rejectEvent(id) {
  try {
    const res = await http.put(`/Events/${id}/reject-by-agency`, {});
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function cancelEvent(id, type, note) {
  try {
    const res = await http.post(`/Events/${id}/cancel`, {
      cancellationTypeId: type,
      cancellationNote: note,
    });
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function updateEventType(id, data) {
  try {
    const res = await http.put(`/EventType/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
