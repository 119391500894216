<template>
  <div class="sidebar__user">
    <div class="image">
      <img v-if="auth.photo" :src="`data:image/png;base64,${auth.photo}`" :alt="userName" />
      <span v-if="!auth.photo">{{ userShort }}</span>
    </div>
    <strong>{{ userName }}</strong>
    <span v-if="userRole">{{ $t(`common.roles.${userRole}`) }}</span>
  </div>
</template>

<script>
  import auth from '../services/auth';
  import { getUserRole } from '@/services/roles';

  export default {
    name: 'UserBox',
    data() {
      return {
        auth: auth.state,
      };
    },
    computed: {
      userName() {
        if (this.auth.firstName) {
          return `${this.auth.firstName} ${this.auth.lastName}`;
        } else {
          return this.auth.name;
        }
      },
      userShort() {
        if (this.auth.firstName && this.auth.lastName) {
          return `${this.auth.firstName[0]}${this.auth.lastName[0]}`;
        } else if (this.auth.name) {
          return this.auth.name[0];
        } else {
          return '';
        }
      },
      userRole() {
        return getUserRole();
      },
    },
  };
</script>
