<template>
  <vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" v-on="$listeners">
    <div class="form form-modal">
      <div class="form-wrapper">
        <div class="form-field">
          <span
            ><div class="field is-horizontal">
              <div class="field-label">
                <label class="label has-text-left is-required">
                  {{ $t('eventsForm.cancellation.type') }}
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control is-expanded">
                    <div class="select is-fullwidth">
                      <select v-model="cancellationTypeId">
                        <option v-for="option in types" :key="option.id" :value="option.id">
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div></span
          >
        </div>
        <div class="form-field">
          <span
            ><div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left">
                  {{ $t('eventsForm.cancellation.note') }}
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <textarea v-model="cancellationNote" class="textarea" :maxlength="1000"></textarea>
                  </div>
                </div>
              </div></div
          ></span>
        </div>
        <div class="buttons">
          <button class="button is-warning" :disabled="!cancellationTypeId" @click="confirm(close)">
            <span class="icon">
              <inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg>
            </span>
            <span>{{ $t('eventsForm.cancellation.confirm') }}</span>
          </button>
          <button class="button is-primary" @click="$emit('cancel', close)">
            <span>
              {{ $t('eventsForm.cancellation.deny') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
  import http from '@/services/http';

  export default {
    name: 'EventCancelModal',
    inheritAttrs: false,
    data() {
      return {
        types: [],
        cancellationTypeId: null,
        cancellationNote: '',
      };
    },
    mounted() {
      this.getCancellationTypes();
    },
    methods: {
      getCancellationTypes() {
        http.get('/CancellationType').then(data => {
          this.types = data.data.data.filter(e => e.active === true);
        });
      },
      confirm(close) {
        if (this.cancellationTypeId) {
          this.$emit('confirm', this.cancellationTypeId, this.cancellationNote, close);
        }
      },
    },
  };
</script>
