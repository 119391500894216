<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.events.searchButton')" />
      </p>
    </div>

    <div class="tabs inner-tabs">
      <ul>
        <li :class="{ 'is-active': active === 'segments' }">
          <a @click="active = 'segments'">
            {{ $t('admin.tabs.events.tabs.segments.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'type' }">
          <a @click="active = 'type'">
            {{ $t('admin.tabs.events.tabs.type.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'category' }">
          <a @click="active = 'category'">
            {{ $t('admin.tabs.events.tabs.category.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'size' }">
          <a @click="active = 'size'">
            {{ $t('admin.tabs.events.tabs.size.title') }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div v-if="active === 'segments' && !loading" class="columns">
        <div class="form column is-5">
          <h3>{{ $t('admin.tabs.events.tabs.segments.subtitle') }}</h3>
          <div class="form-wrapper">
            <div v-for="(eventSegment, index) in filteredEventSegments" :key="eventSegment.id" class="form-field">
              <div class="field is-horizontal">
                <div class="field-label is-counter">
                  <span class="label">{{ parseInt(index) + 1 }}</span>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <input v-model="eventSegment.name" :readonly="!eventSegment.isEditing" class="input" />
                    </p>
                  </div>
                  <div class="field is-narrow">
                    <p class="control">
                      <button v-if="!eventSegment.isEditing" class="button is-input" @click="editItem(eventSegment.id)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                        </span>
                      </button>
                      <button v-else class="button is-input" @click="processItem(eventSegment)">
                        <span class="icon is-green">
                          <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                        </span>
                      </button>
                      <a v-if="!eventSegment.isUsed" class="field-delete" @click.prevent="deleteItem(eventSegment)">
                        <span class="icon">
                          <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                        </span>
                      </a>
                      <a v-else class="field-delete">
                        <span class="icon has-tooltip-right has-tooltip-arrow has-tooltip-danger" :data-tooltip="$t('admin.tabs.events.tabs.segments.tooltip')">
                          <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field">
              <div class="field is-horizontal level-right">
                <div class="field is-grouped">
                  <p class="control">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="active === 'type' && !loading">
        <div class="columns">
          <div class="column is-8">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.events.tabs.type.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.events.tabs.type.headers.headerTwo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(eventType, index) in filteredEventTypes" :key="eventType.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="eventType.name" :readonly="!eventType.isEditing" class="input" />
                  </td>

                  <td>
                    <div class="select is-fullwidth">
                      <select v-model="eventType.segmentId" :disabled="!eventType.isEditing">
                        <option v-for="segment in eventSegments" :key="segment.id" :value="segment.id" class="help">
                          {{ segment.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="table-actions">
                    <button v-if="!eventType.isEditing" class="button is-input" @click="editItem(eventType.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(eventType)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(eventType)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'category' && !loading">
        <div class="columns">
          <div class="column is-5">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.events.tabs.category.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.events.tabs.category.headers.headerTwo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(category, index) in filteredEventCategories" :key="category.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="category.name" :readonly="!category.isEditing" class="input" />
                  </td>

                  <td class="table-order">
                    <input v-model.number="category.priorityOrder" :readonly="!category.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!category.isEditing" class="button is-input" @click="editItem(category.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(category)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(category)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'size' && !loading">
        <div class="columns">
          <div class="column is-5">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.events.tabs.size.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.events.tabs.size.headers.headerTwo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(size, index) in filteredEventSizes" :key="size.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="size.name" :readonly="!size.isEditing" class="input" />
                  </td>

                  <td class="table-order">
                    <input v-model="size.order" :readonly="!size.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!size.isEditing" class="button is-input" @click="editItem(size.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(size)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(size)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as segmentService from '@/api/segmentService';
  import * as eventService from '@/api/eventService';
  import * as eventCategoryService from '@/api/categoryService';
  import * as eventSizeService from '@/api/sizeService';
  import { searchTerm } from '@/filters';

  export default {
    name: 'AdminEvents',
    data() {
      return {
        active: 'segments',
        loading: false,
        filterText: null,
        eventSegments: [],
        eventTypes: [],
        eventCategories: [],
        eventSizes: [],
      };
    },
    computed: {
      filteredEventSegments() {
        const intersection = this.eventSegments.filter(segment => this.eventTypes.find(type => type.segmentId === segment.id));

        intersection.forEach(x => {
          x.isUsed = true;
        });

        if (this.filterText && this.filterText !== '') {
          return this.eventSegments.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.eventSegments;
        }
      },
      filteredEventTypes() {
        if (this.filterText && this.filterText !== '') {
          return this.eventTypes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.eventTypes;
        }
      },
      filteredEventCategories() {
        if (this.filterText && this.filterText !== '') {
          return this.eventCategories.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.eventCategories;
        }
      },
      filteredEventSizes() {
        if (this.filterText && this.filterText !== '') {
          return this.eventSizes.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.eventSizes;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await Promise.all([this.loadEventSegments(), this.loadEventTypes(), this.loadEventCategories(), this.loadEventSizes()]);
      },
      editItem(id) {
        if (this.active === 'segments') {
          this.$set(
            this.eventSegments.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'type') {
          this.$set(
            this.eventTypes.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'category') {
          this.$set(
            this.eventCategories.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'size') {
          this.$set(
            this.eventSizes.find(item => item.id === id),
            'isEditing',
            true
          );
        }
      },
      addItem() {
        if (this.active === 'segments') {
          this.eventSegments.push({ name: '', isEditing: true });
        } else if (this.active === 'type') {
          this.eventTypes.push({ name: '', isEditing: true });
        } else if (this.active === 'category') {
          this.eventCategories.push({ name: '', isEditing: true });
        } else if (this.active === 'size') {
          this.eventSizes.push({ name: '', isEditing: true });
        }
      },
      processItem(data) {
        data.id ? this.updateItem(data) : this.setNewItem(data);
      },
      async updateItem({ id, name, segmentId, priorityOrder, order }) {
        if (this.active === 'segments') {
          try {
            this.loading = true;
            const res = await segmentService.updateEventSegment(id, { name, active: true });
            if (res.status === 200) await this.loadEventSegments();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'type') {
          try {
            this.loading = true;
            const res = await eventService.updateEventType(id, { name, segmentId, active: true });
            if (res.status === 200) await this.loadEventTypes();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'category') {
          try {
            this.loading = true;
            const res = await eventCategoryService.updateCategory(id, { name, priorityOrder, active: true });
            if (res.status === 200) await this.loadEventCategories();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'size') {
          try {
            this.loading = true;
            const res = await eventSizeService.updateSize(id, { name, order, active: true });
            if (res.status === 200) await this.loadEventSizes();
          } finally {
            this.loading = false;
          }
        }
      },
      async deleteItem({ id, name, segmentId, priorityOrder, order }) {
        if (this.active === 'segments') {
          if (!id) {
            this.eventSegments.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await segmentService.updateEventSegment(id, { name, active: false });
              if (res.status === 200) await this.loadEventSegments();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'type') {
          if (!id) {
            this.eventTypes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await eventService.updateEventType(id, { name, segmentId, active: false });
              if (res.status === 200) await this.loadEventTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'category') {
          if (!id) {
            this.eventCategories.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await eventCategoryService.updateCategory(id, { name, priorityOrder, active: false });
              if (res.status === 200) await this.loadEventCategories();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'size') {
          if (!id) {
            this.eventSizes.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await eventSizeService.updateSize(id, { name, order, active: false });
              if (res.status === 200) await this.loadEventSizes();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async setNewItem({ name, segmentId, priorityOrder, order }) {
        if (this.active === 'segments') {
          if (name) {
            try {
              this.loading = true;
              const res = await segmentService.createNewEventSegment({ name });
              if (res.status === 201) await this.loadEventSegments();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'type') {
          if (name) {
            try {
              this.loading = true;
              const res = await eventService.createNewEventType({ name, segmentId });
              if (res.status === 201) await this.loadEventTypes();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'category') {
          if (name) {
            try {
              this.loading = true;
              const res = await eventCategoryService.createNewCategory({ name, priorityOrder });
              if (res.status === 201) await this.loadEventCategories();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'size') {
          if (name) {
            try {
              this.loading = true;
              const res = await eventSizeService.createNewSize({ name, order });
              if (res.status === 201) await this.loadEventSizes();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async loadEventSegments() {
        try {
          this.loading = true;
          const eventSegments = await segmentService.getEventSegments('active');
          this.eventSegments = eventSegments.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadEventTypes() {
        try {
          this.loading = true;
          const eventTypes = await eventService.getEventTypes('active');
          this.eventTypes = eventTypes.sort((a, b) => a.name.localeCompare(b.name));
        } finally {
          this.loading = false;
        }
      },
      async loadEventCategories() {
        try {
          this.loading = true;
          const eventCategories = await eventCategoryService.getCategories('active');
          this.eventCategories = eventCategories.sort((a, b) => a.priorityOrder - b.priorityOrder);
        } finally {
          this.loading = false;
        }
      },
      async loadEventSizes() {
        try {
          this.loading = true;
          const eventSizes = await eventSizeService.getSizes('active');
          this.eventSizes = eventSizes.sort((a, b) => a.order - b.order);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
