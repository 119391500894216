import http from '@/services/http';

export async function getOrganiserUsers(currentUserId, direct) {
  try {
    const res = await http.get(`/Hierarchy/${currentUserId}/subordinates`, { params: { ...(!direct && { direct: false }) } });
    return res.data.data.map(item => ({ ...item, name: `${item?.firstName || ''} ${item?.lastName || ''}` }));
  } catch (e) {
    console.log(e);
  }
}

export async function getOverseerUsers(organiserUserId, direct) {
  try {
    const res = await http.get(`/Hierarchy/${organiserUserId}/superiors`, { params: { ...(!direct && { direct: false }) } });
    return res.data.data.map(item => ({ ...item, name: `${item?.firstName || ''} ${item?.lastName || ''}` }));
  } catch (e) {
    console.log(e);
  }
}

export async function getAgencySupervisors(agencyId) {
  try {
    const res = await http.get(`/Agency/${agencyId}`);
    if (res.data.users) {
      return res.data.users.map(item => ({ ...item, id: item.userId, name: `${item?.firstName || ''} ${item?.lastName || ''}` }));
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
}
