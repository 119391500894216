import auth from '@/services/auth';

export const ROLE_ADMINISTRATOR = 'Administrator';
export const ROLE_AGENCY = 'Agency';
export const ROLE_AGENCY_MANAGER = 'AgencyManager';
export const ROLE_MUSKETEER = 'Musketeer';
export const ROLE_COLONEL = 'Colonel';
export const ROLE_LIEUTENANT_COLONEL = 'LieutenantColonel';
export const ROLE_EVENT_MANAGER = 'EventManager';

export const ROLES = [
  ROLE_AGENCY,
  ROLE_AGENCY_MANAGER,
  ROLE_MUSKETEER,
  ROLE_LIEUTENANT_COLONEL,
  ROLE_COLONEL,
  ROLE_EVENT_MANAGER,
  ROLE_ADMINISTRATOR,
];

export const ROLES_MAPPING = {
  '17cfde52-6029-4d5a-994c-6fa3b508ae29': ROLE_ADMINISTRATOR,
  '4b89c268-293b-4f53-8be9-fc8ac23e9899': ROLE_AGENCY,
  '9775c777-a479-493f-8949-49386adc4ede': ROLE_AGENCY_MANAGER,
  '81711b20-48a1-427b-9134-fda1bcdcc2d4': ROLE_MUSKETEER,
  'fc1f7716-7549-4634-a9f0-33beae637784': ROLE_COLONEL,
  '3054ee8d-b12c-4a44-a434-5a7b9a1199f9': ROLE_LIEUTENANT_COLONEL,
  '956b32b9-e4e1-49e6-8675-848f418e89e6': ROLE_EVENT_MANAGER,
};

export function isUserAgency() {
  return auth.state.roles.some(role => [ROLE_AGENCY, ROLE_AGENCY_MANAGER].includes(role));
}

export function isUserAgencyManager() {
  return auth.state.roles.some(role => [ROLE_AGENCY_MANAGER].includes(role));
}

export function isUserEventManager() {
  return auth.state.roles.some(role => role === ROLE_EVENT_MANAGER);
}

export function isUserRedbull() {
  return auth.state.roles.some(role => [ROLE_MUSKETEER, ROLE_COLONEL, ROLE_LIEUTENANT_COLONEL, ROLE_EVENT_MANAGER].includes(role));
}

export function isUserAdministrator() {
  return auth.state.roles.some(role => role === ROLE_ADMINISTRATOR);
}

export function canApproveSelf() {
  return auth.state.roles.some(role => [ROLE_COLONEL, ROLE_EVENT_MANAGER].includes(role));
}

export function getUserRole() {
  let roleIndex = -1;
  for (let role of auth.state.roles) {
    let index = ROLES.indexOf(role);
    if (index > roleIndex) {
      roleIndex = index;
    }
  }

  return ROLES[roleIndex];
}
