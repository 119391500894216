<template>
  <span :class="{ compinput: hasComparison && !focused }" @click="onClick">
    <input
      v-show="!hasComparison || (hasComparison && focused)"
      ref="numinput"
      v-model="amount"
      :readonly="readonly"
      :placeholder="placeholder"
      :disabled="disabled"
      type="tel"
      class="input"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />
    <span v-show="hasComparison && !focused">
      {{ amount }}
    </span>
    <span v-show="hasComparison && !focused" class="report-difference" :class="!this.colorOff && $options.filters.amountDifferenceClass(value, comparison, inverted)">{{
      comparison | amountDifference(value, true)
    }}</span>
  </span>
</template>

<script>
  import { formatNumber } from '@/filters';

  export default {
    name: 'NumInputComp',
    props: {
      placeholder: {
        type: String,
        default: '',
      },
      value: {
        type: [Number, String],
        default: 0,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      zeronull: {
        type: Boolean,
        default: false,
      },
      integer: {
        type: Boolean,
        default: false,
      },
      comparison: {
        type: [Number, String],
        default: undefined,
      },
      inverted: {
        type: Boolean,
        default: false,
      },
      colorOff: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        amount: '',
        focused: false,
      };
    },
    computed: {
      valueNumber() {
        if (typeof this.value === 'string') {
          if (!this.integer) return parseFloat(this.value.replace(/\s/g, '').replace(/,/g, '.'));
          if (this.integer) return parseInt(this.value.replace(/\s/g, '').replace(/,/g, '.'));
        } else {
          return this.value;
        }
      },
      hasComparison() {
        return !isNaN(parseFloat(this.comparison));
      },
    },
    watch: {
      valueNumber(newValue) {
        if (this.$refs.numinput !== document.activeElement) {
          this.amount = this.format(newValue);
        }
      },
    },
    mounted() {
      this.amount = this.format(this.valueNumber);
    },
    methods: {
      onClick() {
        if (this.hasComparison && !this.focused) {
          if (!this.readonly) {
            this.focused = true;
            this.$nextTick(() => {
              this.$refs.numinput.focus();
            });
          }
        }
      },
      onFocus() {
        if (!this.readonly) {
          this.focused = true;
        }
        if (!this.readonly && this.zeronull && this.valueNumber === 0) {
          this.amount = '';
        } else if (!this.readonly && this.amount !== '') {
          this.amount = this.valueNumber;
        }
      },
      onBlur() {
        this.focused = false;
        if (!this.readonly && this.zeronull && this.amount === '') {
          this.amount = 0;
        } else if (!this.readonly && this.amount !== '') {
          this.amount = this.format(this.valueNumber);
          if (isNaN(parseFloat(this.amount))) this.amount = '';
          this.$emit('change');
        }
        if (!this.readonly || !this.disabled) this.$emit('blur');
      },
      onInput() {
        if (this.amount !== '') {
          this.$emit('input', this.clean(this.amount));
        } else {
          this.$emit('input', null);
        }
      },
      clean(amount) {
        if (typeof amount === 'string') {
          if (!this.integer) amount = parseFloat(amount.replace(/\s/g, '').replace(/,/g, '.'));
          if (this.integer) amount = parseInt(amount.replace(/\s/g, '').replace(/,/g, '.'));
          if (isNaN(amount)) amount = '';
          return amount;
        } else {
          return amount;
        }
      },
      format(value) {
        if (value) {
          return formatNumber(this.valueNumber);
        } else {
          return value;
        }
      },
    },
  };
</script>
