import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getCancellationTypes(filter) {
  try {
    let res = await http.get('/CancellationType');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateCancellationType(id, data) {
  try {
    const res = await http.put(`/CancellationType/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewCancellationType(data) {
  try {
    const res = await http.post('CancellationType', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
