import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getProducts(filter) {
  try {
    let res = await http.get('/Product');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getProductCategories(filter) {
  try {
    let res = await http.get('/ProductCategory');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getProductPrices(filter) {
  try {
    let res = await http.get('/ProductPrice');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateProduct(id, data) {
  try {
    const res = await http.put(`/Product/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function updateProductCategory(id, data) {
  try {
    const res = await http.put(`/ProductCategory/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function updateProductPrice(id, data) {
  try {
    const res = await http.put(`/ProductPrice/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewProduct(data) {
  try {
    const res = await http.post('Product', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function createNewProductCategory(data) {
  try {
    const res = await http.post('ProductCategory', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function createNewProductPrice(data) {
  try {
    const res = await http.post('ProductPrice', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
