<template>
  <div class="expenses-box">
    <div class="expenses-box__toggle">
      <button class="button is-input" :class="{ 'is-active': toggle }" @click.prevent="toggle = !toggle">
        <span class="icon"><inline-svg :src="require('../../assets/icons/arrow-down.svg')"></inline-svg></span>
      </button>
    </div>
    <div class="expenses-box__title" :class="{ 'is-open': toggle }">
      <h3>{{ title }}</h3>
      <span v-if="!toggle">
        <span>{{ costSubtotal | formatPrice(formState.currencyCode) }}</span>
        <span
          v-if="costSubtotalOther !== null && shouldHaveInitialComparison"
          class="report-difference"
          :class="$options.filters.amountDifferenceClass(costSubtotal, costSubtotalOther, showEditablePriceComparison)"
          >{{ costSubtotalOther | amountDifference(costSubtotal, true, formState.currencyCode) }}</span
        >
      </span>
    </div>

    <div v-if="toggle" class="expenses-box__content">
      <div v-if="canAddNewCost && availableExtraCosts.length > 0" class="form-field">
        <div class="field is-horizontal">
          <div class="field-label">
            <label class="label">{{ $t('eventsForm.expenses.costs.add') }}</label>
          </div>
          <div class="field-body">
            <div class="field is-grouped">
              <div class="control is-expanded">
                <div class="select">
                  <select v-model="newCost" :disabled="!verifyEditability">
                    <option v-for="option in availableExtraCosts" :key="option.id" :value="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <button class="button is-input" :disabled="!newCost || !verifyEditability" @click.prevent="addCost">
                  <span class="icon">
                    <inline-svg :src="require('../../assets/icons/plus-circle.svg')"></inline-svg>
                  </span>
                </button>
              </div>
              <p class="control"></p>
            </div>
          </div>
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>{{ $t('eventsForm.expenses.costs.table.item') }}</th>
            <th v-if="isReported" class="has-text-right">{{ $t('eventsForm.expenses.costs.table.priceExpected') }}</th>
            <th class="has-text-right">{{ $t('eventsForm.expenses.costs.table.price') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="type === 'Agency'" class="is-agency-expenses" :class="{ 'is-open': toggleExpensesAgency }">
            <td>
              <div class="agency-expenses-total">
                <span>{{ $t('eventsForm.expenses.costs.expensesAgency.title') }}</span>
                <span class="icon" @click="toggleExpensesAgency = !toggleExpensesAgency"
                  ><inline-svg :src="require('../../assets/icons/arrow-down.svg')"></inline-svg
                ></span>
              </div>
              <transition name="agency-expenses">
                <div v-show="toggleExpensesAgency" class="agency-expenses-detail">
                  <p>{{ $t('eventsForm.expenses.costs.expensesAgency.fix') }}</p>
                  <p>{{ $t('eventsForm.expenses.costs.expensesAgency.executiveFee') }}</p>
                  <p>{{ $t('eventsForm.expenses.costs.expensesAgency.volumeFee') }}</p>
                  <p>{{ $t('eventsForm.expenses.costs.expensesAgency.reportingFee') }}</p>
                </div>
              </transition>
            </td>
            <td v-if="isReported" class="is-width-2">
              <div>
                <span class="report-field has-units is-transparent">
                  <span>
                    {{ agencyCostExpected.total | formatPrice }}
                  </span>
                  <span class="unit">{{ formState.currencyCode | formatCurrency }}</span>
                </span>
              </div>
              <transition name="agency-expenses">
                <div v-show="toggleExpensesAgency" class="agency-expenses-detail has-text-right">
                  <p>{{ agencyCostExpected.fix | formatPrice(formState.currencyCode) }}</p>
                  <p>{{ agencyCostExpected.executiveFee | formatPrice(formState.currencyCode) }}</p>
                  <p>{{ agencyCostExpected.volumeFee | formatPrice(formState.currencyCode) }}</p>
                  <p>{{ agencyCostExpected.reportingFee | formatPrice(formState.currencyCode) }}</p>
                </div>
              </transition>
            </td>
            <td class="is-width-2 has-text-right">
              <div v-if="!isReported" class="agency-expenses-total">
                <span>{{ agencyCost.total | formatPrice(formState.currencyCode) }}</span>
                <span
                    v-if="agencyCostOther.total !== null && shouldHaveInitialComparison"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(agencyCost.total, agencyCostOther.total, showEditablePriceComparison)"
                >{{ agencyCostOther.total | amountDifference(agencyCost.total, true, formState.currencyCode) }}</span
                >
              </div>
              <div v-else>
                <span class="report-field has-units is-transparent">
                  <span>
                    {{ agencyCost.total | formatPrice }}
                    <ArrowCompare :real="agencyCost.total" :expected="agencyCostExpected.total" :expenses="true" />
                  </span>
                  <span class="unit">{{ formState.currencyCode | formatCurrency }}</span>
                </span>
              </div>
              <transition name="agency-expenses">
                <div v-show="toggleExpensesAgency" class="agency-expenses-detail">
                  <p>{{ agencyCost.fix | formatPrice(formState.currencyCode) }}</p>
                  <p>{{ agencyCost.executiveFee | formatPrice(formState.currencyCode) }}</p>
                  <p>{{ agencyCost.volumeFee | formatPrice(formState.currencyCode) }}</p>
                  <p>{{ agencyCost.reportingFee | formatPrice(formState.currencyCode) }}</p>
                </div>
              </transition>
            </td>
          </tr>
          <tr v-for="cost in selectedExtraCosts" :key="cost.costTypeId">
            <td>{{ getCost(cost.costTypeId).name }}</td>
            <td v-if="isReported" class="is-width-2">
              <span class="report-field has-units">
                <span>
                  {{ cost.expectedAmount }}
                </span>
                <span class="unit">{{ formState.currencyCode | formatCurrency }}</span>
              </span>
            </td>

            <td v-if="!isReported || realCostsEditable" class="is-width-2 has-text-right">
              <ValidationProvider
                v-slot="{ errors }"
                :name="`cost.${visibleVariant}Amount`"
                :vid="`cost${visibleVariant}Amount-${cost.costTypeId}`"
                :rules="{ required: true }"
              >
                <span class="control has-icons-right">
                  <NumInputComp
                    v-model.number="cost[`${visibleVariant}Amount`]"
                    :comparison="
                      showDisabledPriceComparison ? (cost.initialAmount ? cost.initialAmount : 0) : showEditablePriceComparison ? cost.expectedAmount : false
                    "
                    :inverted="showEditablePriceComparison"
                    :class="{ 'is-warning': errors[0] }"
                    :readonly="!verifyEditability"
                  />
                  <span class="icon is-small is-right">
                    {{ formState.currencyCode | formatCurrency }}
                  </span>
                  <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                </span>
              </ValidationProvider>
              <a v-if="isCostDeletable(cost)" class="table-delete">
                <span class="icon" @click="deleteCost(cost)">
                  <inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg>
                </span>
              </a>
            </td>
            <td v-if="isReported && !realCostsEditable" class="is-width-2 has-text-right">
              <span class="report-field has-units">
                <span>
                  {{ cost.realAmount }}
                  <ArrowCompare :real="cost.realAmount" :expected="cost.expectedAmount" :expenses="true" />
                </span>
                <span class="unit">{{ formState.currencyCode | formatCurrency }}</span>
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>{{ $t('eventsForm.expenses.staff.subtotal') }}</th>
            <th v-if="isReported"></th>
            <th class="is-subtotal">
              <span>{{ costSubtotal | formatPrice(formState.currencyCode) }}</span>
              <span
                v-if="costSubtotalOther !== null && shouldHaveInitialComparison"
                class="report-difference"
                :class="$options.filters.amountDifferenceClass(costSubtotal, costSubtotalOther, showEditablePriceComparison)"
                >{{ costSubtotalOther | amountDifference(costSubtotal, true, formState.currencyCode) }}</span
              >
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  import form, {
    EDITOR_AGENCY,
    EDITOR_AUTHORITY,
    eventFormDefinition,
    EXPENSES_EXECUTIVE_FEE,
    EXPENSES_FIX,
    EXPENSES_REPORTING_FEE,
    EXPENSES_VOLUME_FEE,
  } from '@/services/eventForm';
  import {
    compareStatus,
    STATUS_CONTROLLING,
    STATUS_FINISHED,
    STATUS_PLANNING,
    STATUS_COST_ESTIMATION,
    STATUS_REPORTING,
    STATUS_MUSKETEER_CHECK,
  } from '@/services/status';
  import { ValidationProvider } from 'vee-validate';
  import ArrowCompare from '@/components/EventsForm/ArrowCompare';
  import { isUserAgency, isUserEventManager, isUserRedbull } from '@/services/roles';
  import NumInputComp from '@/components/EventsForm/NumInputComp';

  export default {
    name: 'ExpensesCostsBox',
    components: { NumInputComp, ArrowCompare, ValidationProvider },
    props: {
      title: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        form: eventFormDefinition,
        formEnums: form.enums,
        formState: form.state,
        toggle: false,
        toggleExpensesAgency: false,
        newCost: null,
      };
    },
    computed: {
      availableExtraCosts() {
        let init = this.formEnums.extraCosts;

        if (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_COST_ESTIMATION)) {
          init = init.filter(e => e.agencyCostEstimation === true);
        }

        if (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_REPORTING)) {
          init = init.filter(e => e.agencyReporting === true);
        }

        return init.filter(e => e.type === this.type).filter(e => !this.selectedExtraCosts.some(d => d.costTypeId === e.id));
      },
      selectedExtraCosts() {
        return this.formState.extraCosts
          .map(e => {
            let costData = this.formEnums.extraCosts.find(f => f.id === e.costTypeId);
            e.type = costData.type;
            return e;
          })
          .filter(e => e.type === this.type);
      },
      costSubtotal() {
        if (!this.formState.extraCosts) return null;
        let subtotal = 0;
        for (let cost of this.selectedExtraCosts) {
          if (cost[`${this.visibleVariant}Amount`] !== null) {
            subtotal += parseFloat(cost[`${this.visibleVariant}Amount`]);
          }
        }
        if (this.type === 'Agency') {
          subtotal += this.agencyCost.total;
        }
        return subtotal;
      },
      costSubtotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.extraCosts) return null;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let cost of this.selectedExtraCosts) {
          if (cost[`${otherVariant}Amount`] !== null) {
            subtotal += parseFloat(cost[`${otherVariant}Amount`]);
          }
        }
        if (this.type === 'Agency') {
          subtotal += this.agencyCostOther.total;
        }
        return subtotal;
      },
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.extraCosts.initial.visible[0], this.form.extraCosts.initial.visible[1])) {
          return 'initial';
        } else if (compareStatus(this.formState.status, this.form.extraCosts.expected.visible[0], this.form.extraCosts.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.extraCosts.real.visible[0], this.form.extraCosts.real.visible[1])) {
          return 'real';
        }
        return false;
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      initialCostsEditable() {
        return compareStatus(this.formState.status, '<=', STATUS_PLANNING) || isUserEventManager();
      },
      expectedCostsEditable() {
        return compareStatus(this.formState.status, '=', STATUS_COST_ESTIMATION) || isUserEventManager();
      },
      realCostsEditable() {
        return compareStatus(this.formState.status, '<', STATUS_FINISHED) || isUserEventManager();
      },
      agencyCostBase() {
        if (this.type === 'Agency') {
          if (!this.formState.assignedStaff && !this.formState.assignedSimpleStaff) return 0;
          let subtotal = 0;
          for (let staff of this.formState.assignedStaff) {
            subtotal += staff[`${this.visibleVariant}Amount`] * staff[`${this.visibleVariant}Units`] * staff[`${this.visibleVariant}UnitCost`];
          }
          for (let staff of this.formState.assignedSimpleStaff) {
            subtotal += staff[`${this.visibleVariant}Amount`] * staff[`${this.visibleVariant}Units`] * staff[`${this.visibleVariant}UnitCost`];
          }
          for (let cost of this.selectedExtraCosts) {
            if (cost[`${this.visibleVariant}Amount`] !== null) {
              subtotal += parseFloat(cost[`${this.visibleVariant}Amount`]);
            }
          }
          return subtotal;
        }
        return false;
      },
      agencyCost() {
        if (this.type === 'Agency') {
          let base = this.agencyCostBase;

          return {
            fix: (base * (EXPENSES_FIX * 1000)) / 1000,
            executiveFee: (((base * (EXPENSES_EXECUTIVE_FEE * 1000)) / 1000) * (form.getSatisfactionExecution(this.visibleVariant) * 1000)) / 1000,
            volumeFee: (base * (EXPENSES_VOLUME_FEE * 1000)) / 1000,
            reportingFee: (((base * (EXPENSES_REPORTING_FEE * 1000)) / 1000) * (form.getSatisfactionReporting(this.visibleVariant) * 1000)) / 1000,
            get total() {
              return this.fix + this.executiveFee + this.volumeFee + this.reportingFee;
            },
          };
        }
        return false;
      },
      agencyCostBaseOther() {
        if (this.type === 'Agency') {
          let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
          if (!this.formState.assignedStaff && !this.formState.assignedSimpleStaff) return 0;
          let subtotal = 0;
          for (let staff of this.formState.assignedStaff) {
            subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
          }
          for (let staff of this.formState.assignedSimpleStaff) {
            subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
          }
          for (let cost of this.selectedExtraCosts) {
            if (cost[`${otherVariant}Amount`] !== null) {
              subtotal += parseFloat(cost[`${otherVariant}Amount`]);
            }
          }
          return subtotal;
        }
        return false;
      },
      agencyCostOther() {
        if (this.type === 'Agency') {
          let base = this.agencyCostBaseOther;

          return {
            fix: (base * (EXPENSES_FIX * 1000)) / 1000,
            executiveFee: (((base * (EXPENSES_EXECUTIVE_FEE * 1000)) / 1000) * (form.getSatisfactionExecution(this.visibleVariant) * 1000)) / 1000,
            volumeFee: (base * (EXPENSES_VOLUME_FEE * 1000)) / 1000,
            reportingFee: (((base * (EXPENSES_REPORTING_FEE * 1000)) / 1000) * (form.getSatisfactionReporting(this.visibleVariant) * 1000)) / 1000,
            get total() {
              return this.fix + this.executiveFee + this.volumeFee + this.reportingFee;
            },
          };
        }
        return false;
      },
      agencyCostBaseExpected() {
        if (this.type === 'Agency' && this.isReported) {
          if (!this.formState.assignedStaff && !this.formState.assignedSimpleStaff) return 0;
          let subtotal = 0;
          for (let staff of this.formState.assignedStaff) {
            subtotal += staff.expectedAmount * staff.expectedUnits * staff.expectedUnitCost;
          }
          for (let staff of this.formState.assignedSimpleStaff) {
            subtotal += staff.expectedAmount * staff.expectedUnits * staff.expectedUnitCost;
          }
          return subtotal;
        }
        return false;
      },
      agencyCostExpected() {
        if (this.type === 'Agency' && this.isReported) {
          let base = this.agencyCostBaseExpected;

          return {
            fix: (base * (EXPENSES_FIX * 1000)) / 1000,
            executiveFee: (((base * (EXPENSES_EXECUTIVE_FEE * 1000)) / 1000) * (form.getSatisfactionExecution('expected') * 1000)) / 1000,
            volumeFee: (base * (EXPENSES_VOLUME_FEE * 1000)) / 1000,
            reportingFee: (((base * (EXPENSES_REPORTING_FEE * 1000)) / 1000) * (form.getSatisfactionReporting('expected') * 1000)) / 1000,
            get total() {
              return this.fix + this.executiveFee + this.volumeFee + this.reportingFee;
            },
          };
        }
        return false;
      },

      verifyEditability() {
        let editable = false;

        if ('editable' in this.form.extraCosts[this.visibleVariant]) {
          editable = compareStatus(
            this.formState.status,
            this.form.extraCosts[this.visibleVariant].editable[0],
            this.form.extraCosts[this.visibleVariant].editable[1]
          );
        } else {
          editable = true;
        }

        if ('editor' in this.form.extraCosts[this.visibleVariant] && editable) {
          if (
            this.form.extraCosts[this.visibleVariant].editor.includes(EDITOR_AGENCY) &&
            !this.form.extraCosts[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)
          ) {
            return isUserAgency() || isUserEventManager();
          }
          if (
            !this.form.extraCosts[this.visibleVariant].editor.includes(EDITOR_AGENCY) &&
            this.form.extraCosts[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)
          ) {
            return isUserRedbull();
          }
        }

        if (editable && this.formState.status === STATUS_FINISHED) {
          editable = false;
        }

        // event manager override
        if (isUserEventManager()) {
          editable = true;
        }

        return editable;
      },
      canAddNewCost() {
        return (
          (this.initialCostsEditable && this.visibleVariant === 'initial') ||
          (this.expectedCostsEditable && this.visibleVariant === 'expected') ||
          (this.realCostsEditable && this.visibleVariant === 'real') ||
          (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_REPORTING))
        );
      },
      showDisabledPriceComparison() {
        return compareStatus(this.formState.status, '=', STATUS_MUSKETEER_CHECK);
      },
      showEditablePriceComparison() {
        return this.shouldHaveInitialComparison && compareStatus(this.formState.status, '=', STATUS_PLANNING);
      },
      shouldHaveInitialComparison() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return false;
        let a = this.formState.assignedStaff.find(e => e.initialUnitCost !== null && e.expectedUnitCost !== null);
        let b = this.formState.extraCosts.find(e => e.initialAmount !== null && e.expectedAmount !== null && e.expectedAmount !== 0);
        return typeof a !== 'undefined' || typeof b !== 'undefined';
      },
    },
    methods: {
      getCost(id) {
        return this.formEnums.extraCosts.find(e => e.id === id);
      },
      addCost() {
        if (this.visibleVariant === 'initial') {
          this.formState.extraCosts.push({
            costTypeId: this.newCost,
            initialAmount: 0,
            expectedAmount: null,
            realAmount: null,
          });
        } else if (this.visibleVariant === 'expected') {
          this.formState.extraCosts.push({
            costTypeId: this.newCost,
            initialAmount: 0,
            expectedAmount: 0,
            realAmount: null,
          });
        } else if (this.visibleVariant === 'real') {
          this.formState.extraCosts.push({
            costTypeId: this.newCost,
            initialAmount: 0,
            expectedAmount: 0,
            realAmount: 0,
          });
        }

        this.newCost = '';
      },
      isCostDeletable(cost) {
        let retVal =
          (this.initialCostsEditable && this.visibleVariant === 'initial') ||
          (this.expectedCostsEditable && this.visibleVariant === 'expected') ||
          (this.realCostsEditable && this.visibleVariant === 'real') ||
          (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_REPORTING));

        if (isUserAgency() && cost.id) {
          retVal = false;
        }
        return retVal;
      },
      deleteCost(cost) {
        this.formState.extraCosts.splice(this.formState.extraCosts.indexOf(cost), 1);
      },
    },
  };
</script>
