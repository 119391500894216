import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getCountries(filter) {
  try {
    let res = await http.get('/Country');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}
