var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{ 'is-inactive': !_vm.isSelected(_vm.product.id) }},[(!_vm.isReported || _vm.isFinancialCheck)?_c('td',[_c('Toggle',{attrs:{"value":_vm.isSelected(_vm.product.id),"disabled":!_vm.isEditable},on:{"input":function($event){return _vm.onSelected(_vm.product, $event)}}})],1):_vm._e(),_c('td',[_c('span',[_vm._v(_vm._s(_vm.getProductCategoryName(_vm.product.id)))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.product.name))])]),(!_vm.isReported || _vm.isFinancialCheck)?_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":("product." + _vm.visibleVariant + "Price"),"vid":("product" + _vm.visibleVariant + "Price-" + (_vm.product.id)),"rules":_vm.validationRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.getProduct(_vm.product.id))?_c('NumInput',{staticClass:"input",class:{ 'is-warning': errors[0] },attrs:{"min":"0","readonly":!_vm.isEditable},on:{"change":_vm.onPriceChange},model:{value:(_vm.getProduct(_vm.product.id)[(_vm.visibleVariant + "Price")]),callback:function ($$v) {_vm.$set(_vm.getProduct(_vm.product.id), (_vm.visibleVariant + "Price"), _vm._n($$v))},expression:"getProduct(product.id)[`${visibleVariant}Price`]"}}):_c('NumInput',{staticClass:"input",attrs:{"readonly":""}}),_c('span',{staticClass:"icon is-small is-right"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.formState.currencyCode))+" ")]),(errors[0])?_c('span',{staticClass:"help is-warning"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3757863574)})],1)]):_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"report-field has-units"},[_c('span',[_vm._v(" "+_vm._s(_vm.product.realPrice)+" "),_c('span',{staticClass:"report-difference",class:_vm.$options.filters.amountDifferenceClass(_vm.product.expectedPrice, _vm.product.realPrice)},[_vm._v(" "+_vm._s(_vm._f("amountDifference")(_vm.product.expectedPrice,_vm.product.realPrice, true)))])]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.formState.currencyCode)))])])]),(!_vm.isReported || _vm.isFinancialCheck)?_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":("product." + _vm.visibleVariant + "FgtOnp"),"vid":("product" + _vm.visibleVariant + "FgtOnp-" + (_vm.product.id)),"rules":_vm.validationRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.getProduct(_vm.product.id))?_c('NumInput',{staticClass:"input",class:{ 'is-warning': errors[0] },attrs:{"min":"0","readonly":!_vm.isEditable,"zeronull":true},model:{value:(_vm.getProduct(_vm.product.id)[(_vm.visibleVariant + "FgtOnp")]),callback:function ($$v) {_vm.$set(_vm.getProduct(_vm.product.id), (_vm.visibleVariant + "FgtOnp"), _vm._n($$v))},expression:"getProduct(product.id)[`${visibleVariant}FgtOnp`]"}}):_c('NumInput',{staticClass:"input",attrs:{"readonly":""}}),_c('span',{staticClass:"icon is-small is-right"},[_vm._v(" "+_vm._s(_vm.$t('common.unit.pcs'))+" ")]),(errors[0])?_c('span',{staticClass:"help is-warning"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,1586840643)})],1)]):_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"report-field has-units"},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.product.realFgtOnp))+" "+_vm._s(_vm._f("amountDifference")(_vm.product.expectedFgtOnp,_vm.product.realFgtOnp, true))+" ")]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$t('common.unit.pcs')))])])]),(!_vm.isReported || _vm.isFinancialCheck)?_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":("product." + _vm.visibleVariant + "FgtMarketing"),"vid":("product" + _vm.visibleVariant + "FgtMarketing-" + (_vm.product.id)),"rules":_vm.validationRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.getProduct(_vm.product.id))?_c('NumInput',{staticClass:"input",class:{ 'is-warning': errors[0] },attrs:{"min":"0","readonly":!_vm.isEditable,"zeronull":true},model:{value:(_vm.getProduct(_vm.product.id)[(_vm.visibleVariant + "FgtMarketing")]),callback:function ($$v) {_vm.$set(_vm.getProduct(_vm.product.id), (_vm.visibleVariant + "FgtMarketing"), _vm._n($$v))},expression:"getProduct(product.id)[`${visibleVariant}FgtMarketing`]"}}):_c('NumInput',{staticClass:"input",attrs:{"readonly":""}}),_c('span',{staticClass:"icon is-small is-right"},[_vm._v(" "+_vm._s(_vm.$t('common.unit.pcs'))+" ")]),(errors[0])?_c('span',{staticClass:"help is-warning"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,1240030230)})],1)]):_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"report-field has-units"},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.product.realFgtMarketing))+" "+_vm._s(_vm._f("amountDifference")(_vm.product.expectedFgtMarketing,_vm.product.realFgtMarketing, true))+" ")]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$t('common.unit.pcs')))])])]),(!_vm.isReported && !_vm.isFinancialCheck)?_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":("product." + _vm.visibleVariant + "Sale"),"vid":("product" + _vm.visibleVariant + "Sale-" + (_vm.product.id)),"rules":_vm.validationRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.getProduct(_vm.product.id))?_c('NumInput',{staticClass:"input",class:{ 'is-warning': errors[0] },attrs:{"min":"0","readonly":!_vm.isEditable},model:{value:(_vm.getProduct(_vm.product.id)[(_vm.visibleVariant + "Sale")]),callback:function ($$v) {_vm.$set(_vm.getProduct(_vm.product.id), (_vm.visibleVariant + "Sale"), _vm._n($$v))},expression:"getProduct(product.id)[`${visibleVariant}Sale`]"}}):_c('NumInput',{staticClass:"input",attrs:{"readonly":""}}),_c('span',{staticClass:"icon is-small is-right"},[_vm._v(" "+_vm._s(_vm.$t('common.unit.pcs'))+" ")]),(errors[0])?_c('span',{staticClass:"help is-warning"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,973471877)})],1)]):(_vm.isReported && !_vm.isFinancialCheck)?_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"report-field has-units"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.product.realSale))+" "),_c('span',{staticClass:"report-difference",class:_vm.$options.filters.amountDifferenceClass(_vm.product.expectedSale, _vm.product.realSale)},[_vm._v(_vm._s(_vm._f("amountDifference")(_vm.product.expectedSale,_vm.product.realSale, true)))])]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$t('common.unit.pcs')))])])]):(_vm.isFinancialCheck)?_c('td',{staticClass:"is-width-2"},[_c('span',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":("product." + _vm.visibleVariant + "Sale"),"vid":("product" + _vm.visibleVariant + "Sale-" + (_vm.product.id)),"rules":_vm.validationRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.getProduct(_vm.product.id))?_c('NumInputComp',{class:{ 'is-warning': errors[0] },attrs:{"comparison":_vm.product.expectedSale,"min":"0","readonly":!_vm.isEditable,"inverted":true},model:{value:(_vm.getProduct(_vm.product.id)[(_vm.visibleVariant + "Sale")]),callback:function ($$v) {_vm.$set(_vm.getProduct(_vm.product.id), (_vm.visibleVariant + "Sale"), _vm._n($$v))},expression:"getProduct(product.id)[`${visibleVariant}Sale`]"}}):_c('NumInput',{staticClass:"input",attrs:{"readonly":""}}),_c('span',{staticClass:"icon is-small is-right"},[_vm._v(" "+_vm._s(_vm.$t('common.unit.pcs'))+" ")]),(errors[0])?_c('span',{staticClass:"help is-warning"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }