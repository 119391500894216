import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getSizes(filter) {
  try {
    let res = await http.get('/Size');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateSize(id, data) {
  try {
    const res = await http.put(`/Size/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewSize(data) {
  try {
    const res = await http.post('Size', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
