import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getHardwares(filter) {
  try {
    let res = await http.get('/Hardware');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getHardwareCategories(filter) {
  try {
    let res = await http.get('/HardwareCategory');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateHardware(id, data) {
  try {
    const res = await http.put(`/Hardware/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function updateHardwareCategory(id, data) {
  try {
    const res = await http.put(`/HardwareCategory/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewHardware(data) {
  try {
    const res = await http.post('/Hardware', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function createNewHardwareCategory(data) {
  try {
    const res = await http.post('/HardwareCategory', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
