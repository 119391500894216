<template>
  <div class="columns is-activities">
    <div class="column is-three-fifths">
      <table class="table offered-activities-table">
        <thead>
          <tr>
            <th v-if="!isReported">{{ $t('eventsForm.activities.listing') }}</th>
            <th>{{ $t('eventsForm.activities.activity') }}</th>
            <th>{{ $t(`eventsForm.activities.amount.${visibleVariant}`) }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(activity, index) in availableActivities" :key="activity.id">
            <td v-if="!isReported" class="is-width-1">
              <Toggle :value="isSelected(activity.id)" :disabled="!isEditable" @input="onSelected(activity, $event)" />
            </td>
            <td>{{ activity.name }}</td>
            <td v-if="!isReported" class="is-width-2">
              <ValidationProvider
                v-slot="{ errors }"
                :name="`activity.${visibleVariant}Amount`"
                :vid="`activity${visibleVariant}Amount-${index}`"
                :rules="{ required: activity[`${visibleVariant}AmountRequired`] && isSelected(activity.id) }"
              >
                <span v-if="activity[`${visibleVariant}AmountRequired`] && getActivity(activity.id)" class="control has-icons-right">
                  <NumInput
                    v-model.number="getActivity(activity.id)[`${visibleVariant}Amount`]"
                    class="input"
                    min="0"
                    :readonly="!isEditable"
                    :class="{ 'is-warning': errors[0] }"
                    :integer="true"
                  />
                  <span class="icon is-small is-right">
                    {{ activity.unitType }}
                  </span>
                  <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                </span>

                <span v-else-if="activity[`${visibleVariant}AmountRequired`]" class="control has-icons-right">
                  <NumInput readonly class="input" />
                  <span class="icon is-small is-right">
                    {{ activity.unitType }}
                  </span>
                </span>
              </ValidationProvider>
            </td>
            <td v-if="isReported" class="is-width-2">
              <span v-if="activity.realAmountRequired" class="report-field has-units">
                <span
                  >{{ activity.realAmount }}
                  <template v-if="activity.expectedAmountRequired">{{ activity.expectedAmount | amountDifference(activity.realAmount) }}</template>
                </span>
                <span class="unit">ks</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!isEditable && selectedCount === 0" class="info-text">{{ $t('eventsForm.activities.overview.none') }}</p>
      <div class="form is-hidden-desktop">
        <h3>{{ $t('eventsForm.activities.notes.title') }}</h3>
        <div class="form-wrapper">
          <FormElement name="noteActivities" :field="form['noteActivities']" css-class="is-note" />
        </div>
      </div>
    </div>
    <div class="column is-two-fifths">
      <div v-if="isOverviewVisible" class="offered-activities-overview">
        <h3>{{ $t('eventsForm.activities.overview.selected') }}</h3>
        <ul v-if="selectedCount > 0" class="item-overview">
          <li v-for="activity in formState.offeredActivities" :key="activity.activityId">
            <strong>{{ getActivityEnum(activity.activityId) }}</strong>
            <span
              ><template v-if="activity[`${visibleVariant}Amount`]">x{{ activity[`${visibleVariant}Amount`] | formatNumber }}</template></span
            >
            <a v-if="isEditable" @click="deselectActivity(activity)"
              ><span class="icon"><inline-svg :src="require('../../assets/icons/cross.svg')"></inline-svg></span
            ></a>
          </li>
        </ul>
        <p v-if="selectedCount === 0">{{ $t('eventsForm.activities.overview.none') }}</p>
      </div>

      <div class="form is-hidden-touch">
        <h3>{{ $t('eventsForm.activities.notes.title') }}</h3>
        <div class="form-wrapper">
          <FormElement name="noteActivities" :field="form['noteActivities']" css-class="is-note" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import form, { EDITOR_AGENCY, EDITOR_AUTHORITY, eventFormDefinition } from '@/services/eventForm';
  import { compareStatus, STATUS_CONTROLLING, STATUS_FINISHED, STATUS_REPORTING } from '@/services/status';
  import Toggle from '@/components/Toggle';
  import FormElement from '@/components/EventsForm/FormElement';
  import { ValidationProvider } from 'vee-validate';
  import { isUserAgency, isUserEventManager, isUserRedbull } from '@/services/roles';
  import NumInput from '@/components/EventsForm/NumInput';

  export default {
    name: 'Activities',
    components: { NumInput, ValidationProvider, FormElement, Toggle },
    data() {
      return {
        form: eventFormDefinition,
        formState: form.state,
        formEnums: form.enums,
      };
    },
    computed: {
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.offeredActivities.expected.visible[0], this.form.offeredActivities.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.offeredActivities.real.visible[0], this.form.offeredActivities.real.visible[1])) {
          return 'real';
        }
        return false;
      },
      availableActivities() {
        if (this.visibleVariant === 'expected') {
          return this.formEnums.activities.filter(e => e.active || this.expectedActivities.includes(e.id));
        } else if (this.isReporting) {
          return this.formEnums.activities
            .filter(e => e.active || this.expectedActivities.includes(e.id))
            .sort((x, y) =>
              this.expectedActivities.includes(x.id) === this.expectedActivities.includes(y.id) ? 0 : this.expectedActivities.includes(x.id) ? -1 : 1
            );
        } else {
          let selectedActivities = this.formState.offeredActivities.map(e => e.activityId);
          return this.formEnums.activities
            .filter(e => selectedActivities.includes(e.id))
            .map(e => {
              let state = this.formState.offeredActivities.find(r => r.activityId === e.id);
              return { ...state, ...e };
            });
        }
      },
      selectedCount() {
        return this.formState.offeredActivities.length;
      },
      expectedActivities() {
        return this.formState.offeredActivities.filter(e => e.expectedListing === true).map(e => e.activityId);
      },
      isEditable() {
        let editable = false;

        if ('editable' in this.form.offeredActivities[this.visibleVariant]) {
          editable = compareStatus(
            this.formState.status,
            this.form.offeredActivities[this.visibleVariant].editable[0],
            this.form.offeredActivities[this.visibleVariant].editable[1]
          );
        } else {
          editable = true;
        }

        if ('editor' in this.form.offeredActivities[this.visibleVariant] && editable) {
          if (
            this.form.offeredActivities[this.visibleVariant].editor.includes(EDITOR_AGENCY) &&
            !this.form.offeredActivities[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)
          ) {
            return isUserAgency() || isUserEventManager();
          }
          if (
            !this.form.offeredActivities[this.visibleVariant].editor.includes(EDITOR_AGENCY) &&
            this.form.offeredActivities[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)
          ) {
            return isUserRedbull();
          }
        }

        if (isUserEventManager()) {
          return true;
        }

        return editable;
      },
      isOverviewVisible() {
        return compareStatus(this.formState.status, '<', STATUS_REPORTING);
      },
      isReporting() {
        return compareStatus(this.formState.status, '=', [STATUS_REPORTING, STATUS_CONTROLLING]);
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
    },
    methods: {
      deselectActivity({ activityId }) {
        this.formState.offeredActivities.splice(
          this.formState.offeredActivities.findIndex(e => e.activityId === activityId),
          1
        );
      },

      onSelected(activity, state) {
        if (this.visibleVariant === 'expected') {
          if (state === true) {
            let is = this.formState.offeredActivities.find(e => e.activityId === activity.id);
            if (!is) {
              this.formState.offeredActivities.push({
                activityId: activity.id,
                expectedListing: true,
              });
            }
          } else if (state === false) {
            this.formState.offeredActivities.splice(
              this.formState.offeredActivities.findIndex(e => e.activityId === activity.id),
              1
            );
          }
        } else {
          if (state === true) {
            let is = this.formState.offeredActivities.find(e => e.activityId === activity.id);
            if (is) {
              is.realListing = true;
            } else {
              this.formState.offeredActivities.push({
                activityId: activity.id,
                expectedListing: false,
                ...(activity.expectedAmountRequired && { expectedAmount: 0 }),
                realListing: true,
              });
            }
          } else if (state === false) {
            let is = this.formState.offeredActivities.find(e => e.activityId === activity.id);
            if (is) {
              is.realListing = false;

              if (is.realListing === false && is.expectedListing === false) {
                this.formState.offeredActivities.splice(this.formState.offeredActivities.indexOf(is), 1);
              }
            }
          }
        }
      },
      isSelected(activityId) {
        if (this.visibleVariant === 'expected') {
          return !!this.formState.offeredActivities.find(e => e.activityId === activityId);
        } else {
          let activity = this.formState.offeredActivities.find(e => e.activityId === activityId);
          if (activity) {
            if (activity.realListing === null) {
              return activity.expectedListing;
            } else {
              return activity.realListing;
            }
          } else {
            return false;
          }
        }
      },
      getActivity(activityId) {
        return this.formState.offeredActivities.find(e => e.activityId === activityId);
      },
      getActivityEnum(id) {
        return this.formEnums.activities.find(e => e.id === id)?.name;
      },
    },
  };
</script>
