<template>
  <div>
    <div class="page-head with-filters">
      <h1 class="title is-2">{{ $t('events.title') }}</h1>

      <div class="actions">
        <router-link v-if="canCreateEvents" :to="{ name: 'NewEvent' }" class="button is-primary">
          <span class="icon">
            <inline-svg :src="require('../assets/icons/plus.svg')"></inline-svg>
          </span>
          <span>{{ $t('events.newEvent') }}</span>
        </router-link>
      </div>
      <div class="actions is-filters" :class="{ 'is-active': mobileFilter }">
        <div class="filters-toggle" @click="mobileFilter = !mobileFilter">
          <span>{{ $t('events.filter.toggle') }}</span>
          <span class="icon"><inline-svg :src="require('../assets/icons/arrow-down.svg')"></inline-svg></span>
        </div>

        <div class="filters-search control has-icons-right">
          <input v-model="search.value" class="input" type="text" :placeholder="$t('events.search')" />
          <span class="icon is-right"><inline-svg :src="require('../assets/icons/search.svg')"></inline-svg></span>
        </div>
        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.segment') }}</div>
          <multiselect
            v-if="existingSegments"
            v-model="search.filterSegment"
            :options="existingSegments"
            class="is-segment"
            track-by="id"
            label="name"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.segment')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.name }}</span></template
            >
          </multiselect>
        </div>
        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.status') }}</div>
          <multiselect
            v-if="listStatus"
            v-model="search.filterStatus"
            :options="listStatus"
            class="is-status"
            track-by="value"
            label="label"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.status')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.label }}</span></template
            >
          </multiselect>
        </div>
        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.organiser') }}</div>
          <multiselect
            v-if="existingOrganisers"
            v-model="search.filterOrganiser"
            :options="existingOrganisers"
            class="is-person"
            track-by="userId"
            :custom-label="labelName"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.organiser')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.firstName }} {{ props.option.lastName }}</span></template
            >
          </multiselect>
        </div>
        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.supervisor') }}</div>
          <multiselect
            v-if="existingSupervisors"
            v-model="search.filterSupervisor"
            :options="existingSupervisors"
            class="is-person"
            track-by="userId"
            :custom-label="labelName"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.supervisor')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.firstName }} {{ props.option.lastName }}</span></template
            >
          </multiselect>
        </div>
        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.overseer') }}</div>
          <multiselect
            v-if="existingOverseers"
            v-model="search.filterOverseer"
            :options="existingOverseers"
            class="is-person"
            track-by="userId"
            :custom-label="labelName"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.overseer')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.firstName }} {{ props.option.lastName }}</span></template
            >
          </multiselect>
        </div>
        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.agency') }}</div>
          <multiselect
            v-if="existingAgencies"
            v-model="search.filterAgency"
            :options="existingAgencies"
            class="is-country"
            track-by="agencyId"
            label="name"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.agency')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.name }}</span></template
            >
          </multiselect>
        </div>

        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.month') }}</div>
          <multiselect
            v-if="listMonths"
            v-model="search.filterMonth"
            :options="listMonths"
            class="is-month"
            track-by="value"
            label="label"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.month')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.label }}</span></template
            >
          </multiselect>
        </div>

        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.year') }}</div>
          <multiselect
            v-if="existingYears"
            v-model="search.filterYear"
            :options="existingYears"
            class="is-year"
            track-by="value"
            label="label"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.year')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.label }}</span></template
            >
          </multiselect>
        </div>

        <div class="multiselect-field">
          <div class="multiselect-label">{{ $t('events.filter.country') }}</div>
          <multiselect
            v-if="existingCountries"
            v-model="search.filterCountry"
            :options="existingCountries"
            class="is-country"
            track-by="code"
            label="name"
            :multiple="true"
            :searchable="false"
            :close-on-select="false"
            :limit-text="count => `+ ${count}`"
            :placeholder="$t('events.filter.country')"
            :show-labels="false"
            @input="searchChanged"
          >
            <template slot="tag" slot-scope="props"
              ><span>{{ props.option.name }}</span></template
            >
          </multiselect>
        </div>
        <div class="button is-primary is-reset-filter" @click.prevent="resetFilters">
          <span class="icon"><inline-svg :src="require('../assets/icons/cross.svg')"></inline-svg></span>
        </div>
      </div>
    </div>

    <div class="page-content">
      <table class="table event-table">
        <thead>
          <tr>
            <th>{{ $t('events.event') }}</th>
            <th class="col-status">{{ $t('events.status') }}</th>
            <th>{{ $t('events.period') }}</th>
            <th>{{ $t('events.segment') }}</th>
            <th>{{ $t('events.contractor') }}</th>
            <th>{{ $t('events.agency') }}</th>
            <th>{{ $t('events.supervisor') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in paginatedEvents" :key="event.id">
            <td @click.prevent="goToEvent(event)">
              <strong>{{ event.name }}</strong>
              <span>{{ event.addressName }}</span>
            </td>
            <td @click.prevent="goToEvent(event)"><EventStatus :status="event.status" type="table" /></td>
            <td @click.prevent="goToEvent(event)">{{ event.eventStart | toLocalDateTimeNoYear }} - {{ event.eventEnd | toLocalDateTime }}</td>
            <td class="is-hidden-desktop">
              <a :class="{ 'is-active': event.open }" @click.prevent="toggleEvent(event)">
                <span class="icon"><inline-svg :src="require('../assets/icons/arrow-down.svg')"></inline-svg></span>
                <span v-if="!event.open">{{ $t('events.table.more') }}</span>
                <span v-if="event.open">{{ $t('events.table.less') }}</span>
              </a>
              <table v-if="event.open" class="event-detail">
                <tr>
                  <th>{{ $t('events.segment') }}</th>
                  <td>{{ event.segment && event.segment.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('events.contractor') }}</th>
                  <td>{{ event.organisedByUser.firstName }} {{ event.organisedByUser.lastName }}</td>
                </tr>
                <tr>
                  <th>{{ $t('events.agency') }}</th>
                  <td>{{ event.agency && event.agency.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('events.supervisor') }}</th>
                  <td>{{ event.supervisorUser | formatUser }}</td>
                </tr>
              </table>
            </td>
            <td @click.prevent="goToEvent(event)">{{ event.segment && event.segment.name }}</td>
            <td @click.prevent="goToEvent(event)">{{ event.organisedByUser.firstName }} {{ event.organisedByUser.lastName }}</td>
            <td @click.prevent="goToEvent(event)">{{ event.agency && event.agency.name }}</td>
            <td @click.prevent="goToEvent(event)">{{ event.supervisorUser | formatUser }}</td>
            <td class="has-menu">
              <span class="event-menu">
                <span class="icon"><inline-svg :src="require('../assets/icons/menu.svg')"></inline-svg></span>
                <ul>
                  <li>
                    <a @click.prevent.stop="duplicateEvent(event.id)">{{ $t('events.duplicate') }}</a>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="filteredEvents && pageCount > 1" v-model="currentPage" :page-count="pageCount" />
    </div>
  </div>
</template>

<script>
  import EventStatus from '../components/EventStatus';
  import { getEvents } from '../api/eventService';
  import form from '@/services/eventForm';
  import { isUserRedbull } from '@/services/roles';
  import { STATUSES, STATUS_FINISHED } from '@/services/status';
  import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
  import Pagination from '@/components/Pagination';
  import { searchTerm } from '@/filters';

  export default {
    name: 'Events',
    components: { Pagination, EventStatus },
    filters: {
      formatUser(value) {
        if (typeof value === 'object' && value !== null) {
          return `${value.firstName || ''} ${value.lastName || ''}`;
        } else {
          return value;
        }
      },
    },
    data() {
      return {
        events: null,
        loading: true,
        search: form.search,
        enums: form.enums,
        pageSize: 15,
        currentPage: 1,
        mobileFilter: false,
      };
    },
    computed: {
      filteredEvents() {
        if (!this.events) return false;

        let events = this.events
          .filter(e => {
            let show = true;

            if (show && this.search.value.length > 0) {
              show = searchTerm(e.name, this.search.value);
            }

            if (show && this.search.filterStatus && this.search.filterStatus.length > 0) {
              let statusMatch = false;
              for (const value of this.search.filterStatus) {
                if (e.status === value.value) statusMatch = true;
              }
              show = statusMatch;
            }

            if (show && this.search.filterCountry && this.search.filterCountry.length > 0) {
              let statusMatch = false;
              for (const value of this.search.filterCountry) {
                if (e.country?.code === value.code) statusMatch = true;
              }
              show = statusMatch;
            }

            if (show && this.search.filterSegment && this.search.filterSegment.length > 0) {
              let statusMatch = false;
              for (const value of this.search.filterSegment) {
                if (e.segment?.id === value.id) statusMatch = true;
              }
              show = statusMatch;
            }

            if (show && this.search.filterOrganiser && this.search.filterOrganiser.length > 0) {
              let statusMatch = false;
              for (const value of this.search.filterOrganiser) {
                if (e.organisedByUser?.userId === value.userId) statusMatch = true;
              }
              show = statusMatch;
            }

            if (show && this.search.filterSupervisor && this.search.filterSupervisor.length > 0) {
              if (this.search.filterSupervisor === 'none') {
                show = !e.supervisorUser;
              } else {
                let statusMatch = false;
                for (const value of this.search.filterSupervisor) {
                  if (value.userId === 'NOSUPERVISOR' && !e.supervisorUser) statusMatch = true;
                  if (e.supervisorUser?.userId === value.userId) statusMatch = true;
                }
                show = statusMatch;
              }
            }

            if (show && this.search.filterOverseer && this.search.filterOverseer.length > 0) {
              if (this.search.filterOverseer === 'none') {
                show = !e.overseerUser;
              } else {
                let statusMatch = false;
                for (const value of this.search.filterOverseer) {
                  if (value.userId === 'NOOVERSEER' && !e.overseerUser) statusMatch = true;
                  if (e.overseerUser?.userId === value.userId) statusMatch = true;
                }
                show = statusMatch;
              }
            }

            if (show && this.search.filterAgency && this.search.filterAgency.length > 0) {
              if (this.search.filterAgency === 'none') {
                show = !e.agency;
              } else {
                let statusMatch = false;
                for (const value of this.search.filterAgency) {
                  if (value.agencyId === 'NOAGENCY' && !e.agency) statusMatch = true;
                  if (e.agency?.agencyId === value.agencyId) statusMatch = true;
                }
                show = statusMatch;
              }
            }

            if (show && this.search.filterMonth && this.search.filterMonth.length > 0) {
              let match = false;
              for (const value of this.search.filterMonth) {
                if (new Date(e.eventStart).getMonth() === value.value) {
                  match = true;
                } else if (new Date(e.eventEnd).getMonth() === value.value) {
                  match = true;
                }
              }
              show = match;
            }

            if (show && this.search.filterYear && this.search.filterYear.length > 0) {
              let match = false;
              for (const value of this.search.filterYear) {
                if (new Date(e.eventStart).getFullYear() === value.value) {
                  match = true;
                } else if (new Date(e.eventEnd).getFullYear() === value.value) {
                  match = true;
                }
              }
              show = match;
            }

            return show;
          })
          .sort((a, b) => new Date(a.eventStart) - new Date(b.eventStart));

        return events.filter(x => x.status !== STATUS_FINISHED).concat(events.filter(x => x.status === STATUS_FINISHED));
      },
      paginatedEvents() {
        if (!this.filteredEvents) return false;
        if (this.filteredEvents.length < this.pageSize) return this.filteredEvents;
        const start = (this.currentPage - 1) * this.pageSize;
        return this.filteredEvents.slice(start, start + this.pageSize);
      },
      pageCount() {
        if (this.filteredEvents.length < this.pageSize) return 0;
        return Math.ceil(this.filteredEvents.length / this.pageSize);
      },
      canCreateEvents() {
        return isUserRedbull();
      },
      existingOrganisers() {
        if (!this.events) return false;
        return [...new Map(this.events.map(e => (e.organisedByUser ? [e.organisedByUser.userId, e.organisedByUser] : []))).values()].sort(
          (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
        );
      },
      existingSupervisors() {
        if (!this.events) return false;
        let supervisors = [...new Map(this.events.map(e => (e.supervisorUser ? [e.supervisorUser.userId, e.supervisorUser] : []))).values()]
          .filter(e => e)
          .sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        supervisors.unshift({ userId: 'NOSUPERVISOR', firstName: this.$t('events.filter.noSupervisor'), lastName: '' });
        return supervisors;
      },
      existingOverseers() {
        if (!this.events) return false;
        let overseer = [...new Map(this.events.map(e => (e.overseerUser ? [e.overseerUser.userId, e.overseerUser] : []))).values()]
          .filter(e => e)
          .sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        overseer.unshift({ userId: 'NOOVERSEER', firstName: this.$t('events.filter.noOverseer'), lastName: '' });
        return overseer;
      },
      existingAgencies() {
        if (!this.events) return false;
        let agency = [...new Map(this.events.map(e => (e.agency ? [e.agency.agencyId, e.agency] : []))).values()]
          .filter(e => e)
          .sort((a, b) => a.name.localeCompare(b.name));

        agency.unshift({ agencyId: 'NOAGENCY', name: this.$t('events.filter.noAgency') });
        return agency;
      },
      existingCountries() {
        if (!this.events) return false;
        return [...new Map(this.events.map(e => (e.country ? [e.country.code, e.country] : []))).values()].filter(e => e);
      },
      existingSegments() {
        if (!this.events) return false;
        return [...new Map(this.events.map(e => (e.segment ? [e.segment.id, e.segment] : []))).values()]
          .filter(e => e)
          .sort((a, b) => a.name.localeCompare(b.name));
      },
      listStatus() {
        let list = [];
        for (const value of STATUSES) {
          list.push({ value, label: this.$t(`events.states.${value}`) });
        }
        return list;
      },
      listMonths() {
        let list = [];
        for (let i = 0; i < 12; i++) {
          list.push({ value: i, label: this.$t(`common.months.${i}`) });
        }
        return list;
      },
      existingYears() {
        let list = [];
        if (!this.events) return false;
        this.events.map(e => {
          let y1 = new Date(e.eventStart).getFullYear();
          let y2 = new Date(e.eventEnd).getFullYear();
          if (!list.find(e => e.value === y1)) list.push({ value: y1, label: y1 });
          if (!list.find(e => e.value === y2)) list.push({ value: y2, label: y2 });
          return e;
        });

        let now = new Date().getFullYear();
        if (!list.find(e => e.value === now)) list.push({ value: now, label: now });

        return list;
      },
    },
    watch: {
      search: {
        deep: true,
        handler() {
          form.saveSearch(this.currentPage);
        },
      },
      currentPage: {
        handler() {
          form.saveSearch(this.currentPage);
        },
      },
    },
    mounted() {
      this.fetchData();
      form.doLoadEnums();
      this.currentPage = form.loadSearch();
    },
    methods: {
      async fetchData() {
        try {
          this.loading = true;
          this.events = await getEvents();
        } finally {
          this.loading = false;
        }
      },
      goToEvent({ id }) {
        this.$router.push({ name: 'EditEvent', params: { id } });
      },
      toggleEvent({ id }) {
        let index = this.events.findIndex(e => e.id === id);
        let open = !this.events[index].open;
        this.$set(this.events[index], 'open', open);
      },
      duplicateEvent(id) {
        this.$router.push({ name: 'NewEvent', params: { duplicate: id } });
      },
      resetFilters() {
        let now = new Date().getFullYear();
        this.$set(this.search, 'value', '');
        this.$set(this.search, 'filterMonth', []);
        this.$set(this.search, 'filterStatus', []);
        this.$set(this.search, 'filterCountry', []);
        this.$set(this.search, 'filterOrganiser', []);
        this.$set(this.search, 'filterSupervisor', []);
        this.$set(this.search, 'filterSegment', []);
        this.$set(this.search, 'filterAgency', []);
        this.$set(this.search, 'filterYear', [{ value: now, label: now }]);
      },
      labelName({ firstName, lastName }) {
        return `${firstName} ${lastName}`;
      },
        searchChanged() {
		      this.currentPage = 1;
        }
    },
  };
</script>
