import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import auth from './services/auth';
import { APPINSIGHT } from '@/consts';

import VueAppInsights from 'vue-application-insights';
if (APPINSIGHT) {
  Vue.use(VueAppInsights, {
    id: APPINSIGHT,
    router,
  });
}

import { InlineSvgPlugin } from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

import VueSlider from 'vue-slider-component';
Vue.component('VueSlider', VueSlider);

import flatpickr from 'flatpickr';
import { Czech } from 'flatpickr/dist/l10n/cs';
flatpickr.localize(Czech);
import VueflatPickr from 'vue-flatpickr-component';
Vue.component('VueflatPickr', VueflatPickr);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import { vfmPlugin } from 'vue-final-modal';
Vue.use(vfmPlugin);

import './validate';
import './filters';
import './scss/redbull.scss';

auth.handleRedirect();

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
