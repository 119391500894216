<template>
  <div class="loader-box">
    <div class="loader-circle"></div>
    <div class="loader-line-mask"><div class="loader-line"></div></div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  };
</script>
