<template>
  <div>
    <div class="list-search">
      <p class="control has-icons-left">
        <span class="icon">
          <inline-svg :src="require('../assets/icons/search.svg')"></inline-svg>
        </span>
        <input v-model="filterText" class="input" type="text" :placeholder="$t('admin.tabs.products.searchButton')" />
      </p>
    </div>

    <div class="tabs inner-tabs">
      <ul>
        <li :class="{ 'is-active': active === 'products' }">
          <a @click="active = 'products'">
            {{ $t('admin.tabs.products.tabs.products.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'categories' }">
          <a @click="active = 'categories'">
            {{ $t('admin.tabs.products.tabs.categories.title') }}
          </a>
        </li>
        <li :class="{ 'is-active': active === 'price' }">
          <a @click="active = 'price'">
            {{ $t('admin.tabs.products.tabs.price.title') }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div v-if="active === 'products' && !loading">
        <div class="columns">
          <div class="column is-8">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.products.tabs.products.form.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.products.tabs.products.form.headers.headerTwo') }}</th>
                  <th colspan="2">{{ $t('admin.tabs.products.tabs.products.form.headers.headerThree') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in filteredProducts" :key="product.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="product.name" :readonly="!product.isEditing" class="input" />
                  </td>

                  <td>
                    <div class="select is-fullwidth">
                      <select v-model="product.categoryId" :disabled="!product.isEditing">
                        <option v-for="category in productCategories" :key="category.id" :value="category.id" class="help">
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="table-order">
                    <input v-model="product.order" :readonly="!product.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!product.isEditing" class="button is-input" @click="editItem(product.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(product)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a class="table-delete">
                      <span class="icon" @click="deleteItem(product)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'categories'">
        <div class="columns">
          <div class="column is-4">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.products.tabs.categories.title') }}</th>
                  <th>{{ $t('admin.tabs.products.tabs.categories.titleOrder') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(category, index) in filteredProductCategories" :key="category.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <input v-model="category.name" :readonly="!category.isEditing" class="input" />
                  </td>
                  <td class="table-order">
                    <input v-model="category.order" :readonly="!category.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!category.isEditing" class="button is-input" @click="editItem(category.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(category)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a v-if="!category.isUsed" class="table-delete">
                      <span class="icon" @click="deleteItem(category)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                    <a v-else class="table-delete">
                      <span
                        class="icon has-tooltip-right has-tooltip-arrow has-tooltip-danger"
                        :data-tooltip="$t('admin.tabs.products.tabs.categories.tooltip')"
                      >
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="active === 'price'">
        <div class="columns">
          <div class="column is-8">
            <table class="table admin-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('admin.tabs.products.tabs.price.headers.headerOne') }}</th>
                  <th>{{ $t('admin.tabs.products.tabs.price.headers.headerTwo') }}</th>
                  <th>{{ $t('admin.tabs.products.tabs.price.headers.headerThree') }}</th>
                  <th>{{ $t('admin.tabs.products.tabs.price.headers.headerFour') }}</th>
                  <th>{{ $t('admin.tabs.products.tabs.price.headers.headerFive') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(price, index) in filteredProductPrices" :key="price.id">
                  <td class="table-counter">
                    <span>{{ parseInt(index) + 1 }}</span>
                  </td>
                  <td>
                    <div class="select is-fullwidth">
                      <select v-model="price.productId" :disabled="!price.isEditing">
                        <option v-for="product in products" :key="product.id" :value="product.id" class="help">
                          {{ product.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <span>{{ getProductPriceCategory(price.categoryId) }}</span>
                  </td>
                  <td>
                    <div class="select is-fullwidth">
                      <select v-model="price.currencyCode" :disabled="!price.isEditing">
                        <option v-for="currency in currencies" :key="currency.code" :value="currency.code" class="help">
                          {{ currency.code }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <input v-model="price.samplePrice" :readonly="!price.isEditing" class="input" />
                  </td>
                  <td class="table-order">
                    <input v-model="price.margin" :readonly="!price.isEditing" class="input" />
                  </td>
                  <td class="table-actions">
                    <button v-if="!price.isEditing" class="button is-input" @click="editItem(price.id)">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/edit.svg')"></inline-svg>
                      </span>
                    </button>
                    <button v-else class="button is-input" @click="processItem(price)">
                      <span class="icon is-green">
                        <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
                      </span>
                    </button>
                    <a v-if="!price.isUsed" class="table-delete">
                      <span class="icon" @click="deleteItem(price)">
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                    <a v-else class="table-delete">
                      <span
                        class="icon has-tooltip-right has-tooltip-arrow has-tooltip-danger"
                        :data-tooltip="$t('admin.tabs.products.tabs.categories.tooltip')"
                      >
                        <inline-svg :src="require('../assets/icons/trash-can.svg')"></inline-svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="6"></td>
                  <td class="table-actions">
                    <button class="button is-input" @click="addItem">
                      <span class="icon">
                        <inline-svg :src="require('../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as productService from '@/api/productService';
  import { getCurrencies } from '@/api/currencyService';
  import { searchTerm } from '@/filters';

  export default {
    name: 'AdminProducts',
    data() {
      return {
        active: 'products',
        loading: false,
        filterText: null,
        products: [],
        productCategories: [],
        productPrices: [],
        currencies: [],
        selectedProductCategoryId: null,
        filteredCategories: [],
      };
    },
    computed: {
      filteredProducts() {
        if (this.filterText && this.filterText !== '') {
          return this.products.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.products;
        }
      },
      filteredProductCategories() {
        const intersection = this.productCategories.filter(category => this.products.find(product => product.categoryId === category.id));

        intersection.forEach(x => {
          x.isUsed = true;
        });

        if (this.filterText && this.filterText !== '') {
          return this.productCategories.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.productCategories;
        }
      },
      filteredProductPrices() {
        this.productPrices.forEach(price => {
          const product = this.products.find(product => product.id === price.productId);
          price.categoryId = product?.categoryId || '';
        });

        if (this.filterText && this.filterText !== '') {
          return this.productPrices.filter(item => searchTerm(item.name, this.filterText));
        } else {
          return this.productPrices;
        }
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      getProductPriceCategory(id) {
        return this.productCategories.find(category => category.id === id) ? this.productCategories.find(category => category.id === id).name : '';
      },
      async fetchData() {
        await Promise.all([this.loadProducts(), this.loadProductCategories(), this.loadCurrencies()]);
        await this.loadProductPrices();
      },
      editItem(id) {
        if (this.active === 'products') {
          this.$set(
            this.products.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'categories') {
          this.$set(
            this.productCategories.find(item => item.id === id),
            'isEditing',
            true
          );
        } else if (this.active === 'price') {
          this.$set(
            this.productPrices.find(item => item.id === id),
            'isEditing',
            true
          );
        }
      },
      addItem() {
        if (this.active === 'products') {
          this.products.push({ name: '', isEditing: true });
        } else if (this.active === 'categories') {
          this.productCategories.push({ name: '', isEditing: true });
        } else if (this.active === 'price') {
          this.productPrices.push({ name: '', isEditing: true });
        }
      },
      processItem(data) {
        data.id ? this.updateItem(data) : this.setNewItem(data);
      },
      async updateItem({ id, name, categoryId, order, margin, samplePrice, currencyCode, productId }) {
        if (this.active === 'products') {
          try {
            this.loading = true;
            const res = await productService.updateProduct(id, { name, categoryId, order, active: true });
            if (res.status === 200) await this.loadProducts();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'categories') {
          try {
            this.loading = true;
            const res = await productService.updateProductCategory(id, { name, order, active: true });
            if (res.status === 200) await this.loadProductCategories();
          } finally {
            this.loading = false;
          }
        } else if (this.active === 'price') {
          try {
            this.loading = true;
            const res = await productService.updateProductPrice(id, { margin, samplePrice, currencyCode, productId, active: true });
            if (res.status === 200) await this.loadProductPrices();
          } finally {
            this.loading = false;
          }
        }
      },
      async deleteItem({ id, name, categoryId, order, margin, samplePrice, currencyCode, productId }) {
        if (this.active === 'products') {
          if (!id) {
            this.products.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await productService.updateProduct(id, { name, categoryId, order, active: false });
              if (res.status === 200) await this.loadProducts();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'categories') {
          if (!id) {
            this.productCategories.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await productService.updateProductCategory(id, { name, order, active: false });
              if (res.status === 200) await this.loadProductCategories();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'price') {
          if (!id) {
            this.productPrices.splice(-1, 1);
          } else {
            try {
              this.loading = true;
              const res = await productService.updateProductPrice(id, { margin, samplePrice, currencyCode, productId, active: false });
              if (res.status === 200) await this.loadProductPrices();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async setNewItem({ name, categoryId, order, margin, samplePrice, currencyCode, productId }) {
        if (this.active === 'products') {
          if (name) {
            try {
              this.loading = true;
              const res = await productService.createNewProduct({ name, categoryId, order });
              if (res.status === 201) await this.loadProducts();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'categories') {
          if (name) {
            try {
              this.loading = true;
              const res = await productService.createNewProductCategory({ name, order });
              if (res.status === 201) await this.loadProductCategories();
            } finally {
              this.loading = false;
            }
          }
        } else if (this.active === 'price') {
          if (productId) {
            try {
              this.loading = true;
              const res = await productService.createNewProductPrice({ margin, samplePrice, currencyCode, productId });
              if (res.status === 201) await this.loadProductPrices();
            } finally {
              this.loading = false;
            }
          }
        }
      },
      async loadProducts() {
        try {
          this.loading = true;
          const products = await productService.getProducts('active');
          this.products = products.sort((a, b) => a.order - b.order);
        } finally {
          this.loading = false;
        }
      },
      async loadProductCategories() {
        try {
          this.loading = true;
          const productCategories = await productService.getProductCategories('active');
          this.productCategories = productCategories.sort((a, b) => a.order - b.order);
          this.filteredCategories = [...productCategories];
        } finally {
          this.loading = false;
        }
      },
      async loadProductPrices() {
        try {
          this.loading = true;
          const productPrices = await productService.getProductPrices('active');
          this.productPrices = productPrices.sort(
            (a, b) =>
              a.currencyCode.localeCompare(b.currencyCode) ||
              this.getProductCategoryName(a.productId).localeCompare(this.getProductCategoryName(b.productId)) ||
              this.getProductName(a.productId).localeCompare(this.getProductName(b.productId))
          );
        } finally {
          this.loading = false;
        }
      },
      async loadCurrencies() {
        try {
          this.loading = true;
          const currencies = await getCurrencies('active');
          this.currencies = currencies.sort((a, b) => a.code.localeCompare(b.code));
        } finally {
          this.loading = false;
        }
      },
      getProductName(id) {
        if (!this.products) return id;
        if (this.products) return this.products.find(e => e.id === id)?.name;
      },
      getProductCategoryName(id) {
        if (!this.products || !this.productCategories) return id;
        let categoryId = this.products.find(e => e.id === id)?.categoryId;
        if (this.productCategories) return this.productCategories.find(e => e.id === categoryId)?.name;
      },
    },
  };
</script>
