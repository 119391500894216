<template>
  <div>
    <div class="page-head">
      <h1 class="title is-2">{{ $t('qc.title') }}</h1>
    </div>

    <div class="page-content">
      <table class="table qc-table">
        <thead>
          <tr>
            <th>{{ $t('events.event') }}</th>
            <th>{{ $t('events.period') }}</th>
            <th>{{ $t('events.contractor') }}</th>
            <th>{{ $t('events.agency') }}</th>
            <th>{{ $t('events.supervisor') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in paginatedEvents" :key="event.id" @click.prevent="goToEvent(event)">
            <td>
              <strong>{{ event.name }}</strong>
              <span>{{ event.addressName }}</span>
            </td>
            <td>{{ event.eventStart | toLocalDateTimeNoYear }} - {{ event.eventEnd | toLocalDateTime }}</td>
            <td>{{ event.organisedByUser.firstName }} {{ event.organisedByUser.lastName }}</td>
            <td>{{ event.agency && event.agency.name }}</td>
            <td>{{ event.supervisorUser | formatUser }}</td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="filteredEvents && pageCount > 1" v-model="currentPage" :page-count="pageCount" />
    </div>
  </div>
</template>

<script>
  import EventStatus from '../components/EventStatus';
  import { getEventsQC } from '../api/eventService';
  import form from '@/services/eventForm';
  import { isUserRedbull } from '@/services/roles';
  import { STATUSES, STATUS_FINISHED } from '@/services/status';
  import flatPickr from 'vue-flatpickr-component';
  import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
  import Pagination from '@/components/Pagination';

  export default {
    name: 'Events',
    components: { Pagination, EventStatus, flatPickr },
    filters: {
      formatUser(value) {
        if (typeof value === 'object' && value !== null) {
          return `${value.firstName || ''} ${value.lastName || ''}`;
        } else {
          return value;
        }
      },
    },
    data() {
      return {
        events: null,
        loading: true,
        search: form.search,
        enums: form.enums,
        pickerConfig: {
          altInput: true,
          plugins: [
            new monthSelectPlugin({
              dateFormat: 'Y-m',
              altFormat: 'F Y',
              theme: 'dark',
            }),
          ],
        },
        listStatus: STATUSES,
        pageSize: 15,
        currentPage: 1,
      };
    },
    computed: {
      filteredEvents() {
        if (!this.events) return false;

        return this.events;
      },
      paginatedEvents() {
        if (!this.filteredEvents) return false;
        if (this.filteredEvents.length < this.pageSize) return this.filteredEvents;
        const start = (this.currentPage - 1) * this.pageSize;
        return this.filteredEvents.slice(start, start + this.pageSize);
      },
      pageCount() {
        if (this.filteredEvents.length < this.pageSize) return 0;
        return Math.ceil(this.filteredEvents.length / this.pageSize);
      },
      canCreateEvents() {
        return isUserRedbull();
      },
      existingOrganisers() {
        if (!this.events) return false;
        return [...new Map(this.events.map(e => (e.organisedByUser ? [e.organisedByUser.userId, e.organisedByUser] : []))).values()];
      },
      existingSupervisors() {
        if (!this.events) return false;
        return [...new Map(this.events.map(e => (e.supervisorUser ? [e.supervisorUser.userId, e.supervisorUser] : []))).values()].filter(e => e);
      },
      existingCountries() {
        if (!this.events) return false;
        return [...new Map(this.events.map(e => (e.country ? [e.country.code, e.country] : []))).values()].filter(e => e);
      },
    },
    watch: {
      search: {
        deep: true,
        handler() {
          this.currentPage = 1;
        },
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          this.loading = true;
          this.events = await getEventsQC();
        } finally {
          this.loading = false;
        }
      },
      goToEvent(event) {
        if (event.qualityCheckUri) {
          window.open(event.qualityCheckUri, '_blank');
        }
      },
      clearPicker() {
        this.search.filterMonth = null;
      },
      resetFilters() {
        this.$set(this.search, 'value', '');
        this.$set(this.search, 'filterMonth', null);
        this.$set(this.search, 'filterStatus', 'all');
        this.$set(this.search, 'filterCountry', 'all');
        this.$set(this.search, 'filterOrganiser', 'all');
        this.$set(this.search, 'filterSupervisor', 'all');
      },
    },
  };
</script>
