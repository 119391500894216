import Vue from 'vue';
import VueI18n from 'vue-i18n';
import cs from './i18n/cs.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'cs-CZ',
  silentFallbackWarn: true,
  messages: {
    cs,
  },
});

export default i18n;
