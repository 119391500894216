<template>
  <div>
    <div v-if="isReported || (isPlanning && shouldHaveInitialComparison) || isMusketeerCheck" class="balance-comparison">
      <p class="control">
        <label class="checkbox">
          <input v-model="compareExpected" type="checkbox" />
          <span>{{ $t('eventsForm.balance.compareExpected') }}</span>
        </label>
      </p>
    </div>

    <div class="balance-row">
      <div class="balance-box">
        <h3>{{ $t('eventsForm.balance.visitors.title') }}</h3>
        <div class="balance-box__content">
          <p class="is-large">
            {{ formState[`${currentVariant === 'initial' ? 'expected' : currentVariant}Attendance`] | formatNumber }}
            <ArrowCompare
              v-if="compareExpected"
              :expected="formState[key(`${otherVariant === 'initial' ? 'expected' : otherVariant}Attendance`)]"
              :real="formState[`${currentVariant === 'initial' ? 'expected' : currentVariant}Attendance`]"
            />
          </p>
          <p v-if="compareExpected" class="is-compare-large">{{ formState[`${otherVariant === 'initial' ? 'expected' : otherVariant}Attendance`] }}</p>
          <p class="is-label">{{ $t('eventsForm.balance.visitors.total') }}</p>
        </div>
      </div>

      <div class="balance-box">
        <h3 class="mobile-smaller">&#8203;</h3>
        <div class="balance-box__content is-horizontal">
          <div>
            <p class="is-large">
              {{ perCan[currentVariant] | formatPrice(formState.currencyCode) }}
              <ArrowCompare v-if="compareExpected" :expected="perCan[otherVariant]" :real="perCan[currentVariant]" />
            </p>
            <p v-if="compareExpected" class="is-compare-large">{{ perCan[otherVariant] | formatPrice(formState.currencyCode) }}</p>
            <p class="is-label">{{ $t('eventsForm.balance.investment.perCan') }}</p>
          </div>
          <hr />
          <div class="mobile-col">
            <div v-for="category in productCategories" :key="category.id">
              <p class="is-normal">
                {{ pcc[currentVariant][category.id] | round3 }}&#8203;
                <ArrowCompare v-if="compareExpected" :expected="pcc[otherVariant][category.id]" :real="pcc[currentVariant][category.id]" />
              </p>
              <p v-if="compareExpected" class="is-compare-small">{{ pcc[otherVariant][category.id] | round3 }}</p>
              <p class="is-label">{{ $t('eventsForm.balance.investment.pcc') }} {{ category.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="balance-row">
      <div class="balance-col">
        <div class="balance-box">
          <h3>{{ $t('eventsForm.balance.sales.title') }}</h3>
          <div class="balance-box__content has-chart" :class="{ 'is-compare': compareExpected }">
            <div class="balance-box__table">
              <table>
                <tbody>
                  <tr v-for="(category, index) in productCategories" :key="category.id">
                    <th><span class="dot" :class="[`is-${colorNames[index]}`]"></span> {{ category.name }}</th>
                    <td>
                      <p>{{ salesPcs[currentVariant][category.id] | formatNumber }} {{ $t('common.unit.pcs') }}</p>
                      <p v-if="compareExpected" class="is-compare">{{ salesPcs[otherVariant][category.id] | formatNumber }} {{ $t('common.unit.pcs') }}</p>
                    </td>
                    <td v-if="compareExpected" class="is-compare">
                      <ArrowCompare :expected="salesPcs[otherVariant][category.id]" :real="salesPcs[currentVariant][category.id]" />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <th colspan="2">
                    <p>{{ salesPcs[currentVariant].total | formatNumber }} {{ $t('common.unit.pcs') }}</p>
                    <p v-if="compareExpected" class="is-compare">{{ salesPcs[otherVariant].total | formatNumber }} {{ $t('common.unit.pcs') }}</p>
                  </th>
                  <th v-if="compareExpected" class="is-compare">
                    <ArrowCompare :expected="salesPcs[otherVariant].total" :real="salesPcs[currentVariant].total" />
                  </th>
                </tfoot>
              </table>
            </div>
            <div class="balance-box__chart">
              <div class="chart-legend">
                <template v-for="item in salesLegend">
                  <span v-if="item.percentage >= 0" :key="item.color" :style="{ color: item.color }">{{ item.percentage }}%</span>
                </template>
              </div>
              <Chart :chart-data="salesChartData" :options="chartOptions" :style="chartStyles" @legend="setLegend('sales', ...arguments)" />
            </div>
            <div v-if="compareExpected" class="balance-box__chart is-expected">
              <div class="chart-legend">
                <template v-for="item in salesLegendOther">
                  <span v-if="item.percentage >= 0" :key="item.color" :style="{ color: item.color }">{{ item.percentage }}%</span>
                </template>
              </div>
              <Chart :chart-data="salesChartDataOther" :options="chartOptions" :style="chartStyles" @legend="setLegend('salesOther', ...arguments)" />
            </div>
          </div>
        </div>

        <div class="balance-box">
          <h3>{{ $t('eventsForm.balance.expenses.title') }}</h3>
          <div class="balance-box__content has-chart">
            <div class="balance-box__table">
              <table>
                <tbody>
                  <tr>
                    <th><span class="dot is-red"></span> {{ $t('eventsForm.balance.expenses.fee') }}</th>
                    <td>
                      <p>{{ feeSum[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                      <p v-if="compareExpected" class="is-compare">{{ feeSum[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                    </td>
                    <td v-if="compareExpected" class="is-compare">
                      <ArrowCompare :expected="feeSum[otherVariant]" :real="feeSum[currentVariant]" :expenses="true" />
                    </td>
                  </tr>
                  <tr>
                    <th><span class="dot is-yellow"></span> {{ $t('eventsForm.balance.expenses.production') }}</th>
                    <td>
                      <p>{{ productionCostTotal[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                      <p v-if="compareExpected" class="is-compare">{{ productionCostTotal[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                    </td>
                    <td v-if="compareExpected" class="is-compare">
                      <ArrowCompare :expected="productionCostTotal[otherVariant]" :real="productionCostTotal[currentVariant]" :expenses="true" />
                    </td>
                  </tr>
                  <tr>
                    <th><span class="dot is-purple"></span> {{ $t('eventsForm.balance.expenses.logistics') }}</th>
                    <td>
                      <p>{{ formState.logisticsCosts | formatPrice(formState.currencyCode) }}</p>
                      <p v-if="compareExpected" class="is-compare">{{ formState.logisticsCosts | formatPrice(formState.currencyCode) }}</p>
                    </td>
                    <td v-if="compareExpected" class="is-compare">
                      <ArrowCompare :expected="formState.logisticsCosts" :real="formState.logisticsCosts" :expenses="true" />
                    </td>
                  </tr>
                  <tr>
                    <th><span class="dot is-cyan"></span> {{ $t('eventsForm.balance.expenses.fgt') }}</th>
                    <td>
                      <p>{{ fgtCostTotal[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                      <p v-if="compareExpected" class="is-compare">{{ fgtCostTotal[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                    </td>
                    <td v-if="compareExpected" class="is-compare">
                      <ArrowCompare :expected="fgtCostTotal[otherVariant]" :real="fgtCostTotal[currentVariant]" :expenses="true" />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="2">
                      <p>{{ expensesTotal[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                      <p v-if="compareExpected" class="is-compare">{{ expensesTotal[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                    </th>
                    <th v-if="compareExpected" class="is-compare">
                      <ArrowCompare :expected="expensesTotal[otherVariant]" :real="expensesTotal[currentVariant]" :expenses="true" />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="balance-box__chart">
              <div class="chart-legend">
                <template v-for="item in expensesLegend">
                  <span v-if="item.percentage >= 0" :key="item.color" :style="{ color: item.color }">{{ item.percentage }}%</span>
                </template>
              </div>
              <Chart :chart-data="expensesChartData" :options="chartOptions" :style="chartStyles" @legend="setLegend('expenses', ...arguments)" />
            </div>
            <div v-if="compareExpected" class="balance-box__chart is-expected">
              <div class="chart-legend">
                <template v-for="item in expensesLegendOther">
                  <span v-if="item.percentage >= 0" :key="item.color" :style="{ color: item.color }">{{ item.percentage }}%</span>
                </template>
              </div>
              <Chart :chart-data="expensesChartDataOther" :options="chartOptions" :style="chartStyles" @legend="setLegend('expensesOther', ...arguments)" />
            </div>
          </div>
        </div>
      </div>
      <div class="balance-box">
        <h3>{{ $t('eventsForm.balance.profit.title') }}</h3>
        <div class="balance-box__content">
          <table>
            <tbody>
              <tr>
                <th>{{ $t('eventsForm.balance.profit.earnings') }}</th>
                <td>
                  <p>{{ earningsTotal[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                  <p v-if="compareExpected" class="is-compare">{{ earningsTotal[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                </td>
                <td v-if="compareExpected" class="is-compare">
                  <ArrowCompare :expected="earningsTotal[otherVariant]" :real="earningsTotal[currentVariant]" />
                </td>
              </tr>
              <tr>
                <th>{{ $t('eventsForm.balance.profit.expenses') }}</th>
                <td>
                  <p>{{ expensesTotal[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                  <p v-if="compareExpected" class="is-compare">{{ expensesTotal[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                </td>
                <td v-if="compareExpected" class="is-compare">
                  <ArrowCompare :expected="expensesTotal[otherVariant]" :real="expensesTotal[currentVariant]" :expenses="true" />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" class="is-large">
                  <p>{{ profit[currentVariant] | formatPrice(formState.currencyCode) }}</p>
                  <p v-if="compareExpected" class="is-compare">{{ profit[otherVariant] | formatPrice(formState.currencyCode) }}</p>
                </th>
                <th v-if="compareExpected" class="is-compare">
                  <ArrowCompare :expected="profit[otherVariant]" :real="profit[currentVariant]" />
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import form, { eventFormDefinition, EXPENSES_EXECUTIVE_FEE, EXPENSES_FIX, EXPENSES_REPORTING_FEE, EXPENSES_VOLUME_FEE } from '@/services/eventForm';
  import Chart from '@/components/EventsForm/Chart';
  import { compareStatus, STATUS_CONTROLLING, STATUS_PLANNING, STATUS_MUSKETEER_CHECK } from '@/services/status';
  import ArrowCompare from '@/components/EventsForm/ArrowCompare';

  const VARIANTS = ['initial', 'expected', 'real'];
  const COLORS = ['#e21e46', '#ffc935', '#7b61ff', '#5cd0ea', '#2ab088'];

  /*  const VARS = {
    'initial':
  }


 */

  export default {
    name: 'EventBalance',
    components: { ArrowCompare, Chart },
    data() {
      return {
        form: eventFormDefinition,
        formEnums: form.enums,
        formState: form.state,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 85,
          legend: {
            display: false,
          },
          legendCallback: this.legendCallback,
          tooltips: {
            enabled: false,
            callbacks: {
              label: this.labelCallback,
            },
          },
        },
        chartStyles: {
          height: `130px`,
          position: 'relative',
        },
        colorNames: ['red', 'yellow', 'purple', 'cyan', 'turquoise'],
        salesLegend: null,
        salesLegendOther: null,
        expensesLegend: null,
        expensesLegendOther: null,
        compareExpected: false,
      };
    },
    computed: {
      currentVariant() {
        if (this.isPlanning) return 'initial';
        if (this.isMusketeerCheck) return 'expected';
        return !this.isReported ? 'expected' : 'real';
      },
      otherVariant() {
        if (this.isPlanning) return 'expected';
        if (this.isMusketeerCheck) return 'initial';
        return !this.isReported ? 'real' : 'expected';
      },
      productCategories() {
        return this.formEnums.productCategories.filter(e => e.active);
      },
      feeSum() {
        let sum = { expected: 0, real: 0, initial: 0 };
        for (let fee of this.formState.fees) {
          sum.initial += !isNaN(parseFloat(fee.expectedAmount)) && parseFloat(fee.expectedAmount);
          sum.expected += !isNaN(parseFloat(fee.expectedAmount)) && parseFloat(fee.expectedAmount);
          sum.real += !isNaN(parseFloat(fee.realAmount)) && parseFloat(fee.realAmount);
        }
        return sum;
      },
      extraCosts() {
        return this.formState.extraCosts.map(e => {
          let costData = this.formEnums.extraCosts.find(f => f.id === e.costTypeId);
          e.type = costData.type;
          return e;
        });
      },
      eventCostSubtotal() {
        let eventCost = {};
        for (let variant of VARIANTS) {
          eventCost[variant] = 0;

          for (let cost of this.extraCosts.filter(e => e.type === 'Event')) {
            if (cost[`${variant}Amount`] !== null) {
              eventCost[variant] += cost[`${variant}Amount`] ? parseFloat(cost[`${variant}Amount`]) : 0;
            }
          }
        }
        return eventCost;
      },
      staffCostSubtotal() {
        let staffCost = {};
        for (let variant of VARIANTS) {
          staffCost[variant] = 0;

          for (let staff of this.formState.assignedStaff) {
            staffCost[variant] += staff[`${variant}Amount`] * staff[`${variant}Units`] * staff[`${variant}UnitCost`];
          }
        }

        return staffCost;
      },
      simpleStaffCostSubtotal() {
        let staffCost = {};
        for (let variant of VARIANTS) {
          staffCost[variant] = 0;

          for (let staff of this.formState.assignedSimpleStaff) {
            staffCost[variant] += staff[`${variant}Amount`] * staff[`${variant}Units`] * staff[`${variant}UnitCost`];
          }
        }

        return staffCost;
      },
      agencyCost() {
        let agencyCost = {};
        for (let variant of VARIANTS) {
          let base = this.staffCostSubtotal[variant] + this.simpleStaffCostSubtotal[variant];
          agencyCost[variant] = {
            fix: (base * (EXPENSES_FIX * 1000)) / 1000,
            executiveFee: (((base * (EXPENSES_EXECUTIVE_FEE * 1000)) / 1000) * (form.getSatisfactionExecution(variant) * 1000)) / 1000,
            volumeFee: (base * (EXPENSES_VOLUME_FEE * 1000)) / 1000,
            reportingFee: (((base * (EXPENSES_REPORTING_FEE * 1000)) / 1000) * (form.getSatisfactionReporting(variant) * 1000)) / 1000,
            get total() {
              return this.fix + this.executiveFee + this.volumeFee + this.reportingFee;
            },
          };
        }

        return agencyCost;
      },
      agencyCostSubtotal() {
        let agencyCost = {};
        for (let variant of VARIANTS) {
          agencyCost[variant] = 0;
          for (let cost of this.extraCosts.filter(e => e.type === 'Agency')) {
            if (cost[`${variant}Amount`] !== null) {
              agencyCost[variant] += cost[`${variant}Amount`] ? parseFloat(cost[`${variant}Amount`]) : 0;
            }
          }
          agencyCost[variant] += this.agencyCost[variant].total;
        }

        return agencyCost;
      },
      productionCostTotal() {
        let productionCost = {};
        for (let variant of VARIANTS) {
          let v = variant === 'initial' ? 'expected' : variant;
          let organizer = this.formState[`${v}OrganizerContribution`] || 0;
          productionCost[variant] =
            this.staffCostSubtotal[variant] +
            this.simpleStaffCostSubtotal[variant] +
            this.agencyCostSubtotal[variant] +
            this.eventCostSubtotal[variant] -
            parseFloat(organizer);
        }
        return productionCost;
      },

      fgtCostTotal() {
        let fgtCost = {};
        for (let variant of VARIANTS) {
          let v = variant === 'initial' ? 'expected' : variant;
          fgtCost[variant] = 0;
          for (let product of this.formState.offeredProducts) {
            let samplePrice = 0;
            if ('samplePrice' in product) {
              samplePrice = !isNaN(parseFloat(product.samplePrice)) ? parseFloat(product.samplePrice) : 0;
            } else {
              let pp = this.formEnums.productPrices.find(
                e => e.active === true && e.currencyCode === this.formState.currencyCode && e.productId === product.productId
              );
              if (pp && pp.samplePrice) samplePrice = !isNaN(parseFloat(pp.samplePrice)) ? parseFloat(pp.samplePrice) : 0;
            }

            let fgtMarketing = product[`${v}FgtMarketing`] ? parseInt(product[`${v}FgtMarketing`]) : 0;
            let fgtOnp = product[`${v}FgtOnp`] ? parseInt(product[`${v}FgtOnp`]) : 0;

            fgtCost[variant] += (fgtMarketing + fgtOnp) * samplePrice;
          }
        }

        return fgtCost;
      },

      expensesTotal() {
        let expenses = {};
        for (let variant of VARIANTS) {
          expenses[variant] = this.feeSum[variant] + this.productionCostTotal[variant] + this.formState.logisticsCosts + this.fgtCostTotal[variant];
        }

        return expenses;
      },

      earningsTotal() {
        let earnings = {};
        for (let variant of VARIANTS) {
          let v = variant === 'initial' ? 'expected' : variant;
          earnings[variant] = !isNaN(parseFloat(this.formState[`${v}Earnings`])) ? parseFloat(this.formState[`${v}Earnings`]) : 0;
          earnings[variant] += this.sales[variant].total;
        }

        return earnings;
      },

      profit() {
        let profit = {};
        for (let variant of VARIANTS) {
          profit[variant] = this.earningsTotal[variant] - this.expensesTotal[variant];
        }
        return profit;
      },

      offeredProducts() {
        return this.formState.offeredProducts.map(e => {
          let def = this.formEnums.products.find(r => r.id === e.productId);
          if (def) {
            e.categoryId = def.categoryId;
          }
          return e;
        });
      },

      sales() {
        let sales = {};
        for (let variant of VARIANTS) {
          sales[variant] = { total: 0 };
          let v = variant === 'initial' ? 'expected' : variant;
          for (let category of this.productCategories) {
            sales[variant][category.id] = 0;
            for (let product of this.offeredProducts.filter(e => e.categoryId === category.id)) {
              let margin = 0;

              if ('margin' in product) {
                margin = !isNaN(parseFloat(product.margin)) ? parseFloat(product.margin) : 0;
              } else {
                let pp = this.formEnums.productPrices.find(
                  e => e.active === true && e.currencyCode === this.formState.currencyCode && e.productId === product.productId
                );
                if (pp && pp.margin) margin = !isNaN(parseFloat(pp.margin)) ? parseFloat(pp.margin) : 0;
              }

              sales[variant][category.id] += product[`${v}Sale`] ? parseInt(product[`${v}Sale`]) * margin : 0;
              sales[variant].total += product[`${v}Sale`] ? parseInt(product[`${v}Sale`]) * margin : 0;
            }
          }
        }
        return sales;
      },

      salesPcs() {
        let sales = {};
        for (let variant of VARIANTS) {
          sales[variant] = { total: 0 };
          let v = variant === 'initial' ? 'expected' : variant;
          for (let category of this.productCategories) {
            sales[variant][category.id] = 0;
            for (let product of this.offeredProducts.filter(e => e.categoryId === category.id)) {
              sales[variant][category.id] += product[`${v}Sale`] ? parseInt(product[`${v}Sale`]) : 0;
              sales[variant].total += product[`${v}Sale`] ? parseInt(product[`${v}Sale`]) : 0;
            }
          }
        }
        return sales;
      },

      pcc() {
        let pcc = {};
        for (let variant of VARIANTS) {
          let v = variant === 'initial' ? 'expected' : variant;
          pcc[variant] = {};
          for (let category of this.productCategories) {
            pcc[variant][category.id] = 0;
            for (let product of this.offeredProducts.filter(e => e.categoryId === category.id)) {
              pcc[variant][category.id] += product[`${v}Sale`] ? parseInt(product[`${v}Sale`]) : 0;
            }
            pcc[variant][category.id] = pcc[variant][category.id] / this.formState[`${v}Attendance`];
          }
        }
        return pcc;
      },

      perCan() {
        let perCan = {};

        for (let variant of VARIANTS) {
          let v = variant === 'initial' ? 'expected' : variant;
          let productSales = 0;
          for (let product of this.formState.offeredProducts) {
            productSales += product[`${v}Sale`] ? parseInt(product[`${v}Sale`]) : 0;
          }

          let earnings = this.formState[`${v}Earnings`] ? parseFloat(this.formState[`${v}Earnings`]) : 0;
          perCan[variant] = (this.expensesTotal[variant] - earnings) / productSales;
        }

        return perCan;
      },

      salesChartData() {
        return {
          labels: this.productCategories.map(e => e.name),
          datasets: [
            {
              backgroundColor: [COLORS[0], COLORS[1], COLORS[2], COLORS[3], COLORS[4]],
              data: this.productCategories.map(e => {
                return this.salesPcs[this.currentVariant][e.id];
              }),
              borderWidth: 0,
              hoverBorderWidth: 0,
            },
          ],
        };
      },

      salesChartDataOther() {
        return {
          labels: this.productCategories.map(e => e.name),
          datasets: [
            {
              backgroundColor: [COLORS[0], COLORS[1], COLORS[2], COLORS[3], COLORS[4]],
              data: this.productCategories.map(e => {
                return this.salesPcs[this.otherVariant][e.id];
              }),
              borderWidth: 0,
              hoverBorderWidth: 0,
            },
          ],
        };
      },

      expensesChartData() {
        return {
          labels: [
            this.$t('eventsForm.balance.expenses.fee'),
            this.$t('eventsForm.balance.expenses.production'),
            this.$t('eventsForm.balance.expenses.logistics'),
            this.$t('eventsForm.balance.expenses.fgt'),
          ],
          datasets: [
            {
              backgroundColor: [COLORS[0], COLORS[1], COLORS[2], COLORS[3]],
              data: [
                this.feeSum[this.currentVariant],
                this.productionCostTotal[this.currentVariant],
                this.formState.logisticsCosts,
                this.fgtCostTotal[this.currentVariant],
              ],
              borderWidth: 0,
              hoverBorderWidth: 0,
            },
          ],
        };
      },

      expensesChartDataOther() {
        return {
          labels: [
            this.$t('eventsForm.balance.expenses.fee'),
            this.$t('eventsForm.balance.expenses.production'),
            this.$t('eventsForm.balance.expenses.logistics'),
            this.$t('eventsForm.balance.expenses.fgt'),
          ],
          datasets: [
            {
              backgroundColor: [COLORS[0], COLORS[1], COLORS[2], COLORS[3]],
              data: [
                this.feeSum[this.otherVariant],
                this.productionCostTotal[this.otherVariant],
                this.formState.logisticsCosts,
                this.fgtCostTotal[this.otherVariant],
              ],
              borderWidth: 0,
              hoverBorderWidth: 0,
            },
          ],
        };
      },

      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      isPlanning() {
        return compareStatus(this.formState.status, '<=', STATUS_PLANNING);
      },
      isMusketeerCheck() {
        return compareStatus(this.formState.status, '=', STATUS_MUSKETEER_CHECK);
      },
      shouldHaveInitialComparison() {
        let a = this.formState.assignedStaff.find(e => e.initialUnitCost !== null && e.expectedUnitCost !== null);
        let b = this.formState.extraCosts.find(e => e.initialAmount !== null && e.expectedAmount !== null && e.expectedAmount !== 0);
        return typeof a !== 'undefined' && typeof b !== 'undefined';
      },
    },
    methods: {
      setLegend(chart, data) {
        if (chart === 'expenses') {
          this.expensesLegend = data;
        } else if (chart === 'sales') {
          this.salesLegend = data;
        } else if (chart === 'expensesOther') {
          this.expensesLegendOther = data;
        } else if (chart === 'salesOther') {
          this.salesLegendOther = data;
        }
      },

      legendCallback(chart) {
        let content = [];
        let total = chart.data.datasets[0].data.reduce((acc, val) => acc + val);

        for (var i = 0; i < chart.data.labels.length; i++) {
          let currentValue = chart.data.datasets[0].data[i];
          let percentage = Math.floor((currentValue / total) * 100 + 0.5);

          content.push({ color: chart.data.datasets[0].backgroundColor[i], percentage: !isNaN(percentage) ? percentage : -1 });
        }

        return content;
      },

      labelCallback(tooltipItem, data) {
        let dataset = data.datasets[tooltipItem.datasetIndex];
        let total = dataset.data.reduce((acc, val) => acc + val);
        let currentValue = dataset.data[tooltipItem.index];
        let percentage = Math.floor((currentValue / total) * 100 + 0.5);

        return `${percentage}%`;
      },

      key(key) {
        const FIX = {
          initialAttendance: 'expectedAttendance',
        };
        console.log(key);

        if (FIX[key]) return FIX[key];
        return key;
      },
    },
  };
</script>
