<template>
  <div>
    <div class="page-head">
      <h1 class="title is-2">{{ $t('admin.title') }}</h1>
    </div>

    <div class="page-tabs">
      <div class="tabs">
        <ul>
          <li :class="{ 'is-active': active === 'staff-contracts' }">
            <a @click="goToTab('staff-contracts')">{{ $t('admin.tabs.staffContracts.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'activities' }">
            <a @click="goToTab('activities')">{{ $t('admin.tabs.activities.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'events' }">
            <a @click="goToTab('events')">{{ $t('admin.tabs.events.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'products' }">
            <a @click="goToTab('products')">{{ $t('admin.tabs.products.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'invoicing-companies' }">
            <a @click="goToTab('invoicing-companies')">{{ $t('admin.tabs.invoicingCompanies.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'wholesaler' }">
            <a @click="goToTab('wholesaler')">{{ $t('admin.tabs.wholesaler.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'hardware' }">
            <a @click="goToTab('hardware')">{{ $t('admin.tabs.hardware.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'inventory' }">
            <a @click="goToTab('inventory')">{{ $t('admin.tabs.inventory.title') }}</a>
          </li>
          <li :class="{ 'is-active': active === 'exports' }">
            <a @click="goToTab('exports')">{{ $t('admin.tabs.exports.title') }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="page-content">
      <div v-if="active === 'staff-contracts' && !loading">
        <AdminGeneral />
      </div>
      <div v-if="active === 'activities'">
        <AdminActivities />
      </div>
      <div v-if="active === 'events'">
        <AdminEvents />
      </div>
      <div v-if="active === 'products'">
        <AdminProducts />
      </div>
      <div v-if="active === 'invoicing-companies'">
        <AdminInvoicingCompanies />
      </div>
      <div v-if="active === 'wholesaler'">
        <AdminWholesaler />
      </div>
      <div v-if="active === 'hardware'">
        <AdminHardware />
      </div>
      <div v-if="active === 'inventory'">
        <AdminInventory />
      </div>
      <div v-if="active === 'exports'">
        <AdminExports />
      </div>
    </div>
  </div>
</template>

<script>
  import AdminGeneral from '@/components/AdminGeneral';
  import AdminInvoicingCompanies from '@/components/AdminInvoicingCompanies';
  import AdminActivities from '@/components/AdminActivities';
  import AdminWholesaler from '@/components/AdminWholesaler';
  import AdminProducts from '@/components/AdminProducts';
  import AdminHardware from '@/components/AdminHardware';
  import AdminInventory from '@/components/AdminInventory';
  import AdminEvents from '@/components/AdminEvents';
  import AdminExports from "@/components/AdminExports";

  export default {
    name: 'Admin',
    components: { AdminEvents, AdminHardware, AdminInventory, AdminGeneral, AdminActivities, AdminProducts, AdminInvoicingCompanies, AdminWholesaler, AdminExports },
    data() {
      return {
        active: 'staff-contracts',
        loading: false,
      };
    },
    mounted() {
      if (this.$route.params.tabName) this.active = this.$route.params.tabName;
    },
    methods: {
      goToTab(tabName) {
        this.$router.push({ name: 'Admin', params: { tabName } });
      },
    },
  };
</script>
