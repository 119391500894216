import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getExtraCosts(filter) {
  try {
    let res = await http.get('/ExtraCost');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateExtraCosts(id, data) {
  try {
    const res = await http.put(`/ExtraCost/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewExtraCost(data) {
  try {
    const res = await http.post('ExtraCost', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
