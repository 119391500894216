<template>
  <nav class="pagination is-centered is-small">
    <a class="pagination-previous" :disabled="hasFirst" @click="prev">&lt;</a>
    <a class="pagination-next" :disabled="hasLast" @click="next">&gt;</a>

    <ul class="pagination-list">
      <li v-for="page in items" :key="page.label">
        <a class="pagination-link" :class="{ 'is-current': page.active }" @click="goto(page.label)">
          {{ page.label }}
        </a>
        <a v-if="page.disable" class="pagination-link" :disabled="page.disable">
          ...
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        required: true,
      },
      pageCount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      items() {
        let valPrev = this.value > 1 ? this.value - 1 : 1;
        let valNext = this.value < this.pageCount ? this.value + 1 : this.pageCount;
        let extraPrev = valPrev === 3 ? 2 : null;
        let extraNext = valNext === this.pageCount - 2 ? this.pageCount - 1 : null;
        let dotsBefore = valPrev > 3 ? 2 : null;
        let dotsAfter = valNext < this.pageCount - 2 ? this.pageCount - 1 : null;
        let output = [];
        for (let i = 1; i <= this.pageCount; i += 1) {
          if ([1, this.pageCount, this.value, valPrev, valNext, extraPrev, extraNext, dotsBefore, dotsAfter].includes(i)) {
            output.push({
              label: i,
              active: this.value === i,
              disable: [dotsBefore, dotsAfter].includes(i),
            });
          }
        }
        return output;
      },
      hasFirst() {
        return this.value === 1;
      },
      hasLast() {
        return this.value === this.pageCount;
      },
    },
    watch: {
      value: function() {
        this.$emit('change');
      },
    },
    mounted() {
      if (this.value > this.pageCount) {
        this.$emit('input', this.pageCount);
      }
    },
    methods: {
      first() {
        if (!this.hasFirst) {
          this.$emit('input', 1);
        }
      },
      prev() {
        if (!this.hasFirst) {
          this.$emit('input', this.value - 1);
        }
      },
      goto(page) {
        this.$emit('input', page);
      },
      next() {
        if (!this.hasLast) {
          this.$emit('input', this.value + 1);
        }
      },
      last() {
        if (!this.hasLast) {
          this.$emit('input', this.pageCount);
        }
      },
    },
  };
</script>
