const STORE_KEY = 'redbull-onp-theme';

const theme = {
  state: {
    currentTheme: 'dark',
  },
  changeTheme(theme) {
    this.state.currentTheme = theme;
    window.localStorage.setItem(STORE_KEY, theme);
  },
  init() {
    let store = window.localStorage.getItem(STORE_KEY);
    if (store) {
      this.state.currentTheme = store;
    }
  },
};

theme.init();

export default theme;
