import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getStaffContracts(filter) {
  try {
    let res = await http.get('/ContractType');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateContractType(id, data) {
  try {
    const res = await http.put(`/ContractType/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewContractType(data) {
  try {
    const res = await http.post('ContractType', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
