<template>
  <div class="columns is-hardware">
    <div class="column is-three-fifths">
      <div class="list-search">
        <p class="control has-icons-left">
          <span class="icon">
            <inline-svg :src="require('../../assets/icons/search.svg')"></inline-svg>
          </span>
          <input v-model="filterText" class="input" type="text" :placeholder="$t('eventsForm.hardware.search')" />
        </p>
      </div>

      <div class="tabs inner-tabs">
        <ul>
          <li :class="{ 'is-active': activeCategory === 'all' }">
            <a @click="activeCategory = 'all'">
              {{ $t('eventsForm.hardware.all') }}
            </a>
          </li>
          <li v-for="category in formEnums.hardwareCategories" :key="category.id" :class="{ 'is-active': activeCategory === category.id }">
            <a @click="activeCategory = category.id">
              {{ category.name }}
            </a>
          </li>
        </ul>
      </div>

      <ValidationProvider v-slot="{ errors }" name="hardwares" :rules="hardwareRules" class="hardware-table-validation">
        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
      </ValidationProvider>

      <table class="table hardware-table">
        <thead>
          <tr>
            <th>{{ $t('eventsForm.hardware.listing') }}</th>
            <th>{{ $t('eventsForm.hardware.name') }}</th>
            <th>{{ $t('eventsForm.hardware.amount') }}</th>
            <th>{{ $t('eventsForm.hardware.amountVip') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="hardware in filteredHardware" :key="hardware.id">
            <td class="is-width-1"><Toggle :value="isSelected(hardware.id)" :disabled="!isEditable" @input="onSelected(hardware.id, $event)" /></td>
            <td>{{ hardware.name }}</td>
            <td class="is-width-2">
              <span v-if="getHardware(hardware.id)" class="control has-icons-right">
                <NumInput v-model.number="getHardware(hardware.id).amount" class="input" :readonly="!isEditable" :integer="true" />
                <span class="icon is-small is-right">
                  {{ $t('common.unit.pcs') }}
                </span>
              </span>
              <span v-else class="control has-icons-right">
                <NumInput readonly class="input" />
                <span class="icon is-small is-right">
                  {{ $t('common.unit.pcs') }}
                </span>
              </span>
            </td>
            <td class="is-width-2">
              <template v-if="hardware.vipAvailable">
                <span v-if="getHardware(hardware.id)" class="control has-icons-right">
                  <NumInput v-model.number="getHardware(hardware.id).amountVIP" class="input" :readonly="!isEditable" :integer="true" />
                  <span class="icon is-small is-right">
                    {{ $t('common.unit.pcs') }}
                  </span>
                </span>
                <span v-else class="control has-icons-right">
                  <NumInput readonly class="input" />
                  <span class="icon is-small is-right">
                    {{ $t('common.unit.pcs') }}
                  </span>
                </span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!isEditable && selectedCount === 0" class="info-text">{{ $t('eventsForm.hardware.overview.none') }}</p>
      <div class="form is-hidden-desktop">
        <h3>{{ $t('eventsForm.hardware.notes.title') }}</h3>
        <div class="form-wrapper">
          <FormElement name="noteHardware" :field="form['noteHardware']" css-class="is-note" />
        </div>
      </div>
    </div>
    <div class="column is-two-fifths">
      <div v-if="isOverviewVisible" class="offered-activities-overview">
        <h3>{{ $t('eventsForm.hardware.overview.selected') }}</h3>

        <ul v-if="selectedCount > 0" class="item-overview">
          <li v-for="hardware in formState.hardwares" :key="hardware.hardwareId">
            <strong>{{ getHardwareEnum(hardware.hardwareId) }}</strong>
            <span
              ><template v-if="hardware.amount">x{{ hardware.amount }}</template> <span v-if="hardware.amountVIP">x{{ hardware.amountVIP }} VIP</span></span
            >
            <a @click="deselectHardware(hardware)"
              ><span class="icon"><inline-svg :src="require('../../assets/icons/cross.svg')"></inline-svg></span
            ></a>
          </li>
        </ul>
        <p v-if="selectedCount === 0">{{ $t('eventsForm.hardware.overview.none') }}</p>
      </div>

      <div class="form is-hidden-touch">
        <h3>{{ $t('eventsForm.hardware.notes.title') }}</h3>
        <div class="form-wrapper">
          <FormElement name="noteHardware" :field="form['noteHardware']" css-class="is-note" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import form, { eventFormDefinition } from '@/services/eventForm';
  import { compareStatus, isMandatoryNow, isMandatoryNext, STATUS_REPORTING, STATUS_FINISHED } from '@/services/status';
  import Toggle from '@/components/Toggle';
  import FormElement from '@/components/EventsForm/FormElement';
  import NumInput from '@/components/EventsForm/NumInput';
  import { searchTerm } from '@/filters';
  import { isUserEventManager } from '@/services/roles';

  export default {
    name: 'Hardware',
    components: { NumInput, ValidationProvider, FormElement, Toggle },
    data() {
      return {
        form: eventFormDefinition,
        formState: form.state,
        formEnums: form.enums,
        filterText: '',
        activeCategory: 'all',
        selected: [],
      };
    },
    computed: {
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.offeredActivities.expected.visible[0], this.form.offeredActivities.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.offeredActivities.real.visible[0], this.form.offeredActivities.real.visible[1])) {
          return 'real';
        }
        return false;
      },
      availableHardware() {
        let available = [];
        for (const category of this.formEnums.hardwareCategories) {
          for (const hardware of this.formEnums.hardware.filter(e => e.categoryId === category.id)) {
            available.push({
              ...hardware,
              categoryId: category.id,
            });
          }
        }

        return available;
      },
      filteredHardware() {
        let available = [];
        if (this.isEditable) {
          available = this.availableHardware;
        } else {
          for (const selectedHardware of this.formState.hardwares) {
            let hw = this.formEnums.hardware.find(e => e.id === selectedHardware.hardwareId);
            if (hw) {
              available.push({
                name: hw.name,
                id: hw.id,
                categoryId: hw.categoryId,
              });
            }
          }
        }

        if (this.filterText) {
          available = available.filter(e => searchTerm(e.name, this.filterText));
        }

        if (this.activeCategory !== 'all') {
          available = available.filter(e => e.categoryId === this.activeCategory);
        }

        return available;
      },
      hardwareRules() {
        const params = { count: this.formState.hardwares.length, min: 1 };
        let rules = {};
        if (this.form.hardwares.mandatory === true) {
          rules.minHardware = params;
        }
        if (this.form.hardwares.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.formState.status, this.form.hardwares.mandatory[0], this.form.hardwares.mandatory[1]);
          if (mandatoryNow) rules.minHardware = params;
          let mandatoryNext = isMandatoryNext(this.formState.status, this.form.hardwares.mandatory[0], this.form.hardwares.mandatory[1]);
          if (!mandatoryNow && mandatoryNext) rules.minHardwareIf = { ...params, target: 'proceedSave' };
        }

        rules.hardwareValidValues = { data: this.formState.hardwares };

        return rules;
      },
      selectedCount() {
        return this.formState.hardwares.length;
      },
      isEditable() {
        if (isUserEventManager()) {
          return true;
        } else {
          return compareStatus(this.formState.status, '<', STATUS_REPORTING);
        }
      },
      isOverviewVisible() {
        return compareStatus(this.formState.status, '<', STATUS_REPORTING);
      },
    },
    methods: {
      deselectHardware({ hardwareId }) {
        this.formState.hardwares.splice(
          this.formState.hardwares.findIndex(e => e.hardwareId === hardwareId),
          1
        );
      },
      onSelected(hardwareId, state) {
        if (state === true) {
          let is = this.formState.hardwares.find(e => e.hardwareId === hardwareId);
          if (!is) {
            this.formState.hardwares.push({
              hardwareId: hardwareId,
              amount: 0,
              amountVIP: 0,
            });
          }
        } else if (state === false) {
          this.formState.hardwares.splice(
            this.formState.hardwares.findIndex(e => e.hardwareId === hardwareId),
            1
          );
        }
      },
      isSelected(hardwareId) {
        return !!this.formState.hardwares.find(e => e.hardwareId === hardwareId);
      },
      getHardware(hardwareId) {
        return this.formState.hardwares.find(e => e.hardwareId === hardwareId);
      },
      getHardwareEnum(id) {
        return this.formEnums.hardware.find(e => e.id === id)?.name;
      },
    },
  };
</script>

<style scoped></style>
