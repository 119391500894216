<template>
  <div>
    <div class="form">
      <h3>{{ $t('eventsForm.products.productGenericForm.title') }}</h3>
      <div class="form-wrapper">
        <div class="columns is-multiline">
          <template v-if="!isReported">
            <FormElement v-for="item in productGenericForm" :key="item" :name="item" :field="form[item]" :css-class="'column is-6'" />
          </template>
          <template v-else-if="isFinancialCheck">
            <div v-for="item in productGenericReport" :key="item[0]" class="form-field column is-6">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label has-text-left">
                    {{ $t(form[item[0]].label) }}
                  </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <span class="control has-icons-right">
                      <ValidationProvider v-slot="{ errors }" :name="item[0]" :rules="{ required: true }">
                        <NumInputComp
                          v-model.number="formState[item[0]]"
                          :comparison="formState[item[1]]"
                          min="0"
                          :class="{ 'is-warning': errors[0] }"
                          :color-off="true"
                        />
                        <span class="icon is-small is-right">
                          {{ $t('common.unit.pcs') }}
                        </span>
                        <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-for="item in productGenericReport" :key="item[0]" class="form-field column is-6">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label has-text-left">
                    {{ $t(form[item[0]].label) }}
                  </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <span class="report-field has-units">
                      <span
                        >{{ formState[item[0]] | formatNumber }}
                        <template v-if="item[1]">{{ formState[item[1]] | amountDifference(formState[item[0]]) }}</template>
                      </span>
                      <span class="unit">{{ formState.currencyCode | formatCurrency }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="form-field column is-6"></div>
        </div>
      </div>
    </div>

    <table v-if="isReported" class="table product-summary-table">
      <thead>
        <tr>
          <th class="is-width-2"></th>
          <template v-for="category in availableCategories">
            <th v-if="salesTotal[category.id]" :key="category.id" class="is-width-3">{{ category.name }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="is-width-2">{{ $t('eventsForm.products.summary.expected') }}</td>
          <template v-for="category in availableCategories">
            <td v-if="salesTotal[category.id]" :key="category.id" class="is-width-3">
              <span class="report-bar is-expected" :style="{ width: `${(salesTotal[category.id].sumExpected * 140) / salesTotal.max}px` }" />
              {{ salesTotal[category.id].sumExpected | formatNumber }} {{ $t('common.unit.pcs') }}
            </td>
          </template>
        </tr>

        <tr>
          <td class="is-width-2">{{ $t('eventsForm.products.summary.reality') }}</td>
          <template v-for="category in availableCategories">
            <td v-if="salesTotal[category.id]" :key="category.id" class="is-width-3">
              <span class="report-bar is-real" :style="{ width: `${(salesTotal[category.id].sumReal * 140) / salesTotal.max}px` }" />
              {{ salesTotal[category.id].sumReal | formatNumber }} {{ $t('common.unit.pcs') }}
              <ArrowCompare :real="salesTotal[category.id].sumReal" :expected="salesTotal[category.id].sumExpected" />
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <ValidationProvider v-slot="{ errors }" name="offeredProducts" :rules="productRules" class="product-listing-validation">
      <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
    </ValidationProvider>


    <div class="product-listing-table-wrapper">
      <table class="table product-listing-table">
        <thead>
          <tr>
            <th v-if="!isReported || isFinancialCheck">{{ $t('eventsForm.products.productListingForm.table.listing') }}</th>
            <th>{{ $t('eventsForm.products.productListingForm.table.category') }}</th>
            <th>{{ $t('eventsForm.products.productListingForm.table.product') }}</th>
            <th>{{ $t('eventsForm.products.productListingForm.table.price') }}</th>
            <th>{{ $t('eventsForm.products.productListingForm.table.fgtOnp') }}</th>
            <th>{{ $t('eventsForm.products.productListingForm.table.fgtMarketing') }}</th>
            <th v-if="isExecutionOrLesser">{{ $t('eventsForm.products.productListingForm.table.volumeForecast') }}</th>
            <th v-else>{{ $t('eventsForm.products.productListingForm.table.sales') }}</th>
          </tr>
        </thead>
        <tbody>
          <ProductsListingRow v-for="product in availableProducts" :key="product.id" :product="product" />
        </tbody>
      </table>
    </div>

    <div class="form">
      <h3>{{ $t('eventsForm.products.notes.title') }}</h3>
      <div class="form-wrapper">
        <FormElement name="noteProducts" class="is-note-mobile" :field="form['noteProducts']" css-class="has-short-label" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import form, { eventFormDefinition } from '@/services/eventForm';
  import FormElement from '@/components/EventsForm/FormElement';
  import ProductsListingRow from '@/components/EventsForm/ProductsListingRow';
  import { compareStatus, isMandatoryNext, isMandatoryNow, STATUS_CONTROLLING, STATUS_FINANCIAL_CHECK, STATUS_REPORTING } from '@/services/status';
  import ArrowCompare from '@/components/EventsForm/ArrowCompare';
  import { isUserRedbull } from '@/services/roles';
  import NumInputComp from '@/components/EventsForm/NumInputComp';

  export default {
    name: 'Products',
    components: { ArrowCompare, ValidationProvider, ProductsListingRow, FormElement, NumInputComp },
    filters: {},
    data() {
      return {
        form: eventFormDefinition,
        formState: form.state,
        formEnums: form.enums,
        productGenericForm: ['expectedPriceRedbullMix', 'realPriceRedbullMix', 'expectedPriceOrganicsMix', 'realPriceOrganicsMix', 'priceBeer'],
        productGenericReport: [['realPriceRedbullMix', 'expectedPriceRedbullMix'], ['realPriceOrganicsMix', 'expectedPriceOrganicsMix'], ['priceBeer']],
      };
    },
    computed: {
      availableCategories() {
        return this.formEnums.productCategories.filter(e => e.active);
      },
      availableProducts() {
        if (!this.isReported) {
          let available = [];
          for (let category of this.formEnums.productCategories) {
            for (let product of this.formEnums.products.filter(e => e.categoryId === category.id)) {
              available.push(product);
            }
          }
          return available.filter(e => e.active || this.expectedProducts.includes(e.id));
        } else if (this.isFinancialCheck) {
          let products = this.formEnums.products.map(e => {
            let state = this.formState.offeredProducts.find(r => r.productId === e.id);
            return { ...state, ...e };
          });

          let list = [];
          for (let category of this.formEnums.productCategories) {
            for (let product of products.filter(e => e.categoryId === category.id)) {
              list.push(product);
            }
          }
          return list;
        } else {
          let selectedProducts = this.formState.offeredProducts.map(e => e.productId);
          let products = this.formEnums.products
            .filter(e => selectedProducts.includes(e.id))
            .map(e => {
              let state = this.formState.offeredProducts.find(r => r.productId === e.id);
              return { ...state, ...e };
            });

          let list = [];
          for (let category of this.formEnums.productCategories) {
            for (let product of products.filter(e => e.categoryId === category.id)) {
              list.push(product);
            }
          }
          return list;
        }
      },
      productRules() {
        const params = { count: this.formState.offeredProducts.length, min: 1 };
        let rules = {};
        if (this.form.offeredProducts.expected.mandatory === true) {
          rules.minProducts = params;
        }
        if (this.form.offeredProducts.expected.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(
            this.formState.status,
            this.form.offeredProducts.expected.mandatory[0],
            this.form.offeredProducts.expected.mandatory[1]
          );
          if (mandatoryNow) rules.minProducts = params;
          let mandatoryNext = isMandatoryNext(
            this.formState.status,
            this.form.offeredProducts.expected.mandatory[0],
            this.form.offeredProducts.expected.mandatory[1]
          );
          if (!mandatoryNow && mandatoryNext) rules.minProductsIf = { ...params, target: 'proceedSave' };
        }

        return rules;
      },
      expectedProducts() {
        return this.formState.products.filter(e => e.expectedListing === true).map(e => e.productId);
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      isFinancialCheck() {
        return isUserRedbull() && compareStatus(this.formState.status, '=', STATUS_FINANCIAL_CHECK);
      },
      isExecutionOrLesser() {
        return compareStatus(this.formState.status, '<', STATUS_REPORTING);
      },
      salesTotal() {
        if (!this.isReported) return false;
        let total = {};
        let max = 0;
        for (let category of this.formEnums.productCategories) {
          total[category.id] = {
            sumExpected: 0,
            sumReal: 0,
          };
          for (let product of this.availableProducts.filter(e => e.categoryId === category.id)) {
            if (product.expectedSale) total[category.id].sumExpected += parseInt(product.expectedSale);
            if (product.realSale) total[category.id].sumReal += parseInt(product.realSale);
            if (total[category.id].sumExpected > max) max = total[category.id].sumExpected;
            if (total[category.id].sumReal > max) max = total[category.id].sumReal;
          }

          if (total[category.id].sumExpected === 0 && total[category.id].sumReal === 0) {
            delete total[category.id];
          }
        }

        total.max = max;

        return total;
      },
    },
  };
</script>
