import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getWages(filter) {
  try {
    let res = await http.get('/Wage');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getWageTypes(filter) {
  try {
    let res = await http.get('/WageType');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateWage(id, data) {
  try {
    const res = await http.put(`/Wage/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function updateWageType(id, data) {
  try {
    const res = await http.put(`/WageType/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewWage(data) {
  try {
    const res = await http.post('Wage', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function createNewWageType(data) {
  try {
    const res = await http.post('WageType', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
