import Vue from 'vue';

export function formatNumber(value) {
  if (typeof value === 'number') {
    return value.toLocaleString('cs-CZ', { maximumFractionDigits: 2 });
  } else {
    return parseFloat(value).toLocaleString('cs-CZ', { maximumFractionDigits: 2 });
  }
}

export function searchTerm(value, search) {
  value = value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  search = search
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  return value.includes(search);
}

Vue.filter('toLocalDateTime', datetime => {
  const date = new Date(datetime);
  return date.toLocaleDateString('cs-CZ');
});

Vue.filter('toLocalDateTimeNoYear', datetime => {
  const date = new Date(datetime);
  return date.toLocaleDateString('cs-CZ', { month: 'numeric', day: 'numeric' });
});

Vue.filter('formatCurrency', value => {
  if (!value) return '';
  if (value === 'CZK') {
    return 'Kč';
  } else if (value === 'EUR') {
    return '€';
  }
});

const formatPrice = (value, currencyCode) => {
  let currency = '';
  if (currencyCode === 'CZK') {
    currency = 'Kč';
  } else if (currencyCode === 'EUR') {
    currency = '€';
  }

  if (typeof value === 'number' && !isNaN(value)) {
    return `${formatNumber(value)} ${currency}`;
  } else if (!isNaN(parseFloat(value))) {
    return `${formatNumber(parseFloat(value))} ${currency}`;
  } else {
    return `– ${currency}`;
  }
};

Vue.filter('formatPrice', (value, currencyCode) => {
  return formatPrice(value, currencyCode);
});

Vue.filter('formatNumber', value => {
  if (typeof value === 'number') {
    return formatNumber(value);
  } else {
    return value;
  }
});

Vue.filter('round2', value => {
  if (typeof value === 'number' && !isNaN(value)) {
    return Math.round(value * 100) / 100;
  }
});

Vue.filter('round3', value => {
  if (typeof value === 'number' && !isNaN(value)) {
    return Math.round(value * 1000) / 1000;
  }
});

Vue.filter('amountDifference', (expected, real, hideZero, currencyCode) => {
  if (typeof expected === 'string') expected = parseInt(expected);
  if (typeof real === 'string') real = parseInt(real);
  if (isNaN(expected)) return '';
  if (isNaN(real)) return '';

  let val = real - expected;
  if (!currencyCode) {
    return hideZero && val === 0 ? '' : `(${val > 0 ? `+${val}` : val})`;
  } else {
    return hideZero && val === 0 ? '' : `(${val > 0 ? `+${formatPrice(val, currencyCode)}` : formatPrice(val, currencyCode)})`;
  }

});

Vue.filter('amountDifferenceClass', (expected, real, inverted) => {
  let more = inverted ? 'is-less' : 'is-more';
  let less = inverted ? 'is-more' : 'is-less';

  if (typeof expected === 'string') expected = parseInt(expected);
  if (typeof real === 'string') real = parseInt(real);
  if (isNaN(expected)) return '';
  if (isNaN(real)) return '';

  let val = real - expected;
  return val === 0 ? 'is-same' : val > 0 ? more : less;
});
