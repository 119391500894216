<script>
  import { Doughnut, mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;
  export default {
    extends: Doughnut,
    mixins: [reactiveProp],

    props: {
      chartData: {
        type: Object,
        default: null,
      },
      options: {
        type: Object,
        default: null,
      },
    },
    watch: {
      chartData() {
        let legendData = this.generateLegend();
        this.$emit('legend', legendData);
      },
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
      let legendData = this.generateLegend();
      this.$emit('legend', legendData);
    },
    methods: {
      update() {
        this.$data._chart.update();
      },
    },
  };
</script>
