<template>
  <div class="columns is-desktop is-multiline">
    <div class="column is-half-desktop">
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.eventInfoForm.title') }}</h3>

        <div class="form-wrapper">
          <div class="form-field">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label
                  class="label has-text-left"
                  :class="{ 'is-required': validationRule(form['addressName']).required && verifyEditability(form['addressName']) }"
                >
                  {{ $t(form['addressName'].label) }}
                </label>
                <div v-show="toggleAddress" class="additionals-labels">
                  <div>
                    <label
                      class="label has-text-left"
                      :class="{ 'is-required': validationRule(form['addressPostCode']).required && verifyEditability(form['addressPostCode']) }"
                    >
                      {{ $t(form['addressPostCode'].label) }}
                    </label>
                    <span v-if="validationRule(form['addressPostCode']).required_if && verifyEditability(form['addressPostCode'])" class="icon is-mandatory">
                      <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
                    </span>
                  </div>
                  <div>
                    <label
                      class="label has-text-left"
                      :class="{ 'is-required': validationRule(form['organisedInCode']).required && verifyEditability(form['organisedInCode']) }"
                    >
                      {{ $t(form['organisedInCode'].label) }}
                    </label>
                    <span v-if="validationRule(form['organisedInCode']).required_if && verifyEditability(form['organisedInCode'])" class="icon is-mandatory">
                      <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
                    </span>
                  </div>
                  <div>
                    <label
                      class="label has-text-left"
                      :class="{ 'is-required': validationRule(form['districtCode']).required && verifyEditability(form['districtCode']) }"
                    >
                      {{ $t(form['districtCode'].label) }}
                    </label>
                    <span v-if="validationRule(form['districtCode']).required_if && verifyEditability(form['districtCode'])" class="icon is-mandatory">
                      <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
                    </span>
                  </div>
                  <div>
                    <label
                      class="label has-text-left"
                      :class="{ 'is-required': validationRule(form['addressCity']).required && verifyEditability(form['addressCity']) }"
                    >
                      {{ $t(form['addressCity'].label) }}
                    </label>
                    <span v-if="validationRule(form['addressCity']).required_if && verifyEditability(form['addressCity'])" class="icon is-mandatory">
                      <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
                    </span>
                  </div>
                  <div>
                    <label
                      class="label has-text-left"
                      :class="{ 'is-required': validationRule(form['addressStreet']).required && verifyEditability(form['addressStreet']) }"
                    >
                      {{ $t(form['addressStreet'].label) }}
                    </label>
                    <span v-if="validationRule(form['addressStreet']).required_if && verifyEditability(form['addressStreet'])" class="icon is-mandatory">
                      <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
                    </span>
                  </div>
                </div>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="field is-grouped">
                    <div class="control is-expanded">
                      <FormElementInput name="addressName" :field="form['addressName']" />
                      <div v-show="toggleAddress" class="additionals">
                        <div class="my-2">
                          <FormElementInput name="addressPostCode" :field="form['addressPostCode']" @change="onZipChange" />
                        </div>
                        <div class="my-2">
                          <FormElementInput name="organisedInCode" :field="form['organisedInCode']" @change="onCountryChange" />
                        </div>
                        <div class="my-2">
                          <FormElementInput name="districtCode" :field="form['districtCode']" :options="availableDistricts" @change="onDistrictChange" />
                        </div>
                        <div class="my-2">
                          <FormElementInput name="addressCity" :field="form['addressCity']" />
                        </div>
                        <div class="my-2">
                          <FormElementInput name="addressStreet" :field="form['addressStreet']" />
                        </div>
                      </div>
                    </div>
                    <p class="control">
                      <button class="button is-input" :class="{ 'is-active': toggleAddress }" @click.prevent="toggleAddress = !toggleAddress">
                        <span class="icon"><inline-svg :src="require('../../assets/icons/arrow-down.svg')"></inline-svg></span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FormElement v-for="item in eventInfoForm" :key="item" :name="item" :field="form[item]" />
        </div>
      </div>
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.redBullPaysForm.title') }}</h3>
        <div class="form-wrapper">
          <div class="columns is-multiline">
            <div v-for="cost in rbCosts" class="form-field column is-6">
              <p class="control">
                <label class="checkbox">
                  <input v-model="rbCostsSelected" type="checkbox" :value="cost.id" :disabled="!isPlanning" />
                  <span>{{ cost.name }}</span>
                </label>
              </p>
            </div>
            <div v-if="rbCosts.length % 2" class="form-field column is-6"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-half-desktop">
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.organizationalTeamForm.title') }}</h3>
        <div class="form-wrapper">
          <FormElement v-for="item in organizationalTeamForm" :key="item" :name="item" :field="form[item]" />
        </div>
      </div>
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.contactsForm.title') }}</h3>

        <div class="form-wrapper">
          <div v-if="!isReported" class="form-field">
            <div class="field is-horizontal">
              <div class="field-label">
                <label :class="{ 'is-required': contactsRules.minContacts }" class="label has-text-left"
                  >{{ $t('eventsForm.basicInfo.contactsForm.addContact.label') }}
                </label>
                <span v-if="contactsRules.minContactsIf" class="icon is-mandatory">
                  <inline-svg :src="require(`@/assets/icons/task.svg`)"></inline-svg>
                </span>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="field is-grouped">
                    <ValidationProvider v-slot="{ errors }" name="contactRole" :rules="contactsRules" class="control is-expanded">
                      <input
                        v-model="newContact"
                        class="input"
                        :class="{ 'is-warning': errors[0] }"
                        name="contactRole"
                        :placeholder="$t('eventsForm.basicInfo.contactsForm.addContact.placeholder')"
                        @keydown.enter.prevent="addContact(newContact)"
                      />
                      <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <p class="control">
                      <button class="button is-input" :disabled="!newContact" @click.prevent="addContact(newContact)">
                        <span class="icon">
                          <inline-svg :src="require('../../assets/icons/plus-circle.svg')"></inline-svg>
                        </span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="contact in formState['eventContacts']" class="form-field">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> {{ contact.role }} </label>
                <div v-show="contact.toggle" class="additionals-labels">
                  <div>
                    <label class="label has-text-left">
                      {{ $t('eventsForm.basicInfo.contactsForm.phone.placeholder') }}
                    </label>
                  </div>
                  <div>
                    <label class="label has-text-left">
                      {{ $t('eventsForm.basicInfo.contactsForm.email.placeholder') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="field is-grouped">
                    <div class="control is-expanded">
                      <span class="floating-required" :class="{ 'is-required': !isReported }" />
                      <ValidationProvider v-slot="{ errors }" name="contactName" rules="required">
                        <p class="control">
                          <input
                            ref="contactNames"
                            v-model="contact.fullName"
                            class="input"
                            :class="{ 'is-warning': errors[0] }"
                            type="text"
                            :readonly="isReported"
                            :placeholder="$t('eventsForm.basicInfo.contactsForm.name.placeholder')"
                          />
                          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                        </p>
                      </ValidationProvider>

                      <div v-show="contact.toggle" class="additionals">
                        <div class="my-2">
                          <ValidationProvider v-slot="{ errors }" name="contactPhoneNumber">
                            <p class="control">
                              <input v-model="contact.phoneNumber" class="input" :class="{ 'is-warning': errors[0] }" type="text" :readonly="isReported" />
                              <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                            </p>
                          </ValidationProvider>
                        </div>
                        <div class="my-2">
                          <p class="control">
                            <ValidationProvider v-slot="{ errors }" name="contactEmail" rules="email">
                              <input v-model="contact.email" class="input" :class="{ 'is-warning': errors[0] }" type="text" :readonly="isReported" />
                              <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </p>
                        </div>
                      </div>
                    </div>
                    <p class="control">
                      <button class="button is-input" :class="{ 'is-active': contact.toggle }" @click.prevent="toggleContact(contact)">
                        <span class="icon"><inline-svg :src="require('../../assets/icons/arrow-down.svg')"></inline-svg></span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <a v-if="!isReported" class="field-delete" @click.prevent="deleteContact(contact)">
                <span class="icon">
                  <inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.contractInfoForm.title') }}</h3>
        <div class="form-wrapper">
          <FormElement v-for="item in contractInfoForm" :key="item" :name="item" :field="form[item]" />
          <div class="columns">
            <div class="form-field column is-6">
              <FormElementInput name="ownCashiers" :field="form['ownCashiers']" />
            </div>
            <div class="form-field column is-6">
              <FormElementInput name="tips" :field="form['tips']" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showEvaluation" class="column is-full">
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.evaluation') }}</h3>
        <div class="form-wrapper">
          <FormElement name="evaluation" class="is-note-mobile" :field="form['evaluation']" />
        </div>
      </div>
    </div>
    <div class="column is-full">
      <div class="form">
        <h3>{{ $t('eventsForm.basicInfo.notesForm.title') }}</h3>
        <div class="form-wrapper">
          <FormElement name="noteGeneral" class="is-note-mobile" :field="form['noteGeneral']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FormElement from '@/components/EventsForm/FormElement';
  import form, { EDITOR_AGENCY, EDITOR_AUTHORITY, eventFormDefinition } from '@/services/eventForm';
  import FormElementInput from '@/components/EventsForm/FormElementInput';
  import { getZipCode } from '@/api/localeService';
  import { compareStatus, isMandatoryNext, isMandatoryNow, STATUS_CONTROLLING, STATUS_FINISHED, STATUS_PLANNING, STATUS_REPORTING } from '@/services/status';
  import { ValidationProvider } from 'vee-validate';
  import { getAgencySupervisors, getOrganiserUsers, getOverseerUsers } from '@/api/userService';
  import auth from '@/services/auth';
  import { canApproveSelf, isUserAgency, isUserEventManager, isUserRedbull } from '@/services/roles';
  import { getHostedUsers, getHostedUserLogisticsCosts } from '@/api/hostedUserService';

  export default {
    name: 'BasicInfo',
    components: { ValidationProvider, FormElementInput, FormElement },
    data() {
      return {
        form: eventFormDefinition,
        formState: form.state,
        formEnums: form.enums,
        eventInfoForm: [
          'eventStart',
          'eventEnd',
          'doorOpen',
          'doorClose',
          'expectedConstructionStart',
          'realConstructionStart',
          'categoryId',
          'segmentId',
          'typeId',
          'expectedAttendance',
          'realAttendance',
          'expectedEarnings',
          'realEarnings',
          'eventActivity',
          'averageAge',
        ],
        organizationalTeamForm: ['organisedByUserId', 'supervisorUserId', 'agencyId', 'overseerUserId'],
        contractInfoForm: ['invoicingCompanyId', 'companyName', 'wholeSalerId', 'contractTypeId', 'sizeId', 'wageTypeId'],
        newContact: '',
        toggleAddress: false,
        auth: auth.state,
        logisticsCosts: [],
      };
    },
    computed: {
      availableDistricts() {
        return this.formEnums.districts
          .filter(e => e.active && e.countryCode === this.formState.organisedInCode)
          .map(e => {
            e.id = e.code;
            return e;
          });
      },
      contactsRules() {
        const params = { count: this.formState['eventContacts'].length, min: 1 };
        let rules = {};
        if (this.form.eventContacts.mandatory === true) {
          rules.minContacts = params;
        }
        if (this.form.eventContacts.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.formState.status, this.form.eventContacts.mandatory[0], this.form.eventContacts.mandatory[1]);
          if (mandatoryNow) rules.minContacts = params;
          let mandatoryNext = isMandatoryNext(this.formState.status, this.form.eventContacts.mandatory[0], this.form.eventContacts.mandatory[1]);
          if (!mandatoryNow && mandatoryNext) rules.minContactsIf = { ...params, target: 'proceedSave' };
        }

        return rules;
      },
      rbCosts() {
        return this.formEnums.extraCosts.filter(e => e.active && e.displayedProminently);
      },
      rbCostsSelected: {
        get() {
          return this.formState.extraCosts.map(e => e.costTypeId);
        },
        set(newVal) {
          let added = newVal.filter(x => !this.rbCostsSelected.includes(x));
          let removed = this.rbCostsSelected.filter(x => !newVal.includes(x));
          if (added.length > 0) this.addRbCost(added[0]);
          if (removed.length > 0) this.removeRbCost(removed[0]);
        },
      },
      isPlanning() {
        return compareStatus(this.formState.status, '<=', STATUS_PLANNING);
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      showEvaluation() {
        return (
          (compareStatus(this.formState.status, '=', [STATUS_REPORTING, STATUS_CONTROLLING]) && isUserAgency()) ||
          compareStatus(this.formState.status, '>=', STATUS_REPORTING)
        );
      },
    },
    watch: {
      'formState.organisedByUserId': 'onOrganiserChange',
      'formState.agencyId': 'getSupervisors',
    },
    async mounted() {
      let organisers;
      if (!isUserAgency()) {
        organisers = await getOrganiserUsers(this.auth.id);
        if (!organisers.find(e => e.id === this.auth.id)) organisers.push({ id: this.auth.id, name: `${this.auth.firstName} ${this.auth.lastName}` });
        organisers.sort((a, b) => a.name.localeCompare(b.name));
        organisers = organisers.filter(e => !e.deletedAt || e.id === this.formState.organisedByUserId);

        this.$set(this.form.organisedByUserId, 'options', organisers);

        if (organisers.length === 1 && !this.formState.organisedByUserId) {
          this.formState.organisedByUserId = organisers[0].id;
        } else if (!this.formState.organisedByUserId) {
          this.formState.organisedByUserId = this.auth.id;
        }
      } else {
        organisers = await getHostedUsers();
        this.$set(this.form.organisedByUserId, 'options', [...organisers]);
      }

      if (this.formState.organisedByUserId !== null) this.onOrganiserChange();
      if (this.formState.agencyId !== null) this.getSupervisors();

      if (this.formState.agencyId) {
        let supervisors = await getAgencySupervisors(this.formState.agencyId);
        this.$set(this.form.supervisorUserId, 'options', supervisors);
      }
    },
    methods: {
      addContact(role) {
        if (role.length > 0) {
          this.newContact = '';
          let length = form.addContact(role);
          this.$nextTick(() => {
            this.$refs.contactNames[length - 1].focus();
          });
        }
      },
      toggleContact(contact) {
        this.$set(contact, 'toggle', !contact.toggle);
      },
      deleteContact(contact) {
        form.removeContact(contact);
      },
      addRbCost(id) {
        if (!id) return false;

        let is = this.formState.extraCosts.find(e => e.costTypeId === id);
        if (!is) {
          this.formState.extraCosts.push({
            costTypeId: id,
            expectedAmount: 0,
            realAmount: null,
          });
        }
      },
      removeRbCost(id) {
        if (!id) return false;

        let index = this.formState.extraCosts.findIndex(e => e.costTypeId === id);
        if (index > -1) {
          this.formState.extraCosts.splice(index, 1);
        }
      },
      onCountryChange() {
        // @ToDo: change after BE update
        if (this.formState.organisedInCode === 'CZ') {
          this.formState.currencyCode = 'CZK';
        } else if (this.formState.organisedInCode === 'SK') {
          this.formState.currencyCode = 'EUR';
        }
        this.checkLogisticsCosts(this.formState.organisedInCode === 'CZ' ? 'EUR' : 'CZK');
      },
      onDistrictChange() {
        let district = this.formEnums.districts.find(e => e.code === this.formState.districtCode);
        if (district) this.formState.addressDistrict = district.name;
      },
      checkLogisticsCosts(oldCurrency, organiserChange) {
        if (
          this.logisticsCosts?.length > 0 &&
          compareStatus(this.formState.status, this.form.logisticsCosts.editable[0], this.form.logisticsCosts.editable[1])
        ) {
          if (oldCurrency) {
            let oldValue = this.logisticsCosts.find(cost => cost.currencyCode === oldCurrency).cost;
            if (oldValue && this.formState.logisticsCosts === oldValue) {
              let newValue = this.logisticsCosts.find(cost => cost.currencyCode === this.formState.currencyCode).cost;
              if (newValue) this.formState.logisticsCosts = newValue;
            }
          }

          if (this.formState.logisticsCosts === 0 || organiserChange) {
            let newValue = this.logisticsCosts.find(cost => cost.currencyCode === this.formState.currencyCode).cost;
            if (newValue) this.formState.logisticsCosts = newValue;
          }
        }
      },
      async onZipChange() {
        const res = await getZipCode(this.formState.addressPostCode);
        if (res && res[0] && res[0].country && res[0].district) {
          this.formState.organisedInCode = res[0].country.code;
          this.formState.addressDistrict = res[0].district.name;
          this.formState.districtCode = res[0].district.code;
        }
      },
      validationRule(field) {
        let rules = {};
        if (field.mandatory === true) {
          rules.required = true;
        }
        if (field.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.formState.status, field.mandatory[0], field.mandatory[1]);
          if (mandatoryNow) rules.required = true;

          let mandatoryNext = isMandatoryNext(this.formState.status, field.mandatory[0], field.mandatory[1]);
          if (mandatoryNext) rules.required_if = 'proceedSave';
        }

        return rules;
      },
      verifyEditability(field) {
        let editable = false;

        if ('editable' in field) {
          editable = compareStatus(this.formState.status, field.editable[0], field.editable[1]);
        } else {
          editable = true;
        }

        if ('editor' in field && editable) {
          if (field.editor.includes(EDITOR_AGENCY) && !field.editor.includes(EDITOR_AUTHORITY)) {
            return isUserAgency() || isUserEventManager();
          }
          if (!field.editor.includes(EDITOR_AGENCY) && field.editor.includes(EDITOR_AUTHORITY)) {
            return isUserRedbull();
          }
        }

        if (editable && this.formState.status === STATUS_FINISHED) {
          editable = false;
        }

        // event manager override
        if (isUserEventManager()) {
          editable = true;
        }

        return editable;
      },
      async onOrganiserChange(newValue, oldValue) {
        let overseers = await getOverseerUsers(this.formState.organisedByUserId);
        if (canApproveSelf()) {
          if (!overseers.find(e => e.id === this.auth.id)) overseers.push({ id: this.auth.id, name: `${this.auth.firstName} ${this.auth.lastName}` });
        }
        overseers.sort((a, b) => a.name.localeCompare(b.name));
		  overseers = overseers.filter(e => !e.deletedAt || e.id === this.formState.overseerUserId);

        this.$set(this.form.overseerUserId, 'options', overseers);

        if (overseers.length === 1 && !this.formState.overseerUserId) {
          this.formState.overseerUserId = overseers[0].id;
        }

        await this.getLogisticsCosts();
        this.checkLogisticsCosts(null, oldValue);
      },
      async getLogisticsCosts() {
        this.logisticsCosts = await getHostedUserLogisticsCosts(this.formState.organisedByUserId);
      },
      async getSupervisors() {
        if (this.formState.agencyId !== null) {
          let supervisors = await getAgencySupervisors(this.formState.agencyId);
          supervisors.sort((a, b) => a.name.localeCompare(b.name));
			supervisors = supervisors.filter(e => !e.deletedAt || e.id === this.formState.supervisorUserId);
          this.$set(this.form.supervisorUserId, 'options', supervisors);
        } else {
          this.$set(this.form.supervisorUserId, 'options', []);
        }
      },
    },
  };
</script>

<style scoped></style>
