import http from '@/services/http';

export async function getEventComments(eventId) {
  try {
    const res = await http.get(`/Events/${eventId}/Comment/`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}

export async function createEventComment(eventId, data) {
  try {
    const res = await http.post(`/Events/${eventId}/Comment/`, data);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getComment(commentId) {
  try {
    const res = await http.get(`/Comment/${commentId}`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}

export async function updateComment(commentId, data) {
  try {
    const res = await http.put(`/Comment/${commentId}`, data);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteComment(commentId) {
  try {
    return await http.delete(`/Comment/${commentId}`);
  } catch (e) {
    console.log(e);
  }
}
