<template>
  <div>
    <h3 v-if="isEditable || inventory">{{ $t('eventsForm.inventory.template') }}</h3>
    <ValidationProvider v-slot="{ errors }" name="inventoryFile" :rules="inventoryRules">
      <span v-if="errors[0]" class="help is-warning inventory-table-validation">{{ errors[0] }}</span>
    </ValidationProvider>
    <div class="inventory-upload">
      <form v-if="isEditable" class="inventory-upload__form" @submit.prevent="submit">
        <div
          ref="dropzone"
          class="inventory-dropzone"
          :class="{ 'is-active': dropActive }"
          @dragenter.prevent.stop="onDragEnter"
          @dragleave.prevent.stop="onDragLeave"
          @dragover.prevent.stop="onDragEnter"
          @drop.stop.prevent="onDrop"
          @click="onClick"
        >
          <span class="icon">
            <inline-svg :src="require('../../assets/icons/file.svg')"></inline-svg>
          </span>
        </div>
        <label>
          <input ref="input" type="file" @change="onChange($event)" />
        </label>
        <div>
          <p>
            <span v-if="file && fileName">{{ fileName }}</span
            >&#8203;
          </p>
          <button class="button is-primary" :disabled="!file">
            <span v-if="!uploading" class="icon">
              <inline-svg :src="require('../../assets/icons/cloud-upload.svg')"></inline-svg>
            </span>
            <span v-else class="icon loader"></span>
            <span>{{ $t('eventsForm.inventory.upload') }}</span>
          </button>
        </div>
        <hr />
        <div class="inventory-template">
          <template v-if="template">
            <strong>{{ template.originalName ? template.originalName : template.name }}</strong>

            <span>
              <a class="is-download" @click.prevent="downloadTemplate(template)"
                ><inline-svg :src="require('../../assets/icons/cloud-download.svg')"></inline-svg
              ></a>
            </span>
          </template>
        </div>
      </form>
      <div v-if="inventory" class="inventory-upload__attachments">
        <div>
          <strong>{{ inventory.originalName ? inventory.originalName : inventory.name }}</strong>
          <span>
            <a class="is-download" @click.prevent="downloadFile(inventory)"
              ><inline-svg :src="require('../../assets/icons/cloud-download.svg')"></inline-svg
            ></a>
            <a v-if="isEditable" class="is-delete" @click.prevent="deleteFile(inventory)"
              ><inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg
            ></a>
          </span>
        </div>
        <p v-if="!isEditable && !inventory">{{ $t('eventsForm.inventory.inventoryEmpty') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/services/http';
  import form, { eventFormDefinition } from '@/services/eventForm';
  import { compareStatus, isMandatoryNext, isMandatoryNow, STATUS_CONTROLLING, STATUS_FINISHED, STATUS_REPORTING } from '@/services/status';
  import { isUserAgency, isUserEventManager } from '@/services/roles';
  import { ValidationProvider } from 'vee-validate';

  const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

  export default {
    name: 'InventoryTemplate',
    components: { ValidationProvider },
    data() {
      return {
        form: eventFormDefinition,
        formState: form.state,
        dropActive: false,
        templateFile: [],
        inventory: {},
        file: null,
        fileName: null,
        uploading: false,
      };
    },
    computed: {
      isEditable() {
        if (isUserEventManager() && compareStatus(this.formState.status, '<', STATUS_FINISHED)) {
          return true;
        } else {
          return compareStatus(this.formState.status, '=', [STATUS_REPORTING, STATUS_CONTROLLING]) && (isUserAgency() || isUserEventManager());
        }
      },
      template() {
        if (this.templateFile.length === 0) return false;
        return this.templateFile.slice(-1)[0];
      },
      inventoryRules() {
        const params = { count: this.inventory, min: 1 };
        let rules = {};
        if (this.form.inventories.mandatory === true) {
          rules.minInventoryFile = params;
        }
        if (this.form.inventories.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.formState.status, this.form.inventories.mandatory[0], this.form.inventories.mandatory[1]);
          if (mandatoryNow) rules.minInventoryFile = params;
          let mandatoryNext = isMandatoryNext(this.formState.status, this.form.inventories.mandatory[0], this.form.inventories.mandatory[1]);
          if (!mandatoryNow && mandatoryNext) rules.minInventoryFileIf = { ...params, target: 'proceedSave' };
        }

        return rules;
      },
    },
    async mounted() {
      if (this.formState.id) {
        await this.loadTemplate();
        await this.loadAttachments();
      }
    },
    methods: {
      async loadAttachments() {
        let res = await http.get(`EventAttachment/${this.formState.id}/template`);
        this.inventory = res.data;
      },
      async loadTemplate() {
        let res = await http.get(`TemplateFile`);
        if (res.data?.data?.length > 0) {
          this.templateFile = res.data.data;
        }
      },
      async submit() {
        if (!this.uploading) {
          this.uploading = true;
          await this.fileUpload(this.file);
          window.setTimeout(async () => {
            await this.loadAttachments();
            this.file = null;
            this.fileName = null;
            this.uploading = false;
          }, 1500);
        }
      },
      async fileUpload(file) {
        const formData = new FormData();
        formData.append('file', file);

        http.post(`EventAttachment/${this.formState.id}/upload-template`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
      },
      onDragEnter({ dataTransfer: { types } }) {
        if (!types.includes('Files')) {
          return;
        }
        this.dropActive = true;
      },
      onDragLeave() {
        this.dropActive = false;
      },
      onDrop(e) {
        this.dropActive = false;
        if (e.dataTransfer?.files.length > 0) {
          this.file = e.dataTransfer.files[0];
          this.fileName = this.file.name;
          if (this.file.size > FILE_SIZE_LIMIT) {
            this.file = null;
            this.fileName = null;
          }
        }
      },
      onClick() {
        this.$refs.input.click();
      },
      onChange(e) {
        this.file = e.target.files[0];
        this.fileName = this.file.name;
        if (this.file.size > FILE_SIZE_LIMIT) {
          this.file = null;
          this.fileName = null;
        }
      },
      async downloadTemplate({ name, originalName }) {
        http({
          url: `TemplateFile/download/${name}`,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          const uri = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = uri;
          link.setAttribute('download', originalName ? originalName : name);
          document.body.appendChild(link);
          link.click();
        });
      },
      async downloadFile({ name, originalName }) {
        http({
          url: `EventAttachment/${this.formState.id}/download/${name}`,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          const uri = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = uri;
          link.setAttribute('download', originalName ? originalName : name);
          document.body.appendChild(link);
          link.click();
        });
      },
      async deleteFile(file) {
        let confirmMsg = this.$t('eventsForm.inventory.deleteInventory').replace('%file%', file.originalName ? file.originalName : file.name);

        if (window.confirm(confirmMsg)) {
          await http.delete(`EventAttachment/${this.formState.id}/delete/${file.id}`);
          this.inventory = false;
        }
      },
    },
  };
</script>
