import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Events from '../views/Events.vue';
import Placeholder from '../views/Placeholder.vue';
import auth from '../services/auth';
import EventsForm from '../views/EventsForm.vue';
import Admin from '../views/Admin.vue';
import PageNotFound from '../views/404.vue';
import QualityCheck from '../views/QualityCheck';

import { isUserRedbull, isUserAgency, isUserAdministrator } from '@/services/roles';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      publicOnly: true,
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
  },
  {
    path: '/new-event',
    name: 'NewEvent',
    component: EventsForm,
  },
  {
    path: '/edit-event/:id',
    name: 'EditEvent',
    component: EventsForm,
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Placeholder,
  },
  {
    path: '/quality-check',
    name: 'QualityCheck',
    component: QualityCheck,
  },
  {
    path: '/admin/:tabName?',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active',
});

if (process.env.NODE_ENV === 'development') {
  router.addRoute({
    path: '/ui',
    name: 'Ui',
    component: () => import('../views/Ui.vue'),
    meta: {
      public: true,
    },
  });
}

router.beforeEach((to, from, next) => {
  if (to.params.forbidden === true) {
    next();
  } else if (to.meta.public === true) {
    next();
  } else if (to.meta.publicOnly === true) {
    auth.checkAuth().then(
      () => {
        if (isUserRedbull() || isUserAgency()) {
          next({ name: 'Events' });
        } else if (isUserAdministrator()) {
          next({ name: 'Admin' });
        } else {
          next({ name: 'Login', params: { forbidden: true } });
        }
      },
      () => {
        next();
      }
    );
  } else {
    auth.checkAuth().then(
      () => {
        next();
      },
      () => {
        next('/');
      }
    );
  }
});

export default router;
