<template>
  <div>
    <div class="columns">
      <div class="column is-two-fifths">
        <div class="form">
          <div class="form-wrapper">
            <FormElement name="totalSales" :field="form['totalSales']" />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-three-fifths">
        <div class="tabs inner-tabs">
          <ul>
            <li :class="{ 'is-active': activeCategory === 'all' }">
              <a @click="activeCategory = 'all'">
                {{ $t('eventsForm.inventory.all') }}
              </a>
            </li>
            <li v-for="category in availableInventoryCategories" :key="category.id" :class="{ 'is-active': activeCategory === category.id }">
              <a @click="activeCategory = category.id">
                {{ category.name }}
              </a>
            </li>
          </ul>
        </div>

        <ValidationProvider v-slot="{ errors }" name="inventories" :rules="inventoryRules" class="inventory-table-validation">
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="inventory-wrapper">
          <div v-if="isEditable" class="form-field">
            <div class="field is-horizontal">
              <div class="field-label">
                <label class="label">{{ $t('eventsForm.inventory.add') }}</label>
              </div>
              <div class="field-body">
                <div class="field is-grouped">
                  <div class="control is-expanded">
                    <div class="select">
                      <select v-model="newInventory">
                        <option v-for="option in filteredInventory" :key="option.id" :value="option.id">
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                    <button class="button is-input" :disabled="!newInventory" @click.prevent="addInventory">
                      <span class="icon">
                        <inline-svg :src="require('../../assets/icons/plus-circle.svg')"></inline-svg>
                      </span>
                    </button>
                  </div>
                  <p class="control"></p>
                </div>
              </div>
            </div>
          </div>

          <table class="table inventory-table">
            <thead>
              <tr>
                <th>{{ $t('eventsForm.inventory.name') }}</th>
                <th>{{ $t('eventsForm.inventory.price') }}</th>
                <th>{{ $t('eventsForm.inventory.barSales') }}</th>
                <th class="has-text-right">{{ $t('eventsForm.inventory.sum') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="inventory in selectedInventory" :key="inventory.id">
                <td>
                  <label class="mobile-th">{{ $t('eventsForm.inventory.name') }}</label>
                  {{ inventory.name }}
                </td>
                <td class="is-width-2">
                  <label class="mobile-th">{{ $t('eventsForm.inventory.price') }}</label>
                  <span class="control has-icons-right">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="`inventory.price`"
                      :vid="`inventory-price-${inventory.inventoryId}`"
                      :rules="{ required: true }"
                    >
                      <NumInput v-model.number="inventory.price" class="input" :class="{ 'is-warning': errors[0] }" :readonly="!isEditable" />
                      <span class="icon is-small is-right">
                        {{ formState.currencyCode | formatCurrency }}
                      </span>
                      <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </span>
                </td>
                <td class="is-width-2">
                  <label class="mobile-th">{{ $t('eventsForm.inventory.barSales') }}</label>
                  <span class="control has-icons-right">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="`inventory.amount`"
                      :vid="`inventory-amount-${inventory.inventoryId}`"
                      :rules="{ required: true }"
                    >
                      <NumInput v-model.number="inventory.amount" class="input" :class="{ 'is-warning': errors[0] }" :readonly="!isEditable" />
                      <span class="icon is-small is-right">
                        {{ $t('common.unit.pcs') }}
                      </span>
                      <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </span>
                </td>
                <td class="is-width-0 has-text-right">
                  <label class="mobile-th">{{ $t('eventsForm.inventory.sum') }}</label>
                  <span>{{ (inventory.amount * inventory.price) | formatPrice(formState.currencyCode) }}</span>
                  <a v-if="isEditable" class="table-delete">
                    <span class="icon" @click="deleteInventory(inventory)">
                      <inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
              <tr v-if="selectedInventory.length === 0">
                <td v-if="activeCategory === 'all'" colspan="3" class="has-text-centered">{{ $t('eventsForm.inventory.empty') }}</td>
                <td v-else colspan="3" class="has-text-centered">{{ $t('eventsForm.inventory.categoryEmpty') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="!isCancelled" class="column is-two-fifths">
        <InventoryTemplate />
        <InventoryFiles />
      </div>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import form, { eventFormDefinition } from '@/services/eventForm';
  import { compareStatus, isMandatoryNext, isMandatoryNow, STATUS_FINISHED, STATUS_REPORTING, STATUS_CONTROLLING } from '@/services/status';
  import FormElement from '@/components/EventsForm/FormElement';
  import InventoryFiles from '@/components/EventsForm/InventoryFiles';
  import InventoryTemplate from '@/components/EventsForm/InventoryTemplate';
  import NumInput from '@/components/EventsForm/NumInput';
  import { isUserEventManager } from '@/services/roles';

  export default {
    name: 'Inventory',
    components: { NumInput, InventoryTemplate, InventoryFiles, FormElement, ValidationProvider },
    data() {
      return {
        form: eventFormDefinition,
        formState: form.state,
        formEnums: form.enums,
        activeCategory: 'all',
        newInventory: false,
      };
    },
    computed: {
      availableInventoryCategories() {
        return this.formEnums.inventoryCategories.filter(e => e.active);
      },
      availableInventory() {
        let available = [];
        for (const category of this.availableInventoryCategories) {
          for (const inventory of this.formEnums.inventory.filter(e => e.active && e.categoryId === category.id)) {
            available.push({
              name: inventory.name,
              id: inventory.id,
              categoryId: category.id,
            });
          }
        }
        available = available.filter(e => !this.selectedInventory.some(d => d.inventoryId === e.id));

        return available.sort((a, b) => a.name.localeCompare(b.name));
      },
      inventoryRules() {
        const params = { count: this.formState.inventories.length, min: 1 };
        let rules = {};
        if (this.form.inventories.mandatory === true) {
          rules.minInventory = params;
        }
        if (this.form.inventories.mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(this.formState.status, this.form.inventories.mandatory[0], this.form.inventories.mandatory[1]);
          if (mandatoryNow) rules.minInventory = params;
          let mandatoryNext = isMandatoryNext(this.formState.status, this.form.inventories.mandatory[0], this.form.inventories.mandatory[1]);
          if (!mandatoryNow && mandatoryNext) rules.minInventoryIf = { ...params, target: 'proceedSave' };
        }

        return rules;
      },
      selectedInventory() {
        let selected = this.formState.inventories
          .map(e => {
            let inv = this.formEnums.inventory.find(f => f.id === e.inventoryId);
            e.name = inv.name;
            e.categoryId = inv.categoryId;
            return e;
          })
          .sort((a, b) => a.name.localeCompare(b.name));

        if (this.activeCategory !== 'all') {
          selected = selected.filter(e => e.categoryId === this.activeCategory);
        }

        return selected;
      },
      filteredInventory() {
        if (this.activeCategory !== 'all') {
          return this.availableInventory.filter(e => e.categoryId === this.activeCategory);
        } else {
          return this.availableInventory;
        }
      },
      isEditable() {
        if (isUserEventManager()) {
          return true;
        } else {
          return compareStatus(this.formState.status, '=', [STATUS_REPORTING, STATUS_CONTROLLING]);
        }
      },
      isCancelled() {
        return this.formState.isCancelled;
      },
    },
    watch: {
      'formState.inventories': {
        handler: 'totalSalesSum',
        deep: true,
      },
    },
    methods: {
      addInventory() {
        this.formState.inventories.push({ price: 0, amount: 0, inventoryId: this.newInventory });
        this.newInventory = null;
      },
      deleteInventory(inventory) {
        this.formState.inventories.splice(this.formState.inventories.indexOf(inventory), 1);
      },
      totalSalesSum() {
        let total = 0;
        for (let item of this.formState.inventories) {
          total += item.price * item.amount;
        }
        this.formState.totalSales = total;
      },
    },
  };
</script>
