<template>
  <div v-if="visible" class="last-visited">
    <h3 class="subtitle is-7">{{ $t('sidebar.lastVisited') }}</h3>
    <ul>
      <li v-for="event in state.lastVisits" :key="event.id">
        <router-link :to="{ name: 'EditEvent', params: { id: event.id } }"
          ><span>{{ event.name }}</span
          ><span class="arrow"><inline-svg :src="require('../assets/icons/arrow.svg')"></inline-svg></span
        ></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import { eventVisited } from '@/services/eventVisit';

  export default {
    name: 'LastVisited',
    data() {
      return {
        state: eventVisited.state,
      };
    },
    computed: {
      visible() {
        return this.state.lastVisits.length > 0;
      },
    },
  };
</script>
