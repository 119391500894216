<template>
  <div class="expenses-box">
    <div class="expenses-box__toggle">
      <button class="button is-input" :class="{ 'is-active': toggleStaff }" @click.prevent="toggleStaff = !toggleStaff">
        <span class="icon"><inline-svg :src="require('../../assets/icons/arrow-down.svg')"></inline-svg></span>
      </button>
    </div>
    <div class="expenses-box__title" :class="{ 'is-open': toggleStaff }">
      <h3>{{ title }}</h3>
      <span v-if="!toggleStaff">
        <span>{{ staffDaySubtotal | formatPrice(formState.currencyCode) }}</span>
        <span
          v-if="staffDaySubtotalOther !== null"
          class="report-difference"
          :class="$options.filters.amountDifferenceClass(staffDaySubtotal, staffDaySubtotalOther, showEditablePriceComparison)"
          >{{ staffDaySubtotalOther | amountDifference(staffDaySubtotal, true, formState.currencyCode) }}</span
        >
      </span>
    </div>

    <div v-if="toggleStaff" class="expenses-box__content">
      <div v-if="canAddNewStaff && availableStaffTypes.length > 0" class="form-field">
        <div class="field is-horizontal">
          <div class="field-label">
            <label class="label">{{ $t('eventsForm.expenses.staff.add') }}</label>
          </div>
          <div class="field-body">
            <div class="field is-grouped">
              <div class="control is-expanded">
                <div class="select">
                  <select v-model="newStaff">
                    <option v-for="option in availableStaffTypes" :key="option.id" :value="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <button class="button is-input" :disabled="!newStaff" @click.prevent="addStaff(day)">
                  <span class="icon">
                    <inline-svg :src="require('../../assets/icons/plus-circle.svg')"></inline-svg>
                  </span>
                </button>
              </div>
              <p class="control"></p>
            </div>
          </div>
        </div>
      </div>

      <table class="table staff-table">
        <thead class="is-hidden-touch">
          <tr>
            <th>{{ $t('eventsForm.expenses.staff.table.item') }}</th>
            <th>{{ $t('eventsForm.expenses.staff.table.count') }}</th>
            <th>{{ $t('eventsForm.expenses.staff.table.hours') }}</th>
            <th>{{ $t('eventsForm.expenses.staff.table.wage') }}</th>
            <th class="has-text-right">{{ $t('eventsForm.expenses.staff.table.total') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(staff, index) in assignedStaffForDay" :key="index">
            <td>
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.item') }}</label>
              {{ getStaff(staff.staffTypeId).name }}
            </td>
            <td v-if="!isReported" class="is-width-2">
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.count') }}</label>
              <div class="field has-addons is-numeric">
                <p class="control">
                  <button class="button addon-button-left" :disabled="!canChangeAmount(staff) || !isEditable" @click.prevent="decreaseAmount(staff)">
                    <inline-svg :src="require('../../assets/icons/minus.svg')"></inline-svg>
                  </button>
                </p>
                <div class="control">
                  <p class="control is-expanded">
                    <NumInputComp
                      v-model.number="staff[`${visibleVariant}Amount`]"
                      :comparison="showDisabledPriceComparison ? staff.initialAmount : showEditablePriceComparison ? staff.expectedAmount : undefined"
                      :inverted="showEditablePriceComparison"
                      class="has-arrows-hidden"
                      min="0"
                      :max="visibleVariant === 'real' && isUserAgency() && staff.expectedAmount"
                      :readonly="!isEditable"
                      @change="updateStaff(staff, 'amount')"
                    />
                  </p>
                </div>
                <p class="control">
                  <button class="button addon-button-right" :disabled="!canChangeAmount(staff, true) || !isEditable" @click.prevent="increaseAmount(staff)">
                    <inline-svg :src="require('../../assets/icons/plus.svg')"></inline-svg>
                  </button>
                </p>
              </div>
            </td>
            <td v-if="isReported" class="is-width-2">
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.count') }}</label>
              <span class="report-field">
                <span>
                  {{ staff.realAmount }}
                  <span class="report-difference"> {{ staff.expectedAmount | amountDifference(staff.realAmount, true) }}</span>
                </span>
              </span>
            </td>
            <td v-if="!isReported" class="is-width-1">
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.hours') }}</label>
              <div v-if="getStaff(staff.staffTypeId).unitRequired" class="field has-addons is-numeric">
                <p class="control has-icons-right">
                  <NumInputComp
                    v-model.number="staff[`${visibleVariant}Units`]"
                    :comparison="showDisabledPriceComparison ? staff.initialUnits : showEditablePriceComparison ? staff.expectedUnits : undefined"
                    :inverted="showEditablePriceComparison"
                    min="0"
                    :max="visibleVariant === 'real' && isUserAgency() && staff.expectedUnits"
                    :readonly="!isEditable"
                    @change="updateStaff(staff, 'units')"
                  />
                  <span class="icon is-small is-right">
                    {{ $t('common.unit.hour') }}
                  </span>
                </p>
              </div>
            </td>
            <td v-if="isReported" class="is-width-1">
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.hours') }}</label>
              <span v-if="getStaff(staff.staffTypeId).unitRequired" class="report-field">
                <span>
                  {{ staff.realUnits }}
                  <span class="report-difference"> {{ staff.expectedUnits | amountDifference(staff.realUnits, true) }}</span>
                </span>
              </span>
            </td>

            <td class="is-width-2">
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.wage') }}</label>
              <p class="control has-icons-right">
                <NumInputComp
                  v-model.number="staff[`${visibleVariant}UnitCost`]"
                  :comparison="showDisabledPriceComparison ? staff.initialUnitCost : showEditablePriceComparison ? staff.expectedUnitCost : undefined"
                  :inverted="showEditablePriceComparison"
                  :readonly="!isEditable || visibleVariant === 'real' || isUserAgency()"
                  @change="updateStaff(staff)"
                />
                <span class="icon is-small is-right">
                  {{ formState.currencyCode | formatCurrency }}
                </span>
              </p>
            </td>
            <td class="is-width-1 has-text-right">
              <label class="mobile-th">{{ $t('eventsForm.expenses.staff.table.total') }}</label>
              <strong>{{
                (staff[`${visibleVariant}Amount`] * staff[`${visibleVariant}Units`] * staff[`${visibleVariant}UnitCost`]) | formatPrice(formState.currencyCode)
              }}</strong>
              <a v-if="isStaffDeletable(staff)" class="table-delete">
                <span class="icon" @click="deleteStaff(staff)">
                  <inline-svg :src="require('../../assets/icons/trash-can.svg')"></inline-svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-if="!grouped">
            <th colspan="5">
              <div class="level">
                <div class="level-left">{{ $t('eventsForm.expenses.staff.subtotal') }}</div>
                <div class="level-right is-subtotal">
                  <span>{{ staffDaySubtotal | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffDaySubtotalOther !== null"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(staffDaySubtotal, staffDaySubtotalOther, showEditablePriceComparison)"
                    >{{ staffDaySubtotalOther | amountDifference(staffDaySubtotal, true, formState.currencyCode) }}</span
                  >
                </div>
              </div>
            </th>
          </tr>
          <tr v-if="grouped" class="is-subtotal-day">
            <th colspan="5">
              <div class="level">
                <div class="level-left">{{ $t('eventsForm.expenses.staff.subtotalDay') }}</div>
                <div class="level-right is-subtotal-day">
                  <span>{{ staffDaySubtotal | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffDaySubtotalOther !== null"
                    class="report-difference"
                    :class="$options.filters.amountDifferenceClass(staffDaySubtotal, staffDaySubtotalOther, showEditablePriceComparison)"
                    >{{ staffDaySubtotalOther | amountDifference(staffDaySubtotal, true, formState.currencyCode) }}</span
                  >
                </div>
              </div>
            </th>
          </tr>
          <tr v-if="grouped">
            <th colspan="5">
              <div class="level">
                <div class="level-left">{{ $t('eventsForm.expenses.staff.subtotal') }}</div>
                <div class="level-right is-subtotal">
                  <span>{{ (staffDaySubtotal * dayList.length) | formatPrice(formState.currencyCode) }}</span>
                  <span
                    v-if="staffDaySubtotalOther !== null"
                    class="report-difference"
                    :class="
                      $options.filters.amountDifferenceClass(
                        staffDaySubtotal * dayList.length,
                        staffDaySubtotalOther * dayList.length,
                        showEditablePriceComparison
                      )
                    "
                    >{{ (staffDaySubtotalOther * dayList.length) | amountDifference(staffDaySubtotal * dayList.length, true, formState.currencyCode) }}</span
                  >
                </div>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  import form, { EDITOR_AGENCY, EDITOR_AUTHORITY, eventFormDefinition } from '@/services/eventForm';
  import {
    compareStatus,
    STATUS_CONTROLLING,
    STATUS_COST_ESTIMATION,
    STATUS_FINISHED,
    STATUS_PLANNING,
    STATUS_REPORTING,
    STATUS_MUSKETEER_CHECK,
  } from '@/services/status';
  import { isUserRedbull, isUserAgency, isUserEventManager } from '@/services/roles';
  import NumInputComp from '@/components/EventsForm/NumInputComp';

  export default {
    name: 'ExpensesStaffBox',
    components: { NumInputComp },
    props: {
      title: {
        type: String,
        required: true,
      },
      day: {
        type: String,
        required: true,
      },
      grouped: {
        type: Boolean,
        default: false,
      },
      dayList: {
        type: Array,
      },
    },
    data() {
      return {
        form: eventFormDefinition,
        formEnums: form.enums,
        formState: form.state,
        toggleStaff: false,
        newStaff: null,
      };
    },
    computed: {
      availableStaffTypes() {
        let init = this.formEnums.staffTypes;

        if (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_COST_ESTIMATION)) {
          init = init.filter(e => e.agencyCostEstimation === true);
        }

        if (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_REPORTING)) {
          init = init.filter(e => e.agencyReporting === true);
        }

        init = init.filter(e => e.type === 'Scheduled');

        return init;
      },
      assignedStaffForDay() {
        return this.formState.assignedStaff.filter(e => e.eventDate === this.day);
      },
      staffDaySubtotal() {
        if (!this.formState.assignedStaff) return null;
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff.filter(e => e.eventDate === this.day)) {
          subtotal += staff[`${this.visibleVariant}Amount`] * staff[`${this.visibleVariant}Units`] * staff[`${this.visibleVariant}UnitCost`];
        }
        return subtotal;
      },
      staffDaySubtotalOther() {
        if (this.formState.status !== STATUS_PLANNING && this.formState.status !== STATUS_MUSKETEER_CHECK) return null;
        if (!this.formState.assignedStaff) return null;
        if (!this.shouldHaveInitialComparisonStaff) return null;
        let otherVariant = this.formState.status === STATUS_PLANNING ? 'expected' : 'initial';
        let subtotal = 0;
        for (let staff of this.formState.assignedStaff.filter(e => e.eventDate === this.day)) {
          subtotal += staff[`${otherVariant}Amount`] * staff[`${otherVariant}Units`] * staff[`${otherVariant}UnitCost`];
        }
        return subtotal;
      },
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.assignedStaff.initial.visible[0], this.form.assignedStaff.initial.visible[1])) {
          return 'initial';
        } else if (compareStatus(this.formState.status, this.form.assignedStaff.expected.visible[0], this.form.assignedStaff.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.assignedStaff.real.visible[0], this.form.assignedStaff.real.visible[1])) {
          return 'real';
        }
        return false;
      },
      isEditable() {
        let editable = false;

        if ('editable' in this.form.assignedStaff[this.visibleVariant]) {
          editable = compareStatus(
            this.formState.status,
            this.form.assignedStaff[this.visibleVariant].editable[0],
            this.form.assignedStaff[this.visibleVariant].editable[1]
          );
        } else {
          editable = true;
        }

        if ('editor' in this.form.assignedStaff[this.visibleVariant] && editable) {
          if (
            this.form.assignedStaff[this.visibleVariant].editor.includes(EDITOR_AGENCY) &&
            !this.form.assignedStaff[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)
          ) {
            return isUserAgency() || isUserEventManager();
          }
          if (
            !this.form.assignedStaff[this.visibleVariant].editor.includes(EDITOR_AGENCY) &&
            this.form.assignedStaff[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)
          ) {
            return isUserRedbull();
          }
        }

        if (isUserEventManager()) {
          return true;
        }

        return editable;
      },
      isReported() {
        return compareStatus(this.formState.status, '>', STATUS_CONTROLLING);
      },
      canAddNewStaff() {
        return (
          (compareStatus(this.formState.status, '<=', STATUS_PLANNING) && this.visibleVariant === 'initial') ||
          (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_COST_ESTIMATION)) ||
          (isUserAgency() && compareStatus(this.formState.status, '=', STATUS_REPORTING))
        );
      },
      showDisabledPriceComparison() {
        return compareStatus(this.formState.status, '=', STATUS_MUSKETEER_CHECK);
      },
      showEditablePriceComparison() {
        return this.shouldHaveInitialComparison && compareStatus(this.formState.status, '=', STATUS_PLANNING);
      },
      shouldHaveInitialComparison() {
        let a = this.formState.assignedStaff.find(e => e.initialUnitCost !== null && e.expectedUnitCost !== null);
        let b = this.formState.extraCosts.find(e => e.initialAmount !== null && e.expectedAmount !== null && e.expectedAmount !== 0);
        return typeof a !== 'undefined' && typeof b !== 'undefined';
      },
      shouldHaveInitialComparisonStaff() {
        let a = this.formState.assignedStaff.find(e => e.initialUnitCost !== null && e.expectedUnitCost !== null);
        return typeof a !== 'undefined';
      },
    },
    methods: {
      getStaff(id) {
        return this.formEnums.staffTypes.find(e => e.id === id);
      },
      staffIsMonkey(id) {
        let staff = this.formEnums.staffTypes.find(e => e.id === id);
        return !!(staff && staff.unitRequired === true);
      },
      addStaff() {
        let newStaff = this.newStaff;
        this.newStaff = '';
        let staffWage = 0;
        let actualDistrictGroup = this.formEnums.districts.find(e => e.code === this.formState.districtCode);
        if (!actualDistrictGroup) actualDistrictGroup = { districtGroupId: this.formEnums.districtGroups[0].id };
        let dayList = this.grouped ? this.dayList : [this.day];

        let wages = this.formEnums.wages.filter(
          e =>
            e.active === true &&
            e.staffTypeId === newStaff &&
            e.wageTypeId === this.formState.wageTypeId &&
            e.sizeId === this.formState.sizeId &&
            e.districtGroupId === actualDistrictGroup.districtGroupId &&
            e.currencyCode === this.formState.currencyCode
        );

        if (wages.length >= 1) {
          staffWage = wages[0].cost;
        }

        for (let day of dayList) {
          if (this.visibleVariant === 'initial') {
            this.formState.assignedStaff.push({
              initialAmount: 1,
              initialUnits: 1,
              initialUnitCost: staffWage,
              expectedAmount: null,
              expectedUnits: null,
              expectedUnitCost: null,
              realAmount: null,
              realUnits: null,
              realUnitCost: null,
              eventDate: day,
              staffTypeId: newStaff,
            });
          } else if (this.visibleVariant === 'expected') {
            this.formState.assignedStaff.push({
              initialAmount: 0,
              initialUnits: 0,
              initialUnitCost: 0,
              expectedAmount: 1,
              expectedUnits: 1,
              expectedUnitCost: staffWage,
              realAmount: null,
              realUnits: null,
              realUnitCost: null,
              eventDate: day,
              staffTypeId: newStaff,
            });
          } else if (this.visibleVariant === 'real') {
            this.formState.assignedStaff.push({
              initialAmount: 0,
              initialUnits: 0,
              initialUnitCost: 0,
              expectedAmount: 0,
              expectedUnits: 0,
              expectedUnitCost: 0,
              realAmount: 1,
              realUnits: 1,
              realUnitCost: staffWage,
              eventDate: day,
              staffTypeId: newStaff,
            });
          }
        }
      },
      updateStaff(staff, field) {
        if (this.grouped) {
          if (this.grouped) this.$emit('change');
        }
      },
      isStaffDeletable(staff) {
        let retVal = this.visibleVariant === 'initial';

        if (
          (isUserAgency() && !staff.id && compareStatus(this.formState.status, '=', STATUS_COST_ESTIMATION)) ||
          compareStatus(this.formState.status, '=', STATUS_REPORTING)
        ) {
          retVal = true;
        }

        if (isUserAgency() && staff.id) {
          retVal = false;
        }

        return retVal;
      },
      deleteStaff(staff) {
        if (this.grouped) {
          let staffTypeId = staff.staffTypeId;
          for (let day of this.dayList) {
            if (!isUserAgency()) {
              // bad hack for bad solution
              this.formState.assignedStaff.splice(
                this.formState.assignedStaff.findIndex(e => e.staffTypeId === staffTypeId && e.eventDate === day),
                1
              );
            } else {
              this.formState.assignedStaff.splice(
                this.formState.assignedStaff.findIndex(e => e.staffTypeId === staffTypeId && e.eventDate === day && !e.id),
                1
              );
            }
          }
        } else {
          this.formState.assignedStaff.splice(this.formState.assignedStaff.indexOf(staff), 1);
        }
      },
      decreaseAmount(staff) {
        if (this.canChangeAmount(staff)) {
          staff[`${this.visibleVariant}Amount`]--;
          this.updateStaff(staff);
        }
      },
      increaseAmount(staff) {
        if (this.canChangeAmount(staff, true)) {
          staff[`${this.visibleVariant}Amount`]++;
          this.updateStaff(staff);
        }
      },
      canChangeAmount(staff, increase) {
        if (!increase && staff[`${this.visibleVariant}Amount`] <= 0) return false;
        if (this.visibleVariant === 'initial' && isUserRedbull()) return true;
        if (this.visibleVariant === 'expected' && isUserAgency()) return true;
        if (this.visibleVariant === 'real' && isUserEventManager()) return true;
        if (this.visibleVariant === 'real' && !staff.id && isUserAgency()) return true;
        if (this.visibleVariant === 'real' && isUserAgency()) return true;
        return false;
      },
      isUserAgency: isUserAgency,
    },
  };
</script>
