<template>
  <nav class="sidebar">
    <div class="is-hidden-touch">
      <img class="sidebar__logo" src="../assets/logo.svg" />
      <hr />
      <UserBox />
      <hr />
      <MainMenu />
      <hr />
      <LastVisited />
    </div>
    <div class="sidebar__mobile is-hidden-desktop">
      <img class="sidebar__logo" src="../assets/logo.svg" @click="goHome" />
      <UserBox />
      <button class="menu-toggle" :class="{ 'is-active': menu }" @click="menu = !menu">Menu</button>
      <div class="mobile-menu" :class="{ 'is-active': menu }">
        <MainMenu @clicked="menu = false" />
      </div>
      <div class="mobile-menu-overlay" :class="{ 'is-active': menu }" @click="menu = !menu"></div>
    </div>
  </nav>
</template>

<script>
  import UserBox from '../components/UserBox';
  import MainMenu from '../components/MainMenu';
  import LastVisited from '../components/LastVisited';
  import { isUserAdministrator, isUserAgency, isUserRedbull } from '@/services/roles';

  export default {
    name: 'SideBar',
    components: { LastVisited, MainMenu, UserBox },
    data() {
      return {
        menu: false,
      };
    },
    methods: {
      goHome() {
        if (isUserRedbull() || isUserAgency()) {
          this.$router.push({ name: 'Events' });
        } else if (isUserAdministrator()) {
          this.$router.push({ name: 'Admin' });
        }
      },
    },
  };
</script>
