import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getAllSubordinates() {
  try {
    const res = await http.get('/Hierarchy/all/subordinates');
    return res.data.data;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateSubordinate(id, data) {
  try {
    const res = await http.put(`/Hierarchy/${id}/subordinates`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
