import http from '@/services/http';

//-------------------- GETS ----------------------

export async function getActivities(filter) {
  try {
    let res = await http.get('/Activity');
    if (filter) {
      return res.data.data.filter(item => item[filter]);
    } else {
      return res.data.data;
    }
  } catch (e) {
    console.log(e);
  }
}

//-------------------- PUTS ----------------------

export async function updateActivity(id, data) {
  try {
    const res = await http.put(`/Activity/${id}`, data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

//-------------------- POSTS ----------------------

export async function createNewActivity(data) {
  try {
    const res = await http.post('Activity', data);
    return res;
  } catch (e) {
    console.log(e);
  }
}
