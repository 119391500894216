<template>
  <div class="home">
    <div v-if="forbidden" class="notification is-danger">
      {{ forbiddenMsg }}
    </div>
    <img alt="Quanti Planner" src="../assets/logo.svg"/>
    <h1>Quanti Planner</h1>
    <hr />
    <button v-if="!forbidden" class="button is-primary" @click="login">{{ $t('common.login') }}</button>
    <button v-if="forbidden" class="button is-primary" @click="logout">{{ $t('common.logOut') }}</button>
  </div>
</template>

<script>
  import auth from '../services/auth';

  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        forbidden: false,
        authState: auth.state,
      };
    },
    computed: {
      forbiddenMsg() {
        return this.$t('common.forbidden').replace('%account%', this.authState.email ? `(${this.authState.email})` : '');
      },
    },
    mounted() {
      if (this.$route.params.forbidden) {
        this.forbidden = true;
      }
    },
    methods: {
      login() {
        auth.login();
      },
      logout() {
        auth.logout();
      },
    },
  };
</script>
