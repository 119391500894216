<template>
  <tr v-if="visibleVariant">
    <td class="is-width-3">
      <label class="label has-text-left" :class="{ 'is-required': validationRule.required }">
        {{ fee.name }}
      </label>
      <span v-if="mandatoryNext" class="icon is-mandatory">
        <inline-svg :src="require(`../../assets/icons/task.svg`)"></inline-svg>
      </span>
    </td>
    <td v-for="category in availableCategories" :key="category.id" class="is-width-3">
      <p class="control is-expanded has-icons-right">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`fees.${visibleVariant}Amount`"
          :vid="`fees${visibleVariant}Amount-${fee.id}-${category.id}`"
          :rules="validationRule"
        >
          <NumInput
            v-model.number="getFee(fee.id, category.id)[`${visibleVariant}Amount`]"
            class="input"
            :class="{ 'is-warning': errors[0] }"
            :readonly="!inputEditability"
          />
          <span class="icon is-small is-right">
            {{ formState.currencyCode | formatCurrency }}
          </span>
          <span v-if="errors[0]" class="help is-warning">{{ errors[0] }}</span>
        </ValidationProvider>
      </p>
    </td>
  </tr>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import form, { EDITOR_AGENCY, EDITOR_AUTHORITY, eventFormDefinition } from '@/services/eventForm';
  import { isMandatoryNow, isMandatoryNext, compareStatus, STATUS_FINISHED } from '@/services/status';
  import { isUserAgency, isUserEventManager, isUserRedbull } from '@/services/roles';
  import NumInput from '@/components/EventsForm/NumInput';
  export default {
    name: 'ExpensesBudgetInput',
    components: { NumInput, ValidationProvider },
    props: {
      fee: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        form: eventFormDefinition,
        formEnums: form.enums,
        formState: form.state,
      };
    },
    computed: {
      availableCategories() {
        return this.formEnums.productCategories.filter(e => e.active);
      },
      validationRule() {
        let rules = {};
        if (this.form.fees[this.visibleVariant].mandatory instanceof Array) {
          let mandatoryNow = isMandatoryNow(
            this.formState.status,
            this.form.fees[this.visibleVariant].mandatory[0],
            this.form.fees[this.visibleVariant].mandatory[1]
          );
          if (this.fee.displayedProminently && mandatoryNow) rules.required = true;

          let mandatoryNext = isMandatoryNext(
            this.formState.status,
            this.form.fees[this.visibleVariant].mandatory[0],
            this.form.fees[this.visibleVariant].mandatory[1]
          );
          if (this.fee.displayedProminently && mandatoryNext) rules.required_if = 'proceedSave';
        }
        return rules;
      },
      mandatoryNext() {
        if (this.form.fees[this.visibleVariant].mandatory instanceof Array) {
          if (!isMandatoryNow(this.formState.status, this.form.fees[this.visibleVariant].mandatory[0], this.form.fees[this.visibleVariant].mandatory[1])) {
            return (
              this.fee.displayedProminently &&
              isMandatoryNext(this.formState.status, this.form.fees[this.visibleVariant].mandatory[0], this.form.fees[this.visibleVariant].mandatory[1])
            );
          }
        }
        return false;
      },
      inputEditability() {
        let editable = false;

        if ('editable' in this.form.fees[this.visibleVariant]) {
          editable = compareStatus(this.formState.status, this.form.fees[this.visibleVariant].editable[0], this.form.fees[this.visibleVariant].editable[1]);
        } else {
          editable = true;
        }

        if ('editor' in this.form.fees[this.visibleVariant] && editable) {
          if (this.form.fees[this.visibleVariant].editor.includes(EDITOR_AGENCY) && !this.form.fees[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)) {
            editable = isUserAgency() || isUserEventManager();
          }
          if (!this.form.fees[this.visibleVariant].editor.includes(EDITOR_AGENCY) && this.form.fees[this.visibleVariant].editor.includes(EDITOR_AUTHORITY)) {
            editable = isUserRedbull();
          }
        }

        if (editable && this.form.status === STATUS_FINISHED) {
          editable = false;
        }

        // event manager override
        if (isUserEventManager()) {
          editable = true;
        }

        return editable;
      },
      visibleVariant() {
        if (compareStatus(this.formState.status, this.form.fees.expected.visible[0], this.form.fees.expected.visible[1])) {
          return 'expected';
        } else if (compareStatus(this.formState.status, this.form.fees.real.visible[0], this.form.fees.real.visible[1])) {
          return 'real';
        }
        return false;
      },
    },
    methods: {
      getFee(typeId, productCategoryId) {
        return this.formState.fees.find(e => e.typeId === typeId && e.productCategoryId === productCategoryId);
      },
    },
  };
</script>
